<template>
  <el-dialog
    :visible.sync="isShow"
    width="480px"
    custom-class="shareDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left"><span>{{ checkedFiles.length > 1 ? 
        '分享多个文件':'分享文件' }}</span></div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content">
        <div class="warn-box">
          <svg-btn icon-class="icon-tips-circle-fill" />
          <span>严禁上传或分享反动、暴力、色情、违法及侵权内容的文件。</span>
        </div>
        <div class="file-info" v-if="checkedFiles.length <=1">
          <img class="icon" :src="handleItem.ext_icon" />
          <div class="info">
            <span class="name"
              >{{ handleItem.name }}{{ handleItem.extension }}</span
            >
            <div>
              <span class="size">文件大小：{{ handleItem.size }}</span>
              <span>分享链接长期有效</span>
            </div>
          </div>
        </div>
        <div class="shareUrl-box income-box" style="margin-top: 16px;">
          <span class="title">收益模式</span>
          <div class="income-conent" v-if="isShow">
            <el-select
              v-model="incomeValue"
              popper-class="share_select"
              placeholder=""
              @change="incomeModeChange"
            >
              <el-option
                v-for="item in incomeList"
                :key="item.key"
                :label="item.value"
                :style="
                  item.disabled == '2'
                    ? 'cursor: pointer'
                    : item.disabled == '1'
                    ? 'cursor: pointer '
                    : ''
                "
                :value="item"
                :disabled="item.disabled == 1 || item.disabled == 2"
              >
                <div class="share_select_box" @click.prevent="toDaren(item)">
                  <div
                    class="income-name"
                    :class="item.disabled == '0' ? '' : 'income-name1'"
                  >
                    {{ item.value }}
                  </div>
                  <div class="income-right">
                    <div
                      class="income-intr"
                      v-if="item.info"
                      :class="
                        item.disabled == '1'
                          ? 'income-daren0'
                          : item.disabled == '0' || item.disabled == '2'
                          ? 'income-daren'
                          : ''
                      "
                    >
                      <img
                        v-if="item.disabled == '1'"
                        style="width: 14px; height: 14px; margin-right: 2px"
                        :src="$utils.getPng('web4/icon_mom')"
                      />
                      {{ item.info }}
                    </div>
                    <div class="income-checkbox">
                      <img
                        class="icon-scheck"
                        v-show="item.key == incomeValue?.key"
                        :src="$utils.getPng('web4/icon-share-check')"
                      />
                    </div>
                  </div>
                </div>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="pucode-box" v-if="boxType == '1'">
          <span class="title" style="margin-bottom: 8px">分享形式</span>
          <div class="share-type">
            <el-select
              v-model="open_pucode"
              placeholder="请选择"
              popper-class="share_select"
              @change="shareTypeChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="s_option">
                  <span>{{ item.label }}</span>
                  <svg-btn
                    icon-class="icon-cloud-check"
                    v-show="open_pucode == item.value"
                  />
                </div>
              </el-option>
            </el-select>
          </div>
          <div v-show="open_pucode == 1">
            <span class="title" style="margin-top: 24px">取件码</span>
            <!-- <div class="line1">
                            <svg-btn :icon-class="'icon-checkbox-'+$store.state.theme"/><span>有取件码</span>
                        </div> -->
            <div class="line2">
              <img
                @click="changeCodeType(1)"
                class="svg-icon"
                :src="
                  $utils.getPng(
                    isSysCode == 1
                      ? 'web4/icon_radio1_' + $store.state.theme
                      : 'web4/icon_radio0_' + $store.state.theme
                  )
                "
              />
              <span>系统随机生成取件码</span>
            </div>
            <div class="line3">
              <img
                @click="changeCodeType(2)"
                class="svg-icon"
                :src="
                  $utils.getPng(
                    isSysCode == 1
                      ? 'web4/icon_radio0_' + $store.state.theme
                      : 'web4/icon_radio1_' + $store.state.theme
                  )
                "
              />
              <el-input
                maxlength="4"
                :disabled="isSysCode == 1"
                v-model="pucode"
                placeholder="请输入4位取件码"
              ></el-input>
            </div>
            <span class="tips">仅支持数字及英文字母</span>
            <div class="line4">
              <div @click="chageAutoSet()">
                <svg-btn
                  :icon-class="
                    auto_set
                      ? 'icon-file-check'
                      : 'icon-file-uncheck-' + $store.state.theme
                  "
                />
              </div>
              <span>分享链接自动填充取件码</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="开启后用户点击链接可自动填充取件码"
                placement="top"
              >
                <svg-btn class="icon-share-1" icon-class="icon-share-1" />
              </el-tooltip>
            </div>
          </div>
        </div>

        <!-- 分享链接 -->
        <div class="shareUrl-box" v-if="boxType == '2'">
          <span class="title">分享链接</span>
          <div class="url-content">
            <el-input
              readonly
              v-model="shareUrl"
              placeholder=""
              id="shareUrl"
            ></el-input>
            <fm-button
              class="copy-btn"
              round
              size="medium"
              @click="copyUrl()"
              >{{
                handleItem.good_count == 1 ? "复制链接及取件码" : "复制链接"
              }}</fm-button
            >
          </div>
        </div>
      </div>
      <div class="d-footer" v-show="boxType == '1'">
        <fm-button
          :disabled="btnDisabled"
          @click="btnDisabled ? '' : addShareUrl()"
          type="primary"
          round
          > {{ checkedFiles.length > 1 ? 
        '创建多个链接':'创建链接' }} </fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { saveFileShareAPI } from "@/utils/apiList/share";
export default {
  props: {
    isShow: Boolean,
    handleItem: Object,
    boxType: String, //1 创建链接 2 展示分享链接
    shareUrl: String,
    auto_set: Number,
    isSysCode: Number, // 1 默认系统 生成  2自定义
    // pucode:String,
    open_pucode: String, //0无取件码(公开)，1取件码(私密)
    incomeList: Array,
    checkedFiles: {
      default: [],
      type: Array,
    }
  },
  data() {
    return {
      // auto_set:true,//自动填充0否1是
      // open_pucode:'0',//0无取件码，1取件码
      pucode: "", //填了就是自定义取件码，没填就是随机取件码
      // isSysCode:1// 1 默认系统 生成  2自定义
      options: [
        { value: "1", label: "私密链接，需要取件码才可访问分享内容" },
        { value: "0", label: "公开链接，不需要密码即可访问分享内容" },
      ],
      regs: {
        pucode: /^[a-z\d]{4}$/i,
      },
      incomeValue: {},
      isShuaxin: false,
    };
  },

  computed: {
    btnDisabled: function () {
      let state = false;
      if (this.isSysCode == 2 && this.open_pucode == 1) {
        state = !this.regs.pucode.test(this.pucode);
      }
      return state;
    },
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.isShuaxin = false;
        var arr = this.incomeList.find((item) => item.select == 1);
        this.incomeValue = arr;
      }
    },
  },

  methods: {
    handleClose() {
      this.$parent.boxType = "-1";
      this.pucode = "";
      if (this.isShuaxin) { 
         this.$store.commit("getfiles");
      }
      this.$emit("handleClose");
    },
    changeCodeType(type) {
      this.isSysCode = type;
      this.$parent.isSysCode = type;
      if (type == 1) {
        this.pucode = "";
      }
    },
    addShareUrl() {
      if (this.isSysCode == 2 && this.open_pucode == "1") {
        if (!this.regs.pucode.test(this.pucode)) {
          this.$toast("仅可输入4位数字或字母", 2);
          return;
        }
      }
      
      let params = {
        file_id: this.checkedFiles.length > 1 ?this.checkedFiles.toString() : this.handleItem.id,
        open_pucode: this.open_pucode,
        pucode: this.open_pucode == "0" ? "" : this.pucode,
        auto_set: this.open_pucode == "0" ? "" : this.auto_set ? 1 : 0,
        rebate_model: this.incomeValue.key,
      };
      this.$emit("setShare", params);
    },
    copyUrl() {
      let msg =
        this.handleItem.good_count == 1
          ? "复制链接及取件码成功"
          : "复制链接成功";
      this.$copyText(this.shareUrl).then(
        (e) => {
          this.$toast(msg, 1);
        },
        function (e) {}
      );
    },
    shareTypeChange(val) {
      this.$parent.open_pucode = val;
    },
    incomeModeChange(item) {
      this.incomeValue = item;
      if (this.boxType == 2) {
        saveFileShareAPI({
          file_id:this.checkedFiles.length > 1 ?this.checkedFiles.toString() : this.handleItem.id,
          rebate_model: this.incomeValue.key,
        }).then((res) => {
          if (res.status == "1") {
            this.$toast(res.msg, 1);
            this.isShuaxin = true;
          } else {
            this.$toast(res.msg, 2);
          }
        });
      }
    },
    toDaren(item) {
      if (item.disabled == 1) {
        window.open(`${this.$glb.goLLZ}`, "_blank");
        return;
      }
      if (item.disabled == 2) {
        this.$toast('所选文件中存在小于100M文件，不可选择该模式', 2)
         return;
      }
    },
    chageAutoSet() {
      this.auto_set = !this.auto_set;
      this.$parent.auto_set = this.auto_set;
    },
  },
};
</script>

<style lang="scss">
.share_select {
  .is-disabled:hover {
     @include useTheme{
            background:getVar('text-color3-ffffff');
        }
  }
}
.share_select_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
</style>
