<template>
  <div class="otherStatus" :class="{'mt':status==4}">
    <span class="title">{{ $t('downPage.fileStatus') }}</span>
    <div class="box">
        <div class="desc">
            <svg-btn :icon-class="status==3 ? 'filestatus-icon2':'filestatus-icon1'"/>
            <span v-if="status==4">
                {{ $t('downPage.timeOut') }}，<a @click="goVip">{{ $t('vip.vip') }}</a> {{ $t('downPage.downloadNow') }}
            </span>
            <span v-else>{{ status==2 ? $t('downPage.fileDelete'):$t('downPage.isprivateFile') }}</span>
        </div>
        <div class="gohelp" v-show="status==2">
            <span>{{ $t('downPage.reference') }}：</span><a @click="goHelp(98)">{{ $t('agreement.downPageDisclosureOfContentReviewMechanism') }}</a>
            <span class="first">{{ $t('downPage.question') }}：</span><a @click="goHelp(0)">{{ $t('agreement.helpCenter') }}</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        status:{
            type:Number,
            default:2
        }
    },
    methods:{
        goVip(){
            window.open('/vip')
            this.$emit('oNherStatus')
        },
        goHelp(type){
            if(type==0){
               window.open('/help')
            }else{
                 window.open(`${this.$glb.fmUrl}/askdetail/98`);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .otherStatus{
        .title{
            margin-top: 0;
        }
        .box{
            margin-top:20px ;
            height: 160px;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include useTheme{
                border:getVar('border-color-e5e5e5')
            }
            .desc{
                display: flex;
                align-items: center;
                span{
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32px;
                    a{
                        color: #F67942;
                        // text-decoration: underline;
                        border-bottom: 2px solid #F67942;
                        cursor: pointer;
                    }
                }
                .svg-icon{
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                }
            }
            .gohelp{
                margin-top: 22px;
                span{
                    @include useTheme{
                        color:getVar('text-color1-8a8b92')
                    }
                }
                a{
                    color: #F67942;
                    cursor: pointer;
                }
                .first{
                    margin-left: 58px;
                }
            }
        }
    }
    .mt{
        margin-bottom: 40px;
    }
</style>