export default class XlsxWorker {
  constructor(opt = {
    src: '',
    ext: '',
    callback: () => {}
  }) {
    this.workerUrl = './xlsxWorker.js?v=2023112212'
    this.src = opt.src
    this.ext = opt.ext
    this.callback = opt.callback

    this.xlsxWorker = new Worker(this.workerUrl)
    this.xlsxWorker.onmessage = (ev) => {
      this.callback({
        action: 'msg',
        code: 1,
        data: ev.data
      })
    }
    this.xlsxWorker.onerror = (err) => {
      this.callback({
        action: 'msg',
        code: 0,
        data: err
      })
    }
    this.sendMessage()
  }

  reload(opt) {
    this.src = opt.src
    this.ext = opt.ext

    this.sendMessage('clearUrl')
  }
  sendMessage(type) {
    this.xlsxWorker.postMessage({
      action: 'start',
      data: {
        src: this.src,
        ext: this.ext
      },
      type
    })
  }
  clearUrl() {
    this.xlsxWorker.postMessage({
      action: 'clearUrl'
    })
  }
  destroy() {
    this.clearUrl()
    if (this.xlsxWorker) {
      this.xlsxWorker.terminate()
      this.xlsxWorker = null
    }
  }
}
