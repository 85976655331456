<template>
    <svg :class="svgClass" @click="handleClick">
      <use :xlink:href="iconName" :class="iconColor"  ></use>
    </svg>
</template>

<script>
export default {
  name: "svg-icon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
    },
    iconColor: {
      type: String,
      default: "iconColor"
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return "svg-icon " + this.className;
      } else {
        return "svg-icon";
      }
    },
  },
  methods:{
    handleClick (e) {
      this.$emit('click', e)
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 20px;
  height: 20px;
  outline: none;
  flex-shrink: 0;

}
</style>
