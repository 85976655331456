<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="supplementInfo"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <span>补全信息</span>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <span class="title">
          依据《网络安全法》及监管要求，账户注销需要绑定手机号
        </span>
        <p class="tips">
          此信息仅作为合规登记与账号安全操作验证使用，不会用于推送营销短信等商业用途
        </p>
        <div class="dc1">
          <el-input v-model="phone" placeholder="请输入手机号" maxlength="11">
            <span style="line-height: 40px;" class="code" slot="prepend">+86</span>
            <svg-btn
              v-show="phone != ''"
              @click="phone = ''"
              slot="suffix"
              icon-class="icon-ipt-clear"
            ></svg-btn>
          </el-input>
        </div>
        <div class="dc2">
          <el-input v-model="ucode" placeholder="请输入验证码">
            <svg-btn
              v-show="ucode != ''"
              @click="ucode = ''"
              slot="suffix"
              icon-class="icon-ipt-clear"
            ></svg-btn>
            <span @click="time == 60 ? getCode() : ''" slot="append">{{
              time == 60 ? "获取验证码" : "已发送(" + time + ")"

            }}</span>
          </el-input>
        </div>
      </div>
      <div class="d-footer">
        <fm-button
          @click="phone == '' || ucode == '' ? '' : confirm()"
          type="primary"
          size="small"
          :disabled="ucode == '' || phone == ''"
          >绑 定</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import VerificationCode from "../Dialog/VerificationCode.vue";
import { bindPhoneSendCode, bindPhoneAPI } from "@/utils/apiList/account";
export default {
  components: {
    VerificationCode,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      phone: "",
      pcode: "+86",
      ucode: "",
      timerA: null,
      time: 60,
    };
  },
  methods: {
    handleClose() {
      this.phone = "";
      this.ucode = "";
      this.time = 60;
      clearInterval(this.timerA);
      this.timerA = null;
      this.$emit("handleClose");
    },
    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    //获取验证码
    getCode(captcha_verify) {
      return new Promise((resolve) => {
        if (this.phone == "") {
          this.$toast("请先输入手机号", 3);
          return;
        }
        var reg_tel =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (this.pcode == "+86" && !reg_tel.test(this.phone)) {
          this.$toast("请正确输入手机号", 3);
          return;
        }
        bindPhoneSendCode({
          phone: this.phone,
          pcode: this.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
            }, 1000);
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            });
            this.$AliCode.handleOpen(this.getCode);
            this.$toast(res.msg, 2);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    confirm() {
      let params = {
        msgid: this.msgid,
        phone: this.phone,
        pcode: this.pcode,
        ucode: this.ucode,
      };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.$emit("handleClose");
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
  },
};
</script>
