<template>
    <el-dialog
      title="提示"
      :visible.sync="isShow" :modal="true"
      width="480px"
      custom-class="fileDirDialog"  :append-to-body="true"  :close-on-click-modal="false">
      <!-- :modal="modal" 遮罩阴影 -->
        <div class="dialog-body">
            <div class="d-header">
                <div class="left">
                    <span>{{type==1? '上传到' :type==2? '解压到':'移动到' }}</span>
                </div>
                <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
            </div>
            <div class="d-content">
                <div class="file-directory" @mouseleave="showboxBreadMove = false">
                    <div class="lessthen4" v-if="moveNav.length < 4">
                        <div class="one" v-for="(item, index) in moveNav" :key="item" @click="toUp(item, index)">
                            <el-tooltip :content="item.name" placement="top">
                                <span class="el-dropdown-link name"> {{ item.name }}</span>
                            </el-tooltip>
                            <svg-btn icon-class="icon-file-direction" v-show="index!==moveNav.length-1"/>
                        </div>
                    </div>
                    <div class="lessthen4 morethen4" v-else>
                        <div class="one" @click="toUp({ name: '全部文件', id: 0 }, 0)">
                            <el-tooltip content="全部文件" placement="top">
                                <span class="el-dropdown-link name"> 全部文件 </span>
                            </el-tooltip>
                            <svg-btn icon-class="icon-file-direction"/>
                        </div>
                        <div class="one" @mousemove="showboxBreadMove = true">...
                            <svg-btn icon-class="icon-file-direction"/>
                        </div>
                        <div class="one" v-for="item in moveNav.slice(moveNav.length - 2,moveNav.length)" :key="item" @click="toUp(item, moveNav.length - 2)">
                            <el-tooltip :content="item.name" placement="top">
                                <span class="el-dropdown-link name">
                                    {{ item.name }}
                                </span>
                            </el-tooltip>
                            <svg-btn icon-class="icon-file-direction"/>
                        </div>
                    </div>
                    <div class="boxBreadMove1" 
                    v-if="showboxBreadMove" @mouseleave="showboxBreadMove = false">
                        <div class="boxOne pointer" v-for="(item, index) in moveNav.slice(1, moveNav.length - 2)" :key="index" @click="toUp(item, index + 1)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="fileList" v-if="list.length > 0">
                    <div class="item" @click="getFolder(item)" v-for="(item,index) in list" :key="index">
                        <img :src="item.ext_icon"/>
                        <span>{{item.name}}</span>
                    </div>
                </div>
                <div class="fileList" v-else>
                    <div class="box">
                        <img class="img" :src="$utils.getPng('web4/file_default')" />
                        <div class="span0">{{type==1? '上传到' :type==2? '解压到':'移动到' }}<div class="span1">{{moveNav[moveNav.length - 1]?.name}}</div>文件夹</div>
                    </div>
                </div>

            </div>
            <div class="d-footer">
                <div class="btnZip" v-if="type==2">
                    <fm-button v-loading="upconfirmLoading" @click="upconfirmLoading ? '' :confirm()" type="primary" size="small">解压到此处</fm-button>

                </div>
                <fm-button @click="confirm" type="primary" size="small" v-else>{{type==1 ?'上传到此处':'移动到此处'}}</fm-button>

                <fm-button @click="handleClose" type="info" plain size="small">取 消</fm-button>
            </div>
        </div>
    </el-dialog>
</template>
  
<script>
export default {
    name:'base',
    props:{
        modal:{
            type: Boolean,
            default: true
        },
        isShow: {
            type: Boolean,
            default: false
        },
        list:{
            type: [Object, Array],
            default: function(){
                return []
            }
        },
        moveNav:{
            type: [Object, Array],
            default: function(){
                return []
            }
        },
        type:{
            type: Number,
            default: 1 //1 移动 2解压 3移动
        },
        upconfirmLoading:{
            type: Boolean,
            default: false
        }
    },  
    data(){
        return{
            showboxBreadMove:false,
        }
    },
    methods:{
        handleClose(){
            this.$emit('handleClose')
        },
        getFolder: _.debounce(
            function (item) {
               this.$emit('getFolder',item)
            },
            500,
            {
                leading: true, //指定在延迟开始前调用
                trailing: false, //指定在延迟结束后调用
            }
        ),
        toUp: _.debounce(
            function (item,index) {
                    this.showboxBreadMove = false;
                    this.$emit('changeNav',item,index) 
            },
            500,
            {
                leading: true, //指定在延迟开始前调用
                trailing: false, //指定在延迟结束后调用
            }
        ),
        confirm(){
            this.$emit('doFileDir')
        }
    }
}
</script>
  
<style lang="scss">
@import "./dialog.scss";
.boxBreadMove1{
    width: 162px;
    box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.06);
    border-radius: 12px;
    position: absolute;
    padding:4px 6px 8px 4px;
    box-sizing: border-box;
    top: 32px;
    display: flex;
    max-height: 250px;
    overflow: hidden;
    overflow-y: scroll;
    flex-direction: column;
    z-index: 1;
    @include useTheme{
        background: getVar("text-color3-ffffff");
        border:  1px solid getVar("text-color1-e5e5e5");;
    }
    .boxOne{
        line-height: 36px;
        width: 150px;
        height: 36px;
        line-height: 36px;
        border-radius: 8px;
        padding-left: 10px;
        margin-top: 4px;
        box-sizing: border-box;
        flex-shrink: 0;
        @include multiline(1);
        &:hover{
            @include useTheme{
                background: getVar("text-color1-f6f6f6");
                color:  getVar('text-color2-2c2c2c');
            }
        }
    }
}
.btnZip{
    .el-loading-mask{
        border-radius: 7.8px;
    }
}
</style>