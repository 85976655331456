import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data){
  if (data?.ak){
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  }else{
    return data
  }
}
function inParameter(data){
  if (data){
    return { sn: ed, jt: utils.secret(data, ak)}
  }else{
    return data
  }
}

//文件分享合集列表
export function shareList(data) {
  return request({
    url: "/disk-service/file/shareGatherList",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//批量取消合集或分享 
export function cancelShare(data) {
  return request({
    url: "/disk-service/file/unShare",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//创建文件合集  
export function addGather(data) {
  return request({
    url: "/disk-service/gather/add",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//修改文件合集 
export function editGather(data) {
  return request({
    url: "/disk-service/gather/edit",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//验证合集取件码
export function checkPucode(data) {
  return request({
    url: "/disk-service/gather/checkPucode",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//获取合集内容
export function gatherInfoList(data) {
  return request({
    url: "/disk-service/gather/gatherInfoList",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//新增/取消收藏合集 
export function setCollect(data) {
  return request({
    url: "/disk-service/gather/setCollect",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 获取分享配置
export function shareSetInfo(data) {
  return request({
    url: "/disk-service/file/shareSetInfo",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 修改文件分享
export function saveFileShareAPI(data) {
  return request({
    url: "/disk-service/file/saveFileShare",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
