<template>
  <div class="app-container">
    <div class="app-box">
      <NavTop style="position: sticky;top: 0;width: 100vw;"></NavTop>
      <div class="app-bottom">
        <div class="submitQuestions">
          <div style="text-align: center;padding-top: 40px;">
            <p style="font-size: 20px;font-weight: 500;margin-bottom: 12px">问题反馈</p>
            <span style="color: #8A8B92;">需要您描述具体问题或上传附件，提交后客服在7个工作日内回复</span>
          </div>
          <div style="padding: 64px 200px;">
            <el-form ref="form" :model="form" label-width="108px" style="margin-right: 100px;">
              <el-form-item>
                <template #label>
                  <span>分类</span>
                  <span style="color: #f53f3f;">*</span>
                </template>
                <el-select v-model="sec_tid" @change="change" placeholder="请选择问题分类" style="width: 100%;">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span>标题</span>
                  <span style="color: #f53f3f;">*</span>
                </template>
                <el-input
                  class="input"
                  :class="!name?'input2':''"
                  :maxlength="30"
                  @change="change"
                  placeholder="请输入标题(30字以内)"
                  v-model="name"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <span>详细描述</span>
                  <span style="color: #f53f3f;">*</span>
                </template>
                <el-input
                  type="textarea"
                  :rows="8"
                  resize="none"
                  @change="change"
                  show-word-limit
                  :maxlength="2000"
                  placeholder="请输入问题描述"
                  v-model="editorContent">
                </el-input>
              </el-form-item>
              <el-form-item label="附件">
                <el-upload
                  class="upload"
                  ref="upload-demo"
                  action=""
                  multiple
                  :limit="limit"
                  :on-exceed="exceed"
                  :before-upload="verify"
                  :file-list="fileList"
                  :show-file-list="false"
                  :http-request="upload"
                >
                  <div style="display: flex;align-items: center;">
                    <el-button type="primary" size="medium" :disabled="fileList.length>=limit" round>点击上传</el-button>
                  </div>
                  <div slot="tip" class="el-upload__tip">
                    <span style="color: #BDBDBE;margin-left: 12px;font-size: 14px;">最多上传{{limit}}个{{ext.join('、')}}文件，且单个文件不超过{{ maxText }}</span>
                  </div>
                </el-upload>
                <FileList :file-list="fileList"/>
              </el-form-item>
            </el-form>
            <div style="text-align: center;margin-top:8px">
              <fm-button class="submit" type="primary" :disabled="disabled" round @click="submit">提交</fm-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog :is-show="dialogVisible" @cancel="cancel" :confirm="confirm" />
  </div>
</template>

<script>
import NavTop from "@/components/NavTop";
import FileList from "./FileList";
import { getCspCategory, commitProductionOrder } from "@/utils/apiList/help";
import Dialog from "./Dialog.vue";
import Upload from "./Upload.vue";

export default {
  mixins: [Upload],
  components: {
    NavTop,
    Dialog,
    FileList,
  },
  data() {
    return {
      editor: null,
      editorContent: "", // 文件描述 富文本
      name: "",
      fileList: [],
      isChange: false,
      confirm: ()=>{},
      article_pic: this.$glb.fileServer + "/upload/articlePic",
      optionsKey: 1, // 联级选择的key
      optionsData: [],
      isSubmit: false,
      options: [],
      dialogVisible: false,
      // 规定联级菜单格式
      optionProps: {
        value: "id",
        label: "name",
        children: "child",
      },
      ext: [],
      maxText: '',
      maxSize: 0,
      limit: 0,
      top_tid: "",
      sec_tid: "",
      newUpType: 8,
    };
  },
  created() {
    this.top_tid = this.$route.query.top_tid;
    this.getCspCategoryFn();
  },
  computed: {
    disabled: function () {
      if (!this.sec_tid) {
        // this.$toast("请选择分类后再提交", 2);
        return true
      }
      if (this.name == "" || this.name.length>30) {
        // this.$toast("标题不能为空，长度30字以内", 2);
        return true
      }
      if (this.editorContent == "") {
        // this.$toast("内容不能为空", 2);
        return true
      }
      return false
    },
  },
  methods: {
    verify(file) {      
      if(file.size>this.maxSize) {
        setTimeout(() => this.$message({
          type: 'warning',
          message: '上传文件大小超过限制',
          customClass: 'warning',
        }), 0)
        return false
      } else if (!this.ext.some(suffix =>file.name.toLowerCase().endsWith('.'+suffix))) {
        setTimeout(() => this.$message({
          type: 'warning',
          message: '上传文件扩展名是不允许的扩展名',
          customClass: 'warning',
        }), 0)
        return false
      } 
    },
    async upload({file}) {
      this.isChange = true
      const item = {file_origin_name: file.name, file_size: file.size, status: 'uploading', path: null}
      this.fileList.push(item)
      const result = await this.asyncUpload(file, this.isVideo(file.name)?14:8);      
      if (result.action === "success") {
        item.path = result.res.data.path;
        item.status = 'success'
      } else {
        item.status = 'fail'
        this.$toast(result.msg||'上传失败')
      }
    },
    getCspCategoryFn() {
      getCspCategory().then((res) => {
        if (res.status == 1) {
          ++this.optionsKey;
          this.optionsData = res.data.top_type.filter((item) => {
            return item.id !== "5";
          });
          this.options = res.data.top_type[0].child
          this.ext = res.data.upload_config.ext.map(suffix => suffix.toLowerCase())
          this.limit = Number(res.data.upload_config.upload_limit||0)
          this.maxSize = Number(res.data.upload_config.max_size||0)
          this.maxText = res.data.upload_config.max_size_format
          if(this.$route.query.sec_tid) {
            this.sec_tid = this.$route.query.sec_tid;
            this.isChange = true
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    submit() {
      if(this.disabled) return
      if(this.fileList.some(item => item.status==='uploading')) return this.$message({
        type: 'warning',
        message: '文件正在上传中，请稍后',
        customClass: 'warning',
      })
      commitProductionOrder({
        top_tid: this.top_tid,
        sec_tid: this.sec_tid,
        title: this.name,
        content: this.editorContent,
        annex: this.fileList
      }).then((res) => {
        if (res.status == 1) {
          this.$toast(res.msg, 1);
          setTimeout(() => {
            this.isSubmit = true
            this.$router.push({ name: 'help' })
          }, 500);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 选择投稿分类
    change() {
      this.isChange = true
    },
    isVideo(val) {
      return [/.mp4$/i, /.flv$/i, /.f4v$/i, /.webm$/i, /.m4v$/i, /.mov$/i, /.3gp$/i, /.3g2$/i, /.rm$/i, /rmvb$/i, /.wmv$/i, /.avi$/i, /.asf$/i, /.mpg$/i, /.mpeg$/i, /.mpe$/i, /.div$/i, /.dv$/i, /.divx$/i, /.vob$/i, /.dat$/i, /.mkv$/i, /.lavf$/i, /.cpk$/i, /.dirac$/i, /.ram$/i, /.qt$/i, /.fli$/i, /.flc$/i, /.mod$/].some(suffix =>suffix.test(val))
    },
    cancel() {
      this.dialogVisible = false
    },
    exceed() {
      this.$message({
        type: 'warning',
        message: `附件数量最多支持上传${this.limit}个`,
        customClass: 'warning',
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isSubmit) next()
    else if (this.isChange) {
      this.confirm = () => next()
      this.dialogVisible = true
    } else next()
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.submit {
  width: 240px;
  font-size: 16px;
  display: inline-block;
}

.el-form {
  .el-button--primary:hover {
    background: #FF9C71 !important;
    border-color: #FF9C71 !important;
  }
  .el-button--primary:active {
    background: #D84F13 !important;
    border-color: #D84F13 !important;
  }
  .el-button--primary.is-disabled {
    background: #F9D3C3 !important;
    border-color: #F9D3C3 !important;
  }
  .upload {
    display: flex;
    align-items: center;
    .el-upload__tip {
      margin-top: 0;
    }
  }
  ::v-deep .el-textarea__inner {
    color: var(--text-color-1);
  }
  .el-form-item {
    margin-bottom: 28px;
  }
  ::v-deep .el-form-item__label {
    padding-right: 24px;
    color: var(--text-color-1);
    font-size: 16px;
  }
}
</style>
<style>
.dark {
  --text-color-1: #ffffffe6;
}
.el-select-dropdown__item:not(.selected) {
  color: var(--text-color-1);
}
.light {
  --text-color-1: #2c2c2c;
}
</style>