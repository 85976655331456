<template>
  <div class="login">
    <div class="navBar">
      <div class="navContent">
        <div class="fmInfo pointer" @click="toHome()">
          <svg-btn class="logo" icon-class="icon-logo0-new"></svg-btn>
          <!-- <img class="logo1" :src="$utils.getPng('web4/icon_fmp')" alt="" /> -->
          <svg-btn class="logo1 pointer" :class="$store.state.language == 'en' ? 'logo1-en':''"  :icon-class="$t('home.FEEMOO')"></svg-btn>
          <svg-btn class="logo2" icon-class="icon-logo-new"></svg-btn>
        </div>
        <div class="fmInfoRight">
          <div class="item" @click="toHome()">{{ $t("login.Home") }}</div>
          <div class="item" @click="tofmp()">{{ $t("login.Clouddrive") }}</div>
          <div class="item" @click="tovip()">{{ $t("login.VIP") }}</div>
        </div>
        <div class="dropDownBox" @mouseenter="showDropFun()" @mouseleave="showDropFun()">
           <div class="titBox" style="color: #2c2c2c;">
              <div class="tit">{{ $store.state.language == 'en'?'English':'简体中文'}}</div>
               <svg-btn class="icon" icon-class="icon-up-close"></svg-btn>
            </div>
            <div class="user2"></div>
            <div class="dropItem">
              <div class="one pointer" @click="changeLanguage(0)" >
                <div class="ontit">English</div>
              </div>
              <div class="one one2 pointer" @click="changeLanguage(1)" >
                <div class="ontit">简体中文</div>
              </div>
            </div>
           </div>
      </div>
    </div>
    <div class="downContent">
      <div class="boxContent" v-show="step < 3 || step == 9">
        <div class="codeBox" v-if="platformname == '飞猫盘' || platformname == '飞猫盘海外版'">
          <div class="ma">
            <div class="mal pointer" v-loading="maLoading">
              <div id="fmstscan"></div>
              <div class="isupdate" v-if="isupdate" @click="dealFmScanf()">
                <img class="imgup" :src="$utils.getPng('web4/update1')" alt="" />
                <div class="titup">{{ $t("login.Clicktorefresh") }}</div>
              </div>
              <div class="isupdate" v-if="sm_succes" @click="dealFmScanf()">
                <img class="imgup" :src="$utils.getPng('web4/iconsucc1')" alt="" />
                <div class="titup">扫码成功</div>
              </div>
            </div>
            <img class="imgr" :src="$utils.getPng('web4/icon_er1')" alt="" />
          </div>
          <div class="allshow" v-show="!smTitle1 && allshowFlag">
            请重新扫描二维码
          </div>
          <div class="allshow" v-show="smTitle1">
            {{ smTitle1 }}
          </div>
          <div class="name_fm">{{ $t("login.ScanQRcodetologin") }}</div>
          <div class="name_intr">
            <div class="intr">
            {{ $t("login.Pleaseuse1") }}<span style="color:#F67942"> {{ $t("login.Pleaseuse2") }}</span> {{ $t("login.Pleaseuse3") }}
            </div>
            <div class="intr">{{ $t("login.Pleaseuse4") }}</div>
          </div>
          <div class="button_home">
            <div class="button_icon pointer" @click="goappdown()">{{ $t("login.DownloadAPP") }}</div>
            <div v-if="pcSwitch == 1" class="button_icon pointer" @click="goappdown()" style="margin-left: 12px">
              {{ $t("login.DownloadDesktop") }}
            </div>
          </div>
        </div>
        <div class="codeBox codeBox2" v-else>
          <div class="name_fm">
            {{ $t("login.ScanQRcodetologin") }}
          </div>
          <div class="name_intr name_intr2">
            <div class="intr">
              请使用<span style="color:#F67942"> 飞猫盘</span> APP
            </div>
            <div class="intr">在页面右上角打开扫一扫</div>
          </div>
          <div class="ma pointer">
            <div class="mal" v-loading="maLoading">
              <div id="fmstscan"></div>
              <div class="isupdate" v-if="isupdate" @click="dealFmScanf()">
                <img class="imgup" :src="$utils.getPng('web4/update')" alt="" />
                <div class="titup">{{ $t("login.Clicktorefresh") }}</div>
              </div>
              <div class="isupdate" v-if="sm_succes" @click="dealFmScanf()">
                <img class="imgup" :src="$utils.getPng('web4/iconsucc')" alt="" />
                <div class="titup">扫描成功</div>
              </div>
            </div>
            <img class="imgr" :src="$utils.getPng('web4/icon_er1')" alt="" />
          </div>
          <div class="allshow" style="top: 340px" v-show="!smTitle1 && allshowFlag">
            请重新扫描二维码
          </div>
          <div class="allshow" style="top: 340px" v-show="smTitle1">
            {{ smTitle1 }}
          </div>
          <div class="button_home button_home2">
            <div class="button_icon pointer" @click="goappdown()">{{ $t("login.DownloadAPP") }}</div>
            <div v-if="pcSwitch == 1" class="button_icon pointer" @click="goappdown()" style="margin-left:12px">
              {{ $t("login.DownloadDesktop") }}
            </div>
          </div>
          <div class="button_text">
            <div class="item_text"><span class="title">{{ platformname }}</span> 将获得以下权限</div>
            <img class="item_img" :src="$utils.getPng('jmdyhinfo')" alt="">
          </div>
        </div>
        <div class="rightBox">
          <div class="step1" v-if="step == 1">
            <div class="rtop1">
              <div class="rtItem pointer" v-for="item in lMethods" :key="item.id" :class="methodCls(item)"
                @click="changeLoginMethod(item, true)">
                {{ $t(item.label) }}
              </div>
            </div>
            <div class="loginMethodFlag1">
              <div class="userBox" v-if="loginMethodFlag == 1">
                <FmInput v-model="loginBox.zhanghao" :inputCls="showError == 5 ? ' is-error' : ''"
                  :placeholder="$t('login.Pleaseenter')" clearable @iBlur="inputblur"></FmInput>
                <el-input type="text" name="test" v-model="loginBox.zhanghao" style="display: none"></el-input>
                <FmInput v-model="loginBox.password" :inputCls="showError == 6 ? ' is-error' : ''"
                  :autocomplete="autoType" class="password" :placeholder="$t('login.Pleasepassword')" type="password" clearable
                  @iBlur="inputblur" @keyupEnter="(ev) => {
                    buttonDisabled_4 ? '' : loginclick(ev);
                  }
                    "></FmInput>
                <div class="user1">
                  <!-- <el-checkbox v-model="checked">记住密码</el-checkbox> -->
                  <div class="rember pointer" @click="checked = !checked">
                    <div class="remberbox">
                      <svg-btn style="width: 16px;" :icon-class="!checked
                        ? 'icon-file-uncheck-light'
                        : 'icon-file-check'">
                      </svg-btn>
                    </div>
                    <div class="rembertiit">{{ $t('login.Rememberpassword') }}</div>
                  </div>
                  <div class="forget pointer" @click="forget()">{{ $t('login.Forgotpassword') }}</div>
                </div>

                <!-- <el-button style="margin-top: 21px" :class="buttonDisabled_4 ? 'down-button-disabled' : 'down-button'
                  " :disabled="buttonDisabled_4" @click="loginclick" :loading="btnloading_4">
                  登录
                </el-button> -->
                <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 29px"
                  :disabled="buttonDisabled_4" @click="loginclick" :loading="btnloading_4" type="primary" size="big"
                  round>{{ $t('login.Login') }}</fm-button>

              </div>
              <div class="userBox" v-else-if="loginMethodFlag == 0">
                <!-- <div class="phone-code-text">
                  未注册的手机号验证后将自动创建飞猫盘账号
                </div> -->
                <div v-if="!showEmail">
                  <FmInput v-model="loginBox.phoneNumber" :inputCls="'elinput' + (showError == 1 ? ' is-error' : '')"
                  :placeholder="$t('login.Pleaseentermobile')" clearable @iBlur="inputblur" @keyupEnter="loginclick2"></FmInput>

                  <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 16px"
                    :disabled="buttonDisabled_1" @click="loginclick2" :loading="btnloadng_1" type="primary" size="big"
                    round>{{ $t('login.Next') }}</fm-button>
                </div>
                <div v-else>
                  <el-autocomplete class="emailReg" :popper-append-to-body="false" v-model="loginBox.email" clearable
                    ref="emailAutoRef" :fetch-suggestions="querySearchEmail"
                    :trigger-on-focus="loginBox.email.length > 0 ? true : false" :placeholder="$t('login.Pleasemailbox')">
                    <div v-if="loginBox.email.length > 0" slot="suffix" class="suffix-close-box">
                      <img :src="$utils.getPng('input-Close')" class="suffix-close" alt="">
                    </div>
                  </el-autocomplete>

                  <div class="intrBox">
                    <el-tooltip placement="bottom-start">
                      <template #content>
                        <div class="intrtit" v-for="item, index in emailSwitchData.infos" :key="index">{{ item }}</div>
                      </template>
                      <svg-btn class="imgi" icon-class="icon-close-1"></svg-btn>
                    </el-tooltip>

                    <div class="intrName">{{ $t('login.ViewSupported') }}</div>
                  </div>

                  <!-- <el-button style="margin-top: 21px" class="down-button"
                   :disabled="buttonDisabled_4" @click="loginclick" :loading="btnloading_8">
                  登录{{ btnloading_8 }}
                </el-button> -->

                  <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 15px"
                    :disabled="loginBox.email == ''" @click="sendEmailCodeFun()" :loading="btnloading_8" type="primary"
                    size="big" round>{{ $t('login.Next') }}</fm-button>

                </div>

              </div>
              <div class="contributeItem Disclaimer" v-if="loginMethodFlag == 0">
                <div class="Disclaimer-title">
                  <svg-btn class="shareicon1" style="width: 16px;margin-right: 8px;" @click="check_act = !check_act"
                    :icon-class="!check_act
                      ? 'icon-file-uncheck-light'
                      : 'icon-file-check'">
                  </svg-btn>
                  <div style="width: 358px;">
                    <span v-if="!showEmail">{{ $t('login.automatically1') }}</span>{{ $t('login.automatically2') }}<a style="color: #F67942;"
                    :href='$glb.fmUrl + "/askdetail/91"' target="_blank">{{ $t('login.automatically3') }}</a>
                  </div>
                </div>
              </div>
              <OtherLogin :show-phone="showPhone" :showWx="wxInfo.wx_login_switch" :change-icon="changeIcon"
                class="optherlogin" @fastLogin="fastLogin"></OtherLogin>
            </div>
          </div>
          <!-- 验证手机号 -->
          <div class="step2" v-show="step == 2">
            <div class="topret" @click="step = 1">
             <svg-btn class="imgret" icon-class="login-ret"></svg-btn>
              <div class="rettit">{{ $t('login.Back') }}</div>
            </div>
            <div class="yzName">{{ showEmail ? $t('login.VerifyEmail') : $t('login.Verifymobile') }}</div>
            <div class="phoneInfo" v-if="!showEmail">
              {{ $t('login.verificationminutes1') }}<span style="color: #2c2c2c; margin: 0 6px; font-weight: 500">{{ loginBox.pcode
                }}{{
                  loginBox.phoneNumber.slice(0, 3) +
                  "******" +
                  loginBox.phoneNumber.slice(9, 11)
                }}</span>{{ $t('login.verificationminutes2') }}
            </div>
            <div class="phoneInfo" v-if="showEmail">
              {{ $t('login.verificationminutes1') }}<span style="color: #2c2c2c; margin: 0 6px; font-weight: 500">{{ loginBox.email
                }}</span>{{ $t('login.verificationminutes2') }}
            </div>
            <VerificationCode v-if="showCodeYZ" ref="ver" @next="next" @changeload="changeload" :error="reload">
            </VerificationCode>
            <div class="sendAgain pointer" v-if="time === 60" @click="!showEmail ? loginclick2() : sendEmailCodeFun()">
              {{ $t('login.Retrievecaptcha') }}
            </div>
            <div class="sendAgain" v-if="time < 60">{{ time }}s{{ $t('login.Retrieve') }}</div>
            <!-- <el-button style="margin-top: 40px" :class="buttonDisabled_2 || !phoneCode
              ? 'down-button-disabled'
              : 'down-button'
              " :disabled="buttonDisabled_2" :loading="btnloading_2" @click="nextApi">
              下一步
            </el-button> -->

            <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 40px"
              :disabled="phoneCode.length < 6" @click="showEmail ? checkEmailCode() : nextApi();"
              :loading="btnloading_2" type="primary" size="big" round>{{ $t('login.Confirm') }}</fm-button>

          </div>
          <div class="step2 step9" v-if="step == 9">
            <div class="topret" @click="retStep()">
              <svg-btn class="imgret" icon-class="login-ret"></svg-btn>
              <div class="rettit">{{ $t('login.Back') }}</div>
            </div>
            <div class="titleNew">{{ $t('login.Setupaccount') }}</div>
            <FmInput v-model="regEmailBox.zhanghao" :placeholder="$t('login.accountdigits')" clearable autocomplete="off"
              @iBlur="inputblur">
            </FmInput>
            <div class="titleNew">{{ $t('login.Setpassword') }}</div>

            <FmInput v-model="regEmailBox.password" :placeholder="$t('login.Setloginpassword')" clearable autocomplete="off"
              @iBlur="inputblur">
            </FmInput>
            <FmInput v-model="regEmailBox.passwordAgain" :inputCls="showError == 4 ? ' is-error' : ''"
              style="margin-top: 16px" :placeholder="$t('login.Enterpassword')" clearable autocomplete="off" @iBlur="inputblur"
              @keyupEnter="regEmailFun"></FmInput>

            <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 32px"
              :disabled="buttonDisabled_9" @click="regEmailFun" :loading="btnloading_9" type="primary" size="big"
              round>{{ $t('login.Confirm') }}</fm-button>

          </div>
        </div>
      </div>
      <!-- 注册 -->
      <div class="boxContent step3" v-if="step == 3">
        <div class="topret" @click="cancelReg()">
          <svg-btn class="imgret" icon-class="login-ret"></svg-btn>
          <div class="rettit">{{ $t('login.Back') }}</div>
        </div>
        <div class="registerBox">
          <div class="regtit">注册</div>
          <div class="regtit1">请设置用户信息</div>
          <FmInput v-model="regBox.zhanghao" placeholder="请设置账号" clearable autocomplete="off" @iBlur="inputblur">
          </FmInput>
          <FmInput v-model="regBox.password" style="margin-top: 16px" placeholder="请设置密码，最少设置 6 位数字/字母" clearable
            autocomplete="off" @iBlur="inputblur"></FmInput>
          <FmInput v-model="regBox.passwordAgain" :inputCls="showError == 4 ? ' is-error' : ''" style="margin-top: 16px"
            placeholder="请再次输入密码" clearable autocomplete="off" @iBlur="inputblur" @keyupEnter="goApp"></FmInput>
          <el-button style="margin-top: 16px" :class="buttonDisabled_3 ? 'down-button-disabled' : 'down-button'"
            :disabled="buttonDisabled_3" :loading="btnloading_3" @click="goApp()">下一步</el-button>
          <FmInput v-model="regBox.yqcode" style="margin-top: 44px" placeholder="邀请码（选填）" clearable autocomplete="off"
            @iBlur="inputblur"></FmInput>
        </div>
      </div>
      <!-- 忘记密码(手机号找回，邮箱找回) -->
      <div class="boxContent step4" v-if="step == 4">
        <div class="topret" @click="forgetRet(4)">
          <svg-btn class="imgret" icon-class="login-ret"></svg-btn>
          <div class="rettit">{{ $t("login.Back") }}</div>
        </div>
        <div class="findBox">
          <div class="findPassTit">
            {{ findPassType ? $t("login.Emailretrieval") : $t("login.Mobilephone") }}
          </div>
          <div class="findPassTit1">
            {{
              findPassType
                ? $t("login.Pleaseemail")
                : $t("login.themobilephone")
            }}
          </div>
          <div class="phoneBoxe" v-if="findPassType == 0">
            <el-select class="elSelect" popper-class="selectStyle" :popper-append-to-body="false"
              v-model="findPassBox.fpcode" :class="isFocusFlag ? 'elSelect1' : ''">
              <div class="choosecountry" style="color: #8a8b92; margin: 10px 0 12px 20px">
                选择国家或地区
              </div>
              <el-option :label="item.tel" :value="item.tel" v-for="item in cty" :key="item.tel_id" style="
                  width: 368px;
                  display: flex;
                  justify-content: space-between;
                ">
                {{ item.name }}
                <span>{{ item.tel }}</span>
              </el-option>
            </el-select>
            <FmInput v-model="findPassBox.fphone" :inputCls="showError == 7 ? ' is-error' : ''" :placeholder="$t('login.Pleaseentermobile')"
              clearable @iBlur="inputblur"></FmInput>
          </div>
          <FmInput v-else v-model="findPassBox.femail" :inputCls="showError == 8 ? ' is-error' : ''" class="emailBox"
            autocomplete="off" :placeholder="$t('login.Pleasemailbox')" clearable @iBlur="inputblur"></FmInput>
          <!-- <el-button style="margin-top: 16px" :class="buttonDisabled_5 ? 'down-button-disabled' : 'down-button'"
            :disabled="buttonDisabled_5" :loading="btnloading_5" @click="findsend()">下一步</el-button> -->

          <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 16px"
            :disabled="buttonDisabled_5" @click="findsend" :loading="btnloading_5" type="primary" size="big"
            round>{{ $t('login.Next') }}</fm-button>

          <div class="findType" v-if="findPassType == 0">
            {{ $t('login.Mobilephonenumber1') }}<span class="fdtype1 pointer" @click="changeFindtype(1)">{{ $t('login.Mobilephonenumber2') }}</span>
          </div>
          <div class="findType" v-if="findPassType == 1">
            {{ $t('login.Emailnot') }}<span class="fdtype1 pointer" @click="changeFindtype(0)">{{ $t('login.Phoneretrieval') }}</span>
          </div>
        </div>
      </div>
      <!-- 验证手机号 -->
      <div class="boxContent step4" v-show="step == 5">
        <div class="topret" @click="forgetRet(5)">
          <svg-btn class="imgret" icon-class="login-ret"></svg-btn>
          <div class="rettit">{{ $t('login.Back') }}</div>
        </div>
        <div class="yzBox">
          <div class="yzBoxTit">
            {{ findPassType == 0 ? $t('login.Verifymobile') : $t('login.VerifyEmail') }}
          </div>
          <div class="yzBoxTit1" v-if="findPassType == 0">
            {{ $t('login.verificationminutes1') }}<span style="color: #2c2c2c; margin: 0 6px; font-weight: 500">{{ findPassBox.fpcode
              }}{{
                findPassBox.fphone.slice(0, 3) +
                "******" +
                findPassBox.fphone.slice(9, 11)
              }}</span>{{ $t('login.verificationminutes2') }}
          </div>
          <div class="yzBoxTit1" v-if="findPassType == 1">
            {{ $t('login.verificationminutes1') }}<span style="color: #2c2c2c; margin: 0 6px; font-weight: 500">{{ findPassBox.femail }}</span>
            {{ $t('login.verificationminutes2') }}
          </div>
          <VerificationCode v-if="showCodeYZ" ref="ver" @next="findcheck" @changeload="changeload" :error="reload"></VerificationCode>
          <div class="sendAgain1 pointer" v-if="time === 60" @click="showhk()">
            {{ $t('login.Retrievecaptcha') }}
          </div>
          <div class="sendAgain1" v-if="time < 60">{{ time }}s{{ $t('login.Retrieve') }}</div>
          <div class="findType" style="margin-top: 8px" v-if="findPassType == 0">
            {{ $t('login.Mobilephonenumber1') }}<span class="fdtype1 pointer" @click="changeFindtype(1)">{{ $t('login.Mobilephonenumber2') }}</span>
          </div>
          <div class="findType" style="margin-top: 8px" v-if="findPassType == 1">
            {{ $t('login.Emailnot') }}<span class="fdtype1 pointer" @click="changeFindtype(0)">{{ $t('login.Phoneretrieval') }}</span>
          </div>
          <!-- <el-button style="margin-top: 40px" :class="buttonDisabled_7 || !phoneCode
            ? 'down-button-disabled'
            : 'down-button'
            " :disabled="buttonDisabled_7" :loading="btnloading_7" @click="findcheckApi()">下一步</el-button> -->

          <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 40px"
            :disabled="buttonDisabled_7" @click="findcheckApi" :loading="btnloading_7" type="primary" size="big"
            round>{{ $t('login.Next') }}</fm-button>
        </div>
      </div>
      <!-- 重设密码 -->
      <div class="boxContent step4" v-if="step == 6">
        <div class="topret" @click="step = 5">
          <svg-btn class="imgret" icon-class="login-ret"></svg-btn>
          <div class="rettit">{{ $t('login.Back') }}</div>
        </div>
        <div class="resetBox">
          <div class="resetBoxTit">{{ $t('login.Resetpassword') }}</div>
          <div class="resetBoxTit1">{{ $t('login.Pleasewithpassword') }}</div>
          <FmInput v-model="changeBox.password" :placeholder="$t('login.Newpassword')" clearable autocomplete="off" @iBlur="inputblur">
          </FmInput>
          <FmInput v-model="changeBox.newpassword" :inputCls="showError == 9 ? ' is-error' : ''"
            style="margin-top: 16px" :placeholder="$t('login.Enternewpassword')" clearable autocomplete="off" @iBlur="inputblur"
            @keyupEnter="resetPass"></FmInput>
          <!-- <el-button style="margin-top: 16px" :class="buttonDisabled_6 ? 'down-button-disabled' : 'down-button'"
            :disabled="buttonDisabled_6" :loading="btnloading_6" @click="resetPass()">完成，去登录</el-button> -->

          <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 32px"
            :disabled="buttonDisabled_6" @click="resetPass" :loading="btnloading_6" type="primary" size="big"
            round>{{ $t('login.Gotologin') }}</fm-button>
        </div>
      </div>
      <!-- 微信扫码登录 -->
      <div class="boxContent step4" v-show="step == 7">
        <div class="topret" @click="step = 1">
          <svg-btn class="imgret" icon-class="login-ret"></svg-btn>
          <div class="rettit">{{ $t('login.Back') }}</div>
        </div>
        <div class="wxlogin">
          <div class="wxtit">{{ $t('login.Wechatscan') }}</div>
          <div class="wxCode">
            <div id="wxCode"></div>
          </div>
        </div>
      </div>

      <!--   选择登录账号   -->
      <div class="boxContent step4" v-show="step == 8">
        <div class="topret" @click="goBackSelect">
          <svg-btn class="imgret" icon-class="login-ret"></svg-btn>
          <div class="rettit">{{ $t('login.Back') }}</div>
        </div>
        <div class="findBox">
          <div class="findPassTit">
            {{ isSelectUser ? "登录账号" : "选择登录账号" }}
          </div>
          <template v-if="!isSelectUser">
            <div class="findPassTit1">请选择您需要登录的飞猫盘账号</div>
            <div class="username-box">
              <el-scrollbar style="height: 100%">
                <div v-for="(it, index) in usernameItems" :key="it.userid"
                  :class="selectUsername === index ? 'active' : ''" class="username-item"
                  @click="selectUsername = index">
                  <div :style="{ backgroundImage: `url(${it.logo})` }" class="user-avatar"></div>
                  <div style="margin-left: 12px" class="mid">
                    <div class="text1">
                      <span class="uname-box">{{ it.username }}</span><span class="u-tag">{{ uTypeMap[it.logintp]
                        }}</span>
                    </div>
                    <div class="text2">
                      {{ it.last_login_time }}
                    </div>
                  </div>
                  <div style="margin-left: 12px">
                    <div class="select-box">
                      <img v-if="selectUsername === index" :src="$utils.getPng('web4/select-active1')" alt="" />
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
            <!-- <el-button style="margin-top: 48px" class="down-button" @click="handleSelectUser">确定</el-button> -->
            <fm-button style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 48px"
              @click="handleSelectUser" type="primary" size="big" round>确定</fm-button>
          </template>
          <template v-else>
            <div class="username-avatar-box">
              <div :style="{ backgroundImage: `url(${selectUser.logo})` }" class="img-user-avatar"></div>
              <div class="text-username">{{ selectUser.username }}</div>
            </div>
            <div>
              <FmInput v-model="selectPsw" placeholder="请输入密码" autocomplete="off" type="password" clearable
                @keyupEnter="handleConfirmLogin"></FmInput>
              <!-- 自动填充的隐藏掉 不让自动填充到上面这个弹框 -->
              <FmInput style="display: none;" v-model="selectPsw" placeholder="请输入密码" autocomplete="new-password"
                type="password" clearable></FmInput>
            </div>
            <!-- <el-button :loading="btnloading_4" :class="selectPswDis ? 'down-button-disabled' : 'down-button'"
              :disabled="selectPswDis" style="margin-top: 16px" class="down-button"
              @click="handleConfirmLogin">确定</el-button> -->
            <fm-button :loading="btnloading_4" :disabled="selectPswDis"
              style="width: 382px;height: 56px;font-size:18px;border-radius: 28px;margin-top: 16px"
              @click="handleConfirmLogin" type="primary" size="big" round>登录</fm-button>
          </template>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="reg_ret" top="0" title="提示">
      <div class="quit">
        <img class="pop_icon" :src="$utils.getPng('web4/icon_zc')" />
        <div class="pop_tit">即将完成注册，确认是否退出</div>
      </div>
      <div class="footer">
        <div class="cancel pointer" @click="popConfirm()">确 认</div>
        <div class="confirm pointer" @click="reg_ret = false">取 消</div>
      </div>
    </el-dialog>
    <SafeDialog @onConfirm="onConfirm" ref="MyDialog" />
    <Disclaimer :showToast="showToast" @sure="sure" @handleCloseToast="handleCloseToast" />
    <BrowserRestrict ref="BrowserRestrictRef"></BrowserRestrict>
    <Modifyintercept
      ref="Modifyintercept"
      :isShow="showModifyintercept"
      title="暂时无法修改密码"
      :updata="updata"
      @handleClose="showModifyintercept = false"
    ></Modifyintercept>
  </div>
</template>

<script>
import FmInput from "@/components/Login/FmInput.vue";
import cty from "@/utils/config/cty2";
import OtherLogin from "@/components/Login/OtherLogin.vue";
import VerificationCode from "@/components/Login/VerificationCode";
import SafeDialog from "@/components/Dialog/SafeDialog.vue";
import Disclaimer from "@/components/Dialog/Disclaimer.vue";
import Modifyintercept from "@/components/Cloud/Modifyintercept";

import {
  limitationDelAPI,
  getqrcodeAPI,
  wxinfoAPI,
  phoneLoginCodeAPI,
  checkqrNewAPI,
  phoneRegLoginAPI,
  switchControlAPI,
  userLoginVerifyAPI,
  loginNewAPI,
  registerAPI,
  forgetSendcodeAPI,
  resetPasswordAPI,
  getCaptnAPI,
  loadAPI,
  getUrl,
  findcheckAPI,
  getPhonePcodeAPI,
  getEmailRegisterSuffixAPI,
  sendEmailRegisterCodeAPI,
  checkEmailCodeAPI,
  emailRegisterAPI,

} from "@/utils/apiList/login";
import BrowserRestrict from "@/components/Dialog/BrowserRestrict";

import { getHomeIndex } from '@/utils/apiList/home'
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";
export default {
  components: { OtherLogin, VerificationCode, FmInput, SafeDialog, Disclaimer, BrowserRestrict, Modifyintercept },
  data() {
    return {
      loginTip: {
        pre: "",
        now: "",
        access_token: "",
      },
      isCheckQr: false,
      wxInfo: {}, // 微信配置信息
      maLoading: false,
      isupdate: false, // 是否刷新二维码的
      sm_succes: false,
      status_stage: "",
      loginTimer: null, // 扫码登录定时器
      lMethods: [
        { id: 1, label: 'login.Login' },
        { id: 0, label: 'login.Signup' },
      ],
      loginMethod: ["短信登录/注册", "密码登录"],
      loginMethodFlag: 1,
      cty: [],
      loginBox: {
        phoneNumber: "",
        zhanghao: "",
        pcode: "+86",
        pcode1: "账号",
        logintp: "username",
        password: "",
        email: '',
        msgid: ''
      },

      listType: [
        {
          id: "username",
          name: "账号",
        },
        {
          id: "email",
          name: "邮箱",
        },
        {
          id: "phone",
          name: "手机",
        },
      ],
      selected: "",
      showError: 0, //showError == 1短信注册，登录，showError ==5 账号，邮箱框登录，showError ==6账号登录密码框，showError == 4 注册页面再次输入密码框，showError == 7 找回密码手机框，showError == 8找回密码邮箱框，
      autoType: "off",
      checked: true,
      step: 1,
      time: 60,
      timerA: "",
      regBox: {
        zhanghao: "",
        passwordAgain: "",
        password: "",
        yqcode: "",
      },
      buttonDisabled_1: false, //buttonDisabled == 1即短信登录注册时候
      buttonDisabled_2: true, //buttonDisabled == 2短信登录获取验证码的时候
      buttonDisabled_3: true, //buttonDisabled == 3注册
      buttonDisabled_4: true, //buttonDisabled == 4账号，邮箱，手机号登录
      buttonDisabled_5: true, //buttonDisabled == 5找回密码发送验证码按钮(第一步)
      buttonDisabled_6: true, //buttonDisabled == 6 重设密码
      buttonDisabled_7: false, //buttonDisabled == 7 找回密码发送验证码按钮(第二步)
      buttonDisabled_8: false, //邮箱获取验证码
      buttonDisabled_9: true,//邮箱注册
      btnloading_1: false,
      btnloading_2: false,
      btnloading_3: false,
      btnloading_4: false,
      btnloading_5: false,
      btnloading_6: false,
      btnloading_7: false,
      btnloading_8: false,
      btnloading_9: false,
      findPassBox: {
        fphone: "",
        fpcode: "+86",
        femail: "",
        fucode: "",
        fmsgid: "",
      },
      findPassType: 0, //1是邮箱，0是手机
      changeBox: {
        password: "",
        newpassword: "",
      },
      nctype: "", //阿里云滑块验证类型
      ncdata: [], //阿里云滑块验证回调数据
      funName: "", //调用滑块的方法名 eg：login
      ncInfo: {}, //滑块弹窗数据,
      reload: false,
      phoneCode: "",
      fastId: "",
      fastType: "",
      reg_info: {}, //手机号登录，但是没有这个手机号，进入注册页带的信息
      path_sec: false,
      placeholder: "",
      reg_ret: false,
      smTitle1: "",
      allshowFlag: false,
      pcSwitch: 0, //pc客户端开关，1-开；0-关
      timeOut1: null,
      imgBg: this.$glb.cdnUrl + "/Public/web/img/webapp/fm2023/main_bg_05.jpg",
      routeList: [],
      showPhone: true,
      changeIcon: "login-phone-icon",
      isRegister: true,
      selectUsername: 0,
      uTypeMap: {
        username: "账号",
        phone: "手机号",
        email: "邮箱号",
      },
      usernameItems: [],
      selectUser: {},
      isSelectUser: false,
      selectPsw: "",
      loginVerify: false,
      platformname: '飞猫盘',
      platfromUrl: '',
      platfro: '',
      check_act: true,
      showToast: false,
      browserDiaInstance: null,
      isFocusFlag: false,
      showEmail: false,
      showbrowsertimes: 0,
      emailSwitchData: {
        email_register_switch: 0,
        infos: {},
        suffixs: [
          "@qq.com",
          "@vip.qq.com",
          "@foxmail.com",
          "@163.com",
          "@126.com",
          "@yeah.net",
          "@vip.163.com",
          "@vip.126.com",
          "@188.com",
          "@sina.com",
          "@sina.cn",
          "@vip.sina.com",
          "@sohu.com",
          "@sohu.cn",
          "@139.com",
        ]
      },
      regEmailBox: {
        zhanghao: "",
        password: "",
        passwordAgain: ""
      },
      showCodeYZ: false,
      emailAutoRef: null,
      showModifyintercept: false,
      updata: {},
      showDropItem: false
    };
  },
  computed: {
    selectPswDis: function () {
      return !this.selectPsw || this.selectPsw.length < 6;
    },
  },
  watch: {
    status_stage(newval, oldval) {
      if (newval !== oldval) {
        if (newval == 10012) {
          this.$toast("扫描成功，请确认", 1);
        }
        if (newval == 10010) {
          this.isupdate = true;
          this.$toast("二维码超时，请刷新", 4);
        }
      }
    },
    loginBox: {
      handler(newval) {
        //手机号登录
        if (this.step == 1 && this.loginMethodFlag == 0) {
          if (newval.pcode == "+86" && newval.phoneNumber.length == 11) {
            this.buttonDisabled_1 = false;
          } else if (newval.pcode != "+86" && newval.phoneNumber) {
            this.buttonDisabled_1 = false;
          } else {
            this.buttonDisabled_1 = true;
          }
        }
        //账号，邮箱密码登录
        if (this.step == 1 && this.loginMethodFlag == 1) {
          if (newval.zhanghao && newval.password.length >= 6) {
            this.buttonDisabled_4 = false;
          } else {
            this.buttonDisabled_4 = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    regBox: {
      handler(newval) {
        if (
          newval.zhanghao &&
          newval.password.length >= 6 &&
          newval.passwordAgain.length >= 6
        ) {
          this.buttonDisabled_3 = false;
        } else {
          this.buttonDisabled_3 = true;
        }
      },
      deep: true,
    },
    findPassBox: {
      handler(newval) {
        if (newval.fphone || newval.femail) {
          this.buttonDisabled_5 = false;
        } else {
          this.buttonDisabled_5 = true;
        }
      },
      deep: true,
    },
    changeBox: {
      handler(newval) {
        if (newval.password.length >= 6 && newval.newpassword.length >= 6) {
          this.buttonDisabled_6 = false;
        } else {
          this.buttonDisabled_6 = true;
        }
      },
      deep: true,
    },
    regEmailBox: {
      handler(newval) {
        if (
          newval.zhanghao.trim().length >= 6 &&
          newval.password.length >= 6 &&
          newval.passwordAgain.length >= 6
        ) {
          this.buttonDisabled_9 = false;
        } else {
          this.buttonDisabled_9 = true;
        }
      },
      deep: true,
    },
    step: {
      handler(newval, oldval) {
        this.reload = false;
        this.buttonDisabled_1 = false;
        this.buttonDisabled_3 = true;
        // this.buttonDisabled_4 = true;
        // this.buttonDisabled_5 = true;
        this.buttonDisabled_6 = true;
        // this.buttonDisabled_7 = true;
        if (newval == 1) {
          clearInterval(this.loginTimer);
          this.dealFmScanf();
        }
        if (newval > 2 && newval < 9) {
          clearInterval(this.loginTimer);
        }
        if (newval == 2 || newval == 5) {
          this.showCodeYZ = true;
        }

        if (newval == 1 && oldval == 2 || newval == 4 && oldval == 5) {
          this.showCodeYZ = false;
          this.time = 60;
          this.timerA && clearInterval(this.timerA);
        }
      },
    },

    phoneCode: {
      handler(newval) {
        if (newval.length == 6) {
          this.buttonDisabled_2 = false;
          this.buttonDisabled_7 = false;
        } else {
          this.buttonDisabled_2 = true;
          this.buttonDisabled_7 = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeCreate() {
    if (!document.getElementById('aliyunCaptcha-window-popup')) {
      this.$AliCode.initAliCode();
    }
  },
  async created() {
    this.getPhonePcode();
    //第三方授权登录授权失败,重定向到登录页提示错误信息
    const urlParams = new URLSearchParams(window.location.search);
    let errinfo = urlParams.get("err");
    if (errinfo) {
      let errToast = JSON.parse(errinfo).msg;
      this.$toast(errToast, 2);
      this.$router.replace({ name: "login" }).catch(() => { });;
    }
    this.routeList = this.getAllNames(this.$router.options.routes);
    document.addEventListener("visibilitychange", this.handleVisiable);
    // 获取上一个页面的路径 ，登录成功后返回如果没得上个路径，跳转至home
    let path_url = this.$route.query.path ? this.$route.query.path : "home";
    // 获取跳转过来的平台名称
    this.platformname = this.$route.query.from == 'fmxz-admin' ? '飞猫小站管理中心' : this.$route.query.from == 'fmxz' ? '飞猫小站' : this.$route.query.from == 'up' ? '橘猫订阅号后台' : this.$route.query.from == 'daren' ? '飞猫达人中心' : this.$route.query.from == 'jmzb' ? '橘猫众包' : this.$route.query.from == 'jmdyh' ? '橘猫订阅号': this.$route.query.from == 'overseas' ? '飞猫盘海外版' : this.platformname
    localStorage.setItem('platformname', this.platformname)
    // 获取平台地址
    if (this.platformname != '飞猫盘') {
      this.platfromUrl = this.$route.query.fromUrl || ''
      localStorage.setItem('platfromUrl', this.platfromUrl)
    }
    //（分享）文件下载中途退出，或者更换浏览器，（获取取件码到登录页 登录成功后返回文件下载页并将参数带过去）
    let index = path_url && path_url.indexOf("/");
    let url = sessionStorage.getItem("obj");
    //判断是否带取件码
    var is_pud = url && url.indexOf("pucode");
    if (is_pud === -1) {
      this.ishave = false;
      var pucode2 = "";
      var pucode = url && url.slice(url.indexOf("#"));
    } else {
      this.ishave = true;
      var pucode2 = url && url.slice(url.indexOf("=") + 1, url.indexOf("#"));
      var pucode = url && url.slice(url.indexOf("=") + 1);
    }

    this.pucode = pucode;
    this.pucode2 = pucode2;

    // 获取地址栏参数(第三方登录获取id和登录类型，这个页面用不到，在fastLogin即loginNew页面调用，参数不需注释)
    // const fasturl = window.location.search.substring(1);
    // this.fastId = this.getQueryString(fasturl).id;
    // this.fastType = this.getQueryString(fasturl).tp;

    // if (this.fastId && this.fastType) {
    //   this.toastTit = "微信/QQ未注册账号，请使用手机号登录绑定/注册";
    //   this.toastSuc = 2;
    //   this.showtoast = true;
    // }

    if (path_url.indexOf("/") == -1) {
      this.path = path_url;
    } else {
      this.path = path_url.slice(0, index);
      this.path_id = path_url.slice(index + 1);
      this.path_sec = true; //是否为二级页面的返回（文件下载页）
    }
    if (this.routeList.indexOf(this.path) == -1) {
      this.path = "home";
    } else if (
      this.path == "releaseContent" ||
      this.path == "pubContent" ||
      this.path == "login"
    ) {
      this.path = "home";
    } else {
      this.path = this.path;
    }

    // let logintp = this.$utils.getCookie("logintp");
    // let username = this.$utils.getCookie("username");
    // let password = "";
    // password = this.$utils.getCookie("password")
    //   ? window.atob(this.$utils.getCookie("password"))
    //   : "";
    let logintp = localStorage.getItem("logintp");
    let username = localStorage.getItem("username");
    let password = localStorage.getItem("password") || "";
    this.loginBox.logintp = logintp;
    this.loginBox.zhanghao = username;
    this.loginBox.password = password;
    this.getWxinfo();
    this.dealFmScanf();
    this.getCaptn();
    this.getStatic();
    // this.$utils.newReported("P/1-5-10-015 ");
    let res = await this.getEmailSwitch();
    if (this.$route.query.tab === "2") {
      this.changeLoginMethod({ id: 0 }, true);
    }
  },
  mounted() {
    let limitation = this.$route.query.limitation;
    if (limitation) {
      limitation = decodeURIComponent(atob(limitation));
      try {
        let lJson = JSON.parse(limitation);
        if (lJson.type == 1) {
          this.showLimitLogin(lJson);
        } else {
          this.showBrowserRestrict(lJson)
        }
      } catch (e) { }
    }
    // this.showBrowserRestrict()
  },
  beforeDestroy() {
    this.time = 60;
    this.timerA && clearInterval(this.timerA);
    clearInterval(this.loginTimer);
    document.querySelectorAll(".geetest_close").forEach((item) => {
      item.click();
    });
    document.removeEventListener('visibilitychange', this.handleVisiable);
  },
  methods: {
    showDropFun: _.debounce(
      function () {
        this.showDropItem = !this.showDropItem;
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    changeLanguage(index) {
      this.$utils.changeLanguage(this.$route,index)
    },
    //获取区号
    getPhonePcode() {
      getPhonePcodeAPI({ type: 3 }).then((res) => {
        let plaintext = this.$utils.rd(res.data.ak, unescape(sfile));
        let obj = this.$utils.secret(res.data.ed, plaintext, true);
        let formObj = JSON.parse(obj);
        this.cty = formObj.list;
      });
    },
    onConfirm() {
      this.$refs.MyDialog.close();
      limitationDelAPI({
        access_token: this.loginTip.access_token,
      })
        .then((res) => {
          if (res.status == 1) {
            if (this.checked) {
              localStorage.setItem("username", this.loginBox.zhanghao);
              localStorage.setItem("logintp", this.loginBox.logintp);
              localStorage.setItem("password", this.loginBox.password);
              this.autoType = "new-password";
            } else {
              localStorage.setItem("username", "");
              localStorage.setItem("password", "");
              this.autoType = "off";
            }
            this.$store.commit("setAttr", { name: "isLoginFlag", val: true });
            if (res.data != '') {
              let plaintext = this.$utils.rd(res.data.ak, unescape(sfile));
              let obj = this.$utils.secret(res.data.ed, plaintext, true);
              let formObj = JSON.parse(obj)
              if (formObj.sso && formObj.sso.length > 0) {
                formObj.sso.forEach(item => {
                  this.platfro = item.token
                  getUrl(item.url, { token: item.token }).then((res) => { });
                });
              }
            }
            setTimeout(() => {
              this.goNext(this.platformname, this.platfro);
            }, 600);
          } else {
            this.toastSuc = 2;
            this.toastTit = res.msg;
            this.showtoast = true;
          }
        })
        .catch((err) => { });
    },
    onClose() {
      this.$refs.MyDialog.close();
      this.loginTip.pre = "";
      this.loginTip.now = "";
      this.loginTip.access_token = "";
    },
    handleVisiable(e) {
      this.startAct = 0;
      if (e.target.hidden) {
        // 页面不可见 要执行的方法 例：
        clearInterval(this.loginTimer);
      } else if (e.target.visibilityState == "visible") {
        clearInterval(this.loginTimer);
        if (this.step < 3 || this.step == 9) {
          this.dealFmScanf();
        }
      }
    },
    methodCls(item) {
      let cls = "";
      if (item.id === this.loginMethodFlag) {
        if (this.loginMethodFlag === 0) {
          cls = this.isRegister ? "rtItem_act" : "";
        } else {
          cls = "rtItem_act";
        }
      } else {
        if (this.loginMethodFlag === 0 && !this.isRegister) {
          cls = "rtItem_act";
        }
      }
      return cls;
    },
    changeLoginMethod(item, isRegis) {
      this.loginMethodFlag = item.id;
      this.isRegister = isRegis;
      this.showEmail = false;

      if (this.loginMethodFlag === 1) {
        this.showPhone = true;
      } else {
        if (this.emailSwitchData.email_register_switch == 1) {
          this.showPhone = true;
        } else {
          this.showPhone = false;
        }
      }
      this.changeIcon =
        this.loginMethodFlag === 0 ? this.isRegister == true ? "login-mailbox-icon" : "login-psw-icon" : "login-phone-icon";
      this.phoneCode = "";
      this.inputblur();
      this.loginBox = {
        phoneNumber: "",
        zhanghao: "",
        pcode: "+86",
        pcode1: "账号",
        logintp: "username",
        password: "",
        email: '',
        msgid: ''
      };

      this.selectChange("账号");
    },
    forgetRet(step) {
      this.time = 60;
      this.timerA && clearInterval(this.timerA);
      this.reload = true;
      if (step == 4) {
        this.step = 1;
      } else {
        this.step = 4;
      }
    },
    goappdown() {
      this.$router.push('/app');
    },
    link2(url, data = {}) {
      let pucode = this.pucode;
      pucode
        ? this.ishave
          ? this.$router.replace({
            path: url,
            query: {
              pucode1: decodeURIComponent(pucode),
              pucode: this.pucode2,
            },
          }).catch(() => { })
          : this.$router.replace({
            path: url,
            query: {
              pucode1: decodeURIComponent(pucode),
            },
          }).catch(() => { })
        : this.$router.replace({ path: url, data }).catch(() => { });
    },
    selectChange(value) {
      this.loginBox.logintp = "";
      this.loginBox.zhanghao = "";
      this.loginBox.password = "";
      // let logintp = this.$utils.getCookie("logintp");
      // let username = this.$utils.getCookie("username");
      // let password = "";
      // password = this.$utils.getCookie("password")
      //   ? window.atob(this.$utils.getCookie("password"))
      //   : "";
      let logintp = localStorage.getItem("logintp");
      let username = localStorage.getItem("username");
      let password = localStorage.getItem("password")
        ? localStorage.getItem("password")
        : "";
      this.placeholder =
        value == "账号" ? "请输入" + value : "请输入" + value + "号";
      var value2;
      value == "手机"
        ? (value2 = "phone")
        : value == "邮箱"
          ? (value2 = "email")
          : (value2 = "username");

      this.loginBox.logintp = value2;

      if (value2 == logintp) {
        this.loginBox.logintp = logintp;
        this.loginBox.zhanghao = username;
        this.loginBox.password = password;
      }
    },
    inputblur() {
      this.showError = 0;
      this.isFocusFlag = false
    },

    dealFmScanf: _.debounce(
      function () {
        if (this.timeOut1) {
          clearTimeout(this.timeOut1);
        }
        this.timeOut1 = setTimeout(() => {
          this.maLoading = true;
          this.loginTimer && clearInterval(this.loginTimer);
          this.isupdate = false;
          this.smTitle1 = "";

          this.sm_succes = false;
          getqrcodeAPI()
            .then((res) => {
              this.showbrowsertimes = 0;
              this.maLoading = false;
              // 结果转成字符串
              const code = JSON.stringify(res.data.qr);
              // 1.创建二维码对象。qrcode(二维码类型（1~40，输入 0 以自动检测）,容错级别（L、M、Q、H）)
              var qr = qrcode(0, "L");
              // 2.添加二维码信息。
              qr.addData(code);
              // 3.生成二维码对象（并不显示）。
              qr.make();
              // createImgTag(cellSize, margin, alt); //cellSize 像素宽度,margin补白像素宽度
              document.getElementById("fmstscan").innerHTML = qr.createImgTag(
                6,
                0
              );
              this.loginTimer = setInterval(() => {
                this.checkQrCode(code);
              }, 2000);
            })
            .catch((err) => { });
        });
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    // 验证二维码
    checkQrCode: _.debounce(
      function (data) {
        if (this.isCheckQr) return;
        this.isCheckQr = true;

        checkqrNewAPI({ code: data })
          .then((res) => {
            this.isCheckQr = false;
            if (res.status) {
              this.status_stage = res.status;
              if (res.status == 10014) { // 等待扫码状态码改成1014 之前是10011
              } else if (res.status == 10012) {
                this.sm_succes = true;
                this.smTitle1 = "请在飞猫盘移动端上确认";
              } else if (res.status == 10013) {
                this.allshowFlag = true;
                this.$toast(res.msg, 2);
                this.dealFmScanf();
              } else if (res.status == 1) {
                this.$toast(res.msg, 1);
                this.loginTimer && clearInterval(this.loginTimer);
                let plaintext = this.$utils.rd(res?.data?.ak, unescape(sfile));
                let obj = plaintext && this.$utils.secret(res?.data?.ed, plaintext, true);
                let formObj = JSON.parse(obj);
                if (formObj.sso && formObj.sso.length > 0) {
                  formObj.sso.forEach((item) => {
                    this.platfro = item.token
                    getUrl(item.url, { token: item.token }).then((res) => { });
                  });
                }
                setTimeout(() => {
                  this.goNext(this.platformname, this.platfro)
                }, 600)
              } else if (res.status == 1011) {
                this.sm_succes = true;
                this.smTitle1 = "请在飞猫盘移动端上确认";
                this.loginTimer && clearInterval(this.loginTimer);
                if (res.data.type == 1) {
                  this.showLimitLogin(res.data);
                } else {
                  //保证只出现一次 扫码登录时候 关闭后不再次出现
                  this.showbrowsertimes += 1;
                  if (this.showbrowsertimes == 1) {
                    this.showBrowserRestrict(res.data)
                  }
                }

              } else {
                this.loginTimer && clearInterval(this.loginTimer);
              }
            } else {
              if (res.msg !== "") {
                this.loginTimer && clearInterval(this.loginTimer);
                this.$toast(res.msg, 2);
              }
            }
          })
          .catch((err) => { });
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    // 快速登录方式
    fastLogin(type) {
      if (type === "wx") {
        localStorage.setItem('platformname2', this.$route.query.from == 'up' ? '橘猫订阅号后台' : this.$route.query.from == 'daren' ? '飞猫达人中心' : this.$route.query.from == 'jmzb' ? '橘猫众包' : this.$route.query.from == 'jmdyh' ? '橘猫订阅号' : this.platformname)
        // 获取平台地址
        if (this.platformname != '飞猫盘' ) {
          localStorage.setItem('platfromUrl2', this.$route.query.fromUrl || '')
        } else {
          localStorage.setItem('platfropath', this.$route.query.path || '')
        }
        this.showWx();
        this.step = 7;
      } else if (type === "qq") {
        localStorage.setItem('platformname2', this.$route.query.from == 'up' ? '橘猫订阅号后台' : this.$route.query.from == 'daren' ? '飞猫达人中心' : this.$route.query.from == 'jmzb' ? '橘猫众包' : this.$route.query.from == 'jmdyh' ? '橘猫订阅号' : this.platformname)
        // 获取平台地址
        if (this.platformname != '飞猫盘') {
          localStorage.setItem('platfromUrl2', this.$route.query.fromUrl || '')
        } else {
          localStorage.setItem('platfropath', this.$route.query.path || '')
        }
        let types = this.platformname == '橘猫订阅号' ? 1 : this.platformname == '橘猫订阅号后台' ? 2 : 3
        let urls = this.platfromUrl != '' ? this.platfromUrl : window.location.origin + '/drive'
        if (types == 3) {
          window.location.href = this.$glb.getApiUrl + "/user-service/passport/" + type + `Login?type=${types}&url=${encodeURIComponent(urls)}`;
        } else {
          window.open(this.$glb.getApiUrl + "/user-service/passport/" + type + `Login?type=${types}&url=${encodeURIComponent(urls)}`, "_blank")
          window.open('', '_self').close();
        }
        // window.location.href = "/user-service/passport/" + type + "login";
      } else if (type === "phone") {
        let _id = this.showEmail ? 0 : this.loginMethodFlag === 1 ? 0 : 1;
        this.changeLoginMethod({ id: _id }, this.showEmail);
      } else if (type == 'email') {
        this.changeIcon = 'login-phone-icon'
        this.step = 1;
        this.showEmail = true;
      }
    },
    // 微信配置信息
    getWxinfo() {
      wxinfoAPI()
        .then((res) => {
          this.wxInfo = res.data;
        })
        .catch((err) => { });
    },
    showWx() {
      let types = this.platformname == '橘猫订阅号' ? 1 : this.platformname == '橘猫订阅号后台' ? 2 : 3
      let urls = this.platfromUrl != '' ? this.platfromUrl : window.location.origin + '/drive'
      this.wxobj = new WxLogin({
        id: "wxCode", // 第三方页面显示二维码的容器id
        appid: this.wxInfo.appid,
        redirect_uri: encodeURIComponent(this.wxInfo.wxcb + `?type=${types}&url=${urls}`),
        scope: "snsapi_login",
        state: this.wxInfo.state,
        style: "black",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLndycF9jb2RlewptYXJnaW4tdG9wOjExMXB4Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CndpZHRoOiAxNjBweDsKaGVpZ2h0OiAxNjBweDsKYmFja2dyb3VuZDogI0ZGRkZGRjsKYm94LXNoYWRvdzogMHB4IDRweCAyNXB4IDBweCByZ2JhKDAsMCwwLDAuMDgpOwpib3JkZXItcmFkaXVzOiA4cHg7CmJvcmRlcjogMXB4IHNvbGlkICNFNUU1RTU7Cn0KCg==",
      });
    },
    // 短信登录start
    loginclick2(event) {
      if (event) {
        event.target.blur();
      }
      if (this.buttonDisabled_1) {
        return;
      }
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (
        this.loginBox.pcode == "+86" &&
        !reg_tel.test(this.loginBox.phoneNumber)
      ) {
        this.$toast("请正确输入手机号", 3);
        this.showError = 1;
        return;
      }
      if (!this.check_act) {
        this.showToast = true;
        return;
      }
      this.showhk();
      this.btnloading_1 = true;
    },
    async showhk() {
      let _type = 3;
      if (this.step == 1) {
        if (this.loginMethodFlag == 0) {
          _type = 2;
        }
      } else if (this.step == 2) {
        _type = 2;
      } else if (this.step == 4 || this.step == 5) {
        if (this.findPassType == 1) {
          _type = 5;
        } else {
          _type = 4;
        }
      }
      // let _state = await this.gt4IsOpen(_type);
      let _state = 1;
      if (_state) {
        this.nctype = "nc_login";
        this.funName = "sendLoginCode";
        // this.$utils.initgt4(
        //   "92600b424aabaef6536b47a49ee8b649",
        //   this.geetcallback
        // );
        this.geetcallback({}, {});
      } else {
        this.geetcallback({}, {});
      }
    },
    geetcallback(captchaObj, validate) {
      if (validate == "close") {
        this.closehk();
        return;
      }
      this.captchaObj = captchaObj;
      this.validate = validate;
      if (this.step == 1 && this.loginMethodFlag == 0) {
        this.time = 60;
        this.timerA && clearInterval(this.timerA);
        this.sendCodeNew();
      } else if (this.loginMethodFlag === 0 && this.step === 2) {
        //重新获取验证码
        this.$refs?.ver?.reset(); //（将验证码重置方法）
        this.time = 60;
        this.timerA && clearInterval(this.timerA);
        this.sendCodeNew();
      } else if (
        this.loginMethodFlag === 1 &&
        (this.step == 1 || this.step == 8)
      ) {
        //账号 手机号 邮箱登录
        this.loginApp();
      } else if (this.step == 4) {
        //忘记密码发送验证码
        //忘记密码
        this.time = 60;
        this.timerA && clearInterval(this.timerA);
        this.forgetSendcode();
      } else if (this.step == 5) {
        this.$refs?.ver?.reset(); //（将验证码重置方法）
        this.time = 60;
        this.timerA && clearInterval(this.timerA);
        this.forgetSendcode();
      }
    },
    closehk() {
      this.btnloading_1 = false;
      this.btnloading_4 = false;
      this.showError = 0;
      this.buttonDisabled_1 = false;
      this.btnloading_5 = false;
      this.buttonDisabled_2 = false;
      this.buttonDisabled_5 = false;
    },
    minute: function () {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    changeload(val) {
      if (this.step == 2) {
        // if (val == true) {
        //   this.buttonDisabled_2 = true;
        // } else {
        //   this.buttonDisabled_2 = false;
        // }
      } else {
        // if (val == true) {
        //   this.buttonDisabled_7 = true;
        // } else {
        //   this.buttonDisabled_7 = false;
        // }
      }
    },
    sendCodeNew(captcha_verify) {
      return new Promise((resolve) => {
        this.btnloading_1 = true; //btn上的loading
        this.reload = false;
        phoneLoginCodeAPI({
          pcode: this.loginBox.pcode,
          phone: this.loginBox.phoneNumber,
          code_type: this.loginMethodFlag,
          captcha_verify: captcha_verify || '',
        })
          .then((res) => {
            this.btnloading_1 = false;
            if (res.status == 1) {
              resolve({
                result: true,
                validate: true,
              });
              let plaintext = this.$utils.rd(res.data.ak, unescape(sfile));
              let obj = this.$utils.secret(res.data.ed, plaintext, true);
              let formObj = JSON.parse(obj);
              this.btnloading_1 = false; //btn上的loading
              this.$toast(res.msg, 1);
              this.loginBox.msgid = formObj.msgid;
              if (this.time === 60) {
                this.timerA = setInterval(() => {
                  this.minute();
                }, 1000);
              }
              this.step = 2;
              this.phoneCode = "";
              this.$refs?.ver?.reset(); //（将验证码重置方法）
            } else if (res.status == 90001) {
              resolve({
                result: false,
                validate: false,
              });
              this.$toast(res.msg, 2);
              this.$AliCode.handleOpen(this.sendCodeNew)
            } else {
              resolve({
                result: true,
                validate: true,
              });
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => { });
      });
    },
    goNextBrowser(data) {
      if (this.checked) {
        localStorage.setItem("username", this.loginBox.zhanghao);
        localStorage.setItem("logintp", this.loginBox.logintp);
        localStorage.setItem("password", this.loginBox.password);
        this.autoType = "new-password";
      } else {
        localStorage.setItem("username", "");
        localStorage.setItem("password", "");
        this.autoType = "off";
      }
      this.$store.commit("setAttr", { name: "isLoginFlag", val: true });
      if (data != '') {
        let formObj = data;
        if (formObj.sso && formObj.sso.length > 0) {
          formObj.sso.forEach(item => {
            this.platfro = item.token
            getUrl(item.url, { token: item.token }).then((res) => { });
          });
        }
      }
      setTimeout(() => {
        this.goNext(this.platformname, this.platfro);
      }, 600);
    },
    goNext(type, platfro) {
      localStorage.setItem("platfro", platfro);
      localStorage.setItem('loginflag', 1);
      if (type == '飞猫小站管理中心' || type == '飞猫小站' || type == '橘猫订阅号后台' || type == '橘猫订阅号' || type == '飞猫达人中心' || type == '橘猫众包' || type == '飞猫盘海外版') {
        window.location.href = this.platfromUrl + `?platfro=${platfro}`;
      } else {
        this.path_sec
          ? this.link2("/" + this.path + "/" + this.path_id)
          : this.$router.replace({ name: this.path }).catch(() => { });;
      }
    },
    next(val) {
      this.btnloading_2 = true;
      const str = val && val.toString().replace(/,/g, "");
      this.phoneCode = str;
      this.showEmail ? this.checkEmailCode() : this.nextApi();
    },
    nextApi() {
      if (this.phoneCode.length < 6) return;
      phoneRegLoginAPI({
        pcode: this.loginBox.pcode,
        phone: this.loginBox.phoneNumber,
        msgid: this.loginBox.msgid,
        ucode: this.phoneCode,
        tp: this.fastType,
        id: this.fastId,
      })
        .then((res) => {
          this.btnloading_2 = false;
          let status = Number(res.status);
          if (status === 1011) {
            if (res.data.type == 1) {
              this.showLimitLogin(res.data);
            } else {
              this.showBrowserRestrict(res.data)
            }

            return;
          }
          if (res.status == 1) {
            //  取消登录成功的提示
            this.$store.commit("setAttr", { name: "isLoginFlag", val: true });
            let plaintext = this.$utils.rd(res?.data?.ak, unescape(sfile));
            let obj = plaintext && this.$utils.secret(res?.data?.ed, plaintext, true);
            let formObj = JSON.parse(obj);
            if (formObj.sso && formObj.sso.length > 0) {
              formObj.sso.forEach((item) => {
                this.platfro = item.token
                getUrl(item.url, { token: item.token }).then((res) => { });
              });
            }
            setTimeout(() => {
              this.goNext(this.platformname, this.platfro)
            }, 600)
          } else if (res.status == 2) {
            //注册
            this.$utils.logWayRecord(140, "phone_" + this.loginBox.phoneNumber);
            this.step = 3;
            let reg_info = {
              pcode: this.loginBox.pcode,
              phone: this.loginBox.phoneNumber,
              code: this.phoneCode,
              msgid: this.loginBox.msgid,
            };
            this.reg_info = reg_info;
            this.gozuce();
          } else {
            this.reload = true; // 需要重置滑块 代表验证未通过给验证码输入框置红
            if (res.msg === "请进行重新验证") {
              this.step = 1;
            }
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => { });
    },
    goApp() {
      let str = this.regBox.password || "";
      if (str.length < 6) {
        this.$toast("请最少设置6位数字或字母为密码", 2);
        this.showError = 4;
        return false;
      } else if (!/^[a-zA-Z0-9]{6,}$/.test(str)) {
        this.$toast("请使用数字或字母设置密码", 2);
        this.showError = 4;
        return false;
      } else if (this.regBox.password !== this.regBox.passwordAgain) {
        this.$toast("密码不一致，请重试", 2);
        this.showError = 4;
        return;
      }
      this.register();
      //this.showhk();
    },
    //注册
    register() {
      let data = this.reg_info;
      if (!this.regBox.password) {
        this.$toast("请输入密码", 2);
        return;
      }
      this.btnloading_3 = true;
      registerAPI({
        invitecode: this.regBox.yqcode ? this.regBox.yqcode : "",
        ...data,

        username: this.regBox.zhanghao,
        password: this.regBox.password,
        cpassword: this.regBox.passwordAgain,
        lot_number: this.validate?.lot_number,
        captcha_output: this.validate?.captcha_output,
        pass_token: this.validate?.pass_token,
        gen_time: this.validate?.gen_time,
        tp: this.fastType,
        id: this.fastId,
      })
        .then((res) => {
          this.btnloading_3 = false;
          if (res.status == 1) {
            this.$utils.logWayRecord(142, "phone_" + this.loginBox.phoneNumber);
            //注册成功status==1
            sessionStorage.removeItem(this.loginBox.phoneNumber);
            this.goNext(this.platformname);
            this.$toast(res.msg, 1);
          } else {
            this.reload = true; // 需要重置滑块
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => { });
    },
    handleSelectUser() {
      this.selectPsw = "";
      this.selectUser = this.usernameItems[this.selectUsername] || {};
      this.isSelectUser = true;
    },
    handleConfirmLogin() {
      if (this.selectPswDis) {
        return false;
      }
      if (!this.selectPsw) {
        this.$message.warning("请输入密码");
        return false;
      }
      this.btnloading_4 = true;
      this.showhk();
    },
    gt4IsOpen(type) {
      // type 验证查询类型，0=所有，1=飞猫盘发送短信验证码， 2=飞猫盘手机登录/注册极验验证, 3=飞猫盘账号密码登录极验验证, 4=飞猫盘找回密码极验验证(手机), 5=飞猫盘找回密码极验验证(邮箱)
      return new Promise((resolve) => {
        switchControlAPI({ ver_type: type })
          .then((_res) => {
            let tMap = {
              1: "webMessageJYVerifySwitch",
              2: "webPhoneJYVerifySwitch",
              3: "webLoginJYVerifySwitch",
              4: "webRePassJYVerifySwitch",
              5: "webEmailJYVerifySwitch",
            };
            let _key = tMap[type] || "";
            if (_res.status == 1) {
              if (_key) {
                let bool = _res.data[_key];
                resolve(bool);
              } else {
                resolve(_res.data);
              }
            } else {
              if (_key) {
                resolve(true);
              } else {
                resolve({});
              }
            }
          })
          .catch((err) => { });
      });
    },
    loginclick(event) {
      if (event) {
        event.target.blur();
      }
      if (this.buttonDisabled_4) {
        return;
      }
      if (this.loginVerify) {
        return;
      }
      this.loginVerify = true;
      this.autoType = "new-password";
      // var reg_tel =
      //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      // var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      //取消手机号的判断正则，国外手机号无法判断
      // if (this.login.pcode == "手机号" && this.login.pcode == "+86" && !reg_tel.test(this.login.zhanghao)) {
      //   this.toastTit = "请正确输入手机号";
      //   this.toastSuc = 2;
      //   this.showtoast = true;
      //   this.toastType = 1;
      //   return;
      // }
      // if (
      //   this.loginBox.pcode1 == "邮箱" &&
      //   !pattern.test(this.loginBox.zhanghao)
      // ) {
      //   this.$toast("请正确输入邮箱号", 2);
      //   return;
      // }
      let _params = {
        username: this.loginBox.zhanghao,
        password: this.loginBox.password,
      };
      userLoginVerifyAPI(_params)
        .then((_res) => {
          this.loginVerify = false;
          if (_res.status == 1) {
            let plaintext = this.$utils.rd(_res.data.ak, unescape(sfile));
            let obj = this.$utils.secret(_res.data.ed, plaintext, true);
            let _data = JSON.parse(obj);

            this.usernameItems = _data.list;
            this.selectUsername = 0;
            this.selectUser = this.usernameItems[this.selectUsername];
            if (_data.list.length > 1) {
              this.step = 8;
            } else {
              this.btnloading_4 = true;
              this.showhk();
            }
          } else {
            this.$message.warning(_res.msg);
          }
        })
        .catch((err) => { });
    },
    //手机号 邮箱 账号登录start
    loginApp(captcha_verify) {
      return new Promise((resolve) => {
        let uid = this.selectUser?.user_id;
        let psw = this.loginBox.password;
        let logintp = this.selectUser?.logintp;
        if (this.step === 8) {
          psw = this.selectPsw;
        }
        let params = {
          lot_number: this.validate?.lot_number,
          captcha_output: this.validate?.captcha_output,
          pass_token: this.validate?.pass_token,
          gen_time: this.validate?.gen_time,
          username: this.loginBox.zhanghao,
          password: psw,
          logintp: logintp,
          // checked true-1记住密码，false-0不记录
          ifauto: this.checked === true ? 1 : 0,
          user_id: uid,
          captcha_verify: captcha_verify || '',
        };
        loginNewAPI(params)
          .then((res) => {
            this.btnloading_4 = false;
            if (res.status == 1) {
              resolve({
                result: true,
                validate: true
              })
              let plaintext = this.$utils.rd(res?.data?.ak, unescape(sfile));
              let obj =
                plaintext && this.$utils.secret(res?.data?.ed, plaintext, true);
              let formObj = JSON.parse(obj);

              let status = Number(res.status);

              if (formObj.sso && formObj.sso.length > 0) {
                formObj.sso.forEach((item) => {
                  this.platfro = item.token
                  getUrl(item.url, { token: item.token }).then((res) => { });
                });
              }

              if (this.checked) {
                localStorage.setItem("username", this.loginBox.zhanghao);
                localStorage.setItem("logintp", this.loginBox.logintp);
                localStorage.setItem("password", psw);
                this.autoType = "new-password";
              } else {
                // this.$utils.setCookie("username", "");
                // this.$utils.setCookie("password", "");
                localStorage.setItem("username", "");
                localStorage.setItem("password", "");
                this.autoType = "off";
              }
              this.$store.commit("setAttr", { name: "isLoginFlag", val: true });
              setTimeout(() => {
                this.goNext(this.platformname, this.platfro);
              }, 600);
            } else if (res.status == 1011) {
              resolve({
                result: true,
                validate: true
              })
              //在滑块消失后展示弹窗设定600毫秒的延时器
              setTimeout(() => {
                if (res.data.type == 1) {
                  this.showLimitLogin(res.data);
                } else {
                  this.showBrowserRestrict(res.data)
                }
              }, 600);


              return;
            } else if (res.status == 4000) {
              resolve({
                result: true,
                validate: true
              })
              this.$toast(res.msg, 2);
              return;
            } else if (res.status == 90001) {
              this.$toast(res.msg, 2);
              this.$AliCode.handleOpen(this.loginApp)
            } else {
              resolve({
                result: true,
                validate: false
              })
              this.reload = true; // 需要重置滑块
              if (res.msg === "请进行重新验证") {
                this.step = 1;
              }
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => { });
      })
    },
    goBackSelect() {
      if (this.isSelectUser) {
        this.isSelectUser = false;
      } else {
        this.step = 1;
      }
    },
    forget() {
      this.step = 4;
      this.findPassType = 0;
      this.findPassBox.fphone = "";
      this.findPassBox.fpcode = "+86";
    },
    showLimitLogin(data) {
      this.loginTip.pre = data.last_province;
      this.loginTip.now = data.current_province;
      this.loginTip.access_token = data.access_token;
      this.$refs.MyDialog.show(data)
    },
    changeFindtype(index) {
      this.findPassType = index;
      this.findPassBox.fphone = "";
      this.findPassBox.femail = "";
      this.findPassBox.fpcode = "+86";
      this.step = 4;
      this.time = 60;
      this.timerA && clearInterval(this.timerA);
      this.reload = true;
    },
    findsend() {
      if (this.buttonDisabled_5) return
      //找回密码的验证手机号和邮箱唤起滑块
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      var pattern =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      // if (
      //   this.findPassType == 0 &&
      //   this.findPassBox.fpcode == "+86" &&
      //   !reg_tel.test(this.findPassBox.fphone)
      // ) {
      //   this.$toast("请正确输入手机号", 2);
      //   this.showError = 7;
      //   return;
      // }

      if (this.findPassType == 1 && !pattern.test(this.findPassBox.femail)) {
        this.$toast("请正确输入邮箱号", 2);
        this.showError = 8;
        return;
      }
      this.showhk();
      this.btnloading_5 = true;
    },
    //忘记密码 手机找回的时候发送验证码接口
    forgetSendcode(captcha_verify) {
      return new Promise((resolve) => {
        this.btnloading_5 = true;
        let URL =
          this.findPassType == 0
            ? "/user-service/passport/phoneLoginCode"
            : "/user-service/passport/sendEmailCode";
        let data =
          this.findPassType == 0
            ? {
              phone: this.findPassBox.fphone,
              pcode: this.findPassBox.fpcode,
              code_type: 1,
              captcha_verify: captcha_verify || ""
            }
            : {
              email: this.findPassBox.femail,
              captcha_verify: captcha_verify || ""
            };
        let params = {
          // seid: this.ncdata.sessionId,
          // to: this.ncdata.token,
          // sig: this.ncdata.sig,
          // sence: this.ncdata.scene,
          lot_number: this.validate?.lot_number,
          captcha_output: this.validate?.captcha_output,
          pass_token: this.validate?.pass_token,
          gen_time: this.validate?.gen_time,
          is_newWEB: 1,
          ...data,
        };
        forgetSendcodeAPI(URL, params)
          .then((res) => {
            this.btnloading_5 = false;
            if (res.status == '1') {
              let plaintext = this.$utils.rd(res.data.ak, unescape(sfile));
              let obj = this.$utils.secret(res.data.ed, plaintext, true);
              let _data = JSON.parse(obj);
              this.step = 5;
              this.findPassBox.fmsgid = _data.msgid;
              this.$toast(res.msg, 1);
              this.timerA = setInterval(() => {
                this.minute();
              }, 1000);
              resolve({
                result: true,
                validate: true,
              });
              this.reload = false;
              //  window.location.reload();
            } else if (res.status == "4000") {
              resolve({
                result: true,
                validate: true,
              });
              this.$toast(res.msg, 2);
            } else if (res.status == "90001") {
              resolve({
                result: false,
                validate: false,
              });
              this.$toast(res.msg)
              this.$AliCode.handleOpen(this.forgetSendcode);
            } else if(res.status == "90300"){
              this.updata = {
                update_time: res.data.update_time || '',
                update_text: res.msg || '',
              }
              this.showModifyintercept = true
            } else {
              this.$toast(res.msg, 2);
              resolve({
                result: true,
                validate: false,
              });
              this.reload = true; // 需要重置滑块
            }
          })
          .catch((err) => { });
      });
    },
    findcheck(val) {
      // this.buttonDisabled_5 = true;
      const str = val && val.toString().replace(/,/g, "");
      this.phoneCode = str;
      this.findcheckApi();
    },
    findcheckApi() {
      if (this.phoneCode.length < 6) return;
      //验证forgetSendcode接口数据
      this.changeBox = {
        password: "",
        newpassword: "",
      };

      this.reload = true;
      this.btnloading_7 = true;
      this.findPassBox.fucode = this.phoneCode;
      //验证邮箱 ，验证手机号
      let URL =
        this.findPassType == 0
          ? "/user-service/passport/forgetPasswordCheck"
          : "/user-service/passport/checkEmailCode";
      let data =
        this.findPassType == 0
          ? {
            phone: this.findPassBox.fphone,
            pcode: this.findPassBox.fpcode,
            ucode: this.phoneCode,
            msgid: this.findPassBox.fmsgid,
          }
          : {
            email: this.findPassBox.femail,
            ucode: this.phoneCode,
            msgid: this.findPassBox.fmsgid,
          };

      findcheckAPI(URL, {
        // seid: this.ncdata.sessionId,
        // to: this.ncdata.token,
        // sig: this.ncdata.sig,
        // sence: this.ncdata.scene,
        lot_number: this.validate?.lot_number,
        captcha_output: this.validate?.captcha_output,
        pass_token: this.validate?.pass_token,
        gen_time: this.validate?.gen_time,
        ...data,
      })
        .then((res) => {
          this.btnloading_7 = false;
          if (res.status == 1) {
            this.$toast(res.msg, 1);
            //重设密码
            this.step = 6;
          } else {
            this.reload = true; // 需要重置滑块
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => { });
    },
    resetPass() {
      if (this.buttonDisabled_6) return;
      let str = this.changeBox.password || "";
      if (str.length < 6) {
        this.$toast("请最少设置6位数字或字母为密码", 2);
        return false;
      } else if (!/^[a-zA-Z0-9]{6,}$/.test(str)) {
        this.$toast("请使用数字或字母设置密码", 2);
        return false;
      } else if (this.changeBox.password !== this.changeBox.newpassword) {
        this.$toast("密码不一致，请重试", 2);
        this.showError = 9;
        return;
      }
      this.btnloading_6 = true;
      let data =
        this.findPassType == 0
          ? {
            phone: this.findPassBox.fphone,
            pcode: this.findPassBox.fpcode,
          }
          : {
            email: this.findPassBox.femail,
          };

      let params = {
        ...data,
        type: this.findPassType == 0 ? "phone" : "email",
        ucode: this.findPassBox.fucode,
        msgid: this.findPassBox.fmsgid,
        password: this.changeBox.newpassword,
      };
      resetPasswordAPI(params)
        .then((res) => {
          this.btnloading_6 = false;
          if (res.status == 1) {
            this.$toast(res.msg, 1);
            this.step = 1;
            this.changeBox.password = "";
            this.changeBox.newpassword = "";
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => { });
    },
    gozuce() {
      if (sessionStorage.getItem(this.loginBox.phoneNumber)) {
        let info = JSON.parse(
          sessionStorage.getItem(this.loginBox.phoneNumber)
        );
        this.regBox = { ...info };
      } else {
        this.regBox = {
          zhanghao: "",
          passwordAgain: "",
          password: "",
          yqcode: "",
        };
      }
    },
    popConfirm() {
      sessionStorage.setItem(
        this.loginBox.phoneNumber,
        JSON.stringify(this.regBox)
      );
      this.step = 1;
      this.reg_ret = false;
      this.phoneCode = "";
      this.$refs?.ver?.reset(); //（将验证码重置方法）
      this.inputblur();
      this.loginBox = {
        phoneNumber: "",
        zhanghao: "",
        pcode: "+86",
        pcode1: "账号",
        logintp: "username",
        password: "",
      };
    },
    cancelReg() {
      this.phoneCode = "";
      this.$refs?.ver?.reset(); //（将验证码重置方法）
      this.inputblur();
      this.loginBox = {
        phoneNumber: "",
        zhanghao: "",
        pcode: "+86",
        pcode1: "账号",
        logintp: "username",
        password: "",
      };
      this.reg_ret = true;
    },
    toHome() {
      window.open("https://www.feimaoyun.com", "_href");
    },
    tofmp() {
      // window.open("/vip", "_blank");
      window.location.reload();
    },
    tovip() {
      window.open("/vip", "_blank");
    },
    tollz() {
      window.open(`${this.$glb.goLLZ}`, "_blank");
    },
    getCaptn() {
      getHomeIndex()
        .then((res) => {
          this.pcSwitch = res.data.pcSwitch;
        })
        .catch((err) => { });
    },
    getStatic() {
      loadAPI()
        .then((res) => {
          this.imgBg = res.data.list[res.data.list.length - 1].imgUrl;
        })
        .catch((err) => { });
    },
    getAllNames(routes, names) {
      names = names || [];
      routes.forEach((route) => {
        if (route.name) {
          names.push(route.name);
        }
        if (route.children) {
          this.getAllNames(route.children, names);
        }
      });
      return names;
    },
    sure(event) {
      this.check_act = true;
      this.showToast = false;
      this.showEmail ? this.sendEmailCodeFun() : this.loginclick2(event)
    },
    handleCloseToast() {
      this.showToast = false;
    },
    showBrowserRestrict: _.debounce(
      function (data) {
        this.$refs.BrowserRestrictRef.handleOpen(data);
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    handleClear() {
      this.loginBox.email = ''
    },
    // 邮箱自动填充后缀名
    querySearchEmail(queryString, callback) {
      var emailList = [];
      emailList = this.emailSwitchData.suffixs
      let results = []
      let queryList = []
      emailList.map(item => {
        queryList.push({ value: queryString.split('@')[0] + item })
      })
      if (queryString) {
        this.$refs.emailAutoRef.activated = true
        results = queryString ? queryList.filter(this.createFilter(queryString)) : queryList;
      }
      callback(results);
    },
    // 邮箱填写过滤
    createFilter(queryString) {
      return (item) => {
        return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    getEmailSwitch() {
      return new Promise((resolve) => {
        getEmailRegisterSuffixAPI()
          .then((res) => {
            this.emailSwitchData = res.data
            resolve(res.data)
          })
          .catch((err) => { });
      });

    },
    decrypt(data) {
      if (data?.ak) {
        let plaintext = this.$utils.rd(data.ak, unescape(sfile));
        let obj = this.$utils.secret(data.ed, plaintext, true);
        return JSON.parse(obj);
      } else {
        return data;
      }
    },
    sendEmailCodeFun(captcha_verify) {
      if (!this.loginBox.email) return;
      if (!this.check_act) {
        this.showToast = true;
        return;
      }
      this.time = 60;
      return new Promise((resolve) => {
        let params = {
          email: this.loginBox.email,
          captcha_verify: captcha_verify || '',
        }
        this.btnloading_8 = true;
        sendEmailRegisterCodeAPI(params)
          .then((res) => {
            this.btnloading_8 = false;
            if (res.status == 1) {
              resolve({
                result: true,
                validate: true
              })

              let formObj = this.decrypt(res.data)
              this.loginBox.msgid = formObj.msgid;
              this.step = 2;
              this.showEmail = true;
              if (this.time === 60) {
                this.timerA = setInterval(() => {
                  this.minute();
                }, 1000);
              }
              this.$toast(res.msg, 1);

            } else if (res.status == 90001) {
              this.$toast(res.msg, 2);
              this.$AliCode.handleOpen(this.sendEmailCodeFun)
            } else {
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => { });

      });
    },
    checkEmailCode() {
      if (this.phoneCode.length < 6) return;
      let params = {
        email: this.loginBox.email,
        msgid: this.loginBox.msgid,
        ucode: this.phoneCode
      };
      checkEmailCodeAPI(params)
        .then((res) => {
          this.btnloading_2 = false
          let formObj = this.decrypt(res.data);
          if (res.status == 1) {
            // this.$toast(res.msg, 1);
            this.step = 9;
          } else {
            this.reload = true;
            this.$toast(res.msg, 2);
          }

        })
        .catch((err) => { });
    },
    regEmailFun() {
      if (this.buttonDisabled_9) return;
      if (this.regEmailBox.zhanghao.trim().length < 6) {
        this.$toast('飞猫盘账号仅支持6-20位数字或字母，请修改', 3);
        return;
      }

      if (this.regEmailBox.password !== this.regEmailBox.passwordAgain) {
        this.$toast('密码不一致，请重试', 3);
        return;
      }
      let params = {
        msgid: this.loginBox.msgid,
        ucode: this.phoneCode,
        email: this.loginBox.email,
        username: this.regEmailBox.zhanghao,
        password: this.regEmailBox.password,
        repassword: this.regEmailBox.passwordAgain
      }
      this.btnloading_9 = true;
      emailRegisterAPI(params)
        .then((res) => {
          this.btnloading_9 = false;
          let formObj = this.decrypt(res.data);
          if (res.status == 1) {
            if (formObj.sso && formObj.sso.length > 0) {
              formObj.sso.forEach(item => {
                this.platfro = item.token
                getUrl(item.url, { token: item.token }).then((res) => { });
              });
            }
            setTimeout(() => {
              this.goNext(this.platformname, this.platfro);
            }, 600);
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => { });

    },
    retStep() {
      this.step = 2;
      this.regEmailBox = {
        zhanghao: "",
        password: "",
        passwordAgain: ""
      }
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

::v-deep .el-loading-mask {
  background-color: rgba(255, 255, 255, .9) !important;
}

::v-deep .el-select-dropdown {
  background: #ffffff !important;
  top: 48px !important;
  border-radius: 10px;

  .popper__arrow {
    border-bottom-color: #EBEEF5 !important;

    &::after {
      border-bottom-color: #FFF !important;
    }
  }
}

.button_icon {
  height: 29px;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_icon:hover {
  padding: 0 16px;
  background: #f7f7f7;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
}

.button_icon:active {
  padding: 0 16px;
  background: #f5f5f5;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
}

.el-select-dropdown__item {
  margin: 0 6px;
  padding: 0 14px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
}

.el-select-dropdown__item.selected {
  color: #f67942 !important;
  // background: #f7f7f7 !important;
  border-radius: 5px;
}

.el-select-dropdown__item:hover {
  background: #f7f7f7 !important;
  border-radius: 5px;
}

::v-deep .el-select .el-input .el-select__caret {
  color: #2c2c2c !important;
  font-weight: 600;
  font-size: 15px;
  width: 35px;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 35px;
}

@mixin flex_layout($direction, $justConten, $alignItems) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justConten;
  align-items: $alignItems;
}

@mixin font_style($fontSize, $fontWeight, $color) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color !important;
}

.down-button {
  width: 382px;
  height: 56px;
  background: #2c2c2c;
  border-radius: 28px;
  @include flex_layout(row, center, center);
  @include font_style(18px, 500, #fff);
  border: none;
}

.down-button-disabled {
  opacity: 0.3;
  width: 382px;
  height: 56px;
  background: #2c2c2c;
  border-radius: 28px;
  @include flex_layout(row, center, center);
  @include font_style(18px, 500, #fff);
  border: none;
}

.down-button-disabled:hover {
  background: #2c2c2c;
  opacity: 0.3;
}

.down-button:hover {
  background: #808080;
}

.down-button:active {
  background: #141110;
}

::v-deep .el-button.is-loading {
  background: #2c2c2c;
  opacity: 0.3;
}

//input框的公共样式宽高还有圆角
::v-deep .el-input {
  .el-input__inner {
    width: 382px;
    height: 56px;
    @include font_style(16px, 400, #2c2c2c);
    background: #F6F6F6 !important;
    border-radius: 28px;
    padding-left: 22px;
  }

  .el-input__inner:hover {
    border-color: #f67942;
  }

  .el-input.is-active,
  .el-input__inner:focus {
    border-color: #f67942;
    @include font_style(16px, 400, #2c2c2c);
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
    // border: 1px solid #f67942 !important;
  }
}

.login {
  height: 100vh;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  overflow: hidden;
  background: #f6f6f6;

  .navBar {
    width: 100%;
    height: 56px;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px 0px #f5f5f5;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 28px;

    .navContent {
      height: 56px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .fmInfo {
        display: flex;
        align-items: center;

        .logo {
          width: 36px;
          height: 36px;
        }

        .logo1 {
          width: 68px;
          height: 28px;
          margin-left: 10px;
          color: #2c2c2c;
        }
        .logo1-en {
          width: 114px;
        }
        .logo2{
          width: 34px;
          height: 15px;
          margin-top: auto;
          margin-left: 4px;
          margin-bottom: 6px;
        }
      }

      .fmInfoRight {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #2c2c2c;
        margin-left: 80px;

        .item {
          margin-right: 64px;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }

  .downContent {
    margin-top: 56px;
    height: calc(100vh - 56px);
    position: relative;

    .topret {
      display: flex;
      align-items: center;
      height: 22px;
      line-height: 22px;
      width: 50px;
      cursor: pointer;

      .imgret {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }

      .rettit {
        font-size: 14px;
        font-weight: 400;
        color: #8a8b92;
        margin-left: 4px;
      }
    }

    .boxContent {
      width: 815px;
      height: 550px;
      background: #ffffff;
      border-radius: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;

      .codeBox2 {
        padding-top: 0px !important;

        .name_intr2 {
          margin-bottom: 36px;
        }

        .button_home2 {
          margin-top: 61px !important;
        }

        .button_text {
          width: 240px;
          height: 80px;
          background: #F9F9F9;
          border-radius: 8px;
          padding: 12px;
          margin-top: 20px;
          // display: flex;

          .item_text {
            font-size: 12px;
            font-weight: 400;
            color: #8A8B92;
            line-height: 16px;
            margin-bottom: 4px;

            .title {
              color: #2C2C2C;
              line-height: 18px;
              margin-left: 9px;
            }
          }

          .item_img {
            width: 130px;
            height: 34px;
          }
        }
      }

      .codeBox {
        box-shadow: inset -0.5px 0px 0px 0px #E5E5E5;
        width: 369px;
        height: 100%;
        padding-top: 114px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;

        .ma {
          display: flex;
          justify-content: center;
          width: 100%;

          .mal {
            width: 160px;
            height: 160px;

            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 1000ms;
            position: relative;
            padding: 7px;
            border-radius: 8px;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.06);

            #fmstscan {
              width: 146px;
              height: 146px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .isupdate {
              display: flex;
              flex-direction: column;
              position: absolute;
              width: 160px;
              height: 160px;
              top: 50%;
              left: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              transform: translate(-50%, -50%);
              border-radius: 8px;
              background: rgba(255, 255, 255, 0.9);

              .imgup {
                width: 32px;
                height: 32px;
              }

              .titup {
                font-size: 12px;
                font-weight: 400;
                color: #8a8b92;
                margin-top: 4px;
              }
            }
          }

          .imgr {
            margin-left: 10px;
            width: 160px;
            height: 160px;
            display: none;
            border-radius: 8px;
            box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
          }
        }

        .ma:hover>.imgr {
          transition: all 1000ms;
          display: block;
        }

        .allshow {
          position: absolute;
          top: 69px;
          height: 22px;
          line-height: 22px;
          @include font_style(14px, 400, #2c2c2c);
        }

        .name_fm {
          height: 28px;
          font-size: 18px;
          font-weight: bold;
          color: #2c2c2c;
          line-height: 28px;
          margin-top: 44px;
        }

        .name_intr {
          font-size: 14px;
          font-weight: 400;
          color: #8a8b92;
          height: 42px;
          line-height: 21px;
          margin-top: 14px;

          .intr {
            text-align: center;
          }
        }

        .button_home {
          display: flex;
          margin-top: 74px;
          font-size: 12px;
          font-weight: 400;
          color: #2c2c2c;
          justify-content: center;
        }
      }

      .rightBox {
        display: flex;
        flex-direction: column;
        padding-left: 32px;

        .rtop {
          height: 33px;
          font-size: 22px;
          font-weight: bold;
          color: #2c2c2c;
          line-height: 33px;
          margin-bottom: 23px;
        }

        .rtop1 {
          display: flex;

          .rtItem {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: #2c2c2c;
            line-height: 30px;
            padding-bottom: 4px;
            margin-right: 32px;
            border-bottom: 3px solid transparent;
            @include textUnSelect;
          }

          .rtItem_act {
            border-color: #f67942;
            color: #f67942;
          }
        }
      }
    }

    .step1 {
      position: relative;
      height: 100%;
      margin-top: 52px;

      .loginMethodFlag1 {
        .userBox {
          margin-top: 32px;
          //只给前面有select 框的加paddingleft：130px

          .phoneBoxe {
            position: relative;

            .elinput {
              ::v-deep .el-input__inner {
                padding-left: 15px !important;
                // width: 319px;
              }
            }

            ::v-deep .el-input {
              .el-input__inner {
                padding-left: 78px;
                // width: 319px;
              }
            }

            .elSelect {
              width: 76px;
              height: 50px;
              position: absolute;
              z-index: 100;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 2px;

              // left: -375px;
              // top: 3px;
              ::v-deep .el-input {
                .el-input__inner {
                  width: 76px;
                  height: 50px;
                  border-radius: 10px;
                  border: none;
                  padding-left: 0px;
                  text-align: right;
                  @include font_style(16px, 400, #2c2c2c);
                }
              }
            }
          }

          .password {
            margin-top: 16px;
          }

          .user1 {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            .rember {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              color: #8a8b92;

              .remberbox {
                margin-right: 8px;
                height: 22px;
                display: flex;
                align-items: center;

                // width: 16px;
                // height: 16px;
                .imgg {
                  width: 16px;
                  height: 16px;
                  box-sizing: border-box;
                  margin-top: -3px;
                }

                .notg {
                  width: 16px;
                  height: 16px;
                  border: 1px solid #e1e1e1;
                  border-radius: 4px;
                }
              }

              .rembertiit {
                height: 22px;
                line-height: 22px;
                @include font_style(14px, 400, #8a8b92);
              }
            }

            .forget {
              height: 22px;
              line-height: 22px;
              @include font_style(14px, 400, #F67942);
            }
          }
        }
      }

      .optherlogin {
        position: absolute;
        bottom: 33px;
      }
    }

    .step2 {
      margin-top: 20px;
      @include flex_layout(column, left, left);

      .yzName {
        height: 32px;
        line-height: 32px;
        @include font_style(22px, 600, #2c2c2c);
        margin-top: 8px;
      }

      .phoneInfo {
        @include font_style(16px, 400, #8a8b92);
        width: 382px;
        height: 48px;
        margin: 6px 0 32px 0;
        word-break: break-all;
      }

      .sendAgain {
        @include font_style(16px, 400, #f67942);
        height: 24px;
        line-height: 24px;
        margin-top: 12px;
      }
    }

    .step3 {
      padding: 24px 32px;
      @include flex_layout(column, left, left);

      .registerBox {
        @include flex_layout(column, left, left);
        width: 382px;
        margin: 8px auto;

        .regtit {
          height: 32px;
          line-height: 32px;
          @include font_style(22px, 600, #2c2c2c);
        }

        .regtit1 {
          @include font_style(16px, 400, #8a8b92);
          height: 24px;
          line-height: 24px;
          margin: 8px 0 32px 0;
        }
      }
    }

    .step4 {
      padding: 20px 23px;
      @include flex_layout(column, left, left);

      .findBox {
        @include flex_layout(column, left, left);
        width: 382px;
        margin: 8px auto;

        .findPassTit {
          height: 32px;
          line-height: 32px;
          @include font_style(22px, 600, #2c2c2c);
        }

        .findPassTit1 {
          @include font_style(16px, 400, #8a8b92);
          height: 24px;
          line-height: 24px;
          margin: 6px 0 32px 0;
        }

        .phoneBoxe {
          position: relative;

          ::v-deep .el-input {
            .el-input__inner {
              padding-left: 78px;
            }
          }

          .elSelect {
            width: 76px;
            height: 50px;
            z-index: 100;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 2px;

            ::v-deep .el-input {
              .el-input__inner {
                width: 76px;
                height: 50px;
                border-radius: 40% 0 0 40%;
                border: none;
                padding-left: 0px;
                text-align: right;
                @include font_style(16px, 400, #2c2c2c);
              }

              .el-input__inner:focus {
                background: #f6f6f6 !important;
              }
            }
          }

          .elSelect1 {
            ::v-deep .el-input {
              .el-input__inner {
                background: #fff !important;
              }

            }
          }
        }

        .findType {
          height: 24px;
          line-height: 24px;
          @include font_style(16px, 400, #8a8b92);
          margin-top: 12px;
          @include textUnSelect;

          .fdtype1 {
            height: 24px;
            line-height: 24px;
            margin-left: 4px;
            @include font_style(16px, 400, #f67942);
          }
        }
      }

      .yzBox {
        @include flex_layout(column, left, left);
        width: 382px;
        margin: 8px auto;

        .yzBoxTit {
          height: 32px;
          line-height: 32px;
          @include font_style(22px, 600, #2c2c2c);
        }

        .yzBoxTit1 {
          height: 48px;
          padding-right: 8px;
          line-height: 24px;
          @include font_style(16px, 400, #8a8b92);
          margin: 8px 0 32px 0;
        }

        .sendAgain1 {
          @include font_style(16px, 400, #f67942);
          height: 24px;
          line-height: 24px;
          margin-top: 12px;
        }
      }

      .findType {
        height: 24px;
        line-height: 24px;
        @include font_style(16px, 400, #8a8b92);
        margin-top: 12px;

        .fdtype1 {
          height: 24px;
          line-height: 24px;
          margin-left: 4px;
          @include font_style(16px, 400, #f67942);
        }
      }

      .wxlogin {
        @include flex_layout(column, center, center);
        width: 570px;
        margin: 86px auto;

        .wxtit {
          height: 36px;
          line-height: 36px;
          @include font_style(24px, 600, #2c2c2c);
        }

        .wxCode {
          display: flex;
          flex-direction: column;
          align-items: center;

          .wxtit {
            font-size: 24px;
            font-weight: bold;
            color: #111734;
          }

          .wxtit2 {
            font-size: 14px;
            font-weight: 400;
            color: #686a74;
            margin-top: 16px;
          }

          #wxCode {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .wxtit1 {
          width: 112px;
          height: 21px;
          font-size: 14px;
          font-weight: 400;
          color: #8a8b92;
          line-height: 21px;
          margin-top: 228px;
        }
      }

      .resetBox {
        @include flex_layout(column, left, left);
        width: 382px;
        margin: 0 auto;

        .resetBoxTit {
          @include font_style(22px, 600, #2c2c2c);
          height: 32px;
          line-height: 32px;
          margin-top: 10px;
        }

        .resetBoxTit1 {
          @include font_style(16px, 400, #8a8b92);
          height: 24px;
          line-height: 24px;
          margin: 6px 0 32px 0;
        }
      }
    }
  }

  ::v-deep .el-dialog {
    width: 480px;
    // height: 188px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
  }

  ::v-deep .el-input.is-error .el-input__inner {
    border-color: #f53f3f !important;
  }

  ::v-deep .el-dialog__header {
    padding: 20px 24px 10px 24px;
  }

  ::v-deep .el-dialog__title {
    font-size: 16px;
    font-weight: 500;
    color: #2c2c2c;
  }

  ::v-deep .el-dialog__body {
    padding: 18px 24px 24px 24px;
  }

  .quit {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .pop_icon {
      width: 24px;
      height: 24px;
    }

    .pop_tit {
      width: 234px;
      height: 28px;
      line-height: 28px;
      @include font_style(18px, 500, #2c2c2c);
      margin-left: 8px;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 30px;

    .cancel {
      width: 64px;
      height: 36px;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      @include font_style(14px, 500, #2c2c2c);
      margin-right: 16px;
    }

    .confirm {
      width: 64px;
      height: 36px;
      background: #f67942;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include font_style(14px, 500, #fff);
    }
  }
}

.phone-code-text {
  font-size: 16px;
  font-weight: 400;
  color: #8a8b92;
  line-height: 24px;
  margin-bottom: 16px;
}

.username-box {
  height: 216px;

  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.username-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px 12px;
  

  &+& {
    margin-top: 16px;
  }

  &.active {
    background-color: #f6f6f6;

    .u-tag {
      background-color: #ffffff;
    }
  }

  &:hover {
    background-color: #f6f6f6;

    .u-tag {
      background-color: #ffffff;
    }
  }

  .mid {
    flex: 1;
    width: 0;

    .text1 {
      font-size: 16px;
      font-weight: 500;
      color: #2c2c2c;
      line-height: 24px;
      display: flex;
      align-items: center;

      .uname-box {
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .text2 {
      margin-top: 2px;
      font-size: 12px;
      font-weight: 400;
      color: #8a8b92;
      line-height: 18px;
    }
  }

  .user-avatar {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .select-box {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1.5px solid #e9e9e9;
    background-color: #ffffff;
    border-radius: 100%;

    &:hover {
      border-color: #f67942;
    }

    img {
      position: absolute;
      left: -1.5px;
      top: -1.5px;
      width: 20px;
      height: 20px;
    }
  }

  .u-tag {
    margin-left: 8px;
    border-radius: 10px;
    padding: 1px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    background-color: #f6f6f6;
    color: #8a8b92;
  }
}

.username-avatar-box {
  text-align: center;
  margin-top: 64px;
  margin-bottom: 40px;
}

.img-user-avatar {
  display: inline-block;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.text-username {
  margin-top: 12px;
  font-size: 18px;
  font-weight: 500;
  color: #2c2c2c;
  line-height: 28px;
}

::v-deep .el-input {
  .el-input__inner {
    background: #F6F6F6;
    border-radius: 28px;

    &:hover {
      border: 1px solid #f67942;
    }

    &:focus {
      border: 1px solid #f67942 ;
      background: #ffffff !important;
    }
  }

  .el-input__suffix {
    display: flex;
    align-items: center;

    .el-icon-error {
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.Disclaimer {
  margin-top: 16px;

  .Disclaimer-title {
    display: flex;
    align-items: center;
    color: #8A8B92;

    .shareicon1 {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}

.intrBox {
  display: flex;
  margin-top: 8px;
  align-items: center;


  .imgi {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .intrName {
    height: 18px;
    font-weight: 400;
    font-size: 12px;
    color: #8A8B92;
    line-height: 18px;
  }
}

.intrtit {
  margin-top: 4px;
  font-size: 12px;
  width: 365px;
  word-break: break-all;
  color: #FFFFFF;
}

.titleNew {
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #2C2C2C;
  line-height: 24px;
  margin: 37px 0 12px 0;
}

.emailReg {
  ::v-deep .el-popper[x-placement^=bottom] .popper__arrow {
    display: none;
  }

  ::v-deep .el-input__clear {
    background: #F7F7F7 !important;
    width: 18px;
    position: absolute;
    right: 11px;
    opacity: 0;
  }
}

::v-deep .el-popper[x-placement^=bottom] .popper__arrow {
  display: none;
}
::v-deep .el-autocomplete-suggestion__wrap{
  height: 180px;
}

::v-deep .el-autocomplete-suggestion {
  top: 48px !important;
  left: 0 !important;
  border-radius: 10px;
  height: 180px;
}

::v-deep .el-autocomplete-suggestion li {
  // color: #ffffff;
  margin: 0 6px;
  padding: 0 14px;
  margin: 0 6px !important;
  border-radius: 5px;
  color: #2C2C2C;
}

::v-deep .el-autocomplete-suggestion li:hover {
  background: #F7F7F7;
}

.suffix-close-box {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.suffix-close {
  width: 18px;
  cursor: pointer;
}
</style>
