<template>
  <el-dialog
      :visible.sync="isShow"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width=" 720px"
      custom-class="upZipDialog" 
  >
  <div class="dialog-body">
    <div class="dialog-body2" v-loading="loading" v-show="loading">
    </div>
    <div class="d-header">
      <div class="left">
        <span>在线云解压</span>
      </div>
      <fm-button @click="handleCloseBox" type="info" size="mini" text icon="icon-model-close"></fm-button>
    </div>
    <div class="d-content">
      <div class="info">
        <img class="icon" :src="handleItem.ext_icon">
        <div>
          <el-tooltip popper-class="preview-tooltip" :content="handleItem.name+handleItem.extension" placement="bottom">
            <span class="name">{{handleItem.name}}{{ handleItem.extension }}</span>
          </el-tooltip>
          <div class="size">
              <span>{{handleItem.size}}</span>
              <span class="line"></span>
              <span>共{{allTotalNum}}项文件</span>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="folder_menu file-directory">
          <el-tooltip  class="item r-tooltip" effect="dark"  content="全部文件" placement="top">
            <span @click="resetRouter" class="pointer" :class="{'allFile-span':path.length > 0}"
              >全部文件</span>
          </el-tooltip>
          <svg-btn
            icon-class="icon-file-direction"
            class="allFile-icon"
            v-show="path.length > 0"
          />
          <div class="lessthen4" v-if="path.length < 4">
            <div
              class="one"
              v-for="(item,index) in path"
              :key="item[0]"
              @click="setFolderRouter(item,index)"
            >
              <el-tooltip :content="item" placement="top">
                <span class="el-dropdown-link name"> {{ item }}</span>
              </el-tooltip>
              <svg-btn icon-class="icon-file-direction" />
            </div>
          </div>
          <div class="lessthen4 morethen4" v-else>
            <div class="one" @mousemove="showboxBreadMove = true">
              ...
              <svg-btn icon-class="icon-file-direction" />
            </div>
            <div
              class="one"
              v-for="(item, index) in path.slice(
                path.length - 2,
                path.length
              )"
              :key="index"
              @click="setFolderRouter(item,index,-1)"
            >
              <el-tooltip :content="item" placement="top">
                <span class="el-dropdown-link name">
                  {{ item }}
                </span>
              </el-tooltip>
              <svg-btn icon-class="icon-file-direction" />
            </div>
          </div>
          <div
            class="boxBreadMove"
            v-if="
              showboxBreadMove &&
              path.slice(0, path.length - 2).length > 0
            "
            @mouseleave="showboxBreadMove = false"
          >
            <div class="boxbreadbox2">
              <div
                class="boxOne pointer"
                v-for="(item, index) in path.slice(0, path.length - 2)"
                :key="index"
                @click="setFolderRouter(item,index)"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="tab_head">
          <svg-btn @click="allCheck == 2 ? cancelCheckedVal() : setCheckedVal()" :icon-class=" allCheck == 2
                ? 'icon-file-check'
                : allCheck == 0
                ? 'icon-file-uncheck-'+$store.state.theme
                : 'icon-file-checks'">
          </svg-btn>
          <span  v-show="allCheck ==0">文件名</span>
          <div class="count" v-show="allCheck !=0">
            <span >全选</span>
            <span >已选择 {{checkedZipFiles.length}} 项</span>
          </div>
          <span class="size">大小</span>
        </div>
        <div class="tab_body">
          <div class="tab_content" v-if="zipFileList.length > 0">
            <div class="item" :class="{ selItem: checkedZipFiles.indexOf(index) > -1, 'item1': item.is_unzip != '1'}" v-for="(item,index) in zipFileList" :key="index"  @click="item.is_dir == '1' ? setFolder(item) : ''">
                <svg-btn @click.stop="chooseZipFile(index)"
                  v-show="item.is_unzip == '1'"
                    class="icon_checks"
                    :icon-class="
                      checkedZipFiles.indexOf(index) > -1
                        ? 'icon-file-check'
                        : 'icon-file-uncheck-'+$store.state.theme
                    "
                ></svg-btn>
                 <svg-btn
                    v-show="item.is_unzip != '1'"
                    class="icon_checks not_choosed"
                    :icon-class="'icon-checkbox-disable-'+$store.state.theme"
                  ></svg-btn>
                <img class="icon" :src="item.ext_icon">
                <div class="nameBox">
                   <span class="name">{{item.name}}</span>
                   <el-tooltip popper-class="preview-tooltip"  content="该文件暂不支持解压" placement="bottom-start">
                      <svg-btn
                        v-show="item.is_unzip != '1'"
                        class="icon_gantanhao"
                        icon-class="icon-gantanhao"
                      ></svg-btn>
                    </el-tooltip>
                   
                </div>
                <span class="size" :class="{'item1': item.is_unzip != '1'}">{{item.is_dir =='1'?item.count +'项':item.size}}</span>
            </div>
          </div>
          <div class="file-default tab_empty" v-else>
            <img class="icon_empty" :src="$utils.getPng('web4/file_default')" />
            <div class="goUplod" >
              <span>暂无文件</span>
            </div>
        </div>
        </div> 
      </div>
    </div>
    <div class="d-footer">
      <div class="left">
        <span>解压到：</span>
        <div class="save" @click="getFolderMoveList(decDirectory)">
          <el-tooltip :content="moveName" placement="top">
            <span class="el-dropdown-link name">
              {{moveName}}
            </span>
          </el-tooltip>
        </div>
      </div>
      <fm-button class="btn_1" v-loading='startButtonLoading' type="primary" size="big" @click="allCheck == 0 ? setPress(2) : setPress(1)">{{ allCheck == 0 ? '解压全部文件' : '开始解压' }}</fm-button>
    </div>
  </div>
    <!-- 解压到 -->
    <FileDirDialog
      :modal="false"
      :isShow="showFileDirDialog"
      :type="dirHandleType"
      :list="folderList"
      :upconfirmLoading="upconfirmLoading"
      :moveNav="moveNav"
      @handleClose="folderDialogClose"
      @changeNav="changeNav"
      @getFolder="getFolderMoveList"
      @doFileDir="doFileDir"
    ></FileDirDialog>

    <!-- 确认 -->
    <UpZipConfirm :prohibitInfo="prohibitInfo" :isShow="isShowUpZipConfirm" @handleClose="isShowUpZipConfirm=false" @confirm="confirmStart">
    </UpZipConfirm>
    <!-- 空间已满 -->
    <PromoteDialog
      @handleClose="isShowPromote = false"
      @handleClose2="handleClose2Fn"
      :isShow="isShowPromote"
      :msg="promoteMsg"
      :vip_update="vip_update"
      :type="promoteType"
      :icon="icon"
    >
    </PromoteDialog>
  </el-dialog>
</template>

<script>
import {
  queryZipInfoTask,
  createUnZipAsyncTaskV2,
  createZipQueryInfoTaskV2,
  folderMoveList,
} from "@/utils/apiList/cloud";
import FileDirDialog from "../Dialog/FileDirDialog";
import UpZipConfirm from '@/components/Cloud/UpZipConfirm';
import PromoteDialog from "@/components/Dialog/PromoteDialog";

export default {
  components: {
    FileDirDialog,
    UpZipConfirm,
    PromoteDialog
  },
  props:{
    isShow:Boolean,
    handleItem:Object,
    zipPwd:String
    
  },
  data(){
    return{
      path: [], //压缩包列表查询参数 文件加名称路径数组
      showboxBreadMove:false,
      zipFileList:[],//压缩包列表
      checkedZipFiles: [], //选中
      allCheck: 0,
      showFileDirDialog: false,
      dirHandleType: 2,
      moveNav: [],
      lastNav:[],
      moveName:'全部文件',
      folderList:[],
      pid:'0',
      folderIds: [], //选中文件夹名称数组
      fileIds: [], //选中文件名称数组
      loading:false,
      isShowUpZipConfirm:false,
      isShowPromote:false,
      vip_update: "",
      promoteMsg: "",
      promoteType: 3,
      icon:'',
      totalNum:0, //解压文件总数
      prohibitNum:0,//不可解压数
      prohibitInfo:'',
      zipFlag:0,
      allTotalNum:0,//总文件数
      startButtonLoading:false,
      decDirectory:{}
    }
  },
  watch: {
    checkedZipFiles: {
      handler(newval, oldVal) {
        let zipFileList = this.zipFileList.filter((item) => item.is_unzip == "1")
        if (newval.length > 0 && newval.length < zipFileList.length) {
          this.allCheck = 1;
        } else if (newval.length == 0) {
          this.allCheck = 0;
        } else if (newval.length == zipFileList.length) {
          this.allCheck = 2;
        }
      },
      immediate: true,
    },
    isShow:{
      handler(newval){
        if(newval){
           this.path = []
           this.getFolderUpzip()
        }
      }
    },
  },
  created(){
  },
  methods:{
    handleClose2Fn(){
      this.$utils.routeModeReported('C18');
    },
    handleCloseBox(){
      this.$emit('handleClose')
      //李总说关闭的时候 出现缺省图 体验不好等关闭了再清
      setTimeout(() => {
        this.path=[];
        this.checkedZipFiles=[];
        this.zipFileList=[];
        this.allCheck=0;
      }, 300);
    },
    //压缩包解压预览
    queryZipInfoTask(id) {
      let params = {
        file_id:id,
        path: this.path,
        password: this.zipPwd,
      };
      this.loading = true;
      createZipQueryInfoTaskV2(params).then((res) => {
        this.loading = false;
        if (res.status == "1") {
          this.zipFileList = res.data.list;
          this.showPressPwd = false;
        } else if (res.status == "30001") {
          this.showPressPwd = true;
        }else if(res.status == "30002"){//解压密码错误
          this.$toast(res.msg, 2);
        } else if (res.status == "5000") {
          this.$toast(res.msg, 2);
        } else if (res.status == "82000") {//解压非会员提示
          this.icon = "icon_diamond";
          this.promoteType = 3;
          this.promoteMsg = res.msg
          this.isShowPromote = true;
          this.vip_update = '2';
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    setFolder: _.debounce(
      function (item) {
        this.allCheck = 0;
        this.checkedZipFiles = [];
        this.path.push(item.name)
        this.queryZipInfoTask(this.handleItem.id)
      },
      500,
      {
          leading: true, //指定在延迟开始前调用
          trailing: false, //指定在延迟结束后调用
      }
    ),
    //文件夹点击顶部路径
    setFolderRouter(name,index,flag = 0){
      this.allCheck = 0;
      this.checkedZipFiles = [];
      let spliceIndex = index + 1;
      if(flag == -1){
       if(index == 0){
        spliceIndex = this.path.length -1
       }else{
        spliceIndex = this.path.length 
       }
      }
      this.path.splice(spliceIndex );
      this.showboxBreadMove = false;
      this.queryZipInfoTask(this.handleItem.id);
    },
    //重置到根目录
    resetRouter() {
      this.path = [];
      this.checkedZipFiles = [];
      this.queryZipInfoTask(this.handleItem.id);
    },
    //选择压缩包的文件
    chooseZipFile(index) {
      // 点击文件选中
      var exist = this.checkedZipFiles.indexOf(index);
      if (exist == -1) {
        this.checkedZipFiles.push(index);
      } else {
        this.checkedZipFiles.splice(exist, 1);
      }
    },
    // 全选
    setCheckedVal() {
      // 点击文件选中
      this.checkedZipFiles = [];
      var tmpfiles = this.zipFileList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        if (this.zipFileList[i].is_unzip == "1") {
          this.checkedZipFiles.push(i);
        }
      }
    },
    // 取消全选
    cancelCheckedVal() {
      this.allCheck = 0;
      this.checkedZipFiles = [];
    },
    //获取云解压文件夹id
    getFolderUpzip: _.debounce(
      function () {
        let params = {
          id:  0,
        };
        folderMoveList(params)
          .then((res) => {
            if (res.status == "1") {
              //首次默认选中云解压的解压路径
              res.data.list.find((v)=>{
                if(v.name == '云解压'){
                  this.pid = v.id;
                  this.moveNav = [{ name: "全部文件", id: "0" },{ name: v.name, id: v.id }];
                  this.lastNav = [{ name: "全部文件", id: "0" },{ name: v.name, id: v.id }];
                  let nameArr = this.moveNav.map((v)=>v.name)
                  this.moveName = nameArr.join('/'); 
                  this.decDirectory = { name: v.name, id: v.id ,id1:'dec'}
                }
              })
            } else {
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {});
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //移动文件夹目录列表  移动弹窗-点击文件夹-进入里层文件夹
    getFolderMoveList: _.debounce(
      function (folder) {
        if (folder) {
          if(folder.id1){
             this.moveNav = [{ name: "全部文件", id: "0" },{name:folder.name,id:folder.id}];
          }else{
             let idlist = this.moveNav.map((v)=>v.id);
             if(idlist.indexOf(folder.id) == -1){
                this.moveNav.push(folder);
             }
          }
        } else {
          this.moveNav = [{ name: "全部文件", id: "0" }];
        }
        let params = {
          id: folder ? folder.id : 0,
        };
        folderMoveList(params)
          .then((res) => {
            if (res.status == "1") {
              this.folderList = res.data.list;
              this.showFileDirDialog = true;
            } else {
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {});
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    // 移动弹窗-点击顶部文件夹路径-跳出当层文件夹
    changeNav(item, index) {
      let arr = this.moveNav.slice(0, index + 1);
      this.moveNav = arr;
      folderMoveList({ id: item.id })
        .then((res) => {
          if (res.status == "1") {
            this.folderList = res.data.list;
            this.isShowFileDir = true;
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    //解压到
    doFileDir() {
      this.pid = this.moveNav.length > 0 ? this.moveNav[this.moveNav.length - 1].id : 0;
      if(this.moveNav.length > 0){
        let nameArr = this.moveNav.map((v)=>v.name);
        this.lastNav = this.moveNav;
        this.moveName = nameArr.join('/'); 
        this.decDirectory = this.moveNav[this.moveNav.length - 1];
      }else{
        this.moveName ='全部文件'
      }
      this.showFileDirDialog=false
    },
    confirmStart(){
      this.setPress(this.zipFlag,1)
    },
    folderDialogClose(){
      this.showFileDirDialog = false;
      this.moveNav = this.lastNav
    },
    //type 2 全部解压 1 选中解压，choosed == 0 第一次 choosed == 1 展示确认弹框再次点击不需要校验这些
    setPress: _.debounce(
      function (type,choosed=0) {
        if (this.startButtonLoading) return;
        this.isShowUpZipConfirm=false;
        this.totalNum = 0;
        this.prohibitNum = 0;
        this.zipFlag = type;
        this.folderIds = [];
        this.fileIds = [];
        this.getSelFileIds();
        //全选的时候也需要遍历zipFileList 获取总数和不可解压数 
        if(type == 2 && choosed == 0) {
          this.zipFileList.map((item) => {
            if (item.is_dir == "1") {
              this.totalNum = parseInt(this.totalNum) + parseInt(item.totalNum)
              this.prohibitNum = parseInt(this.prohibitNum) + parseInt(item.prohibitNum)
            }else{
              this.totalNum +=1
              if(item.is_unzip == "0"){
                this.prohibitNum += 1
              }
            }
          });
        }
        let dirsList = [];
        for (var i = 0; i < this.folderIds.length; i++) {
          dirsList.push([...this.path, this.folderIds[i]]);
        }
        let filesList = [];
        for (var i = 0; i < this.fileIds.length; i++) {
          filesList.push([...this.path, this.fileIds[i]]);
        }
        if(this.prohibitNum > 0 && choosed == 0){
          this.prohibitInfo = `当前可解压${ this.totalNum - this.prohibitNum }项文件，其中${ this.prohibitNum }项文件暂不支持解压`
          this.isShowUpZipConfirm=true;
          return;
        }
        let params = {
          file_id: this.handleItem.id,
          files: type == 2 ? [] : filesList,
          dirs: type == 2 ? [] : dirsList,
          password: this.zipPwd,
          pid: this.pid, //解压到哪个文件夹
        };
        this.startButtonLoading = true
        createUnZipAsyncTaskV2(params).then((res) => {
          this.startButtonLoading = false;
          this.upconfirmLoading = false;
          if (res.status == "1") {
            this.$toast(res.msg, 1);
            this.showFileDirDialog = false;
            this.$parent.closeZip()
            this.checkedZipFiles = [];
            this.allCheck = 0;
            this.$store.commit("getProgressTaskcallback");
            this.$store.commit("setAttr", {
              name: "showUpDownBox",
              val: 1,
            });
            this.$store.commit("setAttr", {
              name: "navAct",
              val: 2,
            });
            this.step = 1;
          } else if (res.status == 30001) {
            //解压密码不能为空
            this.showPressPwd = true;
          } else if (res.status == "52001") {
            this.vip_update = res.data.vip_update;
            this.promoteType = 3;
            this.promoteMsg =
              res.data.vip_update == "1"
                ? "您的云盘空间已满，请升级会员继续使用"
                : "您的云盘空间已满";
            this.icon='icon_space'
            this.isShowPromote = true;
          }else if (res.status == "82000") {//解压非会员提示
            this.icon = "icon_diamond";
            this.promoteType = 3;
            this.promoteMsg = res.msg
            this.isShowPromote = true;
            this.vip_update = '2';
          } else {
            this.$toast(res.msg, 2);
          }
        }); 
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    // 获取选中的文件的id
    getSelFileIds() {
      this.checkedZipFiles.map((item) => {
        if (this.zipFileList[item].is_dir == "1") {
          this.folderIds.push(this.zipFileList[item].name);
          this.totalNum = parseInt(this.totalNum) + parseInt(this.zipFileList[item].totalNum)
          this.prohibitNum = parseInt(this.prohibitNum) + parseInt(this.zipFileList[item].prohibitNum)
        } else {
          this.fileIds.push(this.zipFileList[item].name);
          if(this.zipFileList[item].is_unzip == "1"){
            this.totalNum += 1
          }
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-body2{
  width: 720px;
  height: 512px;
  position: absolute;
  top: 0;
  left: 0;
  ::v-deep .el-loading-mask{
    border-radius: 16px !important;
  }
}
.upZipDialog{
  .info{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 24px;
    height: 44px;
    .icon{
        width: 40px;
        height: 40px;
        margin-right: 12px;
        margin-left: 20px;
        @include textUnSelect();
    }
    .name{
        max-width: 600px;
        height: 22px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden; 
        text-overflow: ellipsis; 
        display: inline-block;
        @include useTheme(){
            color:getVar('text-color2-2c2c2c');
        }
    }
    .size{
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 20px;
        @include useTheme(){
            color:getVar('text-color1-8a8b92');
        }
        .line{
            display: inline-block;
            height: 12px;
            width: 1px;
            margin: 0 8px;
            @include useTheme(){
                background:getVar('text-color1-e5e5e5');
            }
        }
    }
  }
  .list{
    border-radius: 8px;
    width: 672px;
    height: 296px;
    @include useTheme{
        border:getVar('border-color-e5e5e5');
    }
    .folder_menu{
      height: 40px;
      border-radius: 7px 7px 0px 0px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      @include useTheme{
        background: getVar('text-color1-f6f6f6');
      }
      .allFile-icon{
        width: 12px;
        height: 12px;
        margin: 0 6px;
      }
      .lessthen4 {
        display: flex;
        @include useTheme{
            color: getVar("text-color1-8a8b92");
        }
        .svg-icon{
            width: 12px;
            height: 12px;
            margin:  0 6px;
        }
        .one{
            display: flex;
            align-items: center;
            cursor: pointer;
            &:last-child{
                .name{
                    @include useTheme{
                        color: getVar("text-color1-2c2c2c") ;
                    }
                }
                .svg-icon{
                    display: none;
                }
            }
        }
      }
      .boxBreadMove{
        top: 34px;
      }
    }
    .tab_head{
      height: 44px;
      display: flex;
      align-items: center;
      .svg-icon{
        width: 14px;
        margin-right: 12px;
        margin-left: 20px;
        cursor: pointer;
      }
      span{
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        @include textUnSelect();
        @include useTheme(){
            color:getVar('text-color1-8a8b92');
        }
      }
      .size{
        width: 130px;
        margin-right: 0;
        margin-left: auto;
      }
      .count{
        span{
          &:last-child{
            margin-left: 20px;
          }
        }
      }
    }
    .tab_body{
      height: 212px;
      padding-bottom: 4px;
    }
    .tab_content{
      height: 208px;
      overflow: auto;
      // scrollbar-color: transparent transparent;
      // &::-webkit-scrollbar-thumb {
      //   background-color: transparent;
      // }
      // &:hover {
      //   scrollbar-color: #8a8e99 transparent;
      //   &::-webkit-scrollbar-thumb {
      //     background-color: #8a8e99;
      //   }
      // }
      .item{
        position: relative;
        height: 56px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @include textUnSelect;
        @include useTheme{
          box-shadow:getVar('shadow-f6f6f6');
        }
        &:hover{
          @include useTheme{
            background: getVar("text-color1-fff2ec");
          }
        }
        .svg-icon{
          width: 14px;
          margin-left: 20px;
        }
        .icon{
          width: 28px;
          height: 28px;
          margin: 0 16px 0 12px;
        }
        .nameBox{
          display: flex;
          align-content: center;
          width: 428px;
          .name{
            max-width: 408px;
            white-space: nowrap;
            overflow: hidden; 
            text-overflow: ellipsis; 
            height: 22px;
            line-height: 22px;
          }
          .icon_gantanhao{
            margin-left: 8px;
            margin-top: 2px;
            width: 12px ;
            
          }
        }

        .size{
          width: 130px;
          margin-left: auto;
          margin-right: 0;
          font-size: 12px;
          line-height: 20px;
          @include useTheme(){
              color:getVar('text-color1-8a8b92');
          }
        }
      }
      .item1{
        @include useTheme(){
              color:getVar('text-color1-d9d9d9') !important;
          }
      }
      .selItem{
        @include useTheme{
          background: getVar("text-color1-fff2ec");
        }
      }
    }
    .tab_empty{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 208px;
      margin-top: 0;

    }
  }
  .d-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    .left{
      display: flex;
      align-items: center;
      .save{
        max-width: 172px;
        height: 32px;
        line-height: 32px;
        padding: 0 16px;
        border-radius: 8px;
        cursor: pointer;
        @include useTheme{
          border: getVar('border-color-e5e5e5');
        }
        span{
          display: inline-block;
          max-width: 140px;
          white-space: nowrap;
          overflow: hidden; 
          text-overflow: ellipsis; 
        }
      }
    }
  }
}
.d-header{
  @include textUnSelect;
}
.btn_1{
  ::v-deep .el-loading-mask .el-loading-spinner .circular{
    width: 26px;
    height: 26px;
    margin-top: 8px;
  }
  ::v-deep .el-loading-mask{
    border-radius: 8px;
  }

}
</style>