<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/views/DownPage/compoents/memberPopUp.vue
 * @Description: 转存下载会员二次确认弹窗
-->
<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    :append-to-body="true"
    custom-class="memberPopUp"
    :close-on-click-modal="false"
  >
    <div class="dialog-body" style="min-height: 136px">
      <div class="d-header">
        <div class="left">
          <img :src="$utils.getPng('web4/icon-circle-fill')" />
          <span>{{ downFileFlag ? "确定下载文件？" : "确定转存文件？" }}</span>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content" style="margin-top: 30px">
        <img
          @click="tovip()"
          class="popupImg pointer"
          :src="rebate_model_popup_img"
        />
      </div>

      <div class="d-footer" style="margin-top: 24px">
        <fm-button
          style="min-width: 120px"
          @click="toTurnFile()"
          round
          size="medium"
          >{{
            !downFileFlag
              ? "转存至云盘"
              : $store.state.userInfo.is_vip == 1
                ? "转存并极速下载"
                : "转存并普通下载"
          }}</fm-button
        >
        <fm-button
          style="width: 120px"
          type="info"
          plain
          round
          size="medium"
          @click="todown"
          v-if="downFileFlag"
          >{{
            $store.state.userInfo.is_vip == 1 ? "极速下载" : "普通下载"
          }}</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    rebate_model_popup_img: {
      type: String,
      default: "",
    },
    downFileFlag: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },

    todown() {
      this.$emit("todown");
    },
    toTurnFile() {
      this.$emit("toTurnFile", this.downFileFlag);
    },
    tovip() {
      if (this.downFileFlag == 0) {
        //转存 不需要判断会员
        this.$utils.routeModeReported("C36", this.$store.state.voucher);
      } else {
        //下载判断会员身份
        if (this.$store.state.userInfo.is_vip == 1) {
          //会员
          this.$utils.routeModeReported("C34", this.$store.state.voucher);
        } else {
          this.$utils.routeModeReported("C35", this.$store.state.voucher);
        }
      }
      window.open("/vip", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.memberPopUp {
  .d-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .d-footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
  .popupImg {
    width: 416px;
  }
}
</style>
