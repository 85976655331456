<template></template>

<script>
import SparkMD5 from "spark-md5";
import OneUpload from "@/utils/upload/newUpload";
import { getDirectAvatarUploadLinkAPI } from "@/utils/apiList/account";
export default {
  name: "Upload",
  data() {
    return {
      upDis: false,
      newUpList: [],
    };
  },
  computed: {
    isNewUpload() {
      let _userInfo = this.$store.state.userInfo || {};
      return Number(_userInfo.upload_type) || 1;
    },
    part_size() {
      let _userInfo = this.$store.state.userInfo || {};
      return Number(_userInfo.upload_part_size) || 2 * 1024 * 1024;
    },
  },
  methods: {
    hexToBinaryString(hex) {
      let bytes = [],
        length = hex.length,
        x;

      for (x = 0; x < length - 1; x += 2) {
        bytes.push(parseInt(hex.substr(x, 2), 16));
      }

      return String.fromCharCode.apply(String, bytes);
    },
    delUpList(id) {
      for (let i = this.newUpList.length - 1; i >= 0; i--) {
        let vl = this.newUpList[i];
        if (vl.id === id) {
          vl.cancel();
          this.newUpList.splice(i, 1);
        }
      }
    },
    delAllUpList() {
      for (let i = this.newUpList.length - 1; i >= 0; i--) {
        this.newUpList[i].cancel();
        this.newUpList.splice(i, 1);
      }
    },
    asyncUpload(file, type) {
      return new Promise((resolve) => {
        this.upDis = true;
        let opt = {
          id: file.uid,
          name: file.name,
          prdMd5: "",
          fileStatus: 0,
          size: file.size,
          file: file,
          currentSpeed: 0,
          loaded: 0,
          sizeM1: this.part_size,
          $store: this.$store,
        };
        let _file = new OneUpload(opt, resolve, "async");
        this.newUpList.push(_file);
        this.newComputeMD5(_file, type, resolve);
      });
    },
    handleReadFile(_blob) {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer();
        fileReader.onloadend = (e) => {
          spark.append(e.target.result);
          resolve(spark.end());
        };
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(_blob);
      });
    },
    async newComputeMD5(file, type, callback) {
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      let hashAll = "";
      let _flag = true;
      for (let i = 0; i < file.part_count; i++) {
        let _blob;
        if (i === file.part_count - 1) {
          _blob = blobSlice.call(file.file, i * file.sizeM1, file.size);
        } else {
          _blob = blobSlice.call(
            file.file,
            i * file.sizeM1,
            (i + 1) * file.sizeM1
          );
        }
        try {
          let _md5 = await this.handleReadFile(_blob);
          hashAll += this.hexToBinaryString(_md5);
          file.md5List.push({ id: 1, md5: _md5 }) 
        } catch (e) {
          _flag = false;
          break;
        }
      }
      if (!_flag) {
        file.prdMd5 = "";
        callback({
          action: "stepFail",
        });
        return;
      }
      file.md5Flag = 2;
      let md5 = SparkMD5.hashBinary(hashAll);
      file.prdMd5 = md5;
      file.uniqueIdentifier = md5;
      this.createUpTask(file, type, callback);
    },
    createUpTask(file, type, callback) {
      // 2.后端公钥加密
      // rsa公钥加密keyPair.publicKey,key
      let _data = {
        hash: file.prdMd5,
        totalSize: file.size,
        filename: file.name,
        sub_type: type,
      };
      getDirectAvatarUploadLinkAPI(_data).then((res) => {
        if (res.status == 1) {
          file.task_id = res.data.task_id;
          file.merge_part_url = res.data.merge_part_url;
          file.upload_part_url = res.data.upload_part_url;
          file.cancel_url = res.data.cancel_url;
          file.resume();
        } else {
          callback({
            action: "fail",
            msg: res.msg,
            id: file.id,
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
