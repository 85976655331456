<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/components/Dialog/BrowserRestrict.vue
 * @Description: 浏览器限制
-->

<template>
  <div class="EquipmentDialog" id="equipmentDia">
    <el-dialog :visible.sync="equipmentFlag0" :close-on-click-modal="false" :append-to-body="true">
      <div class="diaBox">
        <div class="dialogTop">
          <div class="left">
            <img class="icon" :src="$utils.getPng('web4/icon_i')" alt="" srcset="" />
            <div class="tit2">安全提示</div>
          </div>
          <fm-button @click="close1()" type="info" size="mini" text icon="icon-model-close"></fm-button>
        </div>
        <div class="dialogCenter">
          <img class="icon" :src="$utils.getPng('web4/icon-browser')" alt="" srcset="">
          <div class="tit">
            该账号已在多个常用地点登录浏览器，已达到上限
          </div>
          <div class="tit2">本次登录地点：{{ adress }}</div>

          <div class="bottom">
            <fm-button style="width: 124px; height: 36px" type="info" size="small" plain round
              @click="browserFlag = true">
              常用登录地点</fm-button>
            <fm-button style="width: 124px;height: 36px;margin-left: 16px;" size="small" type="info" plain round
              @click="close1()">
              我知道了</fm-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="browserFlag" :close-on-click-modal="false" :append-to-body="true">
      <div class="diaBox">
        <div class="dialogTop">
          <div class="left">
            <div class="tit2">浏览器登录地点</div>
          </div>
          <fm-button @click="close2()" type="info" size="mini" text icon="icon-model-close"></fm-button>
        </div>
        <div class="dialogCenter1">
          <div class="contentBox">
            <div class="intr">{{ prompt }}</div>
            <div class="list">
              <div class="item" v-for="item in loginAdressList" :key="item.update_at">
                <div class="address">
                  <div class="name">{{ item.ip_home_place }}</div>
                  <div class="time">最近登录：{{ item.update_at }}</div>
                </div>
                <fm-button style="width: 88px;height: 32px;" type="info" plain size="small" @click="deleteItem(item)">
                  删除地点</fm-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="deleteBrowserFlag" :close-on-click-modal="false" :append-to-body="true">
      <div class="diaBox">
        <div class="dialogTop">
          <div class="left">
            <img class="icon" :src="$utils.getPng('web4/icon_i')" alt="" srcset="" />
            <div class="tit2">提示</div>
          </div>
          <fm-button @click="close3()" type="info" size="mini" text icon="icon-model-close"></fm-button>
        </div>
        <div class="dialogCenter">
          <div class="tit" style="margin-top: 0;">
            请确认将该常用登录地点删除，<br /> 删除后该地区的浏览器账号也将退出登录
          </div>
          <div class="checkBox">
            <div class="btn-svg pointer" @click="isChecked = !isChecked">
              <svg-btn :icon-class="isChecked
                ? 'icon-file-check'
                : 'icon-file-uncheck-' + store.state.theme
                ">
              </svg-btn>

            </div>
            <div class="checktit" style="margin-left: 8px;">我已确认删除该登录地点</div>
          </div>

          <div class="bottom bottom1">
            <fm-button type="primary" size="small" :disabled="!isChecked" @click="deleteAdressFun()">
              确认删除</fm-button>
            <fm-button style="margin-left: 16px;" type="info" size="small" plain @click="close3()">
              取 消</fm-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="canNotDeleteFlag" :close-on-click-modal="false" :append-to-body="true">
      <div class="diaBox">
        <div class="dialogTop">
          <div class="left">
            <img class="icon" :src="$utils.getPng('web4/icon_i')" alt="" srcset="" />
            <div class="tit2">{{ deleteObj.info }}</div>
          </div>
          <fm-button @click="close4()" type="info" size="mini" text icon="icon-model-close"></fm-button>
        </div>

        <div class="dialogCenter">
          <div class="tit" style="margin-top: 0;">
            {{ deleteObj.intr }}
          </div>
          <div class="firstDelete">
            <div class="name">{{ deleteObj.name }}</div>
            <div class="time">删除时间：{{ deleteObj.time }}</div>
          </div>

          <div class="bottom bottom1" style="margin-top: 24px;">
            <fm-button style="width: 88px;height: 32px;" type="info" plain size="small" @click="close4()">
              我知道了</fm-button>

          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { browserLoginAddressAPI, pcQuitLoginAddressAPI } from "@/utils/apiList/login";
import store from "@/store";

export default {
  data() {
    return {
      equipmentFlag0: false,
      dataArr: {},
      browserFlag: false,
      deleteBrowserFlag: false,
      canNotDeleteFlag: false,
      isChecked: false,
      store: store,
      sType: 1,
      adress: '',
      loginAdressList: [],
      prompt: '飞猫盘账号支持常用最多5处登录地点，每次删除常用登录地点需间隔7天操作，请勿与他人共用账号。',
      access_token: '',
      ip_home_place: '',
      deleteObj: {
        intr: '每7天只能删除地点1次，以下为上次删除地点',
        name: '上海',
        time: '2024-06-22 12:23:53',
        info: '无法删除该地点'
      },
      index:0
    };
  },
  created() {
  },

  methods: {
    handleOpen(data) {
     
        this.adress = data.current_province;
        this.equipmentFlag0 = true;
        this.access_token = data.access_token;
        this.getList(data.access_token)
    },
    close1() {
      this.equipmentFlag0 = false;
    },
    close2() {
      this.browserFlag = false;
    },
    close3() {
      this.deleteBrowserFlag = false;
    },
    close4() {
      this.deleteBrowserFlag = false;
      this.canNotDeleteFlag = false;
    },
    getList(access_token) {
      browserLoginAddressAPI({ access_token })
        .then((res) => {
          if (res.status == 1) {
            this.loginAdressList = res.data.list;
            this.prompt = res.data.prompt;
          }
        })
        .catch((err) => { });
    },
    deleteItem(item) {
      this.ip_home_place = item.ip_home_place;
      this.deleteBrowserFlag = true;
      this.isChecked = false;
    },
    deleteAdressFun() {
      if (!this.isChecked) return;
      pcQuitLoginAddressAPI({ access_token: this.access_token, ip_home_place: this.ip_home_place })
        .then((res) => {
          if (res.status == 1) {
            this.equipmentFlag0 = false;
            this.browserFlag = false;
            this.deleteBrowserFlag = false;
            this.canNotDeleteFlag = false;
            this.$parent.goNextBrowser(res.data);
            this.$toast(res.msg, 1)
          } else if (res.status == 40300) {
            this.canNotDeleteFlag = true;
            this.deleteObj.info = res.data.info;
            this.deleteObj.intr = res.data.content;
            this.deleteObj.name = res.data.address;
            this.deleteObj.time = res.data.login_time;
            this.$toast(res.msg, 4)
          } else {
            this.$toast(res.msg, 2)
          }
        })
        .catch((err) => { });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 480px;
  min-height: 208px;
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  .el-dialog__body {
    padding: 0;
  }
}

.diaBox {
  .dialogTop {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 20px 24px 0 24px;

    .left {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .tit2 {
      height: 24px;
      font-size: 16px;
      font-weight: 500;

      @include useTheme {
        color: getVar("text-color2-2c2c2c");
      }

      line-height: 24px;
    }
  }

  .dialogCenter {
    font-size: 14px;
    font-weight: 400;

    @include useTheme {
      color: getVar("text-color1-2c2c2c");
    }

    font-size: 14px;
    padding: 32px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 120px;
      height: 86px;
    }

    .tit {
      text-align: center;
      height: 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 32px;

      @include useTheme {
        color: getVar("text-color1-2c2c2c");
      }
    }

    .tit2 {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      height: 22px;
      line-height: 22px;
      margin-top: 8px;

      @include useTheme {
        color: getVar("text-color1-8a8b92");
      }
    }

    .firstDelete {
      width: 432px;
      height: 70px;

      @include useTheme {
        background: getVar('text-color1-f9f9f9');
      }

      border-radius: 8px;
      padding: 12px 16px;
      margin-top: 20px;

      .name {
        height: 22px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        @include multiline(1);

        @include useTheme {
          color: getVar("text-color1-2c2c2c");
        }
      }

      .time {
        height: 22px;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        margin-top: 4px;

        @include useTheme {
          color: getVar("text-color1-8a8b92");
        }
      }
    }



    .bottom {
      width: 100%;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .bottom1 {
      display: flex;
      justify-content: flex-end;

      .btn2 {
        margin-right: 0;
      }
    }

    .checkBox {
      display: flex;
      align-items: center;
      margin-top: 39px;

      .btn-svg {
        display: flex;
        align-items: center;
        justify-content: center
      }

      .svg-icon {
        widows: 16px;
        height: 16px;
      }

      .checktit {
        @include useTheme {
          color: getVar("text-color1-8a8b92");
        }
      }

    }


  }

  .dialogCenter1 {
    padding: 20px 0 34px 0;

    .contentBox {
      .intr {
        width: 480px;
        height: 60px;

        @include useTheme {
          background: getVar('text-color1-f9f9f9');
          color: getVar("text-color1-8a8b92");
        }

        padding: 10px 24px;
        font-size: 12px;
        line-height: 20px;
      }

      .list {
        height: 344px;
        overflow-y: scroll;
        padding-top: 10px;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 24px;

          .address {
            .name {
              height: 22px;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              @include multiline(1);

              @include useTheme {
                color: getVar("text-color1-2c2c2c");
              }
            }

            .time {
              height: 20px;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              margin-top: 4px;

              @include useTheme {
                color: getVar("text-color1-8a8b92");
              }
            }
          }
        }
      }

    }

  }

}
</style>
