<template>
  <el-dialog
      :visible.sync="isShow"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="480px"
      custom-class="tipDialog" 
  >
  <div class="dialog-body">
    <div class="d-header">
      <div class="left">
        <img :src="$utils.getPng('web4/icon-circle-fill')"/>
        <span>提示</span>
      </div>
      <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
    </div>
    <div class="d-content">
        <span>确认是否继续解压？</span>
        <span>
            {{prohibitInfo}}
        </span>
    </div>
    <div class="d-footer">
        <fm-button @click="confirm" type="primary" size="small" >确 定</fm-button>
        <fm-button @click="handleClose" type="info" plain size="small">取 消</fm-button>
    </div>
  </div>
  </el-dialog>
</template>

<script>
export default {
    props:{
        isShow:Boolean,
        prohibitInfo:{
            type:String,
            default:'当前可解压0项文件，其中0项文件暂不支持解压'
        }
    },
    methods:{
        handleClose(){
            this.$emit('handleClose')
        },
        confirm(){
            this.$emit('confirm')
        }
    }
}
</script>

<style lang="scss" scoped>
    .d-content{
        span{
            display: flex;
            justify-content: center;
            &:first-child{
                line-height: 24px;
                height: 24px;
                font-size: 16px;
            }
            &:last-child{
                font-size: 14px;
                margin-top: 12px;
            }
        }
    }
</style>