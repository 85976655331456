<template>
  <div class="home fmDialog">
    <el-dialog
      :before-close="handleCloseToast"
      width="480px"
      :visible.sync="showToast"
    >
      <div class="diaBox">
        <div class="dialogTop">
          <div class="left">
            <div class="tit1">提示</div>
          </div>
          <div class="closeBtn" @click="handleCloseToast()">
            <svg-btn icon-class="icon-model-close" />
          </div>
        </div>
        <div class="dialogCenter">
          <div class="tit">
            点击确定表示您已详读并同意<a

              :href="$glb.fmUrl + '/askdetail/91'"
              target="_blank"
              >《用户服务协议》</a
            >
          </div>
          <div class="bottom">
            <fm-button
              
              ype="primary"
              size="medium"
              @click="sure()"
              >确 认</fm-button
            >
            <div class="btn_no" style="margin-left: 8px" @click="handleCloseToast()">取 消</div>
            
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showToast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleCloseToast() {
      this.$emit("handleCloseToast");
    },
    sure() {
      this.$emit("sure");
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #f67942 !important;
}
.fmDialog {
  .diaBox {
    border-radius: 16px;
    // height: 178px;
    .dialogTop {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 20px 24px 0 24px;
      .left {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .tit1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
      }
      .closeBtn {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        color: #8a8b92;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .closeBtn:hover {
        background: #e5e5e5;
        color: #2c2c2c;
      }
    }
    .dialogCenter {
      font-size: 14px;
      font-weight: 400;
      font-size: 14px;
      padding: 20px 24px 24px 24px;
      color: #2c2c2c;
      text-align: center;
      .tit1 {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #2c2c2c;
      }
      .bottom {
        width: 100%;
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn_no {
          padding: 0 20px;
          border-radius: 8px;
          height: 36px;
          font-size: 14px;
          text-align: center;
          line-height: 34px;
          color: #2c2c2c;
          border: 1px solid #e5e5e5;
          cursor: pointer;
        }
        .btn_no:hover {
          background-color: #f6f6f6;
        }
        .btn_no:active {
          background-color: #e5e5e5;
          border-color: #d9d9d9;
        }
      }
    }
  }
  ::v-deep .el-dialog {
    width: 480px;
    // height: 240px;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    @include useTheme {
      background: #fff;
      color: #2c2c2c !important;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
