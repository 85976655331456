<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="codeDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left"></div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <div class="tab">
          <span class="active">优惠码</span>
        </div>
        <el-input
          v-model="coupeValue"
          placeholder="输入优惠码享受折扣"
        >
        <svg-btn
          v-show="coupeValue != ''"
          @click="coupeValue = ''"
          slot="suffix"
          icon-class="icon-ipt-clear"
        ></svg-btn>
        </el-input>
      </div>
      <div class="d-footer">
        <fm-button type="primary" size="small" @click="checkCoupe()"
          >确 定</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      coupeValue: "",
    };
  },
  methods: {
    handleClose() {
      // this.coupeValue = ''
      this.$emit("handleClose");
    },
    checkCoupe() {
      if (this.coupeValue == "") {
        this.$toast("请输入优惠码", 2);
        return;
      }
      this.$emit("checkCoupe",this.coupeValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.codeDialog {
  .tab {
    margin-top: 36px;
    margin-bottom: 24px;
    height: 36px;
    @include useTheme {
      box-shadow: getVar("shadow-e5e5e5");
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
    .active {
      color: #f67942;
      border-bottom: 4px solid #f67942;
      padding-bottom: 10px;
    }
  }
  .d-footer {
    margin-top: 24px;
  }
  .d-content{
    .svg-icon{
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-right: 8px;
    }
  }
}
</style>
