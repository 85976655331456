<template>
    <div>
        <VerifyPhoneStep2 ref="ChangePhoneDia" :showChangePhoneFlag="showChangePhoneFlag"
            @handleClose="showChangePhoneFlag = false" @goNext="goNext">
        </VerifyPhoneStep2>
        <VerifyPhone :isShow="isShow" :verifyData="verifyData" @handleClose="handleClose" @confirm="confirm">
        </VerifyPhone>
    </div>
</template>

<script>
import VerifyPhoneStep2 from './VerifyPhoneStep2.vue'
import VerifyPhone from './VerifyPhone.vue'

export default {
    components: {
        VerifyPhone,
        VerifyPhoneStep2
    },
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        verifyData: {
            type: Object,
            default: {
                pcode: "+86", //区号
                phone: "" //手机号码
            },
        },
    },
    data() {
        return {
            showVerifyPhoneFlag: false,
            showChangePhoneFlag: false
        }
    },
    methods: {
        handleClose() {
            this.$emit("handleClose");
            this.showChangePhoneFlag = false;
        },
        confirm() {
            this.$refs.ChangePhoneDia.value = this.verifyData.phone;
            this.$refs.ChangePhoneDia.pcode = this.verifyData.pcode;
            this.$refs.ChangePhoneDia.getCode()
        },
        goNext() {
            this.handleClose();
            this.$emit('goNext')
        }
    }

}
</script>

<style lang="scss" scoped></style>