import TheSpeed from "./speedometer";

export default class OneUpload {
  constructor(opt, callback, callbackType) {
    this.callbackType = callbackType
    this.currentSpeed = 0
    this.loaded = 0
    this._loaded = 0
    this.theSpeed = new TheSpeed()
    this.part_number = 1
    this.upCount = 0
    this.md5List=[] //上传每个的md5列表
    this.msgToast = ''
    this.isFileComplete = false
    this.xmlHttp = []
    for (let optKey in opt) {
      this[optKey] = opt[optKey]
    }

    if (!this.sizeM1) {
      this.sizeM1 = 2 * 1024 * 1024
    }
    this.part_count = Math.ceil(this.size / this.sizeM1)

    if (callback) {
      this.upCallback = callback
    }
  }

  get partUp() {
    let _max = 1
    if (this.$store) {
      let _info = this.$store.state.userInfo || {}
      _max = Number(_info.web_direct_upload_max_part) || 1
    }
    return _max
  }

  getExtension() {
    let _name = this.name || ''
    return _name.substring(_name.lastIndexOf(".")+1)
  }

  sizeUploaded() {
    return this.loaded
  }

  timeRemaining() {
    return Math.floor((this.size - this.loaded) / this.currentSpeed)
  }

  progress() {
    return Number((this.loaded / this.size).toFixed(4))
  }

  cancelTask() {
    if (this.task_id) {
      let _form = new FormData()
      _form.append('task_id', this.task_id)
      fetch(this.cancel_url, {
        method: 'post',
        body: _form
      })
        .then((response) => response.json())
        .then(() => {}).catch(() => {})
    }
  }

  getSignUrl() {
    let part = this.part_number
    this.part_number += 1
    if (part > this.part_count) {
      return false
    }
    let _form = new FormData()
    _form.append('task_id', this.task_id)
    _form.append('part_number', part)
    _form.append('m', this.md5List[part-1].md5)
    fetch(this.upload_part_url, {
      method: 'post',
      body: _form
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == 1) {
          this.uploadRequest(res.data.sign_url, part)
        } else {
          this.fail(res.msg, part)
        }
      }).catch(() => {
        this.fail('上传失败', part)
      })
  }

  completeUpload() {
    let _form = new FormData()
    _form.append('task_id', this.task_id)
    fetch(this.merge_part_url, {
      method: 'post',
      body: _form
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == 1) {
          this.success(res)
        } else {
          this.fail(res.msg)
        }
      }).catch(() => {
      this.fail('上传失败')
    })
  }

  uploadRequest(sign_url, part) {
    let _xmlHttp
    if (window.ActiveXObject) {
      _xmlHttp = new ActiveXObject("Microsoft.XMLHTTP")
    } else if (window.XMLHttpRequest) {
      _xmlHttp = new XMLHttpRequest()
    }
    this.xmlHttp[part] = _xmlHttp
    if (_xmlHttp != null) {
      _xmlHttp.open("put", sign_url, true);
      _xmlHttp.responseType = 'text'; //关键
      _xmlHttp.setRequestHeader("content-type", "text/plain")
      let _blob
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
      if (part === this.part_count) {
        _blob = blobSlice.call(this.file, (part - 1) * this.sizeM1, this.size)
      } else {
        _blob = blobSlice.call(this.file, (part - 1) * this.sizeM1, part * this.sizeM1)
      }
      let _start = 0
      _xmlHttp.upload.onprogress = (ev) => {
        let transfer = ev.loaded - _start
        this.theSpeed.spLength += transfer
        this._loaded += transfer
        _start = ev.loaded
      }
      _xmlHttp.onreadystatechange = () => {
        if (_xmlHttp.readyState == 4) {
          if (this.fileStatus == 1) {
            this.upCount += 1
          }
          if (_xmlHttp.status == 200) {
            if (this.upCount >= this.part_count) {
              this.completeUpload()
            } else if (this.fileStatus == 1) {
              this.getSignUrl()
            }
          } else {
            this.fail('', part)
          }
          this.xmlHttp[part] = null
        }
      }
      _xmlHttp.onerror = () => {
        this.fail('上传失败', part)
      }
      _xmlHttp.send(_blob);
    }
  }

  resume() {
    this.fileStatus = 1
    this._loaded = this.loaded
    this.theSpeed.initSpeed((_speed) => {
      this.currentSpeed = _speed
      if (this._loaded > this.size) {
        this.loaded = this.size
      } else {
        this.loaded = this._loaded
      }
      if (this.upCallback && this.callbackType !== 'async') {
        this._callback('progress', { progress: this.progress() })
      }
    })

    let _partUp = this.partUp
    for (let i = 0; i < _partUp; i++) {
      this.getSignUrl()
    }
  }

  pause() {
    let _min = null
    for (let i = 0; i < this.xmlHttp.length; i++) {
      let _xmlHttp = this.xmlHttp[i]
      if (_xmlHttp) {
        if (_min === null) {
          _min = i
        }
        _xmlHttp.abort()
        this.xmlHttp[i] = null
      }
    }
    this.part_number = _min || 1
    this.upCount = this.part_number - 1
    let part = this.part_number
    this.setSize(part)
    this.theSpeed.clearSpeed()
    this.fileStatus = 2
  }

  cancel() {
    for (let i = 0; i < this.xmlHttp.length; i++) {
      let _xmlHttp = this.xmlHttp[i]
      if (_xmlHttp) {
        _xmlHttp.abort()
        this.xmlHttp[i] = null
      }
    }
    this.theSpeed.clearSpeed()
    if (this.fileStatus === 1 || this.fileStatus === 2) {
      this.cancelTask()
      this.fileStatus = 5
    }
  }

  retry() {
    this.resume()
  }

  setSize(part) {
    if (part < 1) {
      part = 1
    }
    let _load = (part - 1) * this.sizeM1
    if (_load > this.size) {
      _load = this.size
    }
    this.loaded = _load
    this._loaded = _load
  }

  fail(msg, part) {
    if (part >= 0) {
      this.part_number = Math.min(this.part_number, part)
      this.upCount = this.part_number - 1
      this.setSize(this.part_number)
    }
    this.theSpeed.clearSpeed()
    this.fileStatus = 3
    this.msgToast = msg || ''
    this._callback('fail', { msg })
  }
  success(res) {
    this.theSpeed.clearSpeed()
    this.fileStatus = 4
    this.isFileComplete = true
    // if (this.$store) {
    //   if (this.pageFrom === 'jm') {
    //     this.$store.commit("getfiles1");
    //   } else {
    //     this.$store.commit("getfiles");
    //   }
    // }
    this._callback('success', res)
  }

  _callback(act, args) {
    if (this.upCallback) {
      this.upCallback({ action: act, res: args, msg: args.msg || '', id: this.id })
    }
  }
}
