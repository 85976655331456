<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    custom-class="titleDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <img :src="$utils.getPng('web4/icon-circle-fill')" />
          <span>提示</span>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content">
        <p class="title">确定{{ deleteHJ }}？</p>
        <div class="txt">
          <span>
            {{ cancelTips }}
          </span>
        </div>
      </div>
      <div class="d-footer">
        <fm-button @click="confirm" type="primary" size="small"
          >确 定</fm-button
        >
        <fm-button @click="handleClose" type="info" plain size="small"
          >取 消</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    cancelTips: {
      type: String,
      default: "",
    },
    deleteHJ: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss">
@import "../Dialog/dialog.scss";
</style>
