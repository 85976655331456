<template>
  <div class="paginationbox">
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="pg"
      :page-size="pageSize"
      prev-text="上一页"
      next-text="下一页"
      layout=" prev, pager, next"
      :total="total"
    >
    </el-pagination>
    {{ page }}
    <div class="jumper">
      <span>到</span>
      <el-input
        v-model="input"
        type="text"
        οnkeyup="value=value.replace(/[^\d]/g,'')"
        placeholder=""
        @keyup.enter.native="gopage()"
      ></el-input>
      <span>页</span>
      <div class="btn pointer" @click="gopage()">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 25,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      input: 1,
      pg: 1,
    };
  },
  created() {},
  methods: {
    handleCurrentChange(item) {
      this.$emit("changepage", item);
      this.pg = item;
      this.input = item;
    },
    gopage() {
      this.input = this.input < 1 ? 1 : parseInt(this.input);
      this.pg = this.input;
      const num = Math.ceil(this.$props.total / this.$props.pageSize);
      if (this.input > num) {
        this.input = num;
      }
      if (this.input <= 0) {
        this.input = 1;
      }
      this.$emit("changepage", this.input);
    },
    setPage(page) {
      this.pg = page;
      this.input = page;
    },
  },
};
</script>

<style lang="scss" scoped>
.paginationbox {
  display: flex;

  ::v-deep .el-input__inner[type="number"] {
    -moz-appearance: textfield;
  }
  ::v-deep .el-input__inner[type="number"]::-webkit-inner-spin-button,
  ::v-deep .el-input__inner[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::v-deep .el-input {
    width: 52px;
  }
  ::v-deep .el-input__inner {
    width: 52px;
    height: 36px;
    padding: 0;
    text-align: center;
     @include useTheme {
      border: getVar("border-color-e5e5e5");
      color: getVar("text-color1-2c2c2c");
      background: getVar("bg-color");
    }
  }

  ::v-deep .el-pager li {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    line-height: 36px;
    // border: 1px solid #e5e5e5;
    @include useTheme {
      border: getVar("border-color-e5e5e5");
      color: getVar("text-color1-2c2c2c");
      background: getVar("bg-color");
    }
    margin: 0 5px;
    font-weight: 400;
  }

  ::v-deep .el-pager li.active {
    @include useTheme {
      color: getVar("text-color4-ffffff");
    }
    background: $primary-color !important;
    cursor: pointer;
    border: none !important;
  }
  ::v-deep .el-pager li:hover {
    color: #f67942 !important;
    cursor: pointer;
  }
  ::v-deep .el-pager li.active:hover {
    color: #ffffff !important;
    cursor: pointer;
  }
  ::v-deep .el-pagination button:hover {
    color: $primary-color !important;
  }
  ::v-deep .btn-next {
    width: 70px;
    height: 36px;
    border-radius: 4px;
    // border: 1px solid #e5e5e5;
    @include useTheme {
      border: getVar("border-color-e5e5e5");
      color: getVar("text-color1-2c2c2c");
      background: getVar("bg-color");
    }
    padding: 0;
    margin-left: 5px;
    margin-right: 10px;
  }
  ::v-deep .btn-prev {
    width: 70px;
    height: 36px;
    border-radius: 4px;
    @include useTheme {
      border: getVar("border-color-e5e5e5");
      color: getVar("text-color1-2c2c2c");
      background: getVar("bg-color");
    }
    padding: 0;
    margin-right: 5px;
  }
  ::v-deep .el-pagination__jump {
    height: 36px;
    line-height: 36px;
  }

  .jumper {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      margin: 0 10px;
    }
    .btn {
      width: 56px;
      height: 36px;
      background: $primary-color;
      border-radius: 4px;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      font-weight: 400;
      color: #fff;
    }
    .btn:active {
      background: rgba(246, 121, 66, 0.6);
    }
  }
}
</style>
