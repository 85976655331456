<template>
    <el-dialog
        title="提示"
        :visible.sync="isShow"
        width="480px"
        custom-class="clientDialog" :append-to-body="true" :close-on-click-modal="false">
        <div class="dialog-body">
            <div class="d-header">
                <div class="left">
                    <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                    <span>客户端下载</span>
                </div>
                <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
            </div>
            <div class="d-content">
                <!-- <p class="title">
                    一键唤起客户端，感受全新下载体验。
                </p>
                <p class="txt">
                    若未安装客户端，<span>请点此下载客户端。</span></p> -->

                <div class="in_process">
                    <img :src="$utils.getPng('web4/use_icon1')">
                    <span>正在唤起客户端…</span>
                </div>
            </div>
            <div class="d-footer">
                <fm-button type="primary" size="small">打开客户端下载</fm-button>
                <fm-button @click="handleClose" type="info" plain size="small">取 消</fm-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name:'title',
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        handleClose(){
            this.$emit('handleClose')
        }
    }
}
</script>

<style lang="scss" scoped>
    .clientDialog{
        .title{
            margin-top: 32px;
            margin-bottom: 12px;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
        }
        .txt{
            line-height: 22px;
            margin-bottom: 24px;
            text-align: center;
            span{
                color: #F67942;
                text-decoration: underline;
            }
        }
        .in_process{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 32px 0 24px 0;
            img{
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
            span{
                font-size: 16px;
                color: #F67942;
                line-height: 24px;
            }
        }
    }
</style>