//埋点 
import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data){
    if (data?.ak){
      let plaintext = utils.rd(data.ak, unescape(sfile));
      let obj = utils.secret(data.ed, plaintext, true);
      return JSON.parse(obj);
    }else{
      return data
    }
}

function inParameter(data){
    if (data){
        return { sn: ed, jt: utils.secret(data, ak)}
    }else{
        return data
    }
}


//下载失败埋点记录-yehong
export function downFailureStat(data) {
    return request({
      url: "/disk-service/stat/downFailureStat",
      method: "post",
      data: inParameter(data),
    }).then((res)=>{
      res.data = decrypt(res.data)
      return res
    }).catch((err) => {});
}
