<template>
    <el-dialog
    custom-class="rulesDialog"
    width="480px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :visible.sync="isShow"
    top="0vh">
      <div class="content">
        <div class="d-header2">
            <div class="title">{{ title }}</div>
            <fm-button
                class="d-close"
                type="info"
                size="mini"
                text
                icon="icon-model-close"
                @click="handleClose()"
            ></fm-button>
        </div>
        <div class="list" v-loading="loading">
          <div class="list-scroll">
            <div class="listOne" v-for="item in rulesList" :key="item">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
</template>
<script>
  export default {
    name: "welfareActiviteRules",
    data() {
      return {
        loading: false,
      };
    },
    props: {
      isShow: Boolean,
      title: {
        type: String,
        default: '查看规则'
      },
      rulesList: {
        default: [],
        type: Array,
      },
    },
    created() {},
    methods: {
      handleClose() {
        this.$emit("handleClose");
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  ::v-deep .rulesDialog.el-dialog .el-dialog__body {
    padding: 0;
  }
  .rulesDialog{
    position: relative;
      width: 480px;
      overflow: hidden;
    .d-header2 {
      text-align: center;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 16px;
      box-shadow: inset 0px -1px 0px 0px #E5E5E5;
    }
    .d-close {
      position: absolute;
      top: 20px;
      right: 20px;
    }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        @include useTheme{
          color: getVar("text-color2-2c2c2c");
        }
      }
      .list {
        padding: 16px 2px 24px 24px;
      }
    .list-scroll {
      font-size: 14px;
      font-weight: 400;
      padding-right: 22px;
      height: 330px;
      overflow: auto;
      @include useTheme{
        color: getVar("text-color2-2c2c2c");
      }
      line-height: 22px;
      text-align: justify;
      text-justify: newspaper;
      word-break: break-all;
    }
  }
  .body-theme[data-theme=dark] {
    .rulesDialog .d-header2 {
      box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
    }
  }
  </style>
