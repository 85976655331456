<template>
    <el-dialog
      title="提示"
      :visible.sync="isShow"
      width="480px"
      :before-close="handleClose"
      custom-class="inputDialog" :append-to-body="true"  :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
              <div class="left">
                  <span>解压密码</span>
              </div>
              <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
          </div>
          <div class="d-content">
            <el-input v-model="value" placeholder="请输入解压密码">
                <svg-btn class="icon-clear" v-show="value!=''" @click="value=''" slot="suffix" icon-class="icon-ipt-clear"></svg-btn>
            </el-input>
          </div>
          <div class="d-footer">
              <fm-button @click="confirm" type="primary" size="small">确 认</fm-button>
              <fm-button @click="handleClose" type="info" plain size="small">取 消</fm-button>
          </div>
      </div>
    </el-dialog>
</template>

<script>
export default {
    name:'title',
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return{
            value:''
        }
    },
    methods:{
        handleClose(){
            this.value=''
            this.$emit('handleClose')
        },
        confirm(){
            this.$emit('confirm',this.value)
        }
    }
}
</script>

<style lang="scss">
</style>