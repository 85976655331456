<template>
  <div class="app-container">
    <div class="app-box">
      <NavTop></NavTop>
      <div class="app-bottom">
        <div class="feedbackHistory">
          <div style="text-align: center;padding: 40px;font-size: 20px;font-weight: 500;">
            反馈详情
          </div>
          <div class="top bottom-line">
            <div class="leftTit">{{ feedbackTemp.tag_name || '' }}</div>
            <div class="tit">{{ feedbackTemp.title }}</div>
          </div>
          <div class="recordListBox">
            <div class="item bottom-line" v-for="(item, index) in list" :key="item">
              <div style="display: flex;align-items: center;justify-content: space-between;padding-bottom: 12px">
                <span class="label">{{item.is_user === '1'?'问题描述：':'客服回复：'}}</span>
                <span class="time">{{ item.in_time }}</span>
              </div>
              <p v-html="item.content" style="font-size: 16px;"></p>
              <FileList :file-list="item.annex" readonly/>
            </div>
          </div>
          <div style="padding: 0 32px;" v-if="feedbackTemp.status==='0'||feedbackTemp.status==='1'">
            <fm-button v-if="!show" class="btnSend" type="primary" round @click="handleReply">回复</fm-button>
            <template v-else >
              <el-form label-position="top" label-width="80px" :model="formLabelAlign">
                <el-form-item>
                  <template #label>
                    <span>回复内容</span>
                    <span style="color: #f53f3f;">*</span>
                  </template>
                  <el-input
                    type="textarea"
                    :rows="8"
                    :maxlength="2000"
                    @change="change"
                    resize="none"
                    show-word-limit
                    placeholder="请输入问题描述"
                    v-model="editorContent">
                  </el-input>
                  <!-- <el-input v-model="editorContent "></el-input> -->
                </el-form-item>
                <el-form-item label="附件">
                  <el-upload
                    class="upload"
                    ref="upload-demo"
                    action=""
                    multiple
                    :limit="limit"
                    :on-exceed="exceed"
                    :before-upload="verify"
                    :file-list="fileList"
                    :show-file-list="false"
                    :http-request="upload"
                  >
                    <div style="display: flex;align-items: center;">
                      <el-button type="primary" size="medium" :disabled="fileList.length>=limit" round>点击上传</el-button>
                    </div>
                    <div slot="tip">
                      <span style="color: #BDBDBE;margin-left: 12px;font-size: 14px;">最多上传{{limit}}个{{ext.join('、')}}文件，且单个文件不超过{{ maxText }}</span>
                    </div>
                  </el-upload>
                  <FileList :file-list="fileList"/>
                </el-form-item>
              </el-form>
              <div style="text-align: center;" >
                <fm-button class="submit" type="primary" :disabled="!editorContent" round @click="submit">提交</fm-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <Dialog :is-show="dialogVisible" @cancel="cancel" :confirm="confirm" />
  </div>
</template>

<script>
import NavTop from "@/components/NavTop";
import Upload from "../SubmitQuestions/Upload.vue";
import Dialog from "../SubmitQuestions/Dialog.vue";
import FileList from "../SubmitQuestions/FileList";
import { getQuestionsDetail, reply } from "@/utils/apiList/feedbackHistory";

export default {
  mixins: [Upload],
  components: {
    NavTop,
    Dialog,
    FileList
  },
  data() {
    return {
      input: "",
      show: false,
      fileList:[],
      dialogVisible: false,
      isChange: false,
      confirm: () => {},
      editorContent: "", // 文件描述 富文本
      article_pic: this.$glb.fileServer + "/upload/articlePic",
      newUpType: 9,
      ext: [],
      maxText: '',
      maxSize: 0,
      limit: 0,
      feedbackId: null,
      feedbackTemp: {},
      replying: false,
      editor: null
    };
  },
  computed: {
    list: function () {
      return (this.feedbackTemp.list || [])
    },
  },
  created() {
    this.feedbackId = this.$route.query.id || null
    if (!this.feedbackId) {
      this.$router.replace({
        name: 'help',
        params: {
          topAct: 1
        }
      })
    }
  },
  mounted() {
    this.getQuestionsDetail()
  },
  methods: {
    handleReply() {
      this.show = true
    },
    async submit() {
      if(!this.editorContent) return
      if(this.fileList.some(item => item.status==='uploading')) return this.$message({
        type: 'warning',
        message: '文件正在上传中，请稍后',
        customClass: 'warning',
      })
      if (this.replying || this.disabled) return false;
      this.replying = true
      try {
        let res = await reply({
          id: this.feedbackId,
          content: this.editorContent,
          annex: this.fileList
        })
        this.$toast(res.msg, res.status == 1 ? 1 : 2);
        if (res.status == 1) {
          this.editorContent = ''
          this.fileList = []
          this.show = false
          this.isChange = false
          await this.getQuestionsDetail()
        }
      } catch (e) {}
      this.replying = false
    },
    verify(file) {      
      if(file.size>this.maxSize) {
        setTimeout(() => this.$message({
          type: 'warning',
          message: '上传文件大小超过限制',
          customClass: 'warning',
        }), 0)
        return false
      } else if (!this.ext.some(suffix =>file.name.toLowerCase().endsWith('.'+suffix))) {
        setTimeout(() => this.$message({
          type: 'warning',
          message: '上传文件扩展名是不允许的扩展名',
          customClass: 'warning',
        }), 0)
        return false
      } 
    },
    async upload({file}) { 
      this.isChange = true
      const item = {file_origin_name: file.name, id: file.uid, file_size: file.size, status: 'uploading', path: null}
      this.fileList.push(item)
      const result = await this.asyncUpload(file, this.isVideo(file.name)?14:9);
      if (result.action === "success") {
        item.path = result.res.data.path;
        item.status = 'success'
      } else {
        item.status = 'fail'
        this.$toast(result.msg||'上传失败')
      }
    },
    async getQuestionsDetail() {
      if (!this.feedbackId) return false
      try {
        let res = await getQuestionsDetail({
          id: this.feedbackId
        })
        if (res.status == 1) {
          this.feedbackTemp = res.data
          this.ext = res.data.upload_config.ext.map(suffix => suffix.toLowerCase())
          this.limit = Number(res.data.upload_config.upload_limit||0)
          this.maxSize = Number(res.data.upload_config.max_size||0)
          this.maxText = res.data.upload_config.max_size_format
        }
      } catch (e) {}
    },
    change() {
      this.isChange = true
    },
    isVideo(val) {
      return [/.mp4$/i, /.flv$/i, /.f4v$/i, /.webm$/i, /.m4v$/i, /.mov$/i, /.3gp$/i, /.3g2$/i, /.rm$/i, /rmvb$/i, /.wmv$/i, /.avi$/i, /.asf$/i, /.mpg$/i, /.mpeg$/i, /.mpe$/i, /.div$/i, /.dv$/i, /.divx$/i, /.vob$/i, /.dat$/i, /.mkv$/i, /.lavf$/i, /.cpk$/i, /.dirac$/i, /.ram$/i, /.qt$/i, /.fli$/i, /.flc$/i, /.mod$/].some(suffix =>suffix.test(val))
    },
    cancel() {
      this.dialogVisible = false
    },
    exceed() {      
      this.$message({
        type: 'warning',
        message: `附件数量最多支持上传${this.limit}个`,
        customClass: 'warning',
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isChange) {
      this.confirm = () => next()
      this.dialogVisible = true
    } else next()
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.app-bottom {
  line-height: normal;
  letter-spacing: normal;
}
.submit {
  width: 240px;
  font-size: 16px;
  display: inline-block;
}
.el-form {
  .el-button--primary:hover {
    background: #FF9C71 !important;
    border-color: #FF9C71 !important;
  }
  .el-button--primary:active {
    background: #D84F13 !important;
    border-color: #D84F13 !important;
  }
  .el-button--primary.is-disabled {
    background: #F9D3C3 !important;
    border-color: #F9D3C3 !important;
  }
  .upload {
    display: flex;
    align-items: center;
    .el-upload__tip {
      margin-top: 0;
    }
  }
  ::v-deep .el-textarea__inner {
    color: var(--text-color-1);
  }
  .el-form-item {
    margin-bottom: 28px;
  }
  ::v-deep .el-form-item__label {
    padding-right: 24px;
    color: var(--text-color-1);
    font-size: 16px;
  }
  ::v-deep .el-textarea {
    .el-input__count {
      background: inherit;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>

<style>
.dark {
  --text-color-1: #ffffffe6;
}

.light {
  --text-color-1: #2c2c2c;
}
</style>
