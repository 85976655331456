<template>
  <div class="welfareSociety-box">
    <TabList curId="4"></TabList>
    <div class="hadeimg">
      <img :src="$utils.getPng('tzpctoub')" alt="">
      <div class="guize" @click="showRulesFun()">规则</div>
    </div>
    <div class="fuliwanfa">
      <img :src="$utils.getPng('web4/icon_active_bg')" alt="">
    </div>
    <div class="haoyouzhuli">
      <div class="haoyouzhuli-num">
        <div class="copylike">
          <div class="linke-input">
            <div class="input"><span>{{inviteConfinfo.invite_link}}</span></div>
            <div class="btn" @click="copyinvite_link(inviteConfinfo.invite_link)">复制邀请链接</div>
          </div>
          <div class="erweima" @mouseover="mouseOvers" @mouseleave="mouseLeaves">
            <img :src="$utils.getPng(erweimashow?'erweimaimg2': $store.state.theme == 'dark' ? 'erweimaimg_dark' : 'erweimaimg')" alt="">
            <div class="erweima-img" v-show="erweimashow">
              <div class="demo"></div>
              <div class="box-img">
                <div class="code" id="qrcode" ref="qrcode"></div>
                <div class="erweima-text" @click="download">获取专属二维码</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fanli-title">
      <img :src="$store.state.theme == 'dark' ? $utils.getPng('xiaxianfanlitit33_dark') : $utils.getPng('xiaxianfanlitit33')" alt="">
    </div>
    <div class="haoyouzhuli">
      <div class="haoyouzhuli-num">
        <div class="haoyouzhuli-title">
          <div class="title">
            下线奖励<div class="spanspan"></div>赚不停
          </div>
          <img style="width:20px;height:20px;" :src="$utils.getPng('huo')" alt="">
        </div>
        <div class="xiaxianfanli-BG" :style="{backgroundImage: `url(${$utils.getPng('xiaxianbg')})`}">
          <div class="kanban">
            <div class="kanban1">
              <div class="kanban-text">
                邀请好友助力
              </div>
              <div class="kanban-img">
                <img :src="$utils.getPng('zuo1')" mode="">
              </div>

              <div class="kanban22">
                <div class="kanban-num">已邀请 <span style="margin: 0 2px;">
                  {{ total || 0 }}
                </span> (人)</div>
              </div>
            </div>
            <div class="kanban2">
              <img :src="$utils.getPng('jian1')" mode="">
            </div>
            <div class="kanban1">
              <div class="kanban-text">
                赚好友收益分成
              </div>
              <div class="kanban-img">
                <img :src="$utils.getPng('you1')" mode="">
              </div>
              <div class="kanban22">
                <div class="kanban-num" v-if="totalMoney>0">已获得 <span style="margin: 0 2px;">{{totalMoney || 0}}</span> (元)</div>
                <div class="kanban-num" v-else>暂无 <span>现金</span> 收益</div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="haoyouzhuli-list">
        <div class="title">
          <div class="title-text">好友列表</div>
          <div class="icon-mingxi" @click="showTab(1)">
            <span>明细</span>
            <svg-btn icon-class="icon-direction-right"></svg-btn>
          </div>
        </div>
        <div class="list" v-if="rebateList.length > 0">
          <div class="item" v-for="(item, index) in rebateList" :key="index">
            <div class="user-info">
              <img class="head" :src="item.avatar">
              <div class="info">
                <div class="user-name">
                  <span>{{item.nickname}}</span>
                </div>
                <div class="jiangli-text">
                  {{item.text}}
                </div>
              </div>
            </div>
            <div class="time">
              {{item.in_time}}
            </div>
          </div>
        </div>
        <div class="list" v-else>
          <div class="queshenbox">
            <div>
              <img style="width: 88px;height: 88px;" :src="$utils.getPng( $store.state.theme == 'dark' ? 'queshen_dark':'queshen')">
              <div class="queshentext">
                邀请好友助力赚好友收益分成
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <WelfareActiviteRules :isShow="showRules" :rulesList="inviteConfinfo.desc" @handleClose="showRules = false"></WelfareActiviteRules>
    <WelfareActiviteTwoTable ref="welfareActiviteTwoTable" :isShow="showTwoTab" @onGetList="onDetailList" @handleClose="showTwoTab = false"></WelfareActiviteTwoTable>
  </div>
</template>

<script>
import TabList from "../TabList/index.vue";
import WelfareActiviteRules from "@/components/Welfare/WelfareActiviteRules.vue"
import WelfareActiviteTwoTable from "@/components/Welfare/WelfareActiviteTwoTable.vue"
import { getInviteConf, dailyShare } from '@/utils/apiList/invite'
import QRCode from "qrcodejs2";
export default {
  components:{
    TabList,
    WelfareActiviteRules,
    WelfareActiviteTwoTable
  },
  data(){
    return{
      erweimashow: false,
      list: [],
      isShow: false,
      listdm1: [],
      listdm2: [],
      inviteConfinfo: {
        desc:[]
      },
      showRules: false,
      showTwoTab: false,
      rebateList: [],
      total: 0,
      totalMoney: 0,
      loading: false
    }
  },
  mounted() {
    this.getInviteConf()
  },
  methods: {
    onDetailList(args) {
      this.total = args.total
      this.totalMoney = args.totalMoney
      this.rebateList = args.list.slice(0, 3)
    },
    createQrcode(href) {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrcode, {
          width: 88, // 二维码宽度，单位像素
          height: 88, // 二维码高度，单位像素
          text: href, // 生成二维码的链接
        });
      });
    },
    async getInviteConf() {
      try {
        let res = await getInviteConf()
        if (res.status == '1') {
          this.inviteConfinfo = res.data
          this.createQrcode(res.data.invite_link_code)
        }
      } catch (e) {}
    },
    copyinvite_link(item) {
      if (!item) {
        return;
      }
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = this.inviteConfinfo.invite_link;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success("复制助力链接成功，快去粘贴分享");
      // 调用接口记录
      this.dailyShare();
      // 复制后移除输入框
      oInput.remove();
    },
    async dailyShare() {
      if (this.loading) return false
      this.loading = true
      try {
        await dailyShare()
      } catch (e) {}
      this.loading = false
    },
    showRulesFun() {
      this.showRules = true;
    },
    mouseOvers() {
      this.erweimashow = true;
    },
    mouseLeaves() {
      this.erweimashow = false;
    },
    download() {
      // 获取要下载的图片
      var parent = document.getElementById("qrcode");
      var child = parent.getElementsByTagName("img");
      var src = child[0].currentSrc;
      var $a = document.createElement("a");
      $a.setAttribute("href", src);
      $a.setAttribute("download", "");
      var evObj = document.createEvent("MouseEvents");
      evObj.initMouseEvent(
          "click",
          true,
          true,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          true,
          false,
          0,
          null
      );
      $a.dispatchEvent(evObj);
      this.$message.success("获取专属二维码成功，快去分享给好友");
    },
    showTab(index) {
      this.showTwoTab = true;
      this.$refs.welfareActiviteTwoTable.tabAct = index;
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
