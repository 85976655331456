<template>
  <!-- 微信登录 -->
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="showWxFlag"
    :append-to-body="true"
    class="showWx"
    top="0vh"
  >
    <div class="box">
      <div class="dialogTop">
        <!-- <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button> -->
        <div class="closeBtn" @click="handleClose">
          <svg-btn icon-class="icon-model-close" />
        </div>
      </div>

      <div id="wxloginCode" style="margin-top: -24px"></div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    showWxFlag: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit("closeWx");
    },
  },
};
</script>
<style lang="scss"></style>

<style lang="scss" scoped>
.showWx {
  ::v-deep .el-dialog {
    height: 360px;
    width: 400px;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 8px 10px -5px rgba(0, 0, 0, 0.08);

    border-radius: 16px;
    .el-dialog__body {
      padding: 0;
    }
  }
  .box {
    width: 400px;
    height: 360px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialogTop {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      padding: 20px 24px 0 24px;
      z-index: 1000;
      .closeBtn {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        color: #8a8b92;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .closeBtn:hover {
        background: #e5e5e5;
        color: #2c2c2c;
      }
    }
  }
}
//用base 64 转码后 生成二维码的时候 href传入（父组件new WxLogin）

.impowerBox .qrcode {
  width: 176px;
  height: 176px;
  border-radius: 8px;
}
</style>
