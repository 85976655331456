<template>
  <div class="my-file-list">
    <div class="file-item" v-for="(item, index) in fileList">
      <img v-if="isVideo(readonly?item.name:item.file_origin_name)" width="24" src="../../../assets/img/video-icon.png">
      <img v-else width="24" src="../../../assets/img/image-icon.png">
      <div style="flex: auto;padding: 0 10px;overflow: hidden;">
        <span style="color:var(--text-color-1);display: block;width: 100%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{ readonly?item.name:item.file_origin_name }}</span>
        <div style="margin-top: 2px;display: flex;align-items: center;">
          <span style="min-width: 60px;">{{ readonly?item.size:format(item.file_size) }}</span>
          <el-link type="primary" :underline="false" style="font-size: 14px;margin-left: 6px;" :disabled="!item.path" @click="preview(item.path)">预览</el-link>
        </div>
      </div>
      <div class="icon" v-if="!readonly">
        <i v-if="item.status === 'uploading'" class="el-icon-loading"></i>
        <i v-else-if="item.status === 'success'" class="el-icon-circle-check" style="color: #67c23a;"></i>
        <i v-else class="el-icon-circle-close" style="color: #F56C6C;"></i>
        <i class="el-icon-close" @click="del(index)"></i>
      </div>
    </div>
    <el-image
      ref="image"
      style="width: 0; height: 0"
      :initial-index="index"
      :preview-src-list="srcList">
    </el-image>
    <div class="preview" v-if="dialogVisible">
      <div class="videoBox">
        <div @click="close" class="close-button">
          <i class="el-icon-close"></i>
        </div>
        <div class="video-container" ref="artVideo"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import Artplayer from "artplayer";

export default {
  props: {
    fileList: Array,
    readonly: Boolean
  },
  data() {
    return {
      dialogVisible: false,
      index: 0,
      srcList: []
    }
  },
  methods: {
    isVideo(val) {
      return [/.mp4$/i, /.flv$/i, /.f4v$/i, /.webm$/i, /.m4v$/i, /.mov$/i, /.3gp$/i, /.3g2$/i, /.rm$/i, /rmvb$/i, /.wmv$/i, /.avi$/i, /.asf$/i, /.mpg$/i, /.mpeg$/i, /.mpe$/i, /.div$/i, /.dv$/i, /.divx$/i, /.vob$/i, /.dat$/i, /.mkv$/i, /.lavf$/i, /.cpk$/i, /.dirac$/i, /.ram$/i, /.qt$/i, /.fli$/i, /.flc$/i, /.mod$/].some(suffix =>suffix.test(val))
    },
    preview(path) {
      if(this.isVideo(path)) {
        this.dialogVisible = true
        this.$nextTick(() => {
          const option = {
            url: this.readonly?path:this.$store.state.userInfo.ucgimg_host+path,
            theme: "#f67942",
            playbackRate: true,
            setting: true,
            pip: true,
            fullscreen: true,
          };
          this.loadVideo(option);
        });
      } else {
        this.srcList = [this.readonly?path:this.$store.state.userInfo.ucgimg_host+path]
        nextTick(() => this.$refs.image.clickHandler())
      }
    },
    format(size) {
      if(size < 1024) {
        return size + 'B'
      } else if( size < 1048576 ) {
        return (size/1024).toFixed(1) + "KB"
      } else {
        return (size/1048576).toFixed(1) + "MB"
      }
    },
    close(){
      this.dialogVisible = false
    },
    del(index) {
      this.fileList.splice(index,1)
    },
    loadVideo(option) {
      Artplayer.CONTEXTMENU = false;
      Artplayer.RECONNECT_TIME_MAX = 1;
      this.instance = new Artplayer({
          ...option,
          container: this.$refs.artVideo,
      });
      // 当播放器首次可以播放时触发
      this.instance.on('ready', () => {
        this.loadingVideo=false;
        this.instance.play();
        this.instance.currentTime = this.currentTime;
      });
    }
  }
}

</script>

<style lang="scss" scoped>
.my-file-list {
  line-height: normal;
  letter-spacing: normal;
  .file-item {
    width: 60%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #8A8B92;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    padding: 8px 12px;
    overflow: hidden;
    .el-icon-close {
      display: none;
      cursor: pointer;
    }
    .el-link {
      color: #F67942;
    }
  }
  .file-item:hover {
    .icon i:not(.el-icon-close) {
      display: none;
    }
    .el-icon-close {
      display: block;
    }
    background-color: var(--background-color);
  }
}
::v-deep .preview-dialog {
  border-radius: 0;
  background-color: transparent !important;
  box-shadow: none !important;
  .el-dialog__body {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
  .bottom {
    position: fixed;
    bottom: 0;
    background-color: #FFF;
  }
  ::v-deep .el-link.el-link--primary.is-underline:hover:after {
    color: #F67942;
  }
}
.preview {
  top: 0;
  left: 0;
  z-index: 101;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.3);
}
.videoBox{
  padding: 10vh 10vw;
  .close-button {
    position: fixed;
    color: #fff;
    font-size: 24px;
    right: 0;
    background-color: #606266;
    right: 40px;
    top: 40px;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    line-height: 40px;
  }
  .video-container{
    width: 100%;
    height: calc(100vh - 192px);
    margin: auto;
  }
}
</style>

<style>
.el-image-viewer__canvas {
  transform: scale(0.8);
}
.dark {
  --text-color-1: #ffffffe6;
  --background-color: #232323;
}

.light {
  --text-color-1: #2c2c2c;
  --background-color: #F9F9F9;
}
</style>