<template>
  <div class="download-box">
    <div class="item" v-for="(item, index) in downloadData" :key="index">
      <div class="box">
        <img :src="$utils.getPng('web4/dl_icon' + item.id)" />
        <span class="name">{{ $t(item.name) }}</span>
        <span @click="onClike(item)" class="btn">{{ $t('home.DownloadNow') }}</span>
      </div>
      <div class="box2">
        <div class="imgBox">
          <img class="ewm" :src="$utils.getPng('downapp')" />
        </div>
        <div class="tit1">{{ $t('home.ScanQRcodedownload') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { clientDownUrlAPI } from "@/utils/apiList/home";
export default {
  data() {
    return {
      downloadData: [
        { id: 1, name: "home.AndroidClient" },
        { id: 2, name: "home.iOSClient" },
        { id: 3, name: "home.WindowsDesktop" },
        { id: 4, name: "home.MacOSDesktop" },
      ],
    };
  },
  created(){
    this.Dow()
  },
  methods: {
    //桌面端下载
    onClike(item) {
      if (item.id == 3) {
        // 创建链接元素
        var link = document.createElement("a");
        link.href = this.exe.win_url;
        link.download = "";
        // 模拟点击链接以下载文件
        link.click();
      } else if (item.id == 4) {
        // 创建链接元素
        var link = document.createElement("a");
        link.href = this.exe.mac_url;
        link.download = "";
        // 模拟点击链接以下载文件
        link.click();
      }
    },
    Dow() {
      try {
        clientDownUrlAPI().then((res) => {
          this.exe = res?.data;
        });
      } catch (error) {
        // 处理异常
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
