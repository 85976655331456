<template>
    <el-dialog
        :visible.sync="isShow"
        width="480px"
        :before-close="handleClose"
        custom-class="shareDialog" :append-to-body='true' :close-on-click-modal="false">
        <div class="dialog-body">
            <div class="d-header">
                <div class="left"><span>分享合集</span></div>
                <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
            </div>
            <div class="d-content">
                <div class="warn-box">
                    <svg-btn icon-class="icon-tips-circle-fill"/>
                    <span>严禁上传或分享反动、暴力、色情、违法及侵权内容的文件。</span>
                </div>
                <div class="file-info">
                    <img class="icon" :src="$utils.getPng('fm_icon_shareList3')">
                    <div class="info">
                        <span class="name" v-if="boxType">{{gather_name}}等{{gather_ids.length}}项文件</span>
                        <span class="name" v-else>{{handleItem.name}}</span>
                        <div>
                            <span class="edit" @click="editGather">编辑合集文件</span>
                            <span>分享链接长期有效</span>
                        </div>
                    </div>
                </div>
                <div class="pucode-box" v-if="boxType">
                    <span class="title" style="margin-bottom: 8px;">分享形式</span>
                    <div class="share-type">
                        <el-select v-model="open_pucode" placeholder="请选择" popper-class="share_select" @change="shareTypeChange">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                        </el-select>
                    </div>
                    <div v-show="open_pucode==1">
                        <span class="title" style="margin-top: 24px;">取件码</span>
                        <!-- <div class="line1">
                            <svg-btn :icon-class="'icon-checkbox-'+$store.state.theme"/><span>有取件码</span>
                        </div> -->
                        <div class="line2">
                            <img @click="changeCodeType(1)" class="svg-icon" :src="$utils.getPng(isSysCode==1 ? 'web4/icon_radio1_'+$store.state.theme :'web4/icon_radio0_'+$store.state.theme)"/>
                            <span>系统随机生成取件码</span>
                        </div>
                        <div class="line3" >
                            <img @click="changeCodeType(2)" class="svg-icon" :src="$utils.getPng(isSysCode==1 ? 'web4/icon_radio0_'+$store.state.theme :'web4/icon_radio1_'+$store.state.theme)"/>
                            <el-input maxlength="4" :disabled="isSysCode==1" v-model="pucode" placeholder="请输入4位取件码"></el-input>
                        </div>
                        <span class="tips">仅支持数字及英文字母</span>
                        <div class="line4">
                            <div @click="chageAutoSet()">
                                <svg-btn :icon-class="auto_set ? 'icon-file-check' : 'icon-file-uncheck-'+$store.state.theme"/>
                            </div>
                            <span>分享链接自动填充取件码</span>
                            <el-tooltip class="item" effect="dark" content="开启后用户点击链接可自动填充取件码" placement="top">
                                 <svg-btn class="icon-share-1" icon-class="icon-share-1"/>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <!-- 分享链接 -->
                <div class="shareUrl-box" v-else>
                    <span class="title">分享链接</span>
                    <div class="url-content">
                        <el-input readonly v-model="shareUrl" placeholder=""></el-input>
                        <fm-button class="copy-btn" round size="medium" @click="copyUrl">{{handleItem.good_count==1?'复制链接及取件码' :'复制链接'}}</fm-button>
                    </div>
                </div>
            </div>
            <div class="d-footer" v-show="boxType">
                <fm-button :disabled="btnDisabled" type="primary" round
                @click="btnDisabled ? '' : addShareUrl()">创建链接</fm-button>
            </div>
        </div>
        </el-dialog>
  </template>

  <script>
  export default {
      props:{
          isShow:Boolean,
          gather_name:String,
          gather_ids:Array,
          boxType:Boolean,//true 创建链接 false 展示分享链接
          shareUrl:String,
          handleItem:Object,
          auto_set:Number,
          isSysCode:Number,// 1 默认系统 生成  2自定义
         // pucode:String,
          open_pucode:String//0无取件码(公开)，1取件码(私密)
      },
      data(){
        return{
            // auto_set:true,//自动填充0否1是
            // type:1,//分享类型:1公开，2私密
            agreement:1,//协议确认1确认0取消
            options:[
                {value: '1', label: '私密链接，需要取件码才可访问分享内容'},
                {value: '0', label: '公开链接，不需要密码即可访问分享内容'}
            ],
          regs: {
              pucode: /^[a-z\d]{4}$/i
          },
          pucode:''
        }
      },
    computed: {
        btnDisabled: function () {
          let state = false
          if (this.isSysCode == 2 && this.open_pucode == 1) {

            state = !this.regs.pucode.test(this.pucode)
          }
          return state
        }
    },
      methods:{
        handleClose(){
            this.pucode = "";
            this.$emit('handleClose')
        },
        changeCodeType(type){
            this.isSysCode=type
            this.$parent.isSysCode = type;
            if(type==1){
                this.pucode=''
            }
        },
        addShareUrl(){
            if(this.isSysCode==2 && this.open_pucode=='1'){
                if(!this.regs.pucode.test(this.pucode)){
                    this.$toast('仅可输入4位数字或字母', 2)
                    return
                }
            }
            let params={
                file_ids:this.gather_ids.toString(),
                agreement:1,
                open_pucode:this.open_pucode,
                fetch_code:this.open_pucode=='0' ? '' : this.pucode,
                auto_set:this.open_pucode=='0' ? '' : this.auto_set? 1:0
            }
            this.$emit("addShareUrl",params)
        },
        copyUrl(){
            let msg = this.handleItem.good_count==1 ? '复制链接及取件码成功' : '复制链接成功'
                this.$copyText(this.shareUrl).then((e) => {
                    this.$toast(msg, 1)
                },function (e) {}
            );
        },
        editGather(){
            this.$emit('dialogEditGather')
        },
        shareTypeChange(val) {
            this.$parent.open_pucode = val;
        },
        chageAutoSet(){
            this.auto_set = !this.auto_set 
            this.$parent.auto_set = this.auto_set
        }
      }
  }
  </script>

  <style>

  </style>
