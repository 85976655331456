<template>
    <el-dialog
      title="提示"
      :visible.sync="isShow"
      width="480px"
      custom-class="tipDialog"  :append-to-body="true"  :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
              <div class="left">
                  <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                  <span>{{title}}</span>
              </div>
              <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
          </div>
          <div class="d-content">
              <span>
                  所选{{recycle_tit}} {{recycle_expire_day}} 天内可在回收站找回
              </span>
          </div>
          <div class="d-footer">
            <fm-button @click="confirm" type="info" plain size="small" >放入回收站</fm-button>
            <fm-button @click="handleClose" type="primary" size="small">取 消</fm-button>
          </div>
      </div>
      </el-dialog>
  </template>
  
  <script>
  export default {
      name:'base',
      props:{
          isShow: {
              type: Boolean,
              default: false
          },
          text:{
              type:String,
              default:''
          },
          title:{
              type:String,
              default:''
          },
          recycle_expire_day:{
            type:String,
            default:''
          },
          recycle_tit:{
            type:String,
            default:''
          }
      },  
      methods:{
          handleClose(){
              this.$emit('handleClose')
          },
          confirm(){
              this.$emit("doDel")
          }
      }
  }
  </script>
  
  <style lang="scss">
      @import "../Dialog/dialog.scss";
  </style>