<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    custom-class="tipDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <img :src="$utils.getPng('web4/icon-circle-fill')" />
          <span>{{ title }}</span>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content">
        <div class="content-title">{{ updata?.update_text }}</div>
        <div class="content-time">
          <span class="spana">修改时间</span> {{ updata?.update_time }}
        </div>
      </div>
      <div class="d-footer">
        <fm-button @click="handleClose" type="info" plain size="small"
          >我知道了</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
export default {
  name: "base",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    recycle_expire_day: {
      type: String,
      default: "",
    },
    recycle_tit: {
      type: String,
      default: "",
    },
    updata: {
      type: Object,
      default: {},
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  height: 24px;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: -2px;
  @include useTheme {
    color: getVar("text-color1-2c2c2c");
  }
  line-height: 24px;
}

.content-time {
  width: 432px;
  height: 44px;
  @include useTheme {
    background: getVar("text-color1-f9f9f9");
    color: getVar("text-color1-8a8b92");
  }
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  .spana {
    margin-right: 10px;
    font-size: 12px;
  }
}
</style>
  
<style lang="scss">
@import "../Dialog/dialog.scss";
</style>