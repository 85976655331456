<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="codeDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">补差价升级说明</div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <div class="title-exolain">
          {{ upvipinfo?.explain?.info }}
        </div>
        <div class="exolain">
          <div
            class="item"
            v-for="(item, index) in upvipinfo?.explain?.content"
            :key="index"
          >
            <div v-if="item?.arr?.length>0">
              <span :class="{colorJIU: item.values.includes(it)}" v-for="(it,indexs) in item.arr" :key="indexs">{{ it }}</span>
            </div>
            <span v-else>{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    upvipinfo: {
      type: Object,
      default: {},
    },
  },
  watch: {
    isShow() {
      this.content();
    },
  },
  data() {
    return {
      contentVal: [],
    };
  },
  methods: {
    generateArrFromValues(items){
        let text = [items.text];
        let values = items.values;
        
        let result = [];
        let temp = text[0];
        
        values.forEach(item => {
            let index = temp.indexOf(item);
            result.push(temp.substring(0, index));
            result.push(item);
            temp = temp.substring(index + item.length);
        });
        
        result.push(temp);

        return result

    },
    content() {
      this.upvipinfo?.explain?.content.forEach(item => {
          if (item.values && item.values.length > 0) {
            const Vals = this.generateArrFromValues(item);
              item.arr = Vals;
          } else {
              item.arr = [];
          }
      });
    },
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.codeDialog {
  .left {
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    @include useTheme {
      color: getVar("text-color2-2c2c2c");
    }
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .tab {
    margin-top: 36px;
    margin-bottom: 24px;
    height: 36px;
    @include useTheme {
      box-shadow: getVar("shadow-e5e5e5");
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
    .active {
      color: #f67942;
      border-bottom: 4px solid #f67942;
      padding-bottom: 10px;
    }
  }
  .d-footer {
    margin-top: 24px;
  }
  .d-content {
    .title-exolain {
      width: 432px;
      height: 20px;
      font-weight: 400;
      font-size: 12px;
      @include useTheme {
        color: getVar("text-color1-8a8b92");
      }
      line-height: 20px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-top: 20px;
    }
    .exolain {
      margin: 12px 0 10px 0;
      padding: 16px 16px 8px 16px;
      width: 432px;
      min-height: 161px;
      
      @include useTheme {
        background: getVar("background-color-fff9f7");
        border: getVar("border-color-944d27")
      }
      border-radius: 8px;
      
      .item {
        font-size: 14px;
        @include useTheme {
          color: getVar("text-color-944d27");
        }
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 8px;
      }
      .colorJIU{
        font-weight: 600;
        @include useTheme {
          color: getVar("text-color-944d272");
        }
      }
    }
  }
}
</style>
  