<template>
    <el-dialog
      title="提示"
      :visible.sync="isShow"
      width="480px"
      :before-close="handleClose"
      custom-class="inputDialog" :append-to-body="true"  :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
              <div class="left">
                  <span>重命名合集</span>
              </div>
              <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
          </div>
          <div class="d-content">
            <el-input v-model="rename" placeholder="请输入合集名称">
                <svg-btn class="icon-clear" v-show="rename!=''" @click="rename=''" slot="suffix" icon-class="icon-ipt-clear"></svg-btn>
            </el-input>
          </div>
          <div class="d-footer">
              <fm-button @click="confirm" type="primary" size="small">确 定</fm-button>
              <fm-button @click="handleClose" type="info" plain size="small">取 消</fm-button>
          </div>
      </div>
    </el-dialog>
</template>

<script>
export default {
    name:'title',
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
        rename:String,
    },
    data(){
        return{
            value:''
        }
    },
    methods:{
        handleClose(){
            this.rename=''
            this.$emit('handleClose')
        },
        confirm(){
            let reg = /^(?=.*\S).+$/
            if(this.rename==''){
                this.$toast('分享合集名称不能为空，长度50字以内', 2)
                return
            }
            if(this.rename.length>50) {
                this.$toast('分享合集名称不能为空，长度50字以内', 2)
                return
            }
             if(!reg.test(this.rename)){
                this.$toast('分享合集名称不能为空，长度50字以内', 2)
                return
            }
            this.$emit('editName',this.rename)
        }
    }
}
</script>

<style lang="scss">
</style>