<template>
    <div class="welfareMod">
      <div class="title">
        <span>得福利点获免费下载机会</span>
        <span>我的福利点：{{ pointNew}}</span>
      </div>
      <span class="desc">做任务获取福利点即可下载或转存云盘文件、兑换体验会员</span>
      <div class="content">
        <div class="headers">
          <div class="left">
            <img :src="new_feimaoTask.icon" />
            <span>{{ new_feimaoTask.title }}</span>
          </div>
          <div class="right" @click="gowelfare">
            <span class="b-txt">{{ new_feimaoTask.label }}</span>
            <img :src="$utils.getPng('goRight_icon')" />
          </div>
        </div>
        <div class="body" @click="gowelfare">
          <div class="list">
            <div class="titleq">每日签到</div>
            <WebSign class="WebSign"></WebSign>
          </div>
          <div class="list list1">
            <div class="titleq">福利任务</div>
            <div v-for="(item,index) in list1" :key="item.id" class="item" :class="index == 0 ? 'item2':''">
              <div class="left">
                <img :src="item.logo" />
                <div class="info">
                  <div class="name">
                    {{ item.title }}
                    <div class="desc2">
                      <img
                        class="species"
                        :src="$utils.getPng('species_fu')"
                        alt=""
                      />
                      {{ item.label }}
                    </div>
                  </div>
                  <span class="num">{{ item.desc }}</span>
                </div>
              </div>
              <span class="btn">{{ item.buttonText }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { downPageExtraInfo } from '@/utils/apiList/welfare'
import WebSign from "@/components/WebSign/index.vue";
export default {
    components: { WebSign },
    data() {
        return {
        new_feimaoTask: {},
        list1: [
          {buttonText: "去查看",desc: "看完视频奖励福利点",id: "57",label:"+3",logo: "https://webimg.fmapp.com/Public/web/img/webapp/app2023/other/new_exchange_1.png",title: "看视频得福利点"},
          {buttonText: "去查看",desc: "前往订阅号并收藏帖子成功",id: "28",label: "+2",logo: "https://webimg.fmapp.com/Public/web/img/webapp/app2023/other/new_exchange_2.png",title: "收藏帖子"},
          {buttonText: "去查看",desc: "前往订阅号并收藏帖子成功",id: "28",label: "+2",logo: "https://webimg.fmapp.com/Public/web/img/webapp/app2023/other/new_exchange_2.png",title: "收藏帖子"}
        ],
        list2: [],
        state: 0
        };
        
    },
    props: {
      pointNew: {
      type: String || Number,
      default: 0,
    },
    },
    created() {
      this.getTask();
    },
    methods: {
        goApp() {
          this.state == 1 ? this.gowelfare() : this.$emit("goApp");
        },
        gowelfare() {
          window.open("/welfare");
        },
        goJvmaozhongbao() {
          this.$emit("goJvmaozhongbao");
        },
        async getTask() {
          try {
            let res = await downPageExtraInfo()
            if (res.status == 1) {
              this.state = res.data.state;
              this.new_feimaoTask = res.data.new_feimaoTask;
              this.list1 = res.data.new_feimaoTask.list.slice(0, 3);
            }
          } catch (e) {}
        }
    }
};
</script>
<style lang="scss" scoped>
.titleq {
  margin-top: 24px;
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @include useTheme{
    color: getVar("text-color1-8a8b92");
  }
}
.welfareMod{
    margin-top: 40px;
    .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        span{
            font-size: 18px;
            &:first-child{
                font-weight: bold;
                line-height:26px;
            }
            &:last-child{
                color: #F67942;
                line-height:18px;
            }
        }
    }
    .desc{
        line-height: 24px;
        margin-bottom: 20px;
        display: flex;
        font-size: 16px;
        @include useTheme{
            color: getVar("text-color1-8a8b92");
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        padding: 24px;
        height: 366px;
        box-sizing: border-box;
        @include useTheme{
          background: getVar("text-color1-ffffff");
          border: getVar("border-color-e5e5e5");
        }
        &:hover{
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.04);
        }
    }
    .body{
        display: flex;
        justify-content: space-between
    }
    .headers{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right{
            cursor: pointer;
            .b-txt{
                text-align: center;
                line-height: 14px;
                margin-right:6px;
                color: #8A8B92;
            }
            img{
                width: 10px;
                height: 10px;
            }
        }
        .left{
            display: flex;
            align-items: center;
            flex-direction: row;
            img{
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
            span{
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    .list{
      flex: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        .item{
            cursor: pointer;
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left{
              display: flex;
              align-items: center;
              justify-content: space-between;
              img{
                  width: 48px;
                  height: 48px;
                  margin-right: 8px;
                  border-radius: 50%;
              }
              .name{
                  height: 22px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #2C2C2C;
                  line-height: 22px;
              }
              .num{
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  line-height: 18px;
                  @include useTheme{
                    color: getVar("text-color1-8a8b92");
                  }
              }
            }
            .btn{
            width: 88px;
            height: 36px;
            background: linear-gradient(164deg, #FF9E41 0%, #FF7031 100%);
            border-radius: 18px;
            display: inline-block;
            line-height: 36px;
            font-weight: 500;
            text-align: center;
            color: #fff;
            }
            .info{
              display: flex;
              flex-direction: column;
            }
        }
        .item2{
          margin-top: 32px;
        }
    }
    .list1{
      flex: 1;
      margin-left: 32px;
        .item{
          .left{
            .name{
              max-width: 234px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: flex;
              @include useTheme{
                color: getVar("text-color2-2c2c2c");
              }
              .desc2 {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: #f2353b;
                .species {
                  width: 14px;
                  height: 14px;
                  margin-left: 8px;
                  margin-right: 2px;
                }
              }
            }
          }
        }
    }
}
</style>
