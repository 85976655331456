<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/views/Account/index.vue
 * @Description: 个人设置页面
-->
<template>
  <div class="account">
    <div class="aTop">
      <div class="imgBox pointer" @click="showDiaFlag = true">
        <img class="avater" :src="userInfo?.logo" alt="" />
        <img
          class="change"
          :src="$utils.getPng('web4/icon-change-avater')"
          alt=""
        />
        <!-- <img
          class="icon-header"
          v-if="userInfo?.frameUrl"
          :src="userInfo?.frameUrl"
          alt=""
        /> -->
      </div>
      <fm-button
        style="width: 96px; height: 36px"
        type="primary"
        round
        size="medium"
        @click="showDiaFlag = true"
        >{{ $t('setup.EditAvatar') }}</fm-button
      >
    </div>
    <div class="aBottom app-right-box">
      <div class="aItem">
        <div class="left">
          <div class="one">{{ $t('setup.AccountNumber') }}</div>
          <div class="one1">{{ userInfo?.username }}</div>
        </div>
      </div>
      <div class="aItem">
        <div class="left">
          <div class="one">{{ $t('setup.PetName') }}</div>
          <div class="one1">{{ userInfo?.nickname }}</div>
        </div>
        <fm-button
          style="width: 88px; height: 36px"
          type="info"
          plain
          round
          @click="changeNickname"
          >{{ $t('setup.Edit') }}</fm-button
        >
      </div>
      <div class="aItem">
        <div class="left">
          <div class="one">{{ userInfo?.phone ? $t('setup.ModifyPhone') : $t('setup.PhoneNumber') }}</div>
          <div class="one1">
            {{ userInfo?.phone ? hidePhone(userInfo?.phone) : $t('setup.Bound3') }}
          </div>
        </div>
        <fm-button
          style="width: 88px; height: 36px"
          type="info"
          plain
          round
          @click="changePhone()"
          >{{ userInfo?.phone ? $t('setup.Edit') : $t('setup.Bound2') }}</fm-button
        >
      </div>
      <div class="aItem">
        <div class="left">
          <div class="one">
            <span>{{ $t('setup.WeChat') }}</span>
            <img
              class="icon_wx"
              :src="$utils.getPng('web4/icon_wx_1')"
              alt=""
            />
          </div>
          <div class="one1">
            {{ userInfo?.is_bind_wx == 0 ? $t('setup.Bound3') : "" }}
          </div>
        </div>
        <fm-button
          style="width: 88px; height: 36px"
          type="info"
          plain
          round
          @click="bindQQWXFn('微信')"
          >{{ userInfo?.is_bind_wx == 0 ? $t('setup.Bound2') : $t('setup.Bound1') }}</fm-button
        >
      </div>
      <div class="aItem">
        <div class="left">
          <div class="one">
            <span>QQ</span>
            <img
              class="icon_qq"
              :src="$utils.getPng('web4/icon_qq_1')"
              alt=""
            />
          </div>
          <div class="one1">
            {{ userInfo?.is_bind_qq == 0 ? $t('setup.Bound3') : "" }}
          </div>
        </div>
        <fm-button
          style="width: 88px; height: 36px"
          type="info"
          plain
          round
          @click="bindQQWXFn('QQ')"
        >
          {{ userInfo?.is_bind_qq == 0 ? $t('setup.Bound2') : $t('setup.Bound1') }}</fm-button
        >
      </div>
      <div class="aItem" style="margin-bottom: 136px">
        <div class="left">
          <div class="one">{{ $t('setup.LoginPassword') }}</div>
          <div class="one1">{{ $t('setup.Setpasswords') }}</div>
        </div>
        <fm-button
          style="width: 88px; height: 36px"
          type="info"
          plain
          round
          @click="changePassword()"
          >{{ $t('setup.Edit') }}</fm-button
        >
      </div>
    </div>
    <!-- 修改头像弹窗-->
    <div class="dialogChangeAvater">
      <el-dialog
        :visible.sync="showDiaFlag"
        :append-to-body="true"
        class="dialogChangeAvater"
      >
        <div class="diaBox">
          <div class="dialogTop">
            <div class="tit1">选择头像</div>
            <fm-button
              type="info"
              size="mini"
              text
              icon="icon-model-close"
              @click="handleCloseChangeAvarer"
            ></fm-button>
          </div>
          <div class="dialogBottom">
            <img class="avater changeAvaterImg" :src="logo" alt="" srcset="" />
            <div class="diaTit">头像预览</div>
            <div class="imgChoosedBox">
              <div
                class="item pointer"
                v-for="(item, index) in iconList"
                @click="changeAvt(item, index)"
                :key="index"
              >
                <img class="one" :src="item" alt="" />
                <img
                  class="act"
                  v-show="act == index ? 'act' : ''"
                  :src="$utils.getPng('web4/avater_act')"
                />
              </div>
            </div>
            <div class="tit">支持jpg、png格式的图片</div>
            <div class="line"></div>
          </div>
          <div class="upBox">
            <el-upload
              class="upload-demo"
              ref="upload-demo"
              action=""
              :list-type="picture"
              :show-file-list="false"
              :limit="3"
              :on-error="handleAvatarError"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="myUploadImg"
            >
              <div id="uploaduhead" class="left" @click="clickaa()">
                上传本地图片
              </div>
            </el-upload>
            <fm-button
              style="width: 88px; height: 36px"
              type="primary"
              size="mini"
              round
              :disabled="disabled"
              @click="disabled ? '' : modilogo()"
              >确定</fm-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <ChangePhoneDiaVue
      ref="ChangePhoneDia"
      :userInfo="userInfo"
      :showChangePhoneFlag="showChangePhoneFlag"
      @handleClose="showChangePhoneFlag = false"
    ></ChangePhoneDiaVue>
    <ChangeNameDia ref="ChangeNameDia"></ChangeNameDia>
    <unBind
      ref="unBind"
      :isBindPhone="userInfo.phone ? true : false"
      :type="unType"
      :unBindFlag="unBindFlag"
      @handleClose="unBindFlag = false"
    ></unBind>
    <changePasswordDia
      :type="$store.state.userInfo.is_pass == 0 ? 0 : 1"
      :isShow="changePassFlag"
      @handleClose="changePassFlag = false"
    ></changePasswordDia>
    <WxLoginModelVue :showWxFlag="showWxFlag" @closeWx="showWxFlag = false" />
    <Modifyintercept
      ref="Modifyintercept"
      :isShow="showModifyintercept"
      title="暂时无法修改密码"
      :updata="updata"
      @handleClose="showModifyintercept = false"
    ></Modifyintercept>
  </div>
</template>

<script>
import ChangePhoneDiaVue from "./compoents/ChangePhoneDia.vue";
import ChangeNameDia from "./compoents/ChangeName";
import unBind from "./compoents/unBind";
import { getUserInfoAPI } from "@/utils/apiList/home";
import {
  getDefaultIconListAPI,
  modilogoAPI,
  getDirectAvatarUploadLinkAPI,
  userBindQQAPI,
  userBindWxAPI,
  getWxinfoAPI,
  checkUserVerificationAPI,
} from "@/utils/apiList/account";
import OneUpload from "./newUpload";
import changePasswordDia from "./compoents/ChangePassword.vue";
import { loginOutAPI } from "@/utils/apiList/login";
import WxLoginModelVue from "./compoents/WxLoginModel.vue";
import Modifyintercept from "@/components/Cloud/Modifyintercept";
export default {
  components: {
    ChangePhoneDiaVue,
    ChangeNameDia,
    changePasswordDia,
    unBind,
    WxLoginModelVue,
    Modifyintercept,
  },
  data() {
    return {
      showDiaFlag: false,
      act: -1,
      userInfo: {},
      changePassFlag: false,
      showModifyintercept: false,
      showChangePhoneFlag: false,
      iconList: [],
      logo: "",
      upAvater: "",
      disabled: true,
      upDis: false,
      newUpList: [],
      newUpType: 1,
      unBindFlag: false,
      unType: "QQ",
      wxInfo: {},
      showWxFlag: false,
      updata: {}
    };
  },
  created() {
    this.getUserInfo();
    this.getDefaultIconList();
    this.getWinXinInfo();
    if (!document.getElementById("aliyunCaptcha-window-popup")) {
      this.$AliCode.initAliCode();
    }
  },
  mounted() {
    var searchParams = new URLSearchParams(window.location.search);
    var bind_qq_token = searchParams.get("bind_qq_token");
    if (bind_qq_token) {
      this.userBindQQ(bind_qq_token);
    }
    var bind_wx_token = searchParams.get("bind_wx_token");
    if (bind_wx_token) {
      this.userBindWX(bind_wx_token);
    }
  },
  computed: {
    isNewUpload() {
      let _userInfo = this.$store.state.userInfo || {};
      return Number(_userInfo.upload_type) || 0;
    },
    part_size() {
      let _userInfo = this.$store.state.userInfo || {};
      return Number(_userInfo.upload_part_size) || 2 * 1024 * 1024;
    },
  },
  watch: {
    upAvater: {
      handler(newval) {
        if (newval) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeAvt(item, index) {
      this.act = index;
      this.upAvater = item;
      this.logo = item;
    },
    clickaa() {
      this.$refs["upload-demo"].clearFiles();
      this.act = -1;
    },
    beforeAvatarUpload(file) {
      var tstmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (tstmsg.toLowerCase() != "jpg" && tstmsg.toLowerCase() != "png") {
        this.$toast("文件格式不正确", 2);
        this.disabled = true;
        return false;
      } else if (file.size > 1024 * 1024 * 10) {
        this.$toast("上传文件大小超过限制", 2);
        this.disabled = true;
        return false;
      }
    },
    handleAvatarSuccess(res) {
      if (res.status == 0) {
        this.$toast(res.msg, 2);
        return;
      }
    },
    handleAvatarError() {
      this.$toast("头像上传失败，请重试", 2);
    },
    async myUploadImg(file) {
      let opt = {
        id: file.file.uid,
        name: file.file.name,
        prdMd5: "",
        size: file.file.size,
        file: file.file,
        loaded: 0,
        sizeM1: this.part_size,
      };

      let _md5 = this.newComputeMD5(opt);
      let fileArr = {
        filename: opt.name,
        sub_type: 1,
        totalSize: opt.size,
        hash: _md5,
      };
      let _file = new OneUpload(opt, this.upCallback);
      const formObj = await this.getLink(fileArr);
      file.task_id = formObj.task_id;
      file.merge_part_url = formObj.merge_part_url;
      file.upload_part_url = formObj.upload_part_url;
      file.cancel_url = formObj.cancel_url;
      file.resume();
    },

    changePhone() {
      if (this.userInfo.phone) {
        this.$refs.ChangePhoneDia.step = 1;
        this.$refs.ChangePhoneDia.topTit = "修改手机号";
      } else {
        this.$refs.ChangePhoneDia.step = 2;
        this.$refs.ChangePhoneDia.topTit = "绑定手机号";
      }
      this.showChangePhoneFlag = true;
    },
    getUserInfo() {
      getUserInfoAPI()
        .then((res) => {
          if (res.status == 1) {
            this.userInfo = res.data;
            this.logo = res.data.logo;
            this.$store.commit("setAttr", {
              name: "userInfo",
              val: res.data,
            });
          }
        })
        .catch((err) => {});
    },
    getDefaultIconList() {
      getDefaultIconListAPI()
        .then((res) => {
          if (res.status == 1) {
            this.iconList = res.data.iconList;
          }
        })
        .catch((err) => {});
    },
    hidePhone(phoneNum) {
      return phoneNum
        ? phoneNum.toString().slice(0, 3).padEnd(9, "*") +
            phoneNum.toString().slice(-2)
        : "";
    },
    changeNickname() {
      this.$refs.ChangeNameDia.nameValue = this.userInfo.nickname;
      this.$refs.ChangeNameDia.realNameDialog = true;
    },
    checkUserVerificationFn() {
      return new Promise((resolve, reject) => {
        checkUserVerificationAPI()
          .then((res) => {
            if (res.status == 1) {
              resolve(false)
            } else if(res.status == 90300){
              this.updata = {
                update_time: res.data.update_time || '',
                update_text: res.msg || '',
              }
              resolve(true)
            } else {
              resolve(false)
              }
          }).catch((err) => {});
  
        })
        
    },
    async changePassword() {
      const fication = await this.checkUserVerificationFn()
      if (fication) {
        this.showModifyintercept = true;
        return;
      }
      if (!this.userInfo?.phone) {
        this.$toast("请先绑定手机", 2);
        return;
      }
      this.$refs.ChangePhoneDia.topTit = "修改密码";
      this.showChangePhoneFlag = true;
    },
    modilogo() {
      let upAddress =
        this.upAvater.indexOf("Public") == -1
          ? this.upAvater
          : this.upAvater.slice(this.upAvater.indexOf("Public") - 1);
      modilogoAPI({ logo: upAddress })
        .then((res) => {
          if (res.status == 1) {
            this.$toast(res.msg, 1);
            this.getUserInfo();
            this.handleCloseChangeAvarer();
          }
        })
        .catch((err) => {});
    },
    getLink(arr) {
      return new Promise((resolve) => {
        getDirectAvatarUploadLinkAPI(arr)
          .then((res) => {
            if (res.status == 1) {
              const uploadUrl = res.data.upload_part_url;
              const task_id = res.data.task_id;
              const merge_part_url = res.data.merge_part_url;
              resolve({ uploadUrl, task_id, merge_part_url });
            }
          })
          .catch((err) => {});
      });
    },
    handleCloseChangeAvarer() {
      this.showDiaFlag = false;
      this.upAvater = "";
      this.act = -1;
    },

    handleReadFile(_blob) {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer();
        fileReader.onloadend = (e) => {
          spark.append(e.target.result);
          resolve(spark.end());
        };
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(_blob);
      });
    },
    hexToBinaryString(hex) {
      let bytes = [],
        length = hex.length,
        x;

      for (x = 0; x < length - 1; x += 2) {
        bytes.push(parseInt(hex.substr(x, 2), 16));
      }

      return String.fromCharCode.apply(String, bytes);
    },

    delUpList(id) {
      for (let i = this.newUpList.length - 1; i >= 0; i--) {
        let vl = this.newUpList[i];
        if (vl.id === id) {
          vl.cancel();
          this.newUpList.splice(i, 1);
        }
      }
    },
    delAllUpList() {
      for (let i = this.newUpList.length - 1; i >= 0; i--) {
        this.newUpList[i].cancel();
        this.newUpList.splice(i, 1);
      }
    },
    asyncMyUploadImg(file) {
      return new Promise((resolve) => {
        this.upDis = true;
        let opt = {
          id: file.file.uid,
          name: file.file.name,
          prdMd5: "",
          fileStatus: 0,
          size: file.file.size,
          file: file.file,
          currentSpeed: 0,
          loaded: 0,
          sizeM1: this.part_size,
          $store: this.$store,
        };
        this.upCallback = resolve;
        let _file = new OneUpload(opt, resolve, "async");
        this.newUpList.push(_file);
        this.newComputeMD5(_file);
      });
    },
    myUploadImg(file) {
      this.upDis = true;
      let opt = {
        id: file.file.uid,
        name: file.file.name,
        prdMd5: "",
        fileStatus: 0,
        size: file.file.size,
        file: file.file,
        currentSpeed: 0,
        loaded: 0,
        sizeM1: this.part_size,
        $store: this.$store,
      };
      let _file = new OneUpload(opt, this.upCallback);
      this.newUpList.push(_file);
      this.newComputeMD5(_file);
    },
    async newComputeMD5(file) {
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      let hashAll = "";
      let _flag = true;
      for (let i = 0; i < file.part_count; i++) {
        let _blob;
        if (i === file.part_count - 1) {
          _blob = blobSlice.call(file.file, i * file.sizeM1, file.size);
        } else {
          _blob = blobSlice.call(
            file.file,
            i * file.sizeM1,
            (i + 1) * file.sizeM1
          );
        }
        try {
          let _md5 = await this.handleReadFile(_blob);
          hashAll += this.hexToBinaryString(_md5);
          file.md5List.push({ id: 1, md5: _md5 });
        } catch (e) {
          _flag = false;
          break;
        }
      }

      if (!_flag) {
        file.prdMd5 = "";
        this.upCallback({
          action: "stepFail",
        });
        return;
      }

      file.md5Flag = 2;
      let md5 = SparkMD5.hashBinary(hashAll);
      file.prdMd5 = md5;
      file.uniqueIdentifier = md5;
      this.createUpTask(file);
    },
    createUpTask(file) {
      let _data = {
        hash: file.prdMd5,
        totalSize: file.size,
        filename: file.name,
        sub_type: this.newUpType,
      };

      getDirectAvatarUploadLinkAPI(_data)
        .then((res) => {
          if (res.status == 1) {
            let formObj = res.data;
            file.task_id = formObj.task_id;
            file.merge_part_url = formObj.merge_part_url;
            file.upload_part_url = formObj.upload_part_url;
            file.cancel_url = formObj.cancel_url;
            file.resume();
          }
        })
        .catch((err) => {});
    },
    upCallback(args) {
      let res = args.res || { data: { path: "" } };
      this.upDis = false;
      if (args.action === "success") {
        this.logo = this.$store.state.userInfo.ucgimg_host + res.data.path;
        this.upAvater = res.data.path;
      } else if (args.action === "fail") {
        this.$toast(args.msg, 2);
      } else if (args.action === "stepFail") {
        this.$toast("头像上传失败，请重试", 2);
      }
    },
    bindQQWXFn(type) {
      this.unType = type;
      if (type == "QQ") {
        if (this.userInfo?.is_bind_qq == 1) {
          //绑定qq 去解绑
          this.unBindFlag = true;
        } else {
          window.location.href = `${this.$glb.getApiUrl}/user-service/passport/qqLogin?is_bind=1`;
        }
      } else {
        if (this.userInfo?.is_bind_wx == 1) {
          //绑定wx 去解绑
          this.unBindFlag = true;
        } else {
          this.showWx();
        }
      }
    },
    getWinXinInfo() {
      getWxinfoAPI({ is_bind: 1 })
        .then((res) => {
          if (res.status == 1) {
            this.wxInfo = res.data;
          }
        })
        .catch((err) => {});
    },
    showWx() {
      this.showWxFlag = true;
      this.$nextTick(() => {
        new WxLogin({
          id: "wxloginCode", // 第三方页面显示二维码的容器id
          appid: this.wxInfo.appid,
          redirect_uri: this.wxInfo.wxcb,
          scope: "snsapi_login",
          state: this.wxInfo.state,
          href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHttYXJnaW4tYm90dG9tOjE3cHh9CgouaW1wb3dlckJveCAucXJjb2RlIHsKd2lkdGg6IDIwMHB4OwpoZWlnaHQ6MjAwcHg7CmJhY2tncm91bmQ6ICNGRkZGRkY7CmJveC1zaGFkb3c6IDBweCA0cHggMjVweCAwcHggcmdiYSgwLDAsMCwwLjA4KTsKYm9yZGVyLXJhZGl1czogOHB4Owpib3JkZXI6IDFweCBzb2xpZCAjRTVFNUU1Owp9CgoK",
        });
      });
    },
    userBindQQ(bind_qq_token) {
      userBindQQAPI({ bind_qq_token })
        .then((res) => {
          if (res.status == 1) {
            this.$toast(res.msg, 1);
            this.getUserInfo();
            this.$router.replace({ query: {} });
            // this.handleCloseChangeAvarer();
          } else {
            this.$toast(res.msg, 2);
            this.$router.replace({ query: {} });
          }
        })
        .catch((err) => {});
    },
    userBindWX(bind_wx_token) {
      userBindWxAPI({ bind_wx_token })
        .then((res) => {
          if (res.status == 1) {
            this.$toast(res.msg, 1);
            this.getUserInfo();
            this.$router.replace({ query: {} });
            // this.handleCloseChangeAvarer();
          } else {
            this.$toast(res.msg, 2);
            this.$router.replace({ query: {} });
          }
        })
        .catch((err) => {});
    },
    loginOut() {
      loginOutAPI()
        .then((res) => {
          if (res.status == 1) {
            localStorage.removeItem("platfro");
            this.myCookieValue = this.$utils.getCookie(this.$glb.fmCookieName);
            this.$store.commit("clearUserData"); //重置缓存
            window.location.reload();
          }
          this.hoverFlag = false;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
