<template>
  <div class="fmCloud" @mousemove.prevent="selectItems">
    <div class="fixed-top" :style="`width:${$store.state.appRightWidth}px`">
      <TabList
        ref="tabList"
        :placeholder="$t('share.sharePlaceholder')"
        curId="1"
        @setNav="setNav"
        @searchList="searchList"
      ></TabList>
      <div class="fc-second">
        <div class="add-box add-box1">
          <div class="btn-add">
            <fm-button
              @click="openAddGatherDialog"
              icon="icon-cloud-add"
              round
              size="medium"
              >{{ $t('share.newCollections') }}</fm-button
            >
          </div>
          <div class="multiple-operation" v-if="checkedShares.length > 0">
            <div @click="isCancelShare()">
              <svg-btn icon-class="icon-share3" />
              <span>{{
               isHasGather()
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="file-type">
        <div class="search-result" v-if="shareParams.keywords == ''">
          <el-tooltip
            class="item r-tooltip"
            effect="dark"
            :content="$t('share.allShare')"
            placement="top"
          >
            <span class="all" style="font-weight: 500" @click="resetRouter"
              >{{ $t('share.allShare') }}</span
            >
          </el-tooltip>
          <div class="total">
            {{ total }}
          </div>
        </div>
        <div class="search-result" v-if="shareParams.keywords !== ''">
          <el-tooltip
            class="item r-tooltip"
            effect="dark"
            :content="$t('share.allShare')"
            placement="top"
          >
            <span class="all" @click="resetRouter">{{ $t('share.allShare') }}</span>
          </el-tooltip>
          <div class="r-search">
            <svg-btn icon-class="icon-file-direction" />
             <div class="r-search-tit">
              {{$utils.searchTitSlice(shareParams.keywords)}}
            </div>
          </div>
        </div>

        <div class="fileClass">
          <span
            @click="chageClass(item.id)"
            v-for="item in fileType"
            :key="item.id"
            :class="['label', { curStyle: item.id == fileTypeId }]"
            >{{ $t(item.name) }}</span
          >
          <div class="screenBtn">
            <fm-button
              icon="icon-cloud-screen"
              type="info"
              text
              size="small"
            ></fm-button>
            <div class="screenBox">
              <div
                @click="changeSort(item.id)"
                v-for="item in screenList"
                :key="item.id"
                :class="{
                  curScreen: item.id == screenId || item.id1 == screenId,
                }"
              >
                <svg-btn icon-class="icon-cloud-check" />
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <span class="line"></span>
          <fm-button
            class="icon-refresh"
            @click="refresh"
            icon="icon-refresh"
            type="info"
            text
            size="small"
          ></fm-button>
        </div>
      </div>
    </div>
    <div
      class="file-content"
      v-infinite-scroll="getShareList"
      ref="scrollBox"
      infinite-scroll-distance="50"
      infinite-scroll-immediate="false"
      @scroll="scrollBoxFun()"
      v-loading="loading"
      @mousedown="handleMouseDown($event, -1)"
      @mouseup.prevent="endSelection"
    >
      <div class="tab-th" v-show="total != 0">
        <div class="selectAll">
          <div @click="allCheck == 2 ? cancelCheckedVal() : setCheckedVal()">
            <svg-btn
              :icon-class="
                allCheck == 2
                  ? 'icon-file-check'
                  : allCheck == 0
                  ? 'icon-file-uncheck-'+$store.state.theme
                  : 'icon-file-checks'
              "
            >
            </svg-btn>
          </div>
          <span>{{ $t('cloud.fileName') }}</span>
        </div>
        <div
          class="pointer"
          :class="['lineup2', { pointer: screenId == 0 }]"
          @click="changeSort(screenId == 2 ? 3 : 2)"
        >
          <span>{{ $t('cloud.size') }}</span>
          <img
            v-show="screenId == 2 || screenId == 3"
            :src="
              screenId == 2
                ? $utils.getPng('web4/icon_order_descending')
                : $utils.getPng('web4/icon_order_ascending')
            "
          />
        </div>
        <div
          class="pointer"
          :class="['lineup3', { pointer: screenId == 1 }]"
          @click="changeSort(screenId == 0 ? 1 : 0)"
        >
          <span>{{ $t('cloud.creatTime') }}</span>
          <img
            v-show="screenId == 0 || screenId == 1"
            :src="
              screenId == 0
                ? $utils.getPng('web4/icon_order_descending')
                : $utils.getPng('web4/icon_order_ascending')
            "
          />
        </div>
      </div>
      <div class="file-list" v-if="shareList.length > 0">
        <div
          class="file-item-hover"
          v-for="(item, index) in shareList"
          :key="index"
          @mousedown="handleMouseDown($event, index)"
          @mouseenter="handleMouseenter($event, index)"
          :class="[
            { hoverItem: item.isHover || checkedShares.indexOf(index) > -1 },
          ]"
        >
          <a-dropdown :trigger="dropdown" @visibleChange="visibleChange">
            <div class="file-item" :class="{'lockeditem':item.is_locked=='1'}">
              <div class="lineup1">
                  <svg-btn
                    class="icon_checks"
                    @click.prevent="chooseFile(index)"
                    :icon-class="
                      checkedShares.indexOf(index) > -1
                        ? 'icon-file-check'
                        : 'icon-file-uncheck-'+$store.state.theme
                    "
                  ></svg-btn>
              <div class="openFoldername">
                <div class="file_icon">
                  <img class="icon" @error="onError($event, item)"
                        :src="item.is_gather == '1' ? item.ext_icon : item.showPlay ? item.ext_icon : $utils.fileIcon(item.extension)"/>
                </div>
                <el-tooltip open-delay="1000" popper-class="item list-tooltip" effect="dark" :content="`${item.name}${item.extension?item.extension :''}`" placement="bottom">
                  <span class="name">{{ item.name }}{{ item.extension }}</span>
                </el-tooltip>
              </div>
                <div class="locked-info" v-show="item.is_locked == '1'">
                  <svg-btn icon-class="icon-tips-warning" />
                  <span>已锁定 </span>
                </div>
              </div>
              <div class="tools-box">
                <div class="itemFilesTools" v-show="checkedShares.length < 2">
                  <template>
                    <div class="act" v-show="item.is_locked == '0'" @click.stop="copyUrl(item)">
                      <el-tooltip open-delay="1000"
                        popper-class="item list-tooltip"
                        effect="dark"
                        content="复制"
                        placement="bottom"
                      >
                        <svg-btn icon-class="icon-share2"></svg-btn>
                      </el-tooltip>
                    </div>
                    <div class="act" v-show="item.is_locked == '0'" @click.stop="openShareBox(item)">
                      <el-tooltip open-delay="1000"
                        popper-class="item list-tooltip"
                        effect="dark"
                        content="分享"
                        placement="bottom"
                      >
                        <svg-btn icon-class="icon-share1"></svg-btn>
                      </el-tooltip>
                    </div>
                    <div
                      class="act"
                      v-show="item.is_gather == '1'"
                      @click.stop="getGatherInfoList(item)"
                    >
                      <el-tooltip open-delay="1000"
                        popper-class="item list-tooltip"
                        effect="dark"
                        content="编辑"
                        placement="bottom"
                      >
                        <svg-btn icon-class="icon-share"></svg-btn>
                      </el-tooltip>
                    </div>
                    <div class="act">
                      <el-dropdown placement="bottom-start"
                        @visible-change="changeVisible($event, item)"
                        @command="(command) => handleCommand(command, item)"
                      >
                        <div>
                          <svg-btn icon-class="icon-cloud-more"></svg-btn>
                        </div>
                        <el-dropdown-menu
                          class="moreAct"
                          slot="dropdown"
                          :class="isOpen ? '' : 'isOpen1'"
                        >
                          <el-dropdown-item
                            command="a"
                            v-if="item.is_gather.toString() == '1'"
                            >{{ $t('share.reName') }}</el-dropdown-item
                          >
                          <el-dropdown-item command="b"
                            ><span>{{
                              item.is_gather.toString() == "1"
                                ? $t(`share.deleteCollection`)
                                : $t(`share.cancelShare`)
                            }}</span></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </div>
              </div>
              <div class="lineup2">
                {{ item.is_gather == "0" ? item.size : item.fileCount }}
              </div>
              <div class="lineup3">
                {{ item.intime }}
              </div>
            </div>
            <template #overlay>
              <a-menu class="menu-a" :class="isOpen2 ? 'menu-b':''">
                <a-menu-item v-show="!isSelectMore && item.is_locked=='0'"
                  @click="rightHandle(menu_item.id, item)"
                  v-for="menu_item in item.is_gather == '1'
                    ? list2
                    : list1 "
                  :key="menu_item.id"
                  :class="menu_item.id == -1 ? 'line' : 'me-item'"
                  >{{ $t(menu_item.name) }}
                </a-menu-item>
                <a-menu-item  @click="rightHandle(6, item)"  class="me-item"> 
                  {{ isHasGather() }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <div
          v-show="showFlag == 1"
          class="selection-box"
          :style="{
            left: selectionBox.left + 'px',
            top: selectionBox.top + 'px',
            width: selectionBox.width + 'px',
            height: selectionBox.height + 'px',
          }"
        ></div>
        <div class="isOver" v-if="isLoading2" v-loading="isLoading2"></div>

        <div class="isOver" v-if="isOver">{{ $t('cloud.noMore') }}</div>
      </div>
      <div class="file-default" v-if="!loading && shareList.length == 0">
        <img :src="$utils.getPng('web4/file_default')" />
        <div class="search"  v-if="shareParams.keywords==''">
          <span>{{ $t('share.notShareFiles') }}</span><span>{{ $t('share.shareLook') }}</span>
        </div>
        <div class="search" v-else>
          <span>{{ $t('cloud.notFile') }}</span><span>{{ $t('cloud.trySearchOther') }}</span>
        </div>
      </div>
    </div>
    <!-- 分享文件 -->
    <GatherDialog
      ref="gatherDialog"
      @addShareUrl="addShareUrl"
      @handleClose="isShowGatherDialog = false"
      @dialogEditGather="dialogEditGather"
      :boxType="boxType"
      :handleItem="handleItem"
      :gather_ids="gather_ids"
      :shareUrl="shareUrl"
      :gather_name="gather_name"
      :isShow="isShowGatherDialog"
      :auto_set="auto_set"
      :isSysCode="isSysCode"
      :pucode="pucode"
      :open_pucode="open_pucode"
    ></GatherDialog>
    <ShareDialog
      boxType="2"
      :shareUrl="shareUrl"
      :handleItem="handleItem"
      :isShow="isShowShareDialog"
      :incomeList="incomeList"
      @handleClose="isShowShareDialog = false"
    ></ShareDialog>
    <!-- 选择合集文件 -->
    <AddGather
      ref="addGather"
      :isShow="isShowAddGather"
      :gatherBoxType="gatherBoxType"
      @gatherSearch="gatherSearch"
      @handleClose="isShowAddGather = false"
      @openShareDialog="openShareDialog"
      @addGather="addGather"
    >
    </AddGather>
    <!-- 重命名合集 -->
    <RenameGather
      @handleClose="isShowRename = false"
      @editName="editName"
      :rename="rename"
      :handleItem="handleItem"
      :isShow="isShowRename"
    ></RenameGather>
    <!-- 取消分享弹框 -->
    <CancelShareTips
      :isShow="isCancelShareTips"
      :cancelTips="cancelTips"
      :deleteHJ="deleteHJ"
      @handleClose="isCancelShareTips = false"
      @confirm="cancelShare"
    >
    </CancelShareTips>
    <!-- 绑定手机1  实名认证2 验证码3-->
    <BindPhone
      :isShow="isShowBindPhone"
      :type="bindType"
      :time="time"
      @getPhoneCode="getPhoneCode"
      @bindPhone="bindPhone"
      @confirmNameAuth="realNameAuth"
      @handleClose="isShowBindPhone = false"
    ></BindPhone>
    <!-- 分享提示 -->
    <BaseDialog
      :isShow="isShowBaseDialog"
      :text="shareTips"
      :showCancel="false"
      @confirm="goAddGather"
      @handleClose="isShowBaseDialog = false"
      title="提示"
      >确 认</BaseDialog
    >

    <div class="toTop-div" v-show="showTop" @click="scrollToTop">
      <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
    </div>
  </div>
</template>

<script>
import {
  shareList,
  cancelShare,
  addGather,
  editGather,
  gatherInfoList,
  shareSetInfo,
} from "@/utils/apiList/share";
import {
  bindPhoneSendCode,
  bindPhoneAPI,
  realNameAuth,
  getPopupStauts,
} from "@/utils/apiList/account";
import TabList from "../TabList/index.vue";
import GatherDialog from "@/components/Cloud/GatherDialog";
import AddGather from "@/components/Cloud/AddGather";
import RenameGather from "@/components/Cloud/RenameGather";
import CancelShareTips from "@/components/Cloud/CancelShareTips";
import ShareDialog from "@/components/Cloud/ShareDialog";
import BindPhone from "@/components/Cloud/BindPhone";
import BaseDialog from "@/components/Dialog/BaseDialog";
export default {
  components: {
    GatherDialog,
    TabList,
    AddGather,
    RenameGather,
    CancelShareTips,
    ShareDialog,
    BindPhone,
    BaseDialog,
  },
  data() {
    return {
      curId: 0,
      fileType: [
        { id: 0, name: "share.all" },
        { id: 1, name: "share.files" },
        { id: 2, name: "share.collention" },
      ],
      fileTypeId: 0,
      screenList: [
        { id: 2, id1: 3, name: "按大小排序" },
        { id: 0, id1: 1, name: "按时间排序" },
      ],
      screenId: 0,
      isShowGatherDialog: false,
      isShowAddGather: false,
      shareList: [], //分享列表
      allCheck: 0,
      checkedShares: [], //已选的分享数据
      total: 0,
      loading: true,
      fileParams: {
        //查询文件列表参数
        down: "", //排序
        folder_id: 0,
        keywords: "",
        page: 0,
        limit: 60,
        size: "",
        time: 1,
      },
      shareParams: {
        //查询分享列表参数
        size_sort: "",
        sort: 1, //1最新0最早
        limit: 60,
        keywords: "",
        page: 0,
        type: 0, //类型0所有，1文件，2合集
      },
      gather_ids: [],
      gather_name: "",
      boxType: false,
      isShowRename: false,
      rename: "",
      handleItem: {}, //当前操作项
      gatherBoxType: 1, // 1选择 2编辑
      isOver: false,
      showTop: false,
      shareUrl: "",
      isCancelShareTips: false,
      cancelTips: "",
      cancelParams: [],
      isShowShareDialog: false,
      isShowBindPhone: false,
      bindType: 1, //
      msgid: "", //验证码id
      isbindphone: "", //是否绑定手机号
      timerA: null,
      time: 60,
      isShowBaseDialog: false,
      shareTips:
        "为了共同维护良好的网络环境，每次分享的文件将会经过机器检测，请勿分享非法文件。",
      auto_set: 0, //是否自动填充
      isSysCode: 1, // 1 默认系统 生成  2自定义
      pucode: "",
      open_pucode:'',
      startX: 0,
      startY: 0,
      selectionBox: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      moveBox: {
        left: 0,
        top: 0,
      },
      mouseDownIsCheck: false,
      isLoading2: false,
      list1: [
        { id: 0, name: "share.copyLink" },
        { id: -1, name: "" },
        { id: 3, name: "share.viewLink" },
        { id: -1, name: "" },
      ],
      list2: [
        { id: 0, name: "share.copyLink" },
        { id: -1, name: "" },
        { id: 3, name: "share.viewLink" },
        { id: 4, name: "share.editCollection" },
        { id: -1, name: "" },
        { id: 5, name: "share.reName"  },
      ],
      showFlag: 0,
      isSelecting: 0,
      isOpen: false,
      isOpen2: true,
      isSelectMore: false,
      deleteHJ: false,
      order: false,
      dropdown: ['contextmenu'],
      phone_item: {
        phone: "",
        pcode: "",
      },
      incomeList:[]
    };
  },
  created() {
    this.getShareList(1);
  },
  mounted() {
    this.$store.commit("setAttr", {
      name: "endSelectFuncallback",
      val: this.endSelectFun,
    });
  },
  watch: {
    checkedShares: {
      handler(newval, oldVal) {
        if (newval.length > 0 && newval.length < this.shareList.length) {
          this.allCheck = 1;
        } else if (newval.length == 0) {
          this.allCheck = 0;
        } else if (newval.length == this.shareList.length) {
          this.allCheck = 2;
        }
      },
      immediate: true,
    },
    pageValue(newVal) {
      if (newVal == 1) {
        this.cancelCheckedVal();
        this.scrollToTop();
      }
    },
  },
  methods: {
    setNav(item) {
      this.$router.push({
        path: item.url,
      });
    },
    chageClass(id) {
      this.fileTypeId = id;
      this.shareParams.type = id;
      this.shareParams.page = 0;
      this.checkedShares = [];
      this.getShareList(1);
    },
    //排序
    changeSort(id) {
      this.screenId = id;
      this.checkedShares = [];
      switch (id) {
        case 0:
          this.shareParams.sort = 1;
          this.shareParams.size_sort = "";
          break;
        case 1:
          this.shareParams.sort = 0;
          this.shareParams.size_sort = "";
          break;
        case 2:
          this.shareParams.size_sort = 1;
          this.shareParams.sort = "";
          break;
        case 3:
          this.shareParams.size_sort = 0;
          this.shareParams.sort = "";
          break;
        default:
          break;
      }
      this.shareParams.page = 0;
      this.getShareList(1);
    },
    //搜索
    searchList(value) {
      this.shareParams.keywords = value;
      this.checkedShares = [];
      this.getShareList(1);
    },
    getShareList: _.debounce(
      function (type = 0) {
        if (type == 1) {
          this.shareParams.page = 0;
          this.isLoading2 = false;
          this.loading = true;
        } else {
          if (this.isOver) return;
          this.isLoading2 = true;
          this.loading = false;
        }
        this.isOver = false;
        this.shareParams.page += 1;
        shareList(this.shareParams)
          .then((res) => {
            if (res.status == "1") {
              this.loading = false;
              this.isLoading2 = false;
              this.total = res.data.total;
              if (res.data.list.length < this.shareParams.limit)
                this.isOver = true;
              res.data.list.forEach((item, index) => {
                item.isHover = false;
                item.itemloading = false;
                item.showPlay = true;
              });
              if (this.shareParams.page == 1) {
                this.shareList = res.data.list;
              } else {
                this.shareList = this.shareList.concat(res.data.list);
              }
              if (
                this.checkedShares.length > 0 &&
                this.checkedShares.length < this.shareList.length
              ) {
                this.allCheck = 1;
              } else if (this.checkedShares.length == 0) {
                this.allCheck = 0;
              } else if (this.checkedShares.length == this.shareList.length) {
                this.allCheck = 2;
              }
              this.dropdown = ['contextmenu']
            }
          })
          .catch((err) => {});
      },
      300,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //下拉框出现/隐藏时触发
    changeVisible(e, item) {
      item.isHover = e;
    },
    isHasGather() {
      let arr = this.checkedShares.map((item) => {
        return this.shareList[item];
      });
      let allIsGather = arr.every((item) => item?.is_gather === "1");
      let allIsFile = arr.every((item) => item?.is_gather === "0");
      var str;
      if (this.checkedShares.length > 1) {
        if (allIsGather && !allIsFile) {
          str = "share.deleteCollection";
        } else if (allIsFile && !allIsGather) {
          str = "share.cancelShare";
        } else {
          str = "share.cancelDelete";
        }
      } else {
        if (allIsGather && !allIsFile) {
          str = "share.deleteCollection";
        } else if (allIsFile && !allIsGather) {
          str = "share.cancelShare";
        }
      }
      return this.$t(str);
    },
    //更多 操作 a重命名 b取消分享
    handleCommand(command, item) {
      if (command == "a") {
        this.handleItem = item;
        this.rename = item.name;
        this.isShowRename = true;
      } else {
        this.handleItem = item;
        this.isCancelShare();
      }
    },
    // 单个点击选中
    chooseShare(index) {
      // 点击文件选中
      var exist = this.checkedShares.indexOf(index);
      if (exist == -1) {
        this.checkedShares.push(index);
      } else {
        this.checkedShares.splice(exist, 1);
      }
    },
    // 全选
    setCheckedVal() {
      // 点击文件选中
      this.checkedShares = [];
      var tmpfiles = this.shareList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        this.checkedShares.push(i);
      }
      this.allCheck = 1;
    },
    // 取消全选
    cancelCheckedVal() {
      this.allCheck = 0;
      this.checkedShares = [];
    },
    // 获取选中的文件的id
    getSelFileIds() {
      var ids = [];
      this.checkedShares.map((item) => {
        ids.push({
          id: this.shareList[item].id,
          is_gather: this.shareList[item].is_gather,
        });
      });
      return this.$utils.arrFilter(ids);
    },
    isCancelShare() {
      if (this.checkedShares.length > 0) {//多选
        this.deleteHJ = this.isHasGather()
        let multipleSelection = this.checkedShares.map((item) => {
          return this.shareList[item];
        });
        this.cancelTips = multipleSelection.some(
          (item) => item.in_share_gather == "1"
        )
          ? "该链接将从列表或相关的分享合集中清除，其他用户无法接收此文件"
          : "其他用户将无法通过当前链接接收此文件";

        this.cancelParams = this.getSelFileIds();
      }else{
        this.cancelTips = this.handleItem.in_share_gather == "0"? "其他用户将无法通过当前链接接收此文件" : "该链接将从列表或相关的分享合集中清除，其他用户无法接收此文件";
        this.cancelParams = [{
          id: this.handleItem.id,
          is_gather: this.handleItem.is_gather,
        }]
        this.deleteHJ = this.handleItem.is_gather =='1' ? '删除合集' : '取消分享'
      }
      this.isCancelShareTips = true;
    },
    //取消分享
    cancelShare() {
      cancelShare(this.cancelParams)
        .then((res) => {
          if (res.status == "1") {
            this.isCancelShareTips = false;
            this.$toast(res.msg, 1);
            this.checkedShares = [];
            this.getShareList(1);
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    //创建合集判断
    openAddGatherDialog() {
      this.handleItem = {};
      //是否绑定手机号 实名 第一次分享
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      // if(this.$store.state.userInfo.user_id_card_status == '0'){
      //   this.bindType=2
      //   this.isShowBindPhone=true
      //   return
      // }
      getPopupStauts({ popup_id: 100 }).then((res) => {
        if (res.status == "1") {
          if (res.data.is_open == "1") {
            this.isShowBaseDialog = true;
          } else {
            this.goAddGather();
          }
        }
      });
    },
    //获取是否自动填充 和 取件码选择
    shareSetInfo() {
      // let file_id = this.handleItem.id ? this.handleItem.is_gather=='1' ? '0': this.handleItem.id : '0'
      let file_id = '0'
      shareSetInfo({ file_id: file_id}).then((res) => {
        if (res.status == "1") {
          this.auto_set = parseInt(res.data.gather_auto_set);
          this.isSysCode = res.data.gather_pucode == "" ? 1 : 2; //空就是系统的
          this.$refs.gatherDialog.pucode = res.data.gather_pucode;
          this.open_pucode = res.data.gather_open_pucode
        }else{
          this.$toast(res.msg, 2);
        }
      });
    },
    //打开创建合集弹框
    goAddGather() {
      this.isShowBaseDialog = false;
      this.$refs.addGather.reset();
      this.$refs.addGather.fileLoading = true;
      this.$refs.addGather.getFileList();
      this.isShowAddGather = true;
    },
    openShareDialog(gather_ids, gather_name) {
      this.shareSetInfo();
      if (this.handleItem.id) {
        //编辑
        let params = {
          file_ids: gather_ids.toString(),
          gather_id: this.handleItem.id,
        };
        this.editGather(params, 2);
      } else {
        //新增合集
        this.gather_name = gather_name;
        this.gather_ids = gather_ids;
        this.boxType = true;
        this.isShowGatherDialog = true;
      }
    },
    //新建合集
    addShareUrl(params) {
      addGather(params)
        .then((res) => {
          if (res.status == "1") {
            this.$toast(res.msg, 1);
            this.isShowAddGather = false;
            this.boxType = false;
            this.shareUrl = res.data.gather_url;
            this.handleItem = {
              name: res.data.gather_name,
              id: res.data.gather_id,
              good_count: res.data.good_count,
            };
            this.$refs.addGather.reset();
            this.getShareList(1);
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    //重命名合集
    editName(name) {
      let params = {
        gather_name: name,
        gather_id: this.handleItem.id,
      };
      this.editGather(params, 1);
    },
    //分享弹框的 编辑合集路径
    dialogEditGather() {
      if (this.boxType) {
        this.isShowGatherDialog = false;
      } else {
        this.getGatherInfoList(this.handleItem);
      }
    },
    //修改合集
    editGather(params, type) {
      // type 1重命名 2编辑合集文件
      editGather(params).then((res) => {
        if (res.status == "1") {
          if (type == 1) {
            this.isShowRename = false;
          } else {
            this.gather_name = res.data.gather_name;
            this.shareUrl = res.data.gather_url;
            this.isShowAddGather = false;
            this.isShowGatherDialog = true;
            this.boxType = false;
          }
          this.getShareList(1);
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //获取合集内容
    getGatherInfoList(item) {
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      this.$refs.addGather.reset()
      this.handleItem = item;
      gatherInfoList({ gather_id: item.id, limit: 1000, page: 1 }).then(
        (res) => {
          if (res.status == "1") {
            let kvlist = []; //用来存放组装后新的数组对象
            let one = {}; //添加的对象
            for (var i in res.data.gather_files) {
              one = res.data.gather_files[i];
              one["isSelect"] = true;
              kvlist.push(one);
            }
            this.$refs.addGather.allCheck2 = 2;
            this.$refs.addGather.editFileList = kvlist;
            this.$refs.addGather.gatherBoxType = 2;
            this.isShowAddGather = true;
          } else {
            this.$toast(res.msg, 2);
          }
        }
      );
    },
    //复制链接
    copyUrl(item) {
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      let msg =
        item.good_count == 1 ? "复制链接及取件码成功" : "复制链接成功";
      this.$copyText(item.share_link).then(
        (e) => {
          this.$toast(msg, 1);
        },
        function (e) {}
      );
    },
    //分享 列表打开分享弹框
    openShareBox(item) {
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      this.handleItem = item;
      this.shareUrl = item.share_link;
      this.boxType = false;
      if (item.is_gather == "1") {
        this.isShowGatherDialog = true;
      } else {
        this.openShareDialog1()
      }
    },
    openShareDialog1() { 
      shareSetInfo({ file_id: this.handleItem.id }).then((res) => {
          if (res.status == "1") {
            this.shareUrl = this.handleItem.share_link;
            this.boxType = '2';
            this.isShowShareDialog = true;
            this.incomeList = res.data.rebate_model;
          }else{
            this.$toast(res.msg,2)
          }
        });
    },
    
    scrollBoxFun() {
      var scrollEl = this.$refs.scrollBox;
      this.showTop = scrollEl.scrollTop > 50;
      let list = document.getElementsByClassName("el-tooltip__popper");
      let list1 = document.getElementsByClassName("el-dropdown-menu");
      if (list.length > 0) {
        list[list.length - 1].style.display = "none";
      }
      if(list1.length > 0){
        list1[list1.length - 1].style.display = "none";
      }
    },
    scrollToTop() {
      let scrollEl = this.$refs.scrollBox;
      scrollEl.scrollTop = 0;
    },
  
    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
            }, 1000);
          } else if (res.status == 90001) {
            resolve({
                  result: false,
                  validate: false
                })
              this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    //发送验证码
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(phone)) {
          this.$toast("请正确输入手机号", 3);
          return true
      }
      this.getPhoneAly();
    },
    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //实名
    realNameAuth(data) {
      realNameAuth(data).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    handleMouseDown: _.debounce(
      function (event, index) {
        if(index == -1){
          this.checkedShares = []
        }
        this.selectionBox = {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        };

        this.startX = event.clientX;
        this.startY = event.clientY;

        const currentX = event.clientX;
        const currentY = event.clientY;
        this.moveBox = {
          left: 0,
          top: 0,
        };
        // 检查触发事件的元素是否是 file-item-hover 的父元素
        if (event.button == 0) {
          let arr = ['icon_checks','iconColor']
          if (!arr.includes(event.target.classList[0])) {
            //点击icon_checks 即左侧选中多选不触发handleMouseDown 只触发chooseFile
            // 执行你的 mousedown 逻辑
            this.selectItemOne(index);
            this.moveBox = {
              left: currentX,
              top: currentY - 56,
            };
          }
        } else if (event.button == 1) {
        } else if (event.button == 2) {
          // this.checkedShares = [];
          if(index == -1) return;
          var exist = this.checkedShares.indexOf(index);
          if (exist == -1) {
            this.checkedShares = [];
            this.checkedShares.push(index);
          } else {
            // this.checkedShares.splice(exist, 1);
          }
          if (this.checkedShares.length > 1) {
            this.isSelectMore = true;
          } else {
            this.isSelectMore = false;
          }
        }
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    handleMouseenter(event, index) {},
    chooseFile(index) {
      // 点击文件选中
      var exist = this.checkedShares.indexOf(index);
      if (exist == -1) {
        this.checkedShares.push(index);
      } else {
        this.checkedShares.splice(exist, 1);
      }
    },

    selectItemOne(index) {
      if (index == -1) {
        this.isSelecting = 1;
        return;
      }
      // 点击文件选中
      var exist = this.checkedShares.indexOf(index);
      if (exist == -1) {
        this.checkedShares = [];
        this.checkedShares.push(index);
        this.isSelecting = 1;
      } else {
        // this.checkedShares.splice(exist, 1);
      }
    },
    selectItems: _.debounce(
      function (event) {
        const currentX = event.clientX;
        const currentY = event.clientY;

        // 计算选择框的位置和大小
        const minX = Math.min(this.startX, currentX);
        const minY = Math.min(this.startY, currentY);
        const maxX = Math.max(this.startX, currentX);
        const maxY = Math.max(this.startY, currentY);
        this.selectionBox = {
          left: minX,
          top: minY - 56,
          width: maxX - minX,
          height: maxY - minY,
        };
        if (this.isSelecting == 1) {
          // 遍历可选择项，判断是否在选择框内并选中
          this.shareList.forEach((item, index) => {
            const itemElement = document.querySelector(
              `.file-item-hover:nth-child(${this.shareList.indexOf(item) + 1})`
            );
            const itemRect = itemElement.getBoundingClientRect();
            if (itemRect.top > minY - 56 && itemRect.bottom < maxY + 69) {
              if (!this.checkedShares.includes(index)) {
                this.checkedShares.push(index);
              }
              this.showFlag = 1;
            } else {
              const index = this.checkedShares.indexOf(index);
              if (index !== -1) {
                this.checkedShares.splice(index, 1);
              }
            }
          });
        }
      },
      0,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    endSelection() {
      this.showFlag = 0;
    },
    endSelectFun() {
      this.isSelecting = 0;
      this.checkStr = "";
      this.showFlag = 0;
      this.$store.commit("setAttr", {
        name: "showFlag",
        val: 0,
      });
      this.$store.commit("setAttr", {
        name: "check_str",
        val: "",
      });
    },
    resetRouter() {
      this.shareParams={
        //查询分享列表参数
        size_sort: "",
        sort: 1, //1最新0最早
        limit: 60,
        keywords: "",
        page: 0,
        type: 0, //类型0所有，1文件，2合集
      },
      this.checkedShares=[]
      this.fileTypeId=0
      this.$refs.tabList.keyword = "";
      this.getShareList(1);
    },
    refresh() {
      this.fileParams.page = 0;
      this.checkedShares = [];
      this.getShareList(1);
    },
    rightHandle(id, item) {
      switch (id) {
        case 0:
          this.copyUrl(item);
          break;
        case 1:
          break;
        case 2:
          break;
        case 3:
          this.openShareBox(item);
          break;
        case 4:
          this.getGatherInfoList(item);
          break;
        case 5:
          this.handleCommand("a", item);
          break;
        case 6:
          this.isCancelShare();
          break;
        default:
          break;
      }
    },
    visibleChange(e) {
      if(e){
        this.dropdown = ['contextmenu','click']
        this.isOpen = e;
        this.isOpen2 = true
      } else {
        this.isOpen2 = false
        this.dropdown = ['contextmenu']
      }
    },
    onError(e, item) {
      this.$set(item, "showPlay", false);
    },
  },
  computed: {
    pageValue() {
      return this.shareParams.page;
    },
  },
};
</script>

<style></style>
