<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/views/News/index.vue
 * @Description: 消息
-->
<template>
  <div class="news">
    <div class="newsType app-right-box newsTypetop">
      <div
        class="item pointer"
        v-for="item in newsType"
        :key="item.id"
        :class="newsTypeAct == item.id ? 'itemAct' : ''"
        @click="topTypeChange(item.id)"
      >
        <el-badge :value="item.num" :max="99" :hidden="item.num == 0">
          <img class="pic" :src="item.pic" alt="" />
        </el-badge>

        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div class="newsCenter">
      <div class="newsTop app-right-box">
        <div class="changeBox" v-if="newsTypeAct == 0">
          <div
            class="item pointer"
            v-for="item in changeType0"
            :key="item.id"
            :class="item.id == changeAct0 ? 'itemAct' : ''"
            @click="changeActFun(0, item.id)"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- <div class="changeBox" v-if="newsTypeAct == 1">
          <div
            class="item pointer"
            v-for="item in changeType1"
            :key="item.id"
            :class="item.id == changeAct1 ? 'itemAct' : ''"
            @click="changeActFun(0, item.id)"
          >
            {{ item.name }}
          </div>
        </div> -->
        <div class="rightBox">
          <div class="all pointer" @click="allRead(1)">全部已读</div>
          <fm-button
            type="info"
            size="medium"
            text
            icon="icon-refresh1"
            @click="allRead(2)"
          ></fm-button>
        </div>
      </div>
      <!-- <div
        class="one"
        ref="scrollBox"
        v-if="newsTypeAct == 1"
        @scroll="scrollBoxFun()"
      >
        <div class="newsDown app-right-box">
          <el-empty
            style="margin: 200px auto"
            :image="$utils.getPng('web4/icon-empty1')"
            description="暂无消息"
          >
          </el-empty>
        </div>
        <div class="newsDown app-right-box">
          <el-skeleton style="width: 100%" :loading="newsDownLoading">
            <div class="item" v-for="item in 10" :key="item">
              <template v-if="true">
                <el-badge :value="1" is-dot>
                  <img class="avater" :src="$utils.getPng('web4/7')" alt="" />
                </el-badge>
                <div class="itemContent">
                  <div class="top">
                    <div class="name1">王崽啾啾糖</div>
                    <div class="to">评论了你</div>
                    <div class="name2">
                      最远的边陲（Farthest Frontier）v0.8.0免安装中文版
                    </div>
                  </div>
                  <div class="time">2099-07-24 20:24</div>
                </div>
                <div class="btnRight">
                  <fm-button type="info" plain round size="mini"
                    >回复</fm-button
                  >
                </div>
              </template>
              <template v-if="false">
                <el-skeleton-item class="avater" variant="circle" />
                <div class="itemContent">
                  <div class="top">
                    <el-skeleton-item class="name1" style="width: 200px" />
                    <el-skeleton-item
                      class="name1"
                      style="width: 100px; margin: 0 10px"
                    />
                    <el-skeleton-item class="name1" style="width: 200px" />
                  </div>
                  <el-skeleton-item class="name1" style="width: 140px" />
                </div>
                <div class="btnRight">
                  <fm-button type="info" plain round size="mini"
                    >回复</fm-button
                  >
                </div>
              </template>
            </div>
          </el-skeleton>
        </div>
      </div> -->
      <div
        class="one"
        ref="scrollBox"
        v-infinite-scroll="getuserMsg"
        infinite-scroll-distance="50"
        v-loading="newsDownLoading2"
        @scroll="scrollBoxFun()"
      >
        <div
          class="newsDown app-right-box"
          :class="!newsDownLoading && userMsglist.length == 0 ? 'default' : ''"
        >
          <el-skeleton style="width: 100%" :loading="newsDownLoading">
            <!-- 内容 -->
            <template>
              <div
                class="itemNotice pointer"
                v-for="item in userMsglist"
                :key="item"
                @click="msgNest(item)"
              >
                <el-badge :is-dot="item.isrd == 0">
                  <img class="avater" :src="item.ext_icon" alt="" />
                </el-badge>
                <div class="rightInfo">
                  <div class="top">
                    <div class="name">{{ item.label }}</div>
                    <div class="time">{{ item.in_time }}</div>
                  </div>
                  <div class="tit">
                    <span v-if="item.amount">￥{{ item.amount }}</span
                    >{{ item.title }}
                  </div>
                  <!-- 官方活动 -->
                  <div class="box1" v-if="typeof item.child == 'string'">
                    <div class="content">
                      {{ item.child }}
                    </div>
                    <img
                      v-if="item.img_url"
                      class="imga"
                      :src="item.img_url"
                      alt=""
                    />
                    <div class="down" v-if="item.jump_url">
                      <div class="btnLeft">去查看</div>
                      <img
                        class="imgNext"
                        :src="$utils.getPng('web4/icon_next')"
                        alt=""
                      />
                    </div>
                  </div>
                  <!-- 订阅审核 -->
                  <!-- <div class="box2" v-else-if="false">
                    <div class="tit1">
                      标题名称：阿里森发生了挨饿室内垃圾粉色
                    </div>
                    <div class="tit1">发布时间：2099-07-24 11:17:24</div>
                    <div class="tit1">审核时间：2099-07-24 11:17:24</div>
                    <div class="refuse">
                      <span class="refTit">拒绝原因：</span
                      >什么原因啊什么原因啊什么原因啊什么原因啊什么原因啊什么原因啊什么原因啊什么原因啊什么原什么原因啊什么原因啊什么原因啊什么原因啊什么原因啊什么原因啊什么原因啊什么原因啊什么原
                    </div>
                  </div> -->
                  <!-- 系统通知 -->
                  <div class="box2" v-else>
                    <div
                      class="refuse"
                      v-for="(i, index) in item.child"
                      :key="index"
                    >
                      <span class="refTit">{{ i.name }}：</span>
                      <span class="refTit" :style="{ color: $store.state.theme == 'dark' && i.color == '2C2C2C'  ? '': `#${i.color}` }">{{
                        i.value
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="isOver" v-if="userMsglist.length != 0 && isOver">没有更多了</div>
            </template>
            <!-- 骨架 -->
            <template slot="template">
              <div class="itemNotice" v-for="item in 10" :key="item">
                <el-skeleton-item class="avater" variant="circle" />
                <div class="rightInfo">
                  <div class="top">
                    <el-skeleton-item style="width: 20%"> </el-skeleton-item>
                    <el-skeleton-item style="width: 10%"> </el-skeleton-item>
                  </div>
                  <el-skeleton-item style="margin: 11px 0"> </el-skeleton-item>
                  <!-- 官方活动 -->
                  <div class="box1">
                    <el-skeleton-item style="margin: 4px 0"> </el-skeleton-item>
                    <el-skeleton-item style="margin: 4px 0"> </el-skeleton-item>
                    <el-skeleton-item style="margin: 4px 0; width: 50%">
                    </el-skeleton-item
                    ><br />
                    <el-skeleton-item class="imga" style="margin: 11px 0">
                    </el-skeleton-item>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="userMsglist.length == 0">
              <div class="newsDown app-right-box">
                <el-empty
                  :image="$utils.getPng('web4/jx_default')"
                  description="暂无消息"
                >
                </el-empty>
              </div>
            </template>
          </el-skeleton>
        </div>
      </div>
    </div>
    <div class="toTop-div" v-show="showTop" @click="scrollToTop">
      <!-- <img :src="$utils.getPng('web4/gotop')" alt="" /> -->
      <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
    </div>
  </div>
</template>

<script>
import { userMsg, userMsgread } from "@/utils/apiList/usermsg";
export default {
  data() {
    return {
      newsType: [
        {
          name: "飞猫小助手",
          id: 0,
          num: this.$store.state.userMsgNub || 0,
          pic: this.$utils.getPng("web4/icon-news1"),
        },
        // {
        //   name: "互动小驿站",
        //   id: 1,
        //   num: 11,
        //   pic: this.$utils.getPng("web4/icon-news2"),
        // },
      ],
      newsTypeAct: 0,
      changeType0: [
        { name: "全部", id: 0 },
        { name: "系统通知", id: 1 },
        { name: "官方通知", id: 2 },
      ],
      changeAct0: 0,
      changeType1: [
        { name: "全部", id: 0 },
        { name: "评论", id: 1 },
      ],
      changeAct1: 0,
      showTop: false,
      newsDownLoading: true,
      newsDownLoading2: false,
      params: {
        page: 0,
        page_size: 30,
        msg_type: -1,
      },
      isOver: false,
      userMsglist: [],
    };
  },
  mounted() {
    this.getuserMsg();
  },
  methods: {
    allRead(type) {
      if (type == 1) {
        this.userMsglist = this.userMsglist.map((obj) => ({
          ...obj,
          isrd: 1,
        }));
        this.userMsgreadFn(-1, true);
      } else {
        this.newsDownLoading2 = true
        this.scrollToTop();
        this.params = {
          page: 0,
          page_size: 30,
          msg_type: this.params.msg_type,
        };
        
        this.isOver = false;
        this.getuserMsg(true);
      }
    },
    msgNest(item) {
      if (item.jump_url) {
        window.open(item.jump_url);
      }
      this.userMsglist = this.userMsglist.map((obj) => {
        if (obj.id == item.id && item.isrd == 0) {
          this.userMsgreadFn(obj.id, false);
          return { ...obj, isrd: 1 };
        }
        return obj;
      });
    },
    userMsgreadFn(id, type) {
      userMsgread({ id, msg_type: this.params.msg_type })
        .then((res) => {
          if (res.status == 1) {
            if (type) {
              this.$toast("已将该列表消息标记为已读", 1);
            }
            this.$store.commit("setAttr", {
              name: "userMsgNub",
              val: +res.data.count,
            });
            this.newsType[0].num = +res.data.count || 0;
          }
        })
        .catch((err) => {});
    },
    getuserMsg: _.debounce(
      function (type) {
        if (this.isOver && !type) return;
        this.params.page++;
        userMsg(this.params)
          .then((res) => {
            if (res.status == 1) {
              this.newsDownLoading = false;
              this.newsDownLoading2 = false
              if (res.data.list.length < this.params.page_size){
                this.isOver = true;
              }
              this.$store.commit("setAttr", {
                name: "userMsgNub",
                val: +res.data.count,
              });
              this.newsType[0].num = +res.data.count;
              if (this.params.page == 1) {
                this.userMsglist = res.data.list;
              } else {
                this.userMsglist = this.userMsglist.concat(res.data.list);
              }
            }else if(res.status == 10020) {
            }else{
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    changeActFun(index, id) {
      if (this.changeAct0 != id) {
        this.isOver = false;
        this.params = {
          page: 0,
          page_size: 30,
          msg_type: id == 0 ? -1 : id,
        };
        this.changeAct0 = id;
        this.getuserMsg();
        this.scrollToTop();
      }

      // this.changeAct1 = id;
    },
    topTypeChange(id) {
      this.newsTypeAct = id;
      this.changeAct0 = 0;
      // this.changeAct1 = 0;
      this.$refs.scrollBox.scrollTop = 0;
    },
    scrollBoxFun() {
      var scrollEl = this.$refs.scrollBox;
      this.showTop = scrollEl.scrollTop > 50;
    },
    scrollToTop() {
      let scrollEl = this.$refs.scrollBox;
      scrollEl.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
