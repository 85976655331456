<template>
	<!-- 顶部-s -->
	<div class="tipHeader">
		<div class="left">
			<img src="https://webimgtest.fmapp.com/Public/web/img/page/appDownload/logo.png" mode="widthFix" class="icon_logo" height="44"/>
			<div class="titleBox">
				<div class="name"> 飞猫盘 </div>
				<div class="tag"> 专注文件加速传输体验 </div>
			</div>
		</div>
		<el-button round type="primary" size="mini" @click="goApp()" style="height: 26px;line-height: normal;padding:0px;width: 71px;">打开APP</el-button>
		<!-- 提示-s -->
		<!-- <tipOpenBrowser :tipShow = "tipShow"></tipOpenBrowser> -->
		<!-- 提示-e-->
	</div>
	<!-- 顶部-e -->
</template>

<script>
	import common from "../common.js";
	export default {
		components: {
		},
		props: {
			pageData: {
				type: Object,
				default: {
					type: "",
					id: "",
					isfixed: false,
				},
				required: true,
				validator: function(value) {
					return value;
				},
			},
			phonedata: {
				type: Object,
				default: {},
				required: true,
				validator: function(value) {
					return value;
				},
			},
			iscarry: {
				default: true,
				type: Boolean,
			},
			copy: {
				default: true,
				type: Boolean,
			},
		},
		data() {
			return {
				tipShow: {
					val: false,
				},
				tipHeader: "tipHeader",
			};
		},
		mounted() {
			let gotoapp = document.getElementsByClassName("bule-btn_small")[0];
			let that = this;
			if (gotoapp) {
				gotoapp.addEventListener("click", () => {
          let timeout = that.phonedata.is_android ? 2000 : 3000
					common.checkOpen(() => {
						if (that.phonedata.is_android) {
							window.top.location.href = common.apkstore;
						} else {
							window.top.location.href = common.appstore;
						}
					}, timeout);
				});
			}
		},
		watch: {
			//haveTask 为接收参数的名称
			pageData: {
				handler(new__, old__) {
					// this.circulation(); //监听到数据变化执行方法
					this.changedata(new__, old__);
				},
				immediate: true,
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		methods: {
			goApp() {
        const timeout = this.phonedata.is_android ? 2000 : 3000
        common.checkOpen(() => {
          if (this.phonedata.is_android) {
            window.location.href = common.apkstore;
          } else {
            window.location.href = common.appstore;
          }
        }, timeout);
				if (this.phonedata.third_party_browser || this.phonedata.is_baiduboxapp) { // 通过第三方app跳转至浏览器打开
					location.href = '/wap.html';
				} else { // 直接在浏览器打开
					common.clickOpen(this.phonedata)
				}
			},
			changedata(new__, old__) {
				if (new__.isfixed) {
					this.tipHeader = "tipHeader";
				} else {
					this.tipHeader = "tipHeader2";
				}
			},
		},
	};
</script>

<style scoped>
	/* 顶部-s */
	.tipHeader {
		width: 100%;
		height: 64px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 20px;
		box-sizing: border-box;
		border-bottom: 1px solid #efefef;
		background-color: #ffffff;
	}
	.icon_logo {
		width: 44px;
		height: 44px;
	}

	.left {
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	.bule-btn_small {
		width: 71px;
		height: 26px;
		line-height: 26px;
		background: #F67942;
		border-radius: 26px;
		font-size: 12px;
		color: #ffffff;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.titleBox {
		margin-left: 8px;
	}

	.name {
		font-weight: 500;
		color: #111734;
		height: 20px;
		line-height: normal;
	}

	.tag {
		font-weight: 400;
		color: #858a9c;
		height: 14px;
		font-size: 12px;
	}

	/* 顶部-e */
</style>
