<template>
    <el-dialog
      title="提示"
      :visible.sync="isShow"
      width="480px"
      :before-close="handleClose"
      custom-class="inputDialog" :append-to-body="true"  :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
              <div class="left">
                  <span>{{}}</span>
              </div>
              <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
          </div>
          <div class="d-content">
            <el-input v-model="value" placeholder="请输入文件名称"></el-input>
          </div>
          <div class="d-footer">
              <fm-button @click="confirm" type="primary" size="small">确 定</fm-button>
              <fm-button @click="handleClose" type="info" plain size="small">取 消</fm-button>
          </div>
      </div>
    </el-dialog>
</template>

<script>
export default {
    name:'title',
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
        type:{
            type: Number,
            default: 1
        },
        rename:{
            type:String,
            default:''
        },
        reextension:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            value:''
        }
    },
    methods:{
        handleClose(){
            this.value=''
            this.$emit('handleClose')
        },
        confirm(){
            this.$emit('modiFolder',this.rename)
        }
    }
}
</script>

<style lang="scss">
    @import "./dialog.scss";
</style>