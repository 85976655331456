<template>
  <el-dialog
    :visible.sync="isShow"
    width="480px"
    custom-class="tipDialog"  :append-to-body="true"  :close-on-click-modal="false">
    <div class="dialog-body">
        <div class="d-header">
            <div class="left">
                <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                <span>提示</span>
            </div>
            <fm-button @click="cancel" type="info" size="mini" text icon="icon-model-close"></fm-button>
        </div>
        <div class="d-content">
            <span>
              退出页面内容不会保存，请确认是否退出页面
            </span>
        </div>
        <div class="d-footer">
          <fm-button @click="confirm" type="primary" size="small" >确认退出</fm-button>
          <fm-button @click="cancel" type="info" plain size="small">取 消</fm-button>
        </div>
    </div>
    </el-dialog>
</template>

<script>
export default {
    name:'base',
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
        text:{
            type:String,
            default:''
        },
        title:{
            type:String,
            default:''
        },
        confirm: Function
    },  
    methods:{
      cancel(){
        this.$emit('cancel')
      },
    }
}
</script>
  
<style lang="scss">
@import "@/components/Dialog/dialog.scss";
</style>