import { render, staticRenderFns } from "./GatherDialog.vue?vue&type=template&id=66b075a4&"
import script from "./GatherDialog.vue?vue&type=script&lang=js&"
export * from "./GatherDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports