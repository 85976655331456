<template>
  <el-dialog
      :visible.sync="isShow"
      width="480px"
      :before-close="handleClose"
      custom-class="PromoteDialog" :append-to-body="true" :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
                <div class="left">
                    <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                    <span>提示</span>
                </div>
              <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
          </div>
          <div class="d-content" style="margin-top: 32px;">
                <img style="margin-bottom: 14px;" :src="$utils.getPng('web4/'+icon)">
                <span style="font-weight: 500;margin-bottom: 6px;">该文件已过期</span>
                <span style="font-size: 14px;opacity: .9;">{{msg}}</span>
          </div>
          <div class="d-footer">
              <fm-button type="primary" round @click="govip" >立即开通</fm-button>
              <fm-button type="info" plain round size="medium" @click="handleClose" >不了，谢谢</fm-button>
          </div>
      </div>
      </el-dialog>
</template>

<script>
export default {
    props:{
        isShow:Boolean,
        msg:String,
        type:Number,
        vip_update:String,
        icon:String
    },
    methods:{
        handleClose(){
            this.$emit('handleClose')
        },
        govip(){
            window.open('/vip')
            this.$emit('handleClose')
            this.$emit('handleClose2',this.type)
        }
    }
}
</script>

<style>

</style>