<template>
  <div class="fmCloud" @mousemove.prevent="selectItems">
    <div class="fixed-top" :style="`width:${$store.state.appRightWidth}px`">
      <TabList
        ref="tabList"
        curId="3"
        @setNav="setNav"
        :placeholder="$t('recycle.recyclePlaceholder')"
        @searchList="searchList"
      ></TabList>
      <div class="fc-second">
        <div class="add-box">
          <div class="btn-add" style="padding: 0;">
            <fm-button
              :disabled="total=='' || total==0"
              @click="total=='' || total==0 ? '' : clearRecycle()"
              icon="icon-recycle"
              round
              size="medium"
              >{{ $t('recycle.clearRecycle') }}</fm-button
            >
          </div>
          <span class="tips">{{ $store.state.language == 'en'? `Recycle Bin contents are saved for ${ recycle_expire_day } days and deleted upon expiration`:`回收站内容保存 ${ recycle_expire_day } 天，到期后自动删除` }}</span>
        </div>
        <div class="multiple-operation" v-show="checkedFiles.length>0">
          <div @click="multipleOpera(1)">
            <svg-btn icon-class="icon-tool-reduction" />
            <span>{{ $t('recycle.restore') }}</span>
          </div>
          <div @click="multipleOpera(2)">
            <svg-btn icon-class="icon-recycle" />
            <span>{{ $t('recycle.sweep') }}</span>
          </div>
        </div>
      </div>
      <div class="file-type">
        <div class="search-result" v-if="params.keywords == ''">
              <el-tooltip
                class="item r-tooltip"
                effect="dark"
                :content="$t('cloud.allFiles')"
                placement="top"
              >
                <span class="all" style="font-weight: 500" @click="resetRouter"
                  >{{ $t('cloud.allFiles') }}</span
                >
              </el-tooltip>
              <div class="total">
                {{ total }}
              </div>
            </div>
        <div class="search-result" v-if="params.keywords !== ''">
          <el-tooltip
            class="item r-tooltip"
            effect="dark"
            :content="$t('cloud.allFiles')"
            placement="top"
          >
            <span class="all" @click="resetRouter">{{ $t('cloud.allFiles') }}</span>
          </el-tooltip>
          <div class="r-search">
            <svg-btn icon-class="icon-file-direction" />
             <div class="r-search-tit">
              {{$utils.searchTitSlice(params.keywords)}}
            </div>
          </div>
        </div>

        <div class="fileClass">
          <span
            @click="chageClass(item.id)"
            v-for="item in fileType"
            :key="item.id"
            :class="['label', { curStyle: item.id == fileTypeId }]"
            >{{ $t(item.name) }}</span
          >
          <div class="screenBtn">
            <fm-button
              icon="icon-cloud-screen"
              type="info"
              text
              size="small"
            ></fm-button>
            <div class="screenBox">
              <div
                @click="changeSort(item.id)"
                v-for="item in screenList"
                :key="item.id"
                :class="{ curScreen: item.id == screenId || item.id1 == screenId}"
              >
                <svg-btn icon-class="icon-cloud-check" />
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <span class="line"></span>
          <fm-button class="icon-refresh" @click="refresh"
                icon="icon-refresh"
                type="info"
                text
                size="small"
              ></fm-button>
        </div>
      </div>
    </div>
    <div class="file-content" v-loading="loading"
      v-infinite-scroll="getList"
      ref="scrollBox"
      infinite-scroll-distance="50"
      infinite-scroll-immediate="false"
      @scroll="scrollBoxFun()"
      @mousedown="handleMouseDown($event, -1)"
      @mouseup.prevent="endSelection">
        <div class="tab-th" v-show="total!=0">
          <div class="selectAll">
            <div @click="allCheck == 2 ? cancelCheckedVal() : setCheckedVal()">
              <svg-btn
                :icon-class="
                  allCheck == 2
                    ? 'icon-file-check'
                    : allCheck == 0
                    ? 'icon-file-uncheck-'+$store.state.theme
                    : 'icon-file-checks'
                "
              >
              </svg-btn>
            </div>
            <span>{{ $t('cloud.fileName') }}</span>
          </div>
          <div class="lineup2">
            <span>{{ $t('cloud.size') }}</span>
            <!-- <img v-show="screenId == 4 || screenId == 5"
            :src="screenId == 4 ? $utils.getPng('web4/icon_order_descending') : $utils.getPng('web4/icon_order_ascending')"> -->
          </div>
          <div class="lineup3">
            <span>{{ $t('recycle.effectiveTime') }}</span>
            <!-- <img v-show="screenId == 2 || screenId == 3"
            :src="screenId == 2 ? $utils.getPng('web4/icon_order_descending') : $utils.getPng('web4/icon_order_ascending')"> -->
          </div>
          <div class="lineup3" @click="changeSort(screenId == 0 ? 1 : 0)">
            <span>{{ $t('recycle.deleteTime') }}</span>
            <img v-show="screenId == 0 || screenId == 1"
            :src="screenId == 0 ? $utils.getPng('web4/icon_order_descending') : $utils.getPng('web4/icon_order_ascending')">
          </div>
        </div>
        <div class="file-list" v-show="filesList.length > 0">
          <div class="file-item-hover" v-for="(item, index) in filesList" :key="index" 
            :class="[{'hoverItem': item.isHover || checkedFiles.indexOf(index) > -1}]"
            @mousedown="handleMouseDown($event, index)"
            @mouseenter="handleMouseenter($event, index)">
            <a-dropdown :trigger="dropdown" @visibleChange="visibleChange">
              <div class="file-item">
                <div class="lineup1">
                    <svg-btn
                      class="icon_checks"
                      @click.prevent="chooseFile(index)"
                      :icon-class="
                        checkedFiles.indexOf(index) > -1
                          ? 'icon-file-check'
                          : 'icon-file-uncheck-'+$store.state.theme
                      "
                    ></svg-btn>
                    
                <div class="openFoldername">
                  <div class="file_icon">
                    <img class="icon" @error="onError($event, item)"
                        :src="item.is_file == '0' ? item.ext_icon : item.showPlay ? item.ext_icon : $utils.fileIcon(item.extension)"/>
                    <div class="pay" v-show="item.ext=='mp4' && item.showPlay && item.is_locked=='0'" >
                      <img class="iconpay" :src="$utils.getPng(`web4/icon_pay`)"/>
                    </div>
                  </div>
                  <el-tooltip open-delay="1000" popper-class="item list-tooltip" effect="dark" :content="item.file_folder_name" placement="bottom">
                    <span class="name">{{ item.file_folder_name }}</span>
                  </el-tooltip>
                </div>
                </div>
                <div class="tools-box-recycle">
                  <div class="itemFilesTools" v-show="checkedFiles.length < 2">
                    <div class="act" @click="restoreFile({ recycle_ids: item.id })">
                      <el-tooltip open-delay="1000" popper-class="item list-tooltip" effect="dark" content="还原" placement="bottom">
                        <svg-btn icon-class="icon-tool-reduction" ></svg-btn>
                      </el-tooltip>
                    </div>
                    <div class="act">
                      <el-dropdown @visible-change="changeVisible($event, item)" placement="bottom-start"
                          @command="(command) => handleCommand(command, item)">
                        <div>
                          <svg-btn icon-class="icon-cloud-more"></svg-btn>
                        </div>
                        <el-dropdown-menu
                          class="moreAct"
                          slot="dropdown"
                          :class="isOpen ? '' : 'isOpen1'"
                        >
                          <el-dropdown-item command="a"
                            ><span>{{ $t('recycle.sweep') }}</span></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                </div>
                <div class="lineup2">
                  {{item.is_file == '1' ? item.size : item.fileCount}}
                </div>
                <div class="lineup3">
                  {{ item.expire_time_text }}
                </div>
                <div class="lineup3">
                  {{ item.intime }}
                </div>
              </div>
              <template #overlay>
                  <a-menu class="menu-a" :class="isOpen2 ? 'menu-b':''" v-if="isOpen" >
                    <a-menu-item @click="rightHandle(menu_item.id,item)"
                    v-for="menu_item in a_menu" 
                    :key="menu_item.id" :class="menu_item.id ==-1 ? 'line' : 'me-item'">{{$t(menu_item.name)}}
                    </a-menu-item>
                  </a-menu>
              </template>
            </a-dropdown>
          </div>
          <div
            v-show="showFlag == 1"
            class="selection-box"
            :style="{
              left: selectionBox.left + 'px',
              top: selectionBox.top + 'px',
              width: selectionBox.width + 'px',
              height: selectionBox.height + 'px',
            }"
          ></div>
          <div class="isOver" v-if="loadNextPage" v-loading="loadNextPage"></div>
          <div class="isOver" v-if="isOver">{{ $t('cloud.noMore') }}</div>
        </div>
        <div class="file-default"  v-show="!loading &&filesList.length ==0">
          <img :src="$utils.getPng('web4/file_default')" />
          <div class="basic" v-if="params.keywords==''">{{ $t('recycle.noFiles') }}</div>
          <div class="search" v-else>
            <span>{{ $t('cloud.notFile') }}</span> <span>{{ $t('cloud.trySearchOther') }}</span>
          </div>
        </div>
    </div>

    <div class="toTop-div" v-show="showTop" @click="scrollToTop">
      <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
    </div>
      <!-- 确认删除 -->
      <CompleteDelTips
        :isShow="showDelTips"
        :delTipsType="delTipsType"
        @handleClose="showDelTips = false"
        @confirmDel="confirmDel"
      ></CompleteDelTips>
  </div>
</template>

<script>
import {
  recycleList,
  restoreFile,
  completeDel,
  clearRecycleBin,
} from "@/utils/apiList/cloud";
import CompleteDelTips from "@/components/Cloud/CompleteDelTips.vue";
import TabList from "../TabList/index.vue";
export default {
  components: {
    TabList,
    CompleteDelTips,
  },
  data() {
    return {
      curId: 0,
      keyword: "",
      fileType: [
        { id: 0, name: "cloud.all" },
        { id: 1, name: "cloud.package" },
        { id: 2, name: "cloud.medium" },
        { id: 3, name: "cloud.document" },
        { id: 4, name: "cloud.picture" },
      ],
      fileTypeId: 0,
      screenList: [
        // { id: 4, id1: 5, name: "按大小排序" },
        // { id: 2, id1: 3, name: "按有效时间排序" },
        { id: 0, id1: 1, name: "按删除时间排序" },
      ],
      screenId: 0,
      filesList: [],
      checkedFiles: [],
      allCheck: 0,
      params: {
        page: 0,
        limit: 60,
        sort: 1,
        keywords: "",
        type: 0,
        size:'',
        time:''
      },
      total: "",
      recycle_expire_day: 0, //加入回收站过期时间
      handleItem: {},
      showDelTips: false,
      delTipsType: 1, //1删除 2清空
      replacingData: false,
      isOver: false,
      showTop: false,
      loading: true,
      loadNextPage:false,
      a_menu:[
        {id:0,name:'recycle.restore'},
        {id:-1,name:''},
        {id:1,name:'recycle.sweep'},
      ],
      isOpen: false,
      isOpen2: true,
      isSelecting: 0,
      startX: 0,
      startY: 0,
      selectionBox: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      moveBox: {
        left: 0,
        top: 0,
      },
      showFlag: 0,
      order:false,
      dropdown: ['contextmenu']
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$store.commit("setAttr", {
      name: "endSelectFuncallback",
      val: this.endSelectFun,
    });

  },
  watch: {
    checkedFiles: {
      handler(newval, oldVal) {
        if (newval.length > 0 && newval.length < this.filesList.length) {
          this.allCheck = 1;
        } else if (newval.length == 0) {
          this.allCheck = 0;
        } else if (newval.length == this.filesList.length) {
          this.allCheck = 2;
        }
      },
      immediate: true,
    },
    pageValue(newVal) {
      if (newVal == 1) {
        this.cancelCheckedVal();
        this.scrollToTop();
      }
    },
  },
  computed: {
    pageValue() {
      return this.params.page; 
    },
  },
  methods: {
    setNav(item) {
      this.$router.push({
        path: item.url,
      });
    },
    chageClass(id) {
      this.fileTypeId = id;
      this.params.type = id;
      this.params.page = 0;
      this.checkedFiles=[]
      this.getList(1);
    },
    //切换排序
    changeSort(id){
      this.screenId = id;
      switch (id) {
        case 0:
          this.params.time = '';
          this.params.size = '';
          this.params.sort = 1
          break;
        case 1:
          this.params.time = '';
          this.params.size = '';
          this.params.sort = 0
          break;
        case 2:
          this.params.time = 1;
          this.params.size = '';
          this.params.sort = ''
          break;
        case 3:
          this.params.time = 0;
          this.params.size = '';
          this.params.sort = ''
          break;
        case 4:
          this.params.time = '';
          this.params.sort = '';
          this.params.size = 1
        break;
        case 5:
          this.params.time = '';
          this.params.sort = '';
          this.params.size = 0
        break;
        default:
        break;
      }
      this.params.page = 0;
      this.checkedFiles = [];
      this.getList(1);
    },
    refresh(){
      this.getList(1);
    },
    //搜索
    searchList(value) {
      this.params.keywords = value;
      this.checkedFiles = [];
      this.getList(1);
    },
    //列表
    getList: _.debounce(
      function (type = 0) {
        if (type == 1) {
          this.loading = true;
          this.params.page = 0;
        } else {
          if (this.isOver) {
            return;
          }
          this.loadNextPage=true
        }
        this.params.page += 1;
        this.isOver = false;
        recycleList(this.params)
          .then((res) => {
            if (res.status == "1") {
              this.total = res.data.total;
              this.loading = false;
              this.loadNextPage=false;
              this.recycle_expire_day = res.data.recycle_expire_day;
              if (res.data.list.length < this.params.limit) this.isOver = true;
              res.data.list.forEach((item, index) => {
                item.isHover = false;
                item.itemloading = false;
                item.showPlay = true
              });
              if (this.params.page == 1) {
                this.filesList = res.data.list;
              } else {
                this.filesList = this.filesList.concat(res.data.list);
              }
              if (this.checkedFiles.length > 0 && this.checkedFiles.length < this.filesList.length) {
                this.allCheck = 1;
              } else if (this.checkedFiles.length == 0) {
                this.allCheck = 0;
              } else if (this.checkedFiles.length == this.filesList.length) {
                this.allCheck = 2;
              }
               this.dropdown = ['contextmenu']
            } else {
              this.loading=false;
              this.loadNextPage=false;
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {});
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    // 单个点击选中
    chooseFile(index) {
      // 点击文件选中
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles.push(index);
      } else {
        this.checkedFiles.splice(exist, 1);
      }
    },
    // 全选
    setCheckedVal() {
      // 点击文件选中
      this.checkedFiles = [];
      var tmpfiles = this.filesList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        this.checkedFiles.push(i);
      }
      this.allCheck = 1;
    },
    // 取消全选
    cancelCheckedVal() {
      this.allCheck = 0;
      this.checkedFiles = [];
    },
    // 获取选中的文件的id
    getSelFileIds() {
      var ids = [];
      this.checkedFiles.map((item) => {
        ids.push(this.filesList[item].id);
      });
      return this.$utils.arrFilter(ids);
    },
    //选中操作 顶部按钮
    multipleOpera(type) {
      if (type == 1) {
        this.restoreFile({ recycle_ids: this.getSelFileIds().toString() });
      } else {
        this.delTipsType = 1;
        this.showDelTips = true;
      }
    },
    //右键
    rightHandle(id,item){
      this.handleItem = item
      if(id==0){//0还原
        this.restoreFile({ recycle_ids: this.checkedFiles.length>0 ? this.getSelFileIds().toString() : item.id });
      }else{
        this.delTipsType = 1;
        this.showDelTips = true;
      }
    },
    //更多 操作 a删除
    handleCommand(command, item) {
      this.handleItem = item;
      if (command == "a") {
        this.delTipsType = 1;
        this.showDelTips = true;
      }
    },
    //下拉框出现/隐藏时触发
    changeVisible(e, item) {
      item.isHover = e;
    },
    //还原
    restoreFile(params) {
      restoreFile(params).then((res) => {
        if (res.status == "1") {
          this.checkedFiles = [];
          this.getList(1);
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //确认删除
    confirmDel() {
      if (this.delTipsType == 1) {
        this.delFile({
          recycle_ids: this.checkedFiles.length > 0 
            ?  this.getSelFileIds().toString()
            : this.handleItem.id,
        });
      } else {
        //清空回收站
        this.clearRecycleBin();
      }
    },
    // 删除
    delFile(params) {
      completeDel(params).then((res) => {
        if (res.status == "1") {
          this.showDelTips = false;
          this.checkedFiles = [];
          this.getList(1);
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //确认清空
    clearRecycle() {
      this.delTipsType = 2;
      this.showDelTips = true;
    },
    //清空回收站
    clearRecycleBin() {
      clearRecycleBin().then((res) => {
        if (res.status == "1") {
          this.$toast(res.msg, 1);
          this.checkedFiles = [];
          this.showDelTips = false;
          this.getList(1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    scrollBoxFun() {
      var scrollEl = this.$refs.scrollBox;
      this.showTop = scrollEl.scrollTop > 50;
      let list = document.getElementsByClassName("el-tooltip__popper");
      let list1 = document.getElementsByClassName("el-dropdown-menu");
      if (list.length > 0) {
        list[list.length - 1].style.display = "none";
      }
      if(list1.length > 0){
        list1[list1.length - 1].style.display = "none";
      }
    },
    scrollToTop() {
      let scrollEl = this.$refs.scrollBox;
      scrollEl.scrollTop = 0;
    },
    visibleChange(e) {
      if(e){
        this.dropdown = ['contextmenu','click']
        this.isOpen = e;
        this.isOpen2 = true
      } else {
        this.isOpen2 = false
        this.dropdown = ['contextmenu']
      }
    },
    handleMouseDown: _.debounce(
      function (event, index) {
        if(index == -1){
          this.checkedFiles = []
        }
        this.selectionBox = {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        };
        this.startX = event.clientX;
        this.startY = event.clientY;
        const currentX = event.clientX;
        const currentY = event.clientY;
        this.moveBox = {
          left: 0,
          top: 0,
        };
        // 检查触发事件的元素是否是 file-item-hover 的父元素
        if (event.button == 0) {
          let arr = ['icon_checks','iconColor']
          if (!arr.includes(event.target.classList[0])) {
            //点击icon_checks 即左侧选中多选不触发handleMouseDown 只触发chooseFile
            // 执行你的 mousedown 逻辑
            this.selectItemOne(index);
            this.moveBox = {
              left: currentX,
              top: currentY - 56,
            };
          }
        } else if (event.button == 1) {
        } else if (event.button == 2) {
          if(index == -1) return;
          var exist = this.checkedFiles.indexOf(index);
          if (exist == -1) {
            this.checkedFiles = [];
            this.checkedFiles.push(index);
          } else {
          }
          if(this.checkedFiles.length >1 ){
            this.isSelectMore = true;
          }else{
            this.isSelectMore = false;
          }
        }
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    handleMouseenter(event, index) {
      this.trage_folder_id = -1;
      if (this.isSelecting != 2) return;
      let arr = this.filesList.filter(
        (item, index1) => index1 === index && item.is_file == 0
      );
      this.trage_folder_id = arr[0]?.id;
    },
    selectItemOne(index) {
      // 点击文件选中
      if (index == -1) {
        this.isSelecting = 1;
        return;
      }
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles = [];
        this.checkedFiles.push(index);
        this.isSelecting = 1;
      } else {
        this.isSelecting = 2;
      }
    },
    selectItems: _.debounce(
      function (event) {
        const currentX = event.clientX;
        const currentY = event.clientY;
        if (this.isSelecting == 2) { 
        } else if (this.isSelecting == 1) {
          this.showFlag = 1;
          this.$store.commit("setAttr", {
            name: "showFlag",
            val: 1,
          });
          // 计算选择框的位置和大小
          const minX = Math.min(this.startX, currentX);
          const minY = Math.min(this.startY, currentY);
          const maxX = Math.max(this.startX, currentX);
          const maxY = Math.max(this.startY, currentY);
          this.selectionBox = {
            left: minX,
            top: minY - 56,
            width: maxX - minX,
            height: maxY - minY,
          };
          // 遍历可选择项，判断是否在选择框内并选中
          this.filesList.forEach((item, index) => {
            const itemElement = document.querySelector(
              `.file-item-hover:nth-child(${this.filesList.indexOf(item) + 1})`
            );
            const itemRect = itemElement.getBoundingClientRect();
            if (itemRect.top > minY - 56 && itemRect.bottom < maxY + 69) {
              if (!this.checkedFiles.includes(index)) {
                this.checkedFiles.push(index);
              }
            } else {
              const index = this.checkedFiles.indexOf(index);
              if (index !== -1) {
                this.checkedFiles.splice(index, 1);
              }
            }
          });
        }
      },
      0,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    endSelection() {
      this.isSelecting = 0;
      this.showFlag = 0;
      this.$store.commit("setAttr", {
        name: "showFlag",
        val: 0,
      });
    },
    endSelectFun() {
      this.isSelecting = 0;
      this.showFlag = 0;
      this.$store.commit("setAttr", {
        name: "showFlag",
        val: 0,
      });
    },
    resetRouter(){
      this.params = {
        page: 0,
        limit: 60,
        sort: 1,
        keywords: "",
        type: 0,
        size:'',
        time:''
      };
      this.checkedFiles=[]
      this.fileTypeId=0
      this.$refs.tabList.keyword = "";
      this.getList(1);
    },
    onError(e,item){
      this.$set(item, 'showPlay', false)
    }
  },
};
</script>

<style lang="scss"></style>
