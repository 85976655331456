<template>
  <el-dialog
      :close-on-click-modal="false"
      :append-to-body="true"
      :visible.sync="isShow"
      width="480px"
      custom-class="twoTableDialog"
      title=""
      top="0vh"
  >
    <div class="content">
      <div class="title">
        <div class="tab pointer" v-for="(item, index) in tab" :key="index" @click="changeTab(index)">
          <div class="name" :class="tabAct == index ? 'tabAct' : ''">
            {{ item }}
          </div>
          <div class="tabLine" v-show="tabAct == index"></div>
        </div>
        <fm-button @click="handleClose()" type="info" size="mini" text icon="icon-model-close"></fm-button>
      </div>
      <div class="line"></div>
      <div
          ref="list"
          v-infinite-scroll="listScroll"
          :infinite-scroll-disabled="disabled"
          v-show="tabAct == 0"
          class="list"
          infinite-scroll-distance="50"
      >
        <div class="itemOne" v-for="item in oneList" :key="item.uid">
          <img class="avater" :src="item.logo" alt="" />
          <div class="center">
            <div class="top">
              <div class="name">{{ item.nickname }}</div>
              <img class="iconaa" :src="$utils.getPng('icocaa')" alt="" />
            </div>
            <div class="bottom" v-if="tabAct == 0">
              <div v-if="item.type == 1">
                奖励<span class="number">{{ item.value }}</span>福利点
              </div>
              <div v-if="item.type == 2">
                奖励<span class="number">{{ item.value }}</span>天体验会员
              </div>
            </div>
          </div>
          <div class="time">{{ item.in_time }}</div>
        </div>
        <div class="lists" v-show="oneList.length <= 0">
          <div class="Empty">
            <img class="emptyIcon" :src="$utils.getPng($store.state.theme == 'dark' ? 'queshen_dark':'queshen')" />
            <div class="emptyTit">暂无明细</div>
          </div>
        </div>
      </div>
      <div
          ref="list"
          v-show="tabAct == 1"
          v-infinite-scroll="listScroll"
          v-loading="loading2"
          :infinite-scroll-disabled="disabled2"
          infinite-scroll-distance="50"
          class="list"
       >
        <div class="itemOne" v-for="item in twoList" :key="item.uid">
          <img class="avater" :src="item.avatar" alt="" />
          <div class="center">
            <div class="top" style="margin-bottom: 8px">
              <div class="name">{{ item.nickname }}</div>
            </div>
            <div class="bottom" v-if="tabAct == 1">{{ item.text }}</div>
          </div>
          <div class="time">{{ item.in_time }}</div>
        </div>
        <div class="lists" v-show="twoList.length <= 0">
          <div class="Empty">
            <img class="emptyIcon" :src="$utils.getPng($store.state.theme == 'dark' ? 'queshen_dark':'queshen')" />
            <div class="emptyTit">暂无明细</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getInviterList, getInviteRebateList } from '@/utils/apiList/invite'
export default {
  name: "welfareActiviteTwoTable",
  data() {
    return {
      loading: false,
      tab: ["奖励明细", "下线返利"],
      tabAct: 0,
      loading2: false,
      twoList: [],
      oneList: [],
      total: 0,
      totalMoney: 0,
      pg1: 1,
      pg2: 1,
      disabled: false,
      disabled2: false
    };
  },
  props: {
    isShow: {
      default: true,
      type: Boolean,
    },
  },
  created() {
    this.getInviterList();
    this.getInviteRebateList();
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    changeTab(index) {
      this.tabAct = index;
      if (this.tabAct == 0) {
        this.pg1 = 1
        this.getInviterList()
      } else {
        this.pg2 = 1
        this.getInviteRebateList()
      }
      this.$refs.list.scrollTop = 0;
    },
    async getInviterList() {
      if (this.loading) return false
      this.loading = true;
      try {
        if (this.pg1 === 1) {
          this.disabled = false
          this.oneList = []
        }
        let res = await getInviterList({
          pg: this.pg1,
          pn: 10,
        })
        if (res.status == '1') {
          this.oneList = this.oneList.concat(res.data.list);
          this.disabled = this.oneList.length >= res.data.total
          this.total = res.data.total || 0
        } else {
          this.oneList = [];
        }
      } catch (e) {}
      this.loading = false;
    },
    async getInviteRebateList() {
      if (this.loading2) return false
      this.loading2 = true;
      try {
        if (this.pg2 === 1) {
          this.disabled2 = false
          this.twoList = []
        }
        let res = await getInviteRebateList({
          pg: this.pg2,
          pn: 10,
        })
        if (res.status == '1') {
          this.twoList = this.twoList.concat(res.data.list);
          this.disabled2 = this.twoList.length >= res.data.count
          this.totalMoney = res.data.money || 0
        } else {
          this.twoList = [];
        }

        this.$emit('onGetList', {
          type: 'rebate',
          total: res.data.count,
          totalMoney: this.totalMoney,
          list: this.twoList
        })
      } catch (e) {}
      this.loading2 = false;
    },
    //滚动加载滑动到底部
    listScroll() {
      if (this.tabAct == 0) {
        this.pg1 += 1
        this.getInviterList()
      } else {
        this.pg2 += 1
        this.getInviteRebateList()
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.twoTableDialog .el-dialog__body) {
  padding-bottom: 0;
}
.twoTableDialog{
  width: 480px;
  height: 580px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 0;
  overflow: hidden;
  .fm-button{
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    :nth-child(2) {
      margin-left: 88px;
    }
    .tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .name {
        @include useTheme {
          color: getVar("text-color2-2c2c2c");
        };
        height: 18px;
        line-height: 18px;
      }
      .tabLine {
        width: 32px;
        height: 4px;
        background: #f67942;
        margin-left: 0;
        margin-top: 12px;
      }
      .tabAct.name {
        color: #f67942 !important;
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    @include useTheme {
      box-shadow: getVar("shadow-e5e5e5");
    }
  }
  .list {
    height: 492px;
    width: 100%;
    margin-top: 33px;
    overflow: scroll;
    border-radius: 16px;
    .itemOne {
      display: flex;
      margin-left: 24px;
      margin-right: 14px;
      height: 56px;
      @include useTheme {
        box-shadow: getVar("shadow-e5e5e5");
      }
      margin-bottom: 20px;

      .avater {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 16px;
      }
      .center {
        display: flex;
        flex-direction: column;
        .top {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          .name {
            height: 12px;
            font-size: 12px;
            font-weight: 500;
            @include useTheme {
              color: getVar("text-color2-2c2c2c");
            };
            line-height: 12px;
            margin-right: 6px;
          }
          .iconaa {
            width: 70px;
            height: 18px;
          }
        }
        .bottom {
          height: 12px;
          font-size: 12px;
          font-weight: 400;
          @include useTheme {
            color: getVar("text-color2-2c2c2c");
          };
          .number {
            color: #f67942;
          }
        }
      }
      .time {
        margin-left: auto;
        margin-right: 0;
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        @include useTheme {
          color: getVar("text-color1-8a8b92");
        };
        line-height: 12px;
      }
    }
  }
  .lists {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: -20px;

    .Empty {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .emptyIcon {
        width: 88px;
        height: 88px;
      }
      .emptyTit {
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        @include useTheme {
          color: getVar("text-color1-8a8b92");
        };
        line-height: 14px;
        margin-top: 12px;
      }
    }
  }
}

</style>
