var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.isShow,"width":"480px","custom-class":"shareDialog","append-to-body":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.isShow=$event}}},[_c('div',{staticClass:"dialog-body"},[_c('div',{staticClass:"d-header"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v(_vm._s(_vm.checkedFiles.length > 1 ? '分享多个文件':'分享文件'))])]),_c('fm-button',{attrs:{"type":"info","size":"mini","text":"","icon":"icon-model-close"},on:{"click":_vm.handleClose}})],1),_c('div',{staticClass:"d-content"},[_c('div',{staticClass:"warn-box"},[_c('svg-btn',{attrs:{"icon-class":"icon-tips-circle-fill"}}),_c('span',[_vm._v("严禁上传或分享反动、暴力、色情、违法及侵权内容的文件。")])],1),(_vm.checkedFiles.length <=1)?_c('div',{staticClass:"file-info"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.handleItem.ext_icon}}),_c('div',{staticClass:"info"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.handleItem.name)+_vm._s(_vm.handleItem.extension))]),_c('div',[_c('span',{staticClass:"size"},[_vm._v("文件大小："+_vm._s(_vm.handleItem.size))]),_c('span',[_vm._v("分享链接长期有效")])])])]):_vm._e(),_c('div',{staticClass:"shareUrl-box income-box",staticStyle:{"margin-top":"16px"}},[_c('span',{staticClass:"title"},[_vm._v("收益模式")]),(_vm.isShow)?_c('div',{staticClass:"income-conent"},[_c('el-select',{attrs:{"popper-class":"share_select","placeholder":""},on:{"change":_vm.incomeModeChange},model:{value:(_vm.incomeValue),callback:function ($$v) {_vm.incomeValue=$$v},expression:"incomeValue"}},_vm._l((_vm.incomeList),function(item){return _c('el-option',{key:item.key,style:(item.disabled == '2'
                  ? 'cursor: pointer'
                  : item.disabled == '1'
                  ? 'cursor: pointer '
                  : ''),attrs:{"label":item.value,"value":item,"disabled":item.disabled == 1 || item.disabled == 2}},[_c('div',{staticClass:"share_select_box",on:{"click":function($event){$event.preventDefault();return _vm.toDaren(item)}}},[_c('div',{staticClass:"income-name",class:item.disabled == '0' ? '' : 'income-name1'},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('div',{staticClass:"income-right"},[(item.info)?_c('div',{staticClass:"income-intr",class:item.disabled == '1'
                        ? 'income-daren0'
                        : item.disabled == '0' || item.disabled == '2'
                        ? 'income-daren'
                        : ''},[(item.disabled == '1')?_c('img',{staticStyle:{"width":"14px","height":"14px","margin-right":"2px"},attrs:{"src":_vm.$utils.getPng('web4/icon_mom')}}):_vm._e(),_vm._v(" "+_vm._s(item.info)+" ")]):_vm._e(),_c('div',{staticClass:"income-checkbox"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.key == _vm.incomeValue?.key),expression:"item.key == incomeValue?.key"}],staticClass:"icon-scheck",attrs:{"src":_vm.$utils.getPng('web4/icon-share-check')}})])])])])}),1)],1):_vm._e()]),(_vm.boxType == '1')?_c('div',{staticClass:"pucode-box"},[_c('span',{staticClass:"title",staticStyle:{"margin-bottom":"8px"}},[_vm._v("分享形式")]),_c('div',{staticClass:"share-type"},[_c('el-select',{attrs:{"placeholder":"请选择","popper-class":"share_select"},on:{"change":_vm.shareTypeChange},model:{value:(_vm.open_pucode),callback:function ($$v) {_vm.open_pucode=$$v},expression:"open_pucode"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}},[_c('div',{staticClass:"s_option"},[_c('span',[_vm._v(_vm._s(item.label))]),_c('svg-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.open_pucode == item.value),expression:"open_pucode == item.value"}],attrs:{"icon-class":"icon-cloud-check"}})],1)])}),1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open_pucode == 1),expression:"open_pucode == 1"}]},[_c('span',{staticClass:"title",staticStyle:{"margin-top":"24px"}},[_vm._v("取件码")]),_c('div',{staticClass:"line2"},[_c('img',{staticClass:"svg-icon",attrs:{"src":_vm.$utils.getPng(
                  _vm.isSysCode == 1
                    ? 'web4/icon_radio1_' + _vm.$store.state.theme
                    : 'web4/icon_radio0_' + _vm.$store.state.theme
                )},on:{"click":function($event){return _vm.changeCodeType(1)}}}),_c('span',[_vm._v("系统随机生成取件码")])]),_c('div',{staticClass:"line3"},[_c('img',{staticClass:"svg-icon",attrs:{"src":_vm.$utils.getPng(
                  _vm.isSysCode == 1
                    ? 'web4/icon_radio0_' + _vm.$store.state.theme
                    : 'web4/icon_radio1_' + _vm.$store.state.theme
                )},on:{"click":function($event){return _vm.changeCodeType(2)}}}),_c('el-input',{attrs:{"maxlength":"4","disabled":_vm.isSysCode == 1,"placeholder":"请输入4位取件码"},model:{value:(_vm.pucode),callback:function ($$v) {_vm.pucode=$$v},expression:"pucode"}})],1),_c('span',{staticClass:"tips"},[_vm._v("仅支持数字及英文字母")]),_c('div',{staticClass:"line4"},[_c('div',{on:{"click":function($event){return _vm.chageAutoSet()}}},[_c('svg-btn',{attrs:{"icon-class":_vm.auto_set
                    ? 'icon-file-check'
                    : 'icon-file-uncheck-' + _vm.$store.state.theme}})],1),_c('span',[_vm._v("分享链接自动填充取件码")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"开启后用户点击链接可自动填充取件码","placement":"top"}},[_c('svg-btn',{staticClass:"icon-share-1",attrs:{"icon-class":"icon-share-1"}})],1)],1)])]):_vm._e(),(_vm.boxType == '2')?_c('div',{staticClass:"shareUrl-box"},[_c('span',{staticClass:"title"},[_vm._v("分享链接")]),_c('div',{staticClass:"url-content"},[_c('el-input',{attrs:{"readonly":"","placeholder":"","id":"shareUrl"},model:{value:(_vm.shareUrl),callback:function ($$v) {_vm.shareUrl=$$v},expression:"shareUrl"}}),_c('fm-button',{staticClass:"copy-btn",attrs:{"round":"","size":"medium"},on:{"click":function($event){return _vm.copyUrl()}}},[_vm._v(_vm._s(_vm.handleItem.good_count == 1 ? "复制链接及取件码" : "复制链接"))])],1)]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.boxType == '1'),expression:"boxType == '1'"}],staticClass:"d-footer"},[_c('fm-button',{attrs:{"disabled":_vm.btnDisabled,"type":"primary","round":""},on:{"click":function($event){_vm.btnDisabled ? '' : _vm.addShareUrl()}}},[_vm._v(" "+_vm._s(_vm.checkedFiles.length > 1 ? '创建多个链接':'创建链接')+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }