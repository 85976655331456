<template>
  <div v-if="list && list.length > 0" class="loop-box">
      <svg-btn icon-class="notice-icon" class-name="notice-box" />
      <el-carousel
              ref="theCarousel"
              :interval="5000"
              autoplay
              height="20px"
              direction="vertical"
              indicator-position="none"
              arrow="never"
              @change="onChange"
      >
          <el-carousel-item v-for="(item, index) in list" :key="item.id">
              <el-tooltip
                  v-model="item.disabled"
                  :key="item.id"
                  :visible-arrow="false"
                  :enterable="false"
                  manual
                  effect="dark"
                  placement="bottom"
                  popper-class="app-pop-tip"
                  open-delay="1000"
              >
                  <div slot="content">{{ item.content }}</div>
                  <div
                      :class="item.url ? 'pointer' : 'cursor-default'"
                      :key="item.id"
                      class="loop-item"
                      @click.prevent.stop="handleClick(item, index)"
                      @mouseenter="() => onMEnter(item)"
                      @mouseleave="() => onMLeave(item)"
                  >{{ item.content }}</div>
              </el-tooltip>
          </el-carousel-item>
      </el-carousel>
  </div>
</template>

<script>
import { bannerTextList } from "@/utils/apiList/cloud";

export default {
    name: "loopText",
    data() {
        return {
            list: [],
            active: 0,
            timer: null,
            moveConfig: {
                total: 0,
                count: 0,
            },
            vTimer: null
        }
    },
    mounted() {
        this.getList()

        document.addEventListener('visibilitychange', this.pageVisible)
    },
    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.pageVisible)
    },
    methods: {
        onMEnter(item) {
            this.vTimer = setTimeout(() => {
                item.disabled = true
                this.vTimer = null
            }, 1000)
        },
        onMLeave(item) {
            if (this.vTimer) {
                clearTimeout(this.vTimer)
                this.vTimer = null
            }
            item.disabled = false
        },
        pageVisible() {
            let pageVisibility = document.visibilityState;
            // 页面变为不可见时触发
            if (pageVisibility === 'visible') {
                if (this.vTimer) {
                    clearTimeout(this.vTimer)
                    this.vTimer = null
                }
                for (let i = 0; i < this.list.length; i++) {
                    if (this.list[i].disabled) {
                        this.list[i].disabled = false
                    }
                }
            } else if (pageVisibility === 'hidden') {
                if (this.vTimer) {
                    clearTimeout(this.vTimer)
                    this.vTimer = null
                }
                for (let i = 0; i < this.list.length; i++) {
                    if (this.list[i].disabled) {
                        this.list[i].disabled = false
                    }
                }
            }
        },
        async getList() {
            try {
                let res = await bannerTextList()
                if (Number(res.status) === 1) {
                    let _list = res.data.list || []
                    if (_list.length > 0) {
                        this.list = _list.map(v => {
                            return {
                                ...v,
                                disabled: false
                            }
                        })
                    } else {
                        this.list = []
                    }
                    this.active = 0
                }
            } catch (e) {}
        },
        handleClick(item) {
            if (item.url) {
                item.disabled = true
                window.open(item.url)
            }
        },
        initPlay() {
            this.clearTimer()
            this.timer = setInterval(() => {
                if (this.$refs.theCarousel) {
                    this.$refs.theCarousel.next()
                } else {
                    this.clearTimer()
                }
            }, 3000)
        },
        clearTimer() {
            if (this.timer) {
                clearInterval(this.timer)
                this.timer = null
            }
        },
        checkContent() {
            this.list[this.active].left = 0
            let state = false
            let item = this.$refs.theCarousel.$el.querySelector('.el-carousel__item.is-active .loop-item')
            if (item) {
                if (item.clientWidth >= item.parentElement.clientWidth) {
                    state = true
                    this.clearTimer()

                    this.moveConfig.count = item.clientWidth - item.parentElement.clientWidth
                    this.moveConfig.total = item.clientWidth - item.parentElement.clientWidth

                    setTimeout(() => {
                        this.moveRender()
                    }, 1500)
                }
            }
            return state
        },
        onChange(inx) {
            this.active = inx

            // this.$nextTick(() => {
            //     this.checkContent()
            // })
        },
        moveRender() {
            this.moveConfig.count -= 1

            if (this.moveConfig.count > 0) {
                this.list[this.active].left = this.moveConfig.total - this.moveConfig.count
                this.initMove()
            } else {
                this.moveConfig.count = 0
                this.moveConfig.total = 0
                this.initPlay()
            }
        },
        initMove() {
            window.requestAnimationFrame(this.moveRender)
        }
    }
}
</script>

<style lang="scss" scoped>
.loop-box {
  position: relative;
  padding-left: 42px;
  padding-right: 40px;
  width: 100%;
  height: 20px;
  line-height: 20px;
}
.loop-items {
  width: 100%;
}
.loop-item {
    display: inline-flex;
  line-height: 20px;
  font-weight: 400;
  font-size: 12px;
    height: 20px;
  @include useTheme{
    color: getVar('text-color1-bdbdbe');
  }
}
.notice-box {
  position: absolute;
  left: 24px;
  top: 4px;
    @include useTheme{
        color: getVar('text-color1-bdbdbe');
    }
  &.svg-icon {
    width: 14px;
    height: 14px;
  }
}
</style>