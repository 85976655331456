<template>
  <div class="app-container">
    <div class="app-box">
      <NavTop></NavTop>
      <div class="main-content">
        <div class="changePhone">
          <div class="changePhone-box">
            <div class="top">申请更换手机号</div>
            <div class="top1">
              需要您完成验证及资料填写，提交后将由客服进行审核后更换手机号
            </div>
            <div class="navTop">
              <div class="box" v-for="(item, index) in navdata" :key="index">
                <img
                  class="mb"
                  style="margin-right: 17px"
                  v-if="item.step == 2"
                  :src="$utils.getPng('breadicon')"
                />
                <div
                  class="one"
                  :class="
                    step_act == item.step
                      ? 'one_act'
                      : item.step < step_act
                      ? 'one_last'
                      : ''
                  "
                >
                  <div class="cir">{{ item.step }}</div>
                  <div class="name">{{ item.stepName }}</div>
                </div>

                <img
                  class="mb"
                  style="margin-left: 17px"
                  v-if="item.step == 2"
                  :src="$utils.getPng('breadicon')"
                />
              </div>
            </div>

            <div
              class="center"
              v-show="step_act == 1"
              style="margin-left: 120px"
            >
              <div class="old">请填写旧手机号</div>
              <div class="old1">申请更换已注销或不再使用的旧手机号</div>
              <div class="phoneBoxeFirst" style="margin-top: 24px">
                <!-- <div class="qcode">+86</div> -->
                <el-select
                  class="elSelect"
                  v-model="loginBox.old_pcode"
                  slot="suffix"
                  width="100px"
                  style="margin-top: -1px"
                >
                  <div
                    class="choosecountry"
                    style="color: #8a8b92; margin: 10px 0 12px 20px"
                  >
                    选择国家或地区
                  </div>
                  <el-option
                    :label="item.tel"
                    :value="item.tel"
                    v-for="item in cty"
                    :key="item.tel_id"
                    style="
                      width: 428px;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    {{ item.name }}
                    <span>{{ item.tel }}</span>
                  </el-option>
                </el-select>
                <!-- @keyup.enter.native="loginclick2($event)" -->
                <el-input
                  :class="{ 'is-error': showError == 1 }"
                  v-model="loginBox.old_phone"
                  placeholder="请输入需要更换的旧手机号"
                  @blur="inputblur"
                  :clearable="true"
                >
                </el-input>
              </div>
              <div class="old" style="margin-top: 40px">请填写新手机号</div>
              <div class="old1">申请更换成功后原帐号将更换绑定至新手机</div>
              <div class="boxInputBtn" style="margin-top: 24px">
                <div class="phoneBoxeFirst">
                  <!-- <div class="qcode">+86</div> -->
                  <el-select
                    class="elSelect"
                    v-model="loginBox.new_pcode"
                    slot="suffix"
                    style="margin-top: -1px"
                  >
                    <div
                      class="choosecountry"
                      style="color: #8a8b92; margin: 10px 0 12px 20px"
                    >
                      选择国家或地区
                    </div>
                    <el-option
                      :label="item.tel"
                      :value="item.tel"
                      v-for="item in cty"
                      :key="item.tel_id"
                      style="
                        width: 428px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      {{ item.name }}
                      <span>{{ item.tel }}</span>
                    </el-option>
                  </el-select>
                  <el-input
                    :class="{ 'is-error': showError == 2 }"
                    v-model="loginBox.new_phone"
                    placeholder="请输入新手机号"
                    @keyup.enter.native="sendCode($event)"
                    @blur="inputblur"
                    :clearable="true"
                  >
                  </el-input>
                </div>
                <fm-button
                  @click="time == '60' ? sendCode() : ''"
                  :disabled="time != '60'"
                  size="medium"
                  style="width: 108px; margin-top: -3px"
                  >{{
                    time == "60" ? "获取验证码" : "已发送(" + time + "s)"
                  }}</fm-button
                >
              </div>

              <div class="phoneBoxemsg">
                <el-input
                  :class="{ 'is-error': showError == 3 }"
                  v-model="loginBox.yzcode"
                  placeholder="输入验证码"
                  @blur="inputblur"
                >
                </el-input>

                <!-- <div
                  class="send pointer"
                  v-if="time === 60"
                  @click="sendCode()"
                >
                  获取验证码
                </div>
                <div class="send" v-if="time < 60">{{ time }}s后重新获取</div> -->
              </div>
              <div class="xieyi">
                <div class="remberbox">
                  <!-- <img
                    class="check pointer"
                    @click="changeCheck"
                    :src="
                      checked
                        ? $utils.getPng('web4/icon_check1')
                        : $utils.getPng('web4/icon_check0')
                    "
                    alt=""
                  /> -->
                  <svg-btn
                    class="check pointer"
                    @click="changeCheck"
                    :icon-class="
                      checked
                        ? 'icon-file-check'
                        : 'icon-file-uncheck-' + $store.state.theme
                    "
                  >
                  </svg-btn>
                  <!-- <img
                    class="check"
                    :src="$utils.getPng('web4/icon_check1')"
                    alt=""
                  /> -->
                </div>
                <div class="xieyiCon">
                  新手机号将更换旧手机号，已详读并同意<a
                    class="span pointer"
                    :href="`${$glb.fmUrl}/askdetail/91`"
                    target="_blank"
                    >《用户服务协议》</a
                  >和<a
                    class="span pointer"
                    target="_blank"
                    :href="`${$glb.fmUrl}/askdetail/86`"
                    >《隐私政策》</a
                  >
                </div>
              </div>
              <el-button
                style="margin-top: 64px; margin-bottom: 174px"
                :class="
                  buttonDisabled_1 ? 'down-button-disabled' : 'down-button'
                "
                :disabled="buttonDisabled_1"
                @click="toAdd()"
              >
                下一步
              </el-button>
            </div>
            <div class="center" style="width: 432px" v-show="step_act == 2">
              <div class="old">请填写相关资料</div>
              <div class="old1">
                请尽可能多的填写信息，将有助于您更快通过审核
              </div>
              <div class="information">
                <div class="info_one">
                  <div class="left">飞猫盘账号</div>
                  <el-input
                    v-model="info_form.username"
                    placeholder="请输入飞猫盘账号"
                    :clearable="true"
                  >
                  </el-input>
                </div>
                <div class="info_one">
                  <div class="left">密码</div>
                  <el-input
                    v-model="info_form.password"
                    placeholder="请输入密码"
                    :clearable="true"
                  >
                  </el-input>
                </div>
                <div class="info_one">
                  <div class="left">真实姓名</div>
                  <el-input
                    v-model="info_form.real_name"
                    placeholder="请输入您的真实姓名"
                    :clearable="true"
                  >
                  </el-input>
                </div>
                <div class="info_one">
                  <div class="left">身份证号</div>
                  <el-input
                    v-model="info_form.identity_card"
                    placeholder="请输入您的身份证号码"
                    :clearable="true"
                  >
                  </el-input>
                </div>
                <div class="info_one">
                  <div class="left">支付订单号</div>
                  <el-input
                    v-model="info_form.order_ids"
                    placeholder="请输入支付的订单号 （多个订单号使用“,”隔开）"
                    :clearable="true"
                  >
                  </el-input>
                  <div class="imgBox">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="支付宝订单：填写“商户单号”
                            微信订单：填写“商家订单号”
                            Apple订单：填写“订单号”"
                      placement="right-start"
                    >
                      <!-- <img
                        class="icon_wwh"
                        :src="$utils.getPng('icon_wwh')"
                        alt=""
                      /> -->
                      <svg-btn  class="icon_wwh" :icon-class="'icon-kong-'+$store.state.theme"></svg-btn>
                    </el-tooltip>
                  </div>
                </div>
                <div class="info_one">
                  <div class="left">订单截图</div>
                  <div class="upload">
                    <div class="upTop">
                      <el-upload
                        :action="action"
                        ref="upload_demo"
                        multiple
                        :show-file-list="false"
                        :on-error="handleAvatarError"
                        :on-success="handleAvatarSuccess"
                        :on-progress="handleProgress"
                        :before-upload="beforeAvatarUpload"
                        :on-exceed="handleExceed"
                        :limit="5"
                        :http-request="myUploadImg"
                        :disabled="uploadFilesList.length >= 5"
                      >
                        <el-button
                          class="btnUp"
                          :disabled="uploadFilesList.length >= 5 ? true : false"
                          >点击上传</el-button
                        >
                      </el-upload>
                      <div class="upinfo">
                        每张截图最大支持10M，最多上传5张截图
                      </div>
                    </div>
                    <div class="upImgList">
                      <div
                        class="imgOne"
                        v-for="(item, index) in uploadFilesList"
                        :key="index"
                      >
                        <div class="imgInfo">
                          <el-image
                            class="imgSrc"
                            :src="item.path"
                            alt=""
                            :preview-src-list="imgList"
                          ></el-image>
                          <div class="imgName">{{ item.name }}</div>

                          <img
                            v-if="item.progress == 100"
                            class="imgSucc"
                            :src="$utils.getPng('web4/icon_succ')"
                            alt=""
                          />
                          <el-progress
                            v-if="item.progress < 100"
                            stroke-linecap="butt"
                            width="18"
                            type="circle"
                            :percentage="item.progress"
                            stroke-width="3"
                            color="hsl(18,91%,61%)"
                            style="margin-left: auto; margin-right: 0"
                          ></el-progress>
                        </div>
                        <img
                          class="imgDel"
                          :src="$utils.getPng('web4/icon_delImg')"
                          alt=""
                          @click="delImg(item)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="info_one"
                  style="margin-top: 64px; margin-bottom: 128px"
                >
                  <div class="left"></div>
                  <el-button
                    :class="
                      !info_btn_flag ? 'down-button-disabled' : 'down-button'
                    "
                    :disabled="!info_btn_flag"
                    @click="toUp()"
                  >
                    下一步
                  </el-button>
                </div>
              </div>
            </div>
            <div
              class="center"
              style="padding-bottom: 180px"
              v-show="step_act == 3"
            >
              <div class="waitExam">
                <img
                  class="waitExamImg"
                  :src="$utils.getPng('web4/waitExamImg')"
                />
                <div class="waitExamTit">
                  提交申请成功，客服人员将在1-7个工作日内完成审核
                </div>
                <div class="waitExamTit1">
                  请耐心等待，审核结果将短信通知到您的新手机号 {{ last_code }}
                  {{ last_phone }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <changePhoneToast
        :showToast="showToast"
        @sure="sure"
        @handleCloseToast="handleCloseToast"
      ></changePhoneToast>
    </div>
  </div>
</template>

<script>
import NavTop from "@/components/NavTop";
import changePhoneToast from "./changePhoneToast.vue";
import {
  alterPhoneAddAPI,
  alterPhoneAddInfoAPI,
  phoneLoginCodeAPI,
  getPhonePcodeAPI,
} from "@/utils/apiList/help";
import TheNewUpload from "@/mixin/TheNewUpload.vue";

export default {
  name: "changePhone",
  mixins: [TheNewUpload],
  data() {
    return {
      backgroundImg: "",
      navdata: [
        {
          step: 1,
          stepName: "确认账号",
        },
        {
          step: 2,
          stepName: "填写资料",
        },
        {
          step: 3,
          stepName: "等待审核",
        },
      ],
      step_act: 1,
      loginBox: {
        old_phone: "",
        old_pcode: "+86",
        new_pcode: "+86",
        new_phone: "",
        yzcode: "",
        msgid: "",
      },
      cty: [
        {
          id: "+86",
          name: "中国",
        },
      ],
      checked: false,
      buttonDisabled_1: true,
      showToast: false,
      // 极验
      captchaObj: null,
      validate: [],
      showError: 0,
      time: 60,
      timerA: "",
      form_flag: false,
      //第二步
      info_form: {
        ap_id: "",
        username: "",
        password: "",
        real_name: "",
        identity_card: "",
        order_ids: "",
        order_imgs: "",
      },
      info_flag: false,
      buttonDisabled_2: true,
      action: "", //上传地址
      viewAction: "", //预览地址
      isUpload: false,
      uploadFilesList: [],
      last_code: "",
      last_phone: "",
      showChangeBox: false,
      bgList: [],
      newUpType: 1,
    };
  },
  created() {
    this.getPhonePcode();
  },
  components: {
    changePhoneToast,
    NavTop,
  },
  watch: {
    loginBox: {
      handler(newval) {
        //手机号登录
        if (
          newval.old_phone.length > 0 &&
          newval.new_phone.length > 0 &&
          newval.yzcode.length > 0
        ) {
          this.form_flag = true;
        } else {
          this.form_flag = false;
        }
      },
      deep: true,
      immediate: true,
    },
    button_flag(newval) {
      if (newval) {
        this.buttonDisabled_1 = false;
      } else {
        this.buttonDisabled_1 = true;
      }
    },
    info_form: {
      handler(newval) {
        if (
          (newval.username.trim() && newval.username.trim().length > 0) ||
          (newval.password.trim() && newval.password.trim().length > 0) ||
          (newval.real_name.trim() && newval.real_name.trim().length > 0) ||
          (newval.identity_card.trim() &&
            newval.identity_card.trim().length > 0) ||
          (newval.order_ids.trim() && newval.order_ids.trim().length > 0) ||
          (newval.order_imgs && newval.order_imgs.length > 0)
        ) {
          this.info_flag = true;
        } else {
          this.info_flag = false;
        }
      },
      deep: true,
    },
    info_btn_flag(newval) {},
  },
  beforeDestroy() {},
  computed: {
    button_flag() {
      return this.checked && this.form_flag;
    },
    imgList() {
      return this.uploadFilesList.map((item) => {
        return item.path;
      });
    },
    info_btn_flag() {
      let flag = false; // 默认为false
      for (let i = 0; i < this.uploadFilesList.length; i++) {
        if (this.uploadFilesList[i].status !== "success") {
          flag = false;
          break;
        }
        flag = true; // 只有当所有对象的status为success时才将flag设为true
      }

      return this.info_flag || flag;
    },
  },
  methods: {
    changeCheck() {
      if (this.checked) {
        this.checked = !this.checked;
      } else {
        this.showToast = true;
      }
    },
    handleCloseToast() {
      this.showToast = false;
    },
    sure() {
      this.showToast = false;
      this.checked = true;
    },
    sendCode(event) {
      if (event) {
        event.target.blur();
      }
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (
        this.loginBox.new_pcode == "+86" &&
        !reg_tel.test(this.loginBox.new_phone)
      ) {
        this.$toast("请正确输入手机号", 3);
        this.showError = 2;
        return;
      }
      // this.nctype = "nc_login";
      // this.funName = "sendLoginCode";
      // if (this.$store.state.webMessageJYVerifySwitch_set) {
      //   this.$AliCode.handleOpen(this.sendCodeApi);
      // } else {
      //   this.geetcallback();
      // }
      this.sendCodeApi();
    },
    geetcallback(captchaObj, validate = {}) {
      if (validate == "close") {
        // this.closehk();
        return;
      }
      this.captchaObj = captchaObj;
      this.validate = validate;
      this.time = 60;
      this.timerA && clearInterval(this.timerA);
      this.sendCodeApi();
    },
    inputblur() {
      this.showError = 0;
    },
    minute: function () {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    sendCodeApi(captcha_verify) {
      return new Promise((resolve) => {
        this.btnloading_1 = true; //btn上的loading
        this.reload = false;

        phoneLoginCodeAPI({
          pcode: this.loginBox.new_pcode,
          phone: this.loginBox.new_phone,
          code_type: 2,
          captcha_verify: captcha_verify || "",
        })
          .then((res) => {
            this.btnloading_1 = false; //btn上的loading
            if (res.status == 1) {
              resolve({
                result: true,
                validate: true,
              });
              this.$toast(res.msg, 1);
              this.loginBox.msgid = res.data;
              if (this.time === 60) {
                this.timerA = setInterval(() => {
                  this.minute();
                }, 1000);
              }
            } else if (res.status == 90001) {
              resolve({
                result: false,
                validate: false,
              });
              this.$toast(res.msg, 2);
              this.$AliCode.handleOpen(this.sendCodeApi);
            } else {
              resolve({
                result: true,
                validate: true,
              });
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {});
      });
    },
    toAdd: _.debounce(function () {
      alterPhoneAddAPI({
        old_pcode: this.loginBox.old_pcode,
        old_phone: this.loginBox.old_phone,
        new_pcode: this.loginBox.new_pcode,
        new_phone: this.loginBox.new_phone,
        ucode: this.loginBox.yzcode,
        msgid: this.loginBox.msgid,
      })
        .then((res) => {
          if (res.status != 0) {
            this.action = res.data.card_up_url;
            this.viewAction = res.data.card_img_url;
          }

          if (res.status == 1) {
            // this.$toast(res.msg, 1);
            this.step_act = 2;
            this.info_form.ap_id = res.data.ap_id;
          } else if (res.status == 81000) {
            this.step_act = 3;
            this.last_code = res.data.new_pcode;
            this.last_phone = res.data.new_phone;
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    }, 500),
    handleExceed(file, fileList) {
      if (this.uploadFilesList.length + file.length > 5) {
        // 提示超出
        this.$toast("最多上传5张截图", 2);
      }
    },
    beforeAvatarUpload(file) {
      var tstmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var reg = /(jpg|png|jpeg)$/i;
      // if ( tstmsg == "jpg" || tstmsg == "png" ||   tstmsg == "JPG" || tstmsg == "PNG" || tstmsg == "JPEG") {
      if (reg.test(tstmsg)) {
      } else {
        this.$toast("图片仅支持PNG、JPG、JPEG图片", 2);
        return false;
      }
      if (file.size > 10 * 1024 * 1024 * 1024) {
        this.$toast("上传文件大小超过限制。", 2);
        return false;
      }
      const fileList = {};
      for (const key in file) {
        fileList[key] = file[key];
      }
      // status:uploading、success、error 文件上传状态
      // progress 文件上传进度
      this.uploadFilesList.push({
        ...fileList,
        progress: 0,
        path: this.$utils.getPng("web4/icon_zwbg"),
        status: "uploading",
      });
    },
    handleProgress(event, file) {
      const progress = ((event.loaded / event.total) * 100) | 0;
      const updatedFile = {
        ...file,
        progress,
        path: this.$utils.getPng("web4/icon_zwbg"),
      };
      const updatedList = this.uploadFilesList.map((item) => {
        if (item.uid === file.uid) {
          return updatedFile;
        }
        return item;
      });
      this.uploadFilesList = updatedList;
    },
    handleAvatarSuccess(res, file) {
      const updatedFile = {
        ...file,
        ...res,
        path: this.viewAction + res.data,
        progress: 0,
        status: "success",
      };
      this.uploadFilesList = this.uploadFilesList.map((item) => {
        if (item.uid === file.uid) {
          return updatedFile;
        }
        return item;
      });

      if (res.status == 0) {
        this.$toast(res.msg, 2);
      } else {
        updatedFile.progress = 100;
      }
      this.uploadFilesList = this.uploadFilesList.filter(
        (item) => item.response.status != 0
      );
      this.$refs.upload_demo.uploadFiles =
        this.$refs.upload_demo.uploadFiles.filter(
          (item) => item.response.status != 0
        );
    },
    handleAvatarError(msg = "图片上传失败") {
      this.$toast(msg, 2);
      this.uploadFilesList = this.uploadFilesList.filter(
        (item) => item.status !== "fail"
      );
      this.$refs.upload_demo.uploadFiles =
        this.$refs.upload_demo.uploadFiles.filter(
          (item) => item.status !== "fail"
        );
    },
    delImg(file) {
      this.$refs.upload_demo.uploadFiles.forEach((v, index) => {
        if (v.status == "uploading") {
          this.$refs.upload_demo.abort(v); //取消上传
          this.$refs.upload_demo.uploadFiles.splice(index, 1);
          this.uploadFilesList.splice(index, 1);
          return;
        }
        if (file.uid === v.uid) {
          this.$refs.upload_demo.uploadFiles.splice(index, 1);
          this.uploadFilesList.splice(index, 1);
        }
      });
    },
    toUp() {
      let flag = true;
      for (let i = 0; i < this.uploadFilesList.length; i++) {
        if (this.uploadFilesList[i].status !== "success") {
          flag = false;
          break;
        }
        flag = true; // 只有当所有对象的status为success时才将flag设为true
      }
      if (flag) {
        this.informationCheck();
      } else {
        this.$toast("图片上传中，请稍后", 2);
      }
    },
    informationCheck: _.debounce(function () {
      alterPhoneAddInfoAPI({
        ap_id: this.info_form.ap_id,
        username: this.info_form.username.trim(),
        password: this.info_form.password.trim(),
        real_name: this.info_form.real_name.trim(),
        identity_card: this.info_form.identity_card.trim(),
        order_ids: this.info_form.order_ids.trim(),
        order_imgs: this.imgList.toString(),
      })
        .then((res) => {
          if (res.status == 1) {
            // this.$toast(res.msg, 1);
            this.step_act = 3;
            this.last_code = res.data.new_pcode;
            this.last_phone = res.data.new_phone;
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    }, 500),
    upCallback(args) {
      let _fileIndex = this.uploadFilesList.findIndex(
        (vl) => vl.uid === args.id
      );

      let _obj = this.uploadFilesList[_fileIndex];
      this.upDis = false;

      if (!_obj) {
        return;
      }

      if (args.action === "progress") {
        _obj.progress = args.res?.progress * 100;
        this.$set(this.uploadFilesList, _fileIndex, _obj);
      } else {
        if (args.action === "success") {
          _obj.status = "success";
          _obj.path = this.$store.state.userInfo.ucgimg_host + args.res.data.path;
          _obj.progress = 100;
          _obj.response = {
            data: args.res.data.path,
          };
          this.$set(this.uploadFilesList, _fileIndex, _obj);
        } else if (args.action === "fail") {
          _obj.status = "fail";
          this.$set(this.uploadFilesList, _fileIndex, _obj);
          this.handleAvatarError(args.msg);
        } else if (args.action === "stepFail") {
          _obj.status = "fail";
          this.$set(this.uploadFilesList, _fileIndex, _obj);
          this.handleAvatarError(args.msg);
        }
      }
    },
    getPhonePcode() {
      getPhonePcodeAPI({ type: 3 }).then((res) => {
        this.cty = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
