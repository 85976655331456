import Vue from "vue";

import aliCodeValidate from "./aliCodeValidate.vue";
import Riskverification from "./Riskverification.vue";
import router from "@/router/index";

const createDiv = (id) => {
  let isExit = document.querySelector("#" + id);
  if (!isExit) {
    let isExit = document.createElement("div");
    isExit.id = id;
    document.body.appendChild(isExit);
  }
  return isExit;
};

export function initAliCodeValidate() {
  const dialogId = "aliCodeValidate";
  const dialogModel = Vue.extend(aliCodeValidate);
  createDiv(dialogId);
  return new dialogModel({ router }).$mount("#" + dialogId);
}

export function initRiskve() {
  const dialogId = "riskverificationId";
  const dialogModel = Vue.extend(Riskverification);
  createDiv(dialogId);
  return new dialogModel({ router }).$mount("#" + dialogId);
}
