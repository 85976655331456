<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/views/Collection/index.vue
 * @Description: 收藏
-->
<template>
  <div class="collection">
    <div
      class="top app-right-box"
      :style="`width:${$store.state.appRightWidth}px`"
    >
      <div class="leftBox">
        <div
          v-for="item in topList"
          :key="item.id"
          :class="params.type == item.id ? 'item_act' : ''"
          class="item pointer"
          @click="changeType(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="rightBox">
        <el-input class="input search-box" placeholder="搜索收藏内容" v-model="searchInput" @keyup.enter.native="onChange" @input="onInput">
          <svg-btn v-show="searchInput!=''" @click="clearValue" slot="suffix" icon-class="icon-ipt-clear"></svg-btn>
          <svg-btn @click="onChange" slot="suffix" icon-class="icon-ipt-search"></svg-btn>
        </el-input>
      </div>
    </div>
    <div
      class="content"
      :class="['infinite-list', 'scroll']"
      v-infinite-scroll="getList"
      :infinite-scroll-distance="50"
      ref="scrollBox"
      @scroll="scrollBoxFun"
    >
      <div class="topInfo app-right-box">
        <div class="infoItem">
          <div class="type">类型</div>
          <div class="name">名称</div>
          <div class="rightBox">
            <div class="info">文件信息</div>
            <div class="time">收藏时间</div>
            <div class="screenBtn">
              <fm-button
                icon="icon-cloud-screen"
                type="info"
                text
                size="small"
              ></fm-button>
              <div class="screenBox">
                <template v-for="item in screenList">
                  <div
                      v-if="item.show === 'all' || item.show == params.type"
                      :key="item.id"
                      :class="{ curScreen: item.id == screenId }"
                      @click="handleFilter(item)"
                  >
                    <svg-btn icon-class="icon-cloud-check" />
                    <span>{{ item.name }}</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list app-right-box">
        <template v-if="!loading">
          <div
              v-for="item in filesList"
              :key="item.id"
              class="list-wapp item item1 pointer"
              @click="handleSelect(item)"
          >
            <div class="one">
              <img class="icon" :src="item.ext_icon" />
              <div class="name" v-html="renderLight(item.name + (item.extension || ''))"></div>
              <div class="rightBox">
                <template v-if="item.is_file == '1'">
                  <div class="info">{{ item.size }}</div>
                </template>
                <template v-else>
                  <div class="info">文件：{{ item.fileCount }}</div>
                </template>
                <div class="time">{{ item.intime }}</div>
                <div class="btn">
                  <fm-button
                    class="cancelCollect"
                    type="info"
                    plain
                    round
                    size="mini"
                    @click.prevent.stop="cancelCollectFun(item)"
                  >取消收藏</fm-button>
                </div>
              </div>
            </div>
          </div>
          <div class="isOver" v-show="isOver && filesList.length > 0">没有更多了</div>
        </template>
        <template v-else>
          <div class="list-wapp item pointer" v-for="item in 100" :key="item">
            <div class="one">
              <el-skeleton-item class="icon"></el-skeleton-item>
              <el-skeleton-item class="name"></el-skeleton-item>
              <div class="rightBox">
                <el-skeleton-item class="info"></el-skeleton-item>
                <el-skeleton-item class="time"></el-skeleton-item>
              </div>
            </div>
          </div>
        </template>
        <el-empty
            v-show="filesList.length === 0"
            :image="$utils.getPng('web4/jx_default')"
            :image-size="100"
            style="margin: 200px auto"
            description="暂无相关收藏"
        >
          <div v-show="params.keywords" class="empty-text">试试搜索其他关键词</div>
        </el-empty>
      </div>
    </div>
    <div class="toTop-div" v-show="showTop" @click="scrollToTop">
      <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
    </div>
  </div>
</template>

<script>
import { collectList } from "@/utils/apiList/subscribe";
import { handleCollect } from "@/utils/api/utils";

export default {
  data() {
    return {
      isOver: false,
      cLoading: false,
      loading: false,
      filesList: [],
      topList: [
        { id: 0, name: "全部" },
        { id: 1, name: "文件" },
        { id: 2, name: "合集" },
      ],
      itemAct: 0,
      searchInput: "",
      screenList: [
        { id: 0, name: "最新", params: '1', prop: 'time', show: 'all' },
        { id: 1, name: "最早", params: '0', prop: 'time', show: 'all' },
        { id: 2, name: "最大", params: '1', prop: 'size', show: '1' },
        { id: 3, name: "最小", params: '0', prop: 'size', show: '1' },
      ],
      screenId: 0,
      movieScreenId: 0,
      clientWidth: 800,
      showTop: false,
      params: {
        //查询列表参数
        down: "", //排序
        folder_id: 0,
        keywords: "",
        page: 0,
        limit: 60,
        size: "",
        time: 1,
        type: 0,
      },
      timer: null,
      keyReg: '',
      total: 0
    };
  },
  computed: {
    filterQuery: function () {
      if (this.params.time) {
        return ''
      }
      return ''
    }
  },
  created() {
    this.getList();
  },
  mounted() {
    this.clientWidth = document.getElementById("ph")?.clientWidth;
  },
  methods: {
    renderLight(text) {
      let html = text
      if (this.params.keywords) {
        html = html.replace(this.keyReg, `<span class="red">${this.params.keywords}</span>`)
      }
      return html
    },

    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    getList: _.debounce(
      function (type = 0) {
        if (type == 1) {
          this.params.page = 0;
        } else {
          if (this.isOver || this.replacingData) return;
        }
        this.params.page += 1;
        this.isOver = false;
        this.clearTimer()
        this.timer = setTimeout(() => {
          this.loading = true;
          this.timer = null
        }, 600)

        // for (var i = 0; i < this.params.limit; i++) {
        //   this.filesList.push({
        //     itemloading: true,
        //   });
        // }
        this.replacingData = true; // 开始替换状态
        collectList(this.params)
          .then((res) => {
            if (res.status == "1") {
              this.total = Number(res.data.total);

              res.data.list.forEach((item, index) => {
                item.isHover = false;
                item.itemloading = false;
              });
              if (this.params.page == 1) {
                this.filesList = res.data.list;
              } else {
                // this.filesList.splice(
                //   -this.params.limit,
                //   this.params.limit,
                //   ...res.data.list
                // );
                this.filesList = this.filesList.concat(res.data.list);
              }
              this.replacingData = false;
            } else {
              this.filesList = [];
              this.$toast(res.msg, 2);
            }

            this.isOver = this.filesList.length >= this.total
            this.clearTimer()
            this.loading=false
          })
          .catch((err) => {
            this.filesList = [];
            this.clearTimer()
            this.loading = false;
          });
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    handleSelect(item) {
      window.open(item.share_link)
    },
    async cancelCollectFun(v) {
      if (this.cLoading) return
      this.cLoading = true
      let act = '0'
      let res = await handleCollect({
        action: act,
        type: v.is_file == '1' ? 'file' : 'folder',
        id: v.id
      })
      if (res.state) {
        this.getList(1);
      }
      this.cLoading = false
    },
    scrollBoxFun: _.debounce(
      function (e) {
        {
          let scrollTop = e.target.scrollTop
          this.showTop = scrollTop >= 50;
        }
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: true, //指定在延迟结束后调用
      }
    ),
    scrollToTop() {
      let scrollEl = this.$refs.scrollBox;
      scrollEl.scrollTop = 0;
    },
    changeType(item) {
      if (this.loading) return false;
      if (this.params.type == 1 && item.id != 1) {
        this.params.type = item.id
        this.handleFilter({ id: 0, params: '1', prop: 'time' },)
        return false
      }
      this.params.type = item.id
      this.getList(1);
    },
    handleFilter(item) {
      if (this.loading) return false;
      this.screenId = item.id
      this.params.time = ''
      this.params.size = ''
      this.params[item.prop] = item.params
      this.getList(1);
    },
    clearValue() {
      this.searchInput = ''
      this.onChange()
    },
    onInput(v) {
      if (!v && this.params.keywords) {
        this.params.keywords = this.searchInput
        this.onChange()
      }
    },
    onChange() {
      this.params.keywords = this.searchInput
      if (this.params.keywords) {
        this.keyReg = new RegExp(this.params.keywords, 'g')
      } else {
        this.keyReg = ''
      }
      this.getList(1);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
