<template>
  <div></div>
</template>

<script>
import {
  pcPayMemberInfoAPI,
  couponValidateAPi,
  getBuyListAPI,
} from "@/utils/apiList/vip.js";
import flowers from "@/utils/animation/flowers";
import lottie from "lottie-web";
export default {
  components: {},
  data() {
    return {
      loading: false,
      introduceList: [
        {
          id: 1,
          icon: 4,
          tit: "专属下载额度",
          tit1: "最高享300次/天",
        },
        {
          id: 2,
          icon: 3,
          tit: "下载加速",
          tit1: "最高享极致加速",
        },
        {
          id: 3,
          icon: 2,
          tit: "去广告体验",
          tit1: "会员专享纯净体验",
        },
        {
          id: 4,
          icon: 1,
          tit: "更多云体验",
          tit1: "6项在线功能",
        },
      ],
      commentData: [],
      scrollLoading: false,
      isShowPayOrder: false,
      showDiscountCode: false,
      showUpgradeexplain: false,
      memberInfo: {
        tag: {},
      },
      payData: [
        {
          pid: "alipay",
          name: "支付宝",
          icon: this.$utils.getPng("icon_pay_2_1"),
        },
        {
          pid: "huabei",
          name: "花呗免息分期",
          icon: this.$utils.getPng("icon_pay_3"),
        },
        {
          pid: "wxpay",
          name: "微信支付",
          icon: this.$utils.getPng("icon_pay_1"),
        },
      ],
      showBankInfo: [],
      showSuccessfullyModel: false,
      payToast: {
        vlist: [],
      },
      animation: "",
      allMember: [],
      superSlideData: [],
      upvipinfo:{}
    };
  },
  created() {
    this.getPcMember();
    this.getBuyList();
  },
  methods: {
    getPcMember() {
      this.loading = true;
      pcPayMemberInfoAPI()
        .then((res) => {
          if (res.status == 1) {
            this.loading = false;
            this.memberInfo = res.data;
            this.upvipinfo = res.data.up_vip_info || []

            this.$nextTick(() => {
              if (this.$refs.VipBenefits) {
                this.$refs.VipBenefits.loFlag = false;
                this.$refs.VipBenefits.memberIntr = res.data.info_list;
                this.$refs.VipBenefits.upvipinfo = res.data.up_vip_info || []
              }
            });
          } else {
            this.loading = false;
            // this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClosePayDia() {
      this.isShowPayOrder = false;
      this.clearCoupon();
      this.$refs.VipBenefits.closePopup();
    },

    showPayDiaFun: _.debounce(
      async function (item, type) {
        this.$refs.PayOrder.paytit = item[0].show_title == 'Unlimited Time VIP'?'不限时长会员':item[0].show_title;
        let choosedArr = this.memberInfo.pay_list.find((item1) => {
          return item1.pay_id == item[0].vip_id;
        });

        if (choosedArr.pay_id == "1057" || choosedArr.pay_id == "1056") {
          this.$refs.PayOrder.payModeId = "cycle";
          choosedArr = this.memberInfo.pay_list.find((item1) => {
            return item1.pay_id == "1057";
          });
        } else {
          this.$refs.PayOrder.payModeId = "alipay";
        }
        this.showBankInfo = choosedArr; //收银台信息,选中会员
        this.allMember = this.memberInfo.pay_list; //所有会员信息

        let tag = choosedArr.is_huabei; //确定支付方式中是花呗还是连续包月
        if (tag == 1) {
          this.payData = [
            {
              pid: "alipay",
              name: "支付宝",
              icon: this.$utils.getPng("icon_pay_1"),
            },
            {
              pid: "huabei",
              name: "花呗免息分期",
              icon: this.$utils.getPng("icon_pay_3"),
            },
            {
              pid: "wxpay",
              name: "微信支付",
              icon: this.$utils.getPng("icon_pay_2_1"),
            },
          ];
        } else {
          this.payData = [
            {
              name: "连续包月",
              pid: "cycle",
              icon: this.$utils.getPng("icon_pay_1"),
            },
            {
              pid: "alipay",
              name: "支付宝",
              icon: this.$utils.getPng("icon_pay_1"),
            },

            {
              pid: "wxpay",
              name: "微信支付",
              icon: this.$utils.getPng("icon_pay_2_1"),
            },
          ];
        }

        this.$refs.PayOrder.memberAct = type;
        this.isShowPayOrder = true;
        setTimeout(() => {
           this.newPay(this.showBankInfo.pay_id, this.$refs.PayOrder.payModeId);
        }, 0);
       
      },
      200,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //生成订单(微信，支付宝，花呗，连续包月)
    newPay: _.debounce(
      function (pay_id, pay_type, coupndata) {
        this.$refs.PayOrder.newPay(pay_id, pay_type, coupndata);
      },
      300,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    couponValidate: _.debounce(
      function () {
        couponValidateAPi({
          coupon: this.$refs.DiscountCodeBox.coupeValue,
        })
          .then((res) => {
            if (res.status == 1) {
              this.$toast(
                `使用当前优惠码购买，享受${res.data.discount_label}折优惠`,
                1
              );
              this.newPay(
                this.showBankInfo.pay_id,
                this.$refs.PayOrder.payModeId,
                {
                  coupon: this.$refs.DiscountCodeBox.coupeValue,
                  discount: res.data.discount,
                }
              );
              this.handleCloseDis();
            } else {
              this.$toast(res.msg, 2);
              this.$refs.DiscountCodeBox.coupeValue = "";
              this.handleCloseDis();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    handleCloseDis() {
      this.showDiscountCode = false;
    },
    handleCloseUpgrade() {
      this.showUpgradeexplain = false;
    },
    openSuccess(payToast) {
      this.payToast = payToast;
      this.isShowPayOrder = false;
      this.showSuccessfullyModel = true;
      this.$nextTick(() => {
        this.animation = lottie.loadAnimation({
          container: document.getElementById("lottie"), // Required
          animationData: flowers,
          renderer: "svg", // Required
          loop: false, // Optional
          autoplay: true, // Optional
        });
      });
      this.handleClosePayDia();
      this.refreshPage();
    },
    successRefresh() {
      this.showSuccessfullyModel = false;
      this.animation = "";
      lottie.stop();
      lottie.destroy();
      this.getPcMember();
      this.getBuyList();
      this.gopageA();
    },
    clearCoupon() {
      this.$refs.DiscountCodeBox.coupeValue = "";
    },
    getBuyList() {
      this.scrollLoading = true;
      getBuyListAPI()
        .then((res) => {
          if (res.status == 1) {
            this.scrollLoading = false;
            this.commentData = res.data.reviews;
            let listArr = res.data.list.map((item) => {
              return { ...item, id: Math.random(0, 1) };
            });
            this.superSlideData = listArr;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
