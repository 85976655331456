<template>
  <div @mouseenter="onmouseenter" @mouseleave="onmouseleave">
    <el-input
      v-model="iVal"
      :class="inputCls"
      :placeholder="placeholder"
      :type="type"
      :autocomplete="autocomplete"
      @focus="handleFocus"
      @input="iChange"
      @blur="handleBlur"
      @keyup.enter.native="handleEnterKeyup"
    >
      <div slot="suffix" class="suffix-close-box"  @click="handleClear">
        <svg-btn v-show="clear&&clearable"  class="suffix-close"  icon-class="icon-close-0"></svg-btn>
      </div>
    </el-input>
  </div>
</template>

<script>
export default {
  name: "FmInput",
  props: {
    value: '',
    placeholder: '',
    inputCls: '',
    type: '',
    autocomplete: {
      type: null,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iVal: this.value,
      clear: false,
      focus: false,
      timeOut: null,
    }
  },
  watch: {
    value: function (newVal) {
      if (this.iVal !== newVal) {
        this.iVal = newVal
      }
    }
  },
  methods: {
    handleClear() {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
        this.timeOut = null
      }
      this.iVal = ''
      this.clear = false
      this.$emit('input', this.iVal)
    },
    handleFocus() {
      this.focus = true
      this.$parent.isFocusFlag = true;
    },
    iChange() {
      this.clear = !!this.iVal;
      this.$emit('input', this.iVal)
    },
    handleBlur() {
      this.focus = false
      this.$emit('iBlur', '1')
      this.timeOut = setTimeout(() => {
        this.clear = false
        this.timeOut = null
      }, 100)
    },
    onmouseenter() {
      if (this.iVal) {
        this.clear = true
      }
    },
    onmouseleave() {
      if (!this.focus) {
        this.clear = false
      }
    },
    handleEnterKeyup(event) {
      this.$emit('keyupEnter', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.suffix-close-box {
  width: 18px;
  height:18px;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}
.suffix-close {
  width: 18px;
  cursor: pointer;
}
</style>
