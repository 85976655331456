<template>
  <!-- 其他登录方式 -->
  <div class="otherLogin">
    <div class="zgx">
      <div class="zgline"></div>
      <div class="zgtit">{{ $t('login.Moreways') }}</div>
      <div class="zgline"></div>
    </div>
    <div class="other">
      <div v-if="showWx == 1" class="item-login" @click="fastLogin('wx')">
        <img :src="$utils.getPng('login-wx-icon')" alt="">
      </div>
      <div class="item-login" @click="fastLogin('qq')">
        <img :src="$utils.getPng('login-qq-icon')" alt="">
      </div>
      <div v-if="showPhone" class="item-login" @click="fastLogin(changeIcon =='login-mailbox-icon'? 'email':'phone')">
        <img :src="$utils.getPng(changeIcon)" alt="">
      </div>
      <!-- <div  class="item-login" @click="fastLogin('email')">
        <img :src="$utils.getPng('login-mailbox-icon')" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPhone: true,
    showWx: {
      type: Number,
      default: 0
    },
    changeIcon: 'login-phone-icon'
  },
  methods:{
    fastLogin(type) {
       this.$emit("fastLogin",type);
    }
  }
};
</script>

<style lang="scss" scoped>
.zgx {
  display: flex;
  align-items: center;
  // margin-top: 52px;
  margin: 35px 2px 25px 2px;
  width: 379px;

  // background: skyblue;
  .zgline {
    width: 128px;
    height: 1px;
    background: #f5f5f5;
  }

  .zgtit {
    font-size: 14px;

    font-weight: 400;
    color: #bdbdbe;
    width: 96px;
    margin: 0 15px;
    text-align: center;
  }
}

.other {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 234px;
  .item-login {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
.item-login + .item-login {
  margin-left: 52px;
}
</style>
