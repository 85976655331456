import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data) {
  if (data?.ak) {
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  } else {
    return data;
  }
}

function inParameter(data) {
  if (data) {
    return { sn: ed, jt: utils.secret(data, ak)};
  } else {
    return data;
  }
}

export function getIndex() {
  return request({
    url: "/user/index",
    method: "get",
  }).catch((err) => {});
}
export function saveUserInfo(data) {
  return request({
    url: "/user-service/user/saveUserInfo",
    method: "post",
    data: { sn: ed, jt: utils.secret(data, ak) },
  }).catch((err) => {
    console.log(err);
  });
}



//实名认证
export function realNameAuth(data) {
  return request({
    url: "/user-service/user/realNameAuth",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

//绑定手机发送短信验证码
export function bindPhoneSendCode(data) {
  return request({
    url: "/user-service/user/bindPhoneSendCode",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

//获取弹窗状态
export function getPopupStauts(data) {
  return request({
    url: "/user-service/popup/find",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function setPasswordAPI(data) {
  return request({
    url: "/user-service/passport/setPassword",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//旧手机号码发送短信
export function beforePhoneSendCodeAPI(data) {
  return request({
    url: "/user-service/user/beingPhoneSendCode",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//旧手机号码校验
export function beforePhoneCodeCheck(data) {
  return request({
    url: "/user-service/user/beingPhoneCodeCheck",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

//绑定手机
export function bindPhoneAPI(data) {
  return request({
    url: "/user-service/user/bindPhone",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//获取头像list
export function getDefaultIconListAPI(data) {
  return request({
    url: "/user-service/user/getDefaultIconList",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//修改头像
export function modilogoAPI(data) {
  return request({
    url: "/user-service/user/modilogo",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//上传头像
export function getDirectAvatarUploadLinkAPI(data) {
  return request({
    url: "/disk-service/file/getDirectAvatarUploadLink",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
// 绑定QQ
export function userBindQQAPI(data) {
  return request({
    url: "/user-service/user/userBindQQ",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
// 解绑qq
export function unBindQQAPI(data) {
  return request({
    url: "/user-service/user/unBindQQ",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
export function navTopAdInfo(data) {
  return request({
    url: "/user-service/user/navTopAdInfo",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
// 获取微信登录信息
export function getWxinfoAPI(data) {
  return request({
    url: "/user-service/passport/wxinfo",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}


// 绑定wx
export function userBindWxAPI(data) {
  return request({
    url: "/user-service/user/userBindWx",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
// 解绑wx
export function unBindWxAPI(data) {
  return request({
    url: "/user-service/user/unBindWx",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 验证修改密码权限
export function checkUserVerificationAPI(data) {
  return request({
    url: "/user-service/user/checkUserVerification",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
