<template>
  <el-dialog
    :visible.sync="isShow"
    width="580px"
    :before-close="handleClose"
    custom-class="shareListDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <span>{{
            gatherBoxType == 1 ? "选择合集文件" : "编辑合集文件"
          }}</span>
        </div>
        <div>
          <fm-button
            @click="refreshList"
            v-show="gatherBoxType == 1"
            class="btn-refresh"
            type="info"
            size="mini"
            text
            icon="icon-refresh"
          ></fm-button>
          <fm-button
            @click="handleClose"
            type="info"
            size="mini"
            text
            icon="icon-model-close"
          ></fm-button>
        </div>
      </div>
      <!-- 新增 -->
      <div
        class="d-content"
        v-show="gatherBoxType == 1"
        v-loading="fileLoading"
      >
        <div class="top-box addGateher">
          <div class="file-directory">
            <div class="lessthen4" v-if="moveNav.length < 4">
              <div
                class="one"
                v-for="(item, index) in moveNav"
                :key="item"
                @click="toUp(item, index)"
              >
                <el-tooltip :content="item.name" placement="top">
                  <span class="el-dropdown-link name"> {{ item.name }}</span>
                </el-tooltip>
                <svg-btn
                  icon-class="icon-file-direction"
                  v-show="index !== moveNav.length - 1"
                />
              </div>
            </div>
            <div class="lessthen4 morethen4" v-else>
              <div class="one" @click="toUp({ name: '全部文件', id: 0 }, 0)">
                <el-tooltip content="全部文件" placement="top">
                  <span class="el-dropdown-link name"> 全部文件 </span>
                </el-tooltip>
                <svg-btn icon-class="icon-file-direction" />
              </div>
              <div class="one" @mousemove="showboxBreadMove = true">
                ...
                <svg-btn icon-class="icon-file-direction" />
              </div>
              <div
                class="one"
                v-for="item in moveNav.slice(
                  moveNav.length - 2,
                  moveNav.length
                )"
                :key="item"
                @click="toUp(item, moveNav.length - 2)"
              >
                <el-tooltip :content="item.name" placement="top">
                  <span class="el-dropdown-link name">
                    {{ item.name }}
                  </span>
                </el-tooltip>
                <svg-btn icon-class="icon-file-direction" />
              </div>
            </div>
            <div
              class="boxBreadMove"
              style="left:70px"
              v-if="showboxBreadMove"
              @mouseleave="showboxBreadMove = false"
            >
              <div class="boxbreadbox2">
                <div
                  class="boxOne pointer"
                  v-for="(item, index) in moveNav.slice(1, moveNav.length - 2)"
                  :key="index"
                  @click="toUp(item, index + 1)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <el-input
            v-model="keywords"
            placeholder="搜索云盘文件"
            @keyup.enter.native="doSearchList"
          >
            <svg-btn
              v-show="keywords != ''"
              @click="keywords = ''"
              slot="suffix"
              icon-class="icon-ipt-clear"
            ></svg-btn>
            <svg-btn
              @click="doSearchList"
              slot="suffix"
              icon-class="icon-ipt-search"
            ></svg-btn>
          </el-input>
        </div>
        <div
          class="file-list"
          v-infinite-scroll="getFileList"
          ref="scrollBox"
          infinite-scroll-distance="50"
          v-if="filesList.length > 0"
        >
          <div
            @click="item.is_file == '1' ? '' : openFolder(item)"
            class="hover-item"
            v-for="(item, index) in filesList"
            :key="index"
            :class="[
              { selItem: item.isSelect, 'is-lockfile': item.is_locked == 1 },
            ]"
          >
            <div class="item">
              <div
                class="file-checkbox"
                :class="
                  item.is_allowed_share == 0 || item.is_locked == 1
                    ? 'not_choosed'
                    : ''
                "
                @click="item.is_file == '1' ? chooseFile(item) : ''"
              >
                <svg-btn
                  :class="[{ icon_checks: item.is_file == '1' }, 'check-box']"
                  :icon-class="
                    item.is_allowed_share == 0 || item.is_locked == 1 ? 'icon-checkbox-disable-'+$store.state.theme:
                    item.isSelect
                      ? 'icon-file-check'
                      : 'icon-file-uncheck-' + $store.state.theme
                  "
                ></svg-btn>
              </div>
              <div class="file_icon">
                <el-image
                  :src="item.showPlay ? item.ext_icon : $utils.fileIcon(item.extension)" 
                  class="icon"
                  @error="onError($event, item)"
                >
                  <div slot="error" class="image-slot">
                    <img
                      :src="
                        item.is_file == '1'
                          ? $utils.fileIcon(item.extension)
                          : ''
                      "
                    />
                  </div>
                </el-image>
                <div
                  class="pay"
                  v-show="item.ext == 'mp4' && item.showPlay"
                >
                  <img class="iconpay" :src="$utils.getPng(`web4/icon_pay`)" />
                </div>
              </div>
              <div class="info">
                <span class="name">{{ item.name }}{{ item.extension }}</span>
                <div v-if="item.is_file == '1'">
                  <div v-if="item.is_locked == 0">
                    <span>大小：{{ item.size }}</span>
                    <span>时间：{{ item.intime }}</span>
                    <span
                      >浏览/下载：{{ item.file_views }}/{{
                        item.file_downs
                      }}</span
                    >
                  </div>
                  <div v-else class="lock-style">
                    <svg-btn icon-class="icon-tips-warning" />
                    <span>已锁定 </span>
                  </div>
                </div>
                <div v-else>
                  <span>文件：{{ item.fileCount }}</span>
                  <span>时间：{{ item.intime }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="isOver" v-if="isOver">没有更多了</div>
        </div>
        <div class="file-default" v-else>
          <img :src="$utils.getPng('web4/file_default')" />
          <div class="goUplod" v-if="keywords">
            <span>暂无相关文件</span>
            <span>试试搜索其他关键词</span>
          </div>
          <div class="goUplod" @click="goUp()" v-if="!keywords">
            <span>暂无可选文件</span>
            <span>请先在云盘 <font>上传文件</font></span>
          </div>
        </div>
      </div>
      <!-- 编辑 -->
      <div
        class="d-content"
        v-show="gatherBoxType == 2"
        v-loading="fileLoading"
      >
        <div class="top-box">
          <span>已选分享文件</span>
          <el-input
            v-model="edit_keywords"
            placeholder="搜索已选文件"
            @keyup.enter.native="searchEditList"
          >
            <svg-btn
              v-show="edit_keywords != ''"
              @click="edit_keywords = ''"
              slot="suffix"
              icon-class="icon-ipt-clear"
            ></svg-btn>
            <svg-btn
              @click="searchEditList"
              slot="suffix"
              icon-class="icon-ipt-search"
            ></svg-btn>
          </el-input>
        </div>
        <div class="file-list" v-if="!isSearch">
          <div
            v-show="editFileList.length > 0"
            class="hover-item"
            v-for="(item, index) in editFileList"
            :key="index"
            :class="{ selItem: item.isSelect }"
          >
            <div class="item">
              <div @click="chooseFile2(item)" class="file-checkbox">
                <svg-btn
                  class="icon_checks check-box"
                  :icon-class="
                    item.isSelect
                      ? 'icon-file-check'
                      : 'icon-file-uncheck-' + $store.state.theme
                  "
                ></svg-btn>
              </div>
              <div class="file_icon">
                <el-image
                  :src="item.ext_icon"
                  class="icon"
                  @error="onError($event, item)"
                >
                  <div slot="error" class="image-slot">
                    <img
                      :src="
                        item.is_file == '1'
                          ? $utils.fileIcon(item.extension)
                          : ''
                      "
                    />
                  </div>
                </el-image>
                <div
                  class="pay"
                  v-show="item.ext == 'mp4' && item.showPlay != 1"
                >
                  <img class="iconpay" :src="$utils.getPng(`web4/icon_pay`)" />
                </div>
              </div>
              <div class="info">
                <span class="name">{{ item.name }}{{ item.extension }}</span>
                <div>
                  <span>大小：{{ item.size }}</span>
                  <span>时间：{{ item.intime }}</span>
                  <span
                    >浏览/下载：{{ item.file_views }}/{{
                      item.file_downs
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="file-default" v-show="editFileList.length == 0">
            <img :src="$utils.getPng('web4/file_default')" />
            <div class="goUplod">
              <span>暂无合集文件</span>
              <span>请重新添加文件</span>
            </div>
          </div>
        </div>
        <div class="file-list" v-else>
          <div
            v-show="searchList.length > 0"
            class="hover-item"
            v-for="(item, index) in searchList"
            :key="index"
            :class="{ selItem: item.isSelect }"
          >
            <div class="item">
              <div @click="chooseFile2(item)" class="file-checkbox">
                <svg-btn
                  class="icon_checks check-box"
                  :icon-class="
                    item.isSelect
                      ? 'icon-file-check'
                      : 'icon-file-uncheck-' + $store.state.theme
                  "
                ></svg-btn>
              </div>
              <img class="icon" :src="item.ext_icon" />
              <div class="info">
                <span class="name">{{ item.name }}{{ item.extension }}</span>
                <div>
                  <span>大小：{{ item.size }}</span>
                  <span>时间：{{ item.intime }}</span>
                  <span
                    >浏览/下载：{{ item.file_views }}/{{
                      item.file_downs
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="file-default" v-show="searchList.length == 0">
            <img :src="$utils.getPng('web4/file_default')" />
            <div class="goUplod">
              <span>暂无相关文件</span>
              <span>试试搜索其他关键词</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 新增 -->
      <div class="d-footer" v-show="gatherBoxType == 1">
        <div class="left">
          <div @click="allCheck == 2 ? cancelCheckedVal() : setCheckedVal()">
            <svg-btn
              :icon-class="
                allCheck == 2
                  ? 'icon-file-check'
                  : allCheck == 0
                  ? 'icon-file-uncheck-' + $store.state.theme
                  : 'icon-file-checks'
              "
            >
            </svg-btn>
          </div>
          <span>{{ allCheck == 2 ? "取消选择" : "全选" }}</span>
          <span class="total" v-if="editFileList.length == 0">未选择</span>
          <span class="total" v-else>已选择 {{ editFileList.length }} 项</span>
        </div>
        <div class="right">
          <span
            @click="editFileList.length == 0 ? '' : seeCheckedFiles()"
            :class="editFileList.length == 0 ? 'disable' : 'go'"
            >查看已选文件</span
          >
          <fm-button
            :disabled="editFileList.length == 0 ? true : false"
            @click="editFileList.length == 0 ? '' : confirm()"
            type="primary"
            size="small"
            >确 定</fm-button
          >
        </div>
      </div>
      <!-- 编辑 -->
      <div class="d-footer" v-show="gatherBoxType == 2">
        <div class="left">
          <div @click="allCheck2 == 2 ? cancelCheckedVal2() : setCheckedVal2()">
            <svg-btn
              :icon-class="
                allCheck2 == 2
                  ? 'icon-file-check'
                  : allCheck2 == 0
                  ? 'icon-file-uncheck-' + $store.state.theme
                  : 'icon-file-checks'
              "
            >
            </svg-btn>
          </div>
          <span>{{ allCheck2 == 2 ?  "取消选择" : "全选" }}</span>
          <span class="total" v-if="isSelSum().length == 0">未选择</span>
          <span class="total" v-else>已选择 {{ isSelSum() }} 项</span>
        </div>
        <div class="right">
          <span @click="continueAdd()" class="go">继续添加文件</span>
          <fm-button
            :disabled="allCheck2 == 0 ? true : false"
            @click="allCheck2 == 0 ? '' : confirm()"
            type="primary"
            size="small"
            >确 定</fm-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getCloudList } from "@/utils/apiList/cloud";

export default {
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      allCheck: 0,
      editFileList: [], //选中文件
      allCheck2: 0,
      keywords: "",
      edit_keywords: "",
      moveNav: [{ name: "全部文件", id: "0" }],
      showboxBreadMove: false,
      searchList: [],
      isSearch: false,
      filesList: [],
      gatherBoxType: 1,
      fileParams: {
        //查询文件列表参数
        down: "", //排序
        folder_id: 0,
        keywords: "",
        page: 0,
        limit: 60,
        size: "",
        time: 1,
      },
      isOver: false,
      fileLoading: false,
    };
  },
  watch: {
    editFileList: {
      handler(newval, oldVal) {
        let selList = this.editFileList.filter((item) => item.isSelect);
        if (selList.length > 0 && selList.length < newval.length) {
          this.allCheck2 = 1;
        } else if (selList.length == 0) {
          this.allCheck2 = 0;
        } else if (selList.length == newval.length) {
          this.allCheck2 = 2;
        }
      },
      immediate: true,
    },
    searchList: {
      handler(newval, oldVal) {
        var selList = this.searchList.filter((item) => item.isSelect);
        if (selList.length > 0 && selList.length < newval.length) {
          this.allCheck2 = 1;
        } else if (selList.length == 0) {
          this.allCheck2 = 0;
        } else if (selList.length == newval.length) {
          this.allCheck2 = 2;
        }
      },
      immediate: true,
    },
    filesList: {
      handler(newval, oldVal) {
        let isFileList = this.filesList.filter(
          (item) =>
            item.is_file == "1" &&
            item.is_locked == 0 &&
            item.is_allowed_share == "1"
        );
        let selList = this.filesList.filter((item) => item.isSelect);
        if (selList.length > 0 && selList.length < isFileList.length) {
          this.allCheck = 1;
        } else if (selList.length == 0) {
          this.allCheck = 0;
        } else if (selList.length == isFileList.length) {
          this.allCheck = 2;
        }
      },
      immediate: true,
    },
    keywords: {
      handler(newval) {
        if (newval == "") {
          this.doSearchList();
        }
      },
    },
    edit_keywords: {
      handler(newval) {
        if (newval == "") {
          this.searchEditList();
        }
      },
    },
    pageValue(newVal) {
      if (newVal == 1) {
        if(this.$refs.scrollBox){
          this.$refs.scrollBox.scrollTop = 0;
        }
      }
    },
  },
  computed: {
    pageValue() {
      return this.fileParams.page;
    },
  },
  created() {},
  methods: {
    isSelSum() {
      var arr = this.editFileList.filter((item) => item.isSelect);
      return arr.length;
    },
    reset() {
      this.fileParams = {
        down: "", //排序
        folder_id: 0,
        keywords: "",
        page: 0,
        limit: 60,
        size: "",
        time: 1,
      };
      this.keywords = "";
      this.isOver = false;
      this.moveNav = [{ name: "全部文件", id: "0" }];
      this.allCheck = 0;
      this.isSearch = false;
      this.searchList = [];
      this.edit_keywords = "";
      this.editFileList = []; //选中文件
    },
    handleClose() {
      this.reset();
      this.$emit("handleClose");
    },
    chooseFile(item) {
      var id = item.id;
      if (item.is_locked == 1) {
        this.$toast("无法完成操作，该文件已被锁定", 2);
        return;
      }
      if (item.is_allowed_share == 0) {
        this.$toast("该类型文件暂不支持分享", 2);
        return;
      }
      var tmpfiles = this.filesList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        if (tmpfiles[i].id === id) {
          if (this.editFileList.length < 1000) {
            tmpfiles[i].isSelect = !tmpfiles[i].isSelect;
            if (tmpfiles[i].isSelect) {
              this.editFileList.push(tmpfiles[i]);
            } else {
              var index = this.editFileList.findIndex((item) => item.id === id);
              if (index !== -1) {
                this.editFileList.splice(index, 1);
              }
            }
          } else {
            tmpfiles[i].isSelect = false;
            var index = this.editFileList.findIndex((item) => item.id === id);
            if (index !== -1) {
              this.editFileList.splice(index, 1);
            }
          }
          Vue.set(this.filesList, i, tmpfiles[i]);
          return;
        }
      }
    },
    //全选
    setCheckedVal() {
      if (this.editFileList.length == 1000 || this.filesList.length == 0) {
        return;
      }
      var tmpfiles = this.filesList;
      if (this.editFileList.length > 0) {
        for (let i = 0; i < tmpfiles.length; i++) {
          let found = false;
          for (let j = 0; j < this.editFileList.length; j++) {
            if (tmpfiles[i].id === this.editFileList[j].id) {
              found = true;
              break;
            }
          }
          if (!found) {
            if (this.editFileList.length == 1000) {
              return;
            }
            if (
              tmpfiles[i].is_file == "1" &&
              tmpfiles[i].is_locked == 0 &&
              tmpfiles[i].is_allowed_share == 1
            ) {
              this.editFileList.push(tmpfiles[i]);
            }
            tmpfiles[i].is_file == "1" &&
            tmpfiles[i].is_locked == 0 &&
            tmpfiles[i].is_allowed_share == 1
              ? (tmpfiles[i].isSelect = true)
              : "";
            Vue.set(this.filesList, i, tmpfiles[i]);
          }
        }
      } else {
        for (var i = 0, len = tmpfiles.length; i < len; i++) {
          if (this.editFileList.length == 1000) {
            return;
          }
          if (
            tmpfiles[i].is_file == "1" &&
            tmpfiles[i].is_locked == 0 &&
            tmpfiles[i].is_allowed_share == 1
          ) {
            this.editFileList.push(tmpfiles[i]);
          }
          tmpfiles[i].is_file == "1" &&
          tmpfiles[i].is_locked == 0 &&
          tmpfiles[i].is_allowed_share == 1
            ? (tmpfiles[i].isSelect = true)
            : "";
          Vue.set(this.filesList, i, tmpfiles[i]);
        }
      }
    },
    // 取消全选
    cancelCheckedVal() {
      var tmpfiles = this.filesList;
      for (var j = 0; j < tmpfiles.length; j++) {
        for (var i = 0; i < this.editFileList.length; i++) {
          tmpfiles[j].isSelect = false;
          if (tmpfiles[j].id == this.editFileList[i].id) {
            this.editFileList.splice(i, 1);
            Vue.set(this.filesList, j, tmpfiles[j]);
          }
        }
      }
    },
    //编辑 取消全选
    cancelCheckedVal2() {
      if (this.isSearch) {
        var tmpfiles = this.searchList;
        for (var i = 0, len = tmpfiles.length; i < len; i++) {
          if (tmpfiles[i].isSelect) {
            tmpfiles[i].isSelect = !tmpfiles[i].isSelect;
            Vue.set(this.searchList, i, tmpfiles[i]);
          }
        }
      } else {
        var tmpfiles = this.editFileList;
        for (var i = 0, len = tmpfiles.length; i < len; i++) {
          if (tmpfiles[i].isSelect) {
            tmpfiles[i].isSelect = !tmpfiles[i].isSelect;
            Vue.set(this.editFileList, i, tmpfiles[i]);
          }
        }
      }
      this.isSelSum()
    },
    //编辑 全选
    setCheckedVal2() {
      if(this.isSearch){
        var tmpfiles = this.searchList;
        this.searchList = [];
        for (var i = 0, len = tmpfiles.length; i < len; i++) {
          tmpfiles[i].isSelect = true;
          Vue.set(this.searchList, i, tmpfiles[i]);
        }
      }else{
        var tmpfiles = this.editFileList;
        this.editFileList = [];
        for (var i = 0, len = tmpfiles.length; i < len; i++) {
          tmpfiles[i].isSelect = true;
          Vue.set(this.editFileList, i, tmpfiles[i]);
        }
      } 
    },
    //编辑 单个选中
    chooseFile2(item) {
      var id = item.id;
      var tmpfiles = this.editFileList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        if (tmpfiles[i].id === id) {
          tmpfiles[i].isSelect = !tmpfiles[i].isSelect;
          Vue.set(this.editFileList, i, tmpfiles[i]);
          return;
        }
      }
    },
    //查看已选文件
    seeCheckedFiles() {
      let arr = [];
      for (var i = 0; i < this.editFileList.length; i++) {
        if (this.editFileList[i].isSelect) {
          arr.push(this.editFileList[i]);
        }
      }
      this.editFileList = arr;
      this.gatherBoxType = 2;
    },
    //继续添加文件
    continueAdd() {
      let arr = [];
      for (var i = 0; i < this.editFileList.length; i++) {
        if (this.editFileList[i].isSelect) {
          arr.push(this.editFileList[i]);
        }
      }
      this.editFileList = arr;
      this.isSearch = false; //还原搜索状态
      this.edit_keywords = "";
      this.isOver = false;
      this.fileLoading = true;
      this.getFileList();
    },
    //编辑 搜索
    searchEditList() {
      this.isSearch = true;
      this.searchList = [];
      this.searchList = this.editFileList.filter(item => {
        if (item.search_name.indexOf(this.edit_keywords) > -1) {
          return item
        }
      });
    },
    //确定
    confirm() {
      let file_ids = []
      this.editFileList.map((item) => {
        if(item.isSelect) {
          file_ids.push(item.id)
        }
      });
      this.$emit("openShareDialog", file_ids, this.editFileList[0].name);
    },
    //刷新
    refreshList() {
      this.isOver = false;
      this.fileParams.page = 0;
      this.fileLoading = true;
      this.getFileList();
    },
    doSearchList() {
      this.isOver = false;
      this.fileParams.page = 0;
      this.fileParams.keywords = this.keywords;
      this.fileLoading = true;
      this.getFileList();
    },
    openFolder(item) {
      this.isOver = false;
      this.moveNav.push({ name: item.name, id: item.id });
      this.fileParams.page = 0;
      this.fileParams.folder_id = item.id;
      this.fileLoading = true;
      this.getFileList();
    },
    toUp(item, index) {
      this.isOver = false;
      this.showboxBreadMove = false;
      let arr = this.moveNav.slice(0, index + 1);
      this.moveNav = arr;
      this.fileParams.page = 0;
      this.fileParams.folder_id = item.id;
      this.fileLoading = true;
      this.getFileList();
    },
    getFileList: _.debounce(
      function () {
        this.fileParams.page += 1;
        this.goAddGather();
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    goAddGather() {
      if (this.isOver) {
        return;
      }
      getCloudList(this.fileParams)
        .then((res) => {
          if (res.status == "1") {
            this.fileLoading = false;
            if (res.data.list.length < this.fileParams.limit)
              this.isOver = true;
            res.data.list.forEach((item) => {
                item.showPlay = true;
            });
            if (this.fileParams.page == 1) {
              this.filesList = res.data.list;
            } else {
              this.filesList = this.filesList.concat(res.data.list);
            }
            if (this.editFileList.length != 0) {
              for (var i = 0; i < this.editFileList.length; i++) {
                for (var j = 0; j < this.filesList.length; j++) {
                  if (
                    this.filesList[j].id == this.editFileList[i].id &&
                    this.editFileList[i].isSelect
                  ) {
                    this.filesList[j].isSelect = true;
                  } else {
                  }
                }
              }
            }
            this.gatherBoxType = 1;
          }
        })
        .catch((err) => {});
    },
    goUp() {
      this.$router.push({
        name: "drive",
        params: {
          upload: 1,
        },
      });
    },
    onError(e, item) {
      this.$set(item, "showPlay", false);
    },
  },
};
</script>

<style lang="scss">
.shareListDialog {
  .el-dialog__body {
    padding: 20px 0 24px 0 !important;
  }
  .d-header {
    margin: 0 24px;
    .btn-refresh {
      right: 56px !important;
    }
  }
  .top-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    margin: 0 24px;
    .el-input {
      width: 212px;
      .el-input__inner {
        width: 212px;
        height: 28px;
        border-radius: 14px;
        padding-right: 54px;
      }
      .svg-icon {
        width: 16px;
        margin-right: 8px;
        cursor: pointer;
      }
    }
    .el-input__suffix-inner {
      display: flex;
      align-items: center;
    }
    .file-directory .name {
      max-width: 80px;
    }
  }
  .file-list {
    height: 340px;
    overflow: auto;
    margin-right: 2px;
    @include useTheme {
      box-shadow: getVar("collection-top-shadow");
    }
    .hover-item {
      padding: 0 8px;
      margin: 0 7px 0 16px;
      &:hover {
        @include useTheme {
          background-color: getVar("secondary-bg-color-hover");
        }
        .icon_checks {
          opacity: 1;
        }
      }
    }
    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 68px;
      @include useTheme {
        box-shadow: getVar(collection-top-shadow);
      }
      .file-checkbox {
        height: 16px;
      }
      .check-box {
        width: 16px;
        height: 16px;
        opacity: 0;
        @include useTheme {
          color: getVar("text-color1-e5e5e5");
        }
      }
      .icon {
        width: 40px;
        height: 40px;
        margin: 0 14px;
        @include textUnSelect;
        border-radius: 5px;
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }
      .file_icon {
        height: 40px;
        position: relative;
        .pay {
          width: 12px;
          height: 12px;
          background: linear-gradient(
            180deg,
            rgba(225, 225, 225, 0.1) 0%,
            rgba(225, 225, 225, 0.1) 100%
          );
          border-radius: 50%;
          backdrop-filter: blur(8px);
          position: absolute;
          top: calc(50% - 6px);
          left: calc(50% - 6px);
          .iconpay {
            width: 8px;
            height: 8px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        .name {
          max-width: 452px;
          line-height: 22px;
          @include multiline(1);
        }
        div {
          span {
            line-height: 20px;
            font-size: 12px;
            margin-right: 20px;
            @include useTheme {
              color: getVar("text-color1-8a8b92");
            }
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .selItem {
      @include useTheme {
        background-color: getVar("secondary-bg-color-hover");
      }
      .icon_checks {
        opacity: 1;
      }
    }
    .is-lockfile {
      .info {
        span {
          @include useTheme {
            color: getVar("text-color1-bdbdbe");
          }
        }
        .lock-style {
          display: flex;
          align-items: center;
          span {
            margin-left: 3px;
            @include useTheme {
              color: getVar("text-color1-bdbdbe");
            }
          }
          .svg-icon {
            width: 14px;
          }
        }
      }
    }
  }
  .d-footer {
    padding-top: 16px;
    justify-content: space-between !important;
    margin: 0 24px;
    .left {
      display: flex;
      align-items: center;
      div {
        display: flex;
        align-items: center;
      }
      .svg-icon {
        width: 16px;
        height: 16px;
        margin-right: 12px;
        cursor: pointer;
        @include useTheme {
          color: getVar("text-color1-e5e5e5");
        }
      }
      span {
        @include textUnSelect();
      }
      .total {
        @include useTheme {
          color: getVar("text-color1-8a8b92");
        }
        margin-left: 16px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .disable {
        @include useTheme{
          color:getVar('text-color1-f9d3c3')
        }
        cursor: not-allowed;
        @include textUnSelect;
      }
      .go {
        color: #f67942;
        cursor: pointer;
        @include textUnSelect;
      }
    }
  }
  .file-default {
    height: 340px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    img {
      margin-bottom: 0px;
    }
  }
  .file-directory {
    padding-left: 0;
  }
}
</style>
