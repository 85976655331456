<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/components/DownPage/SuccessBox.vue
 * @Description: 
-->
<template>
  <el-dialog
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="PromoteDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left"></div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <img class="icon" :src="$utils.getPng('web4/icon_success')" />
        <span>{{ successBoxMsg }}</span>
      </div>
      <div class="d-footer">
        <fm-button type="primary" round @click="goCloud">去查看</fm-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { queryParentFolders } from "@/utils/apiList/cloud";
export default {
  props: {
    isShow: Boolean,
    successBoxMsg: String,
  },
  data() {
    return {
      saveFileFolderId: 0,
      saveFileFolderName: "",
    };
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    goCloud() {
      this.$emit("handleClose");

      this.queryParentFolders(this.saveFileFolderId);
    },
    queryParentFolders(id) {
      let params = {
        folder_id: id,
      };
      queryParentFolders(params).then((res) => {
        if (res.status == "1") {
          this.goFolder(res.data.folders, res.data.folder_id);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //跳转飞猫盘
    goFolder(folders, id) {
      if (this.$router.currentRoute.name == "drive") {
        this.$router.push({
          path: this.$store.state.fmPath,
        });
        this.$nextTick(() => {
          this.$store.commit("closePreviewPublicVuex");
          this.$store.commit("getfiles", {
            name: "query",
            value: {
              folder_id: id,
              folders: folders,
            },
          });
        });
      } else {
        const routeUrl = this.$router.resolve({
          name: "drive",
          params: { folder_id: id, folders: folders },
        });
        localStorage.setItem('openFolder',JSON.stringify({ folder_id: id, folders: folders }))
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.PromoteDialog {
  .d-content {
    .icon {
      width: 88px;
      height: 88px;
      margin-bottom: 16px;
    }
  }
  .d-footer {
    .fm-button {
      margin-left: 0;
    }
  }
}
</style>
