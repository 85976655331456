<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/components/VipBenefits/index.vue
 * @Description: 会员套餐组件
-->
<template>
  <div class="privilegeVipBoX">
    <div class="leftOne">
      <div
        class="memOne"
        :class="[`co${item.color}`, `bg${item.bg}`]"
        v-for="item in memberIntr[0]"
        :key="item"
      >
        {{ item.name }}
      </div>
    </div>

    <div
      class="rightItem"
      v-for="(item, index) in memberIntr.slice(1)"
      :class="[
        isActive2 && index == 0 ? 'Clickstyle' : '',
        isActive3 && index == 1 ? 'Clickstyle' : '',
        isActive4 && index == 2 ? 'Clickstyle' : '',
        isActive5 && index == 3 ? 'Clickstyle' : '',
      ]"
      @click="showPayDiaFun(item, index)"
      @mouseenter="mouseenterFn(index)"
      @mouseleave="mouseleaveFn(index)"
      :key="index"
    >
      <div class="box_icon2" :style="$store.state.language == 'en'?'width:100px':''"  v-if="index == 0 || index == 2">
        {{ index == 0 ? $t('vip.limitedSeries') : $t('vip.greatValue') }}
      </div>
      <div class="itemOne" v-for="item in memberIntr[index + 1]" :key="item">
        <div
          :class="['type0', `bg${item.bg}`, index == 3 ? 'border-a' : '']"
          v-if="item.type == 0"
          :style="{
            height: `${item.itemHeight}px`,
            backgroundImage:
              isresetting && index == indexid
                ? $store.state.theme == 'light'
                  ? `url(${$utils.getPng('web4/vipcarbg')}) !important`
                  : `url(${$utils.getPng('web4/vipcarbg_dark')}) !important`
                : '',
          }"
        >
          <div class="memName">{{ item.show_title }}</div>
          <div class="memPriceBox" v-if="!loFlag">
            <div class="dizhi">{{ item.intr_name }}</div>
            <div class="price">
              <div class="yuan">¥</div>
              <div class="nub">{{ item.show_price }}</div>
              <div class="month" v-if="item.isShowMonth">
                {{ item.end_name }}
              </div>
            </div>
          </div>
          <div v-else class="memPriceBox">{{ "--" }}</div>
          <div class="memBtn">{{(upvipinfo?.explain?.info && (item.show_title == '不限时长会员' || item.show_title == 'Unlimited Time VIP')) ? $t('vip.buy1'): $t('vip.buy')}}</div>
          <div class="hbBox" v-if="item.isSupporthb && index !== 3">
            <img class="hbImg" :src="$utils.getPng('icon_pay_3')" alt="" />
            <div class="hbName">支持花呗分期</div>
            <div class="hbmx">免息</div>
          </div>
        </div>
        <div
          :class="['type1', `bg${item.bg}`]"
          v-if="item.type == 1"
          :style="`height:${item.itemHeight}px;align-items: ${
            item.itemHeight == '52' ? 'centet' : 'flex-end'
          };`"
        >
          {{ !loFlag ? item.name : "--" }}
        </div>
        <div
          :class="['type2', `bg${item.bg}`]"
          v-if="item.type == 2"
          :style="`height:${item.itemHeight}px;`"
        >
          <i
            style="font-size: 20px"
            v-if="!item.isShow"
            class="el-icon-check"
          ></i>
        </div>
        <div
          :style="`height:${item.itemHeight}px;`"
          :class="['type3', `bg${item.bg}`]"
          v-if="item.type == 3"
        >
          <img class="icon_hj" :src="$utils.getPng(item.src)" alt="" />
          <div class="type3_1">{{ !loFlag ? item.name : "--" }}</div>
        </div>
        <div
          v-if="item.type == 5"
          :class="['type2', `bg${item.bg}`]"
          style="height: 36px;"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      memberIntr: [
        [
          {
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            color: 1, //color == 0 字体浅色，color == 1 深色
            name: "开通会员",
          },
          {
            bg: 0,
            color: 0,
            name: "下载权益",
          },
          {
            bg: 0,
            color: 1,
            name: "下载额度",
          },
          {
            bg: 1,
            color: 1,
            name: "下载加速",
          },
          {
            bg: 0,
            color: 1,
            name: "云转存*",
          },
          {
            bg: 1,
            color: 0,
            name: "云盘权益",
          },
          {
            bg: 1,
            color: 1,
            name: "云盘空间",
          },
          {
            bg: 0,
            color: 1,
            name: "云解压",
          },
          {
            bg: 1,
            color: 1,
            name: "云看图",
          },
          {
            bg: 0,
            color: 1,
            name: "云阅读",
          },
          {
            bg: 1,
            color: 1,
            name: "回收站有效期",
          },
          {
            bg: 0,
            color: 0,
            name: "影音权益",
          },
          {
            bg: 0,
            color: 1,
            name: "云视频",
          },
          {
            bg: 1,
            color: 1,
            name: "云音乐",
          },
          {
            bg: 0,
            color: 0,
            name: "体验权益",
          },
          {
            bg: 0,
            color: 1,
            name: "会员去广告",
          },
          {
            bg: 1,
            color: 1,
            name: "专属头像框",
          },
        ],
        [
          {
            show_title: "--",
            intr_name: "--",
            isSupporthb: 1,
            isShowMonth: 0, //  == 1 显示月， == 0 不显示月
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            type: 0, //每个标识对应一个css样式（type == 0 顶部会员，type == 1 z展示文字信息，type == 2 展示图片，type == 4 展示图片加文字）
            itemHeight: 170,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            // isShow: true,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },

          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            src: "web4/diamond_icon",
            bg: 1,
            type: 3,
            itemHeight: 52,
          },
        ],
        [
          {
            show_title: "--",
            intr_name: "--",
            isSupporthb: 1,
            isShowMonth: 0, //  == 1 显示月， == 0 不显示月
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            type: 0, //每个标识对应一个css样式（type == 0 顶部会员，type == 1 z展示文字信息，type == 2 展示图片，type == 4 展示图片加文字）
            itemHeight: 170,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            // isShow: true,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },

          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            src: "web4/gold_icon",
            bg: 1,
            type: 3,
            itemHeight: 52,
          },
        ],
        [
          {
            show_title: "--",
            intr_name: "--",
            isSupporthb: 1,
            isShowMonth: 0, //  == 1 显示月， == 0 不显示月
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            type: 0, //每个标识对应一个css样式（type == 0 顶部会员，type == 1 z展示文字信息，type == 2 展示图片，type == 4 展示图片加文字）
            itemHeight: 170,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            // isShow: true,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },

          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            src: "web4/silver_icon",
            bg: 1,
            type: 3,
            itemHeight: 52,
          },
        ],
        [
          {
            show_title: "--",
            intr_name: "--",
            isSupporthb: 1,
            isShowMonth: 0, //  == 1 显示月， == 0 不显示月
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            type: 0, //每个标识对应一个css样式（type == 0 顶部会员，type == 1 z展示文字信息，type == 2 展示图片，type == 4 展示图片加文字）
            itemHeight: 170,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            // isShow: true,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },

          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            src: "web4/bronze_icon",
            bg: 1,
            type: 3,
            itemHeight: 52,
          },
        ],
      ],
      upvipinfo:[],
      loFlag: true,
      isStop: false,
      indexid: 0,
      isActive2: true,
      isActive3: false,
      isActive4: false,
      isActive5: false,
    };
  },
  props: {
    memberInfoList: {
      type: Array,
      // 设置默认值：如果不传值，那么使用default
      default: [
        [
          {
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            color: 1, //color == 0 字体浅色，color == 1 深色
            name: "会员权益",
          },
          {
            bg: 0,
            color: 0,
            name: "下载权益",
          },
          {
            bg: 0,
            color: 1,
            name: "下载额度",
          },
          {
            bg: 1,
            color: 1,
            name: "下载加速",
          },
          {
            bg: 0,
            color: 0,
            name: "云盘权益",
          },
          {
            bg: 0,
            color: 1,
            name: "云盘空间",
          },
          {
            bg: 1,
            color: 1,
            name: "云解压",
          },
          {
            bg: 0,
            color: 1,
            name: "云看图",
          },
          {
            bg: 1,
            color: 1,
            name: "云阅读",
          },
          {
            bg: 0,
            color: 1,
            name: "回收站有效期",
          },
          {
            bg: 1,
            color: 0,
            name: "影音权益",
          },
          {
            bg: 1,
            color: 1,
            name: "云视频",
          },
          {
            bg: 0,
            color: 1,
            name: "云音乐",
          },
          {
            bg: 1,
            color: 0,
            name: "体验权益",
          },
          {
            bg: 1,
            color: 1,
            name: "会员去广告",
          },
          {
            bg: 0,
            color: 1,
            name: "专属头像框",
          },
        ],
        [
          {
            show_title: "--",
            intr_name: "--",
            isSupporthb: 1,
            isShowMonth: 0, //  == 1 显示月， == 0 不显示月
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            type: 0, //每个标识对应一个css样式（type == 0 顶部会员，type == 1 z展示文字信息，type == 2 展示图片，type == 4 展示图片加文字）
            itemHeight: 170,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            // isShow: true,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },

          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            src: "web4/diamond_icon",
            bg: 0,
            type: 3,
            itemHeight: 52,
          },
        ],
        [
          {
            show_title: "--",
            intr_name: "--",
            isSupporthb: 1,
            isShowMonth: 0, //  == 1 显示月， == 0 不显示月
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            type: 0, //每个标识对应一个css样式（type == 0 顶部会员，type == 1 z展示文字信息，type == 2 展示图片，type == 4 展示图片加文字）
            itemHeight: 170,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            // isShow: true,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },

          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            src: "web4/gold_icon",
            bg: 0,
            type: 3,
            itemHeight: 52,
          },
        ],
        [
          {
            show_title: "--",
            intr_name: "--",
            isSupporthb: 1,
            isShowMonth: 0, //  == 1 显示月， == 0 不显示月
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            type: 0, //每个标识对应一个css样式（type == 0 顶部会员，type == 1 z展示文字信息，type == 2 展示图片，type == 4 展示图片加文字）
            itemHeight: 170,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            // isShow: true,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },

          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            src: "web4/silver_icon",
            bg: 0,
            type: 3,
            itemHeight: 52,
          },
        ],
        [
          {
            show_title: "--",
            intr_name: "--",
            isSupporthb: 1,
            isShowMonth: 0, //  == 1 显示月， == 0 不显示月
            bg: 1, //bg == 0 背景浅色，bg == 1 深色
            type: 0, //每个标识对应一个css样式（type == 0 顶部会员，type == 1 z展示文字信息，type == 2 展示图片，type == 4 展示图片加文字）
            itemHeight: 170,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            // isShow: true,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 1,
            itemHeight: 52,
          },

          {
            name: "--",
            bg: 0,
            type: 1,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 5,
            itemHeight: 52,
          },
          {
            name: "--",
            bg: 1,
            type: 2,
            itemHeight: 52,
          },
          {
            name: "--",
            src: "web4/bronze_icon",
            bg: 0,
            type: 3,
            itemHeight: 52,
          },
        ],
      ],
    },
  },
  computed: {
    isresetting() {
      return (
        this.isActive2 || this.isActive3 || this.isActive4 || this.isActive5
      );
    },
  },
  mounted() {},

  methods: {
    mouseleaveFn: _.debounce(
      function (index) {
        if (!this.isStop) {
          this.closePopup(index);
          this.isActive2 = true;
        }
      },
      5,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    mouseenterFn: _.debounce(
      function (index) {
        if (this.isStop) return;
        this.closePopup(index);
        this.indexid = index;

        switch (index) {
          case 0:
            this.isActive2 = true;
            break;
          case 1:
            this.isActive3 = true;
            break;
          case 2:
            this.isActive4 = true;
            break;
          case 3:
            this.isActive5 = true;
            break;

          default:
            break;
        }
      },
      5,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    closePopup(index) {
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isStop = false;
      this.indexid = 0;

      setTimeout(() => {
        if (!this.isresetting) {
          this.isActive2 = true;
        }
      }, 500);
    },
    showPayDiaFun(item, index) {
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      if (item[0].vip_id) {
        this.$parent.showPayDiaFun(item, index);
        this.isStop = true;
      }
    },
    goLogin() {
      let path = this.$route.fullPath.toString().slice(1);
      this.$router.push({ path: "/login", query: { path: path } });
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
