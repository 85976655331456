<template>
  <!-- 修改昵称 -->
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="realNameDialog"
    :append-to-body="true"
    class="changeName"
    top="0vh"
  >
    <div class="diaBox1">
      <div class="dialogTop">
        <div class="left">
          <div class="tit1">修改昵称</div>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="realNameDialog = false"
        ></fm-button>
      </div>
      <div class="dialogCenter">
        <el-input placeholder="请输入昵称" v-model="nameValue" clearable />
        <div class="bottom">
          <fm-button class="btn" @click="relnameConfirm" type="primary" size="small"
            >确 认</fm-button
          >
          <fm-button
            @click="realNameDialog = false"
            type="info"
            plain
            size="small"
            >取 消</fm-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { saveUserInfo } from "@/utils/apiList/account";
export default {
  data() {
    return {
      realNameDialog: false,
      nameValue: "",
    };
  },
  methods: {
    relnameConfirm() {
      if (!this.nameValue) {
        this.$toast("昵称不能为空，长度20字以内", 2);
        return;
      }

      saveUserInfo({
        field: "nickname",
        value: this.nameValue,
      }).then((res) => {
        if (res.status == 1) {
          this.$toast(res.msg, 1);
          this.realNameDialog = false;
          this.$parent.getUserInfo();
          // this.$store.commit("getuserVuex");
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.changeName {
  ::v-deep .el-dialog {
    width: 480px;
    height: auto;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    @include useTheme {
      background: getVar("text-color3-ffffff");
      color: getVar("text-color1-2c2c2c");
    }
    border-radius: 16px;
    .el-dialog__body {
      padding: 0;
    }
  }

  .diaBox1 {
    .dialogTop {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 20px 24px 10px 24px;
      .left {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .tit1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        @include useTheme {
          color: getVar("text-color1-2c2c2c");
        }
      }
    }
    .dialogCenter {
      padding:  24px;
      .bottom {
        width: 100%;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn {
         margin-right: 12px;
        }
        
      }
    }
  }
}
</style>
