<template>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="0vh"
      custom-class="pointDialog"
      :visible.sync="isShow"
      :append-to-body="true"
      width="788px">
        <div class="dialog-body">
            <div class="box">
                <div class="bTop">
                    <div class="tit">福利点明细</div>
                    <fm-button type="info" size="mini" text icon="icon-model-close" @click="handleClose"></fm-button>
                </div>
              <div style="padding: 0 2px 0 24px">
                <div
                    v-infinite-scroll="listScroll"
                    v-loading="loading"
                    :infinite-scroll-disabled="disabled"
                    infinite-scroll-distance="50"
                    class="dialist"
                >
                  <div v-if="infoDetail.length > 0">
                    <div class="diaItem" v-for="item in infoDetail" :key="item.point">
                      <div class="itemName">
                        <div class="itemName1">{{ item.title }}</div>
                        <div class="itemName2">{{ item.intime }}</div>
                      </div>
                      <div class="itemNum" :class="parseInt(item.point) > 0 ? '' : 'fu'">
                        {{ parseInt(item.point) > 0 ? "+" + item.point : item.point }}福利点
                      </div>
                    </div>
                  </div>
                  <div class="emp" v-else>
                    <img :src="$utils.getPng('web4/jx_default')" class="empImg" alt="" />
                    <div class="empTit">暂无福利点明细</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { welfareRecord } from '@/utils/apiList/welfare'
export default {
    data(){
        return{
            loading:false,
          page: 1,
          limit: 50,
          infoDetail:[],
          noMore: false
        }
    },
    props:{
        isShow:Boolean
    },
  computed: {
    disabled () {
      return this.loading || this.noMore
    }
  },
    methods:{
        async listScroll(){
          this.loading = true
          try {
            let params = {
              page: this.page,
              limit: this.limit
            }
            let res = await welfareRecord(params)
            if (res.status == '1') {
              this.page++
              this.infoDetail = this.infoDetail.concat(res.data.list)
              this.noMore = this.infoDetail.length >= res.data.total
            }
          } catch (e) {}
          this.loading = false
        },
      handleClose() {
        this.$emit("handleClose");
      },
    }
}
</script>

<style lang="scss">
.pointDialog.el-dialog .el-dialog__body {
  padding: 20px 0 24px;
}
    .pointDialog{
        .bTop{
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: inset 0px -1px 0px 0px #E5E5E5;
            padding-bottom: 16px;
            .tit{
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
            .fm-button{
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }
        .dialist{
            display: flex;
            flex-direction: column;
            height: 468px;
            overflow: auto;
            overflow-x: hidden;
            .diaItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 22px 16px 0;
                box-sizing: border-box;
              box-shadow: inset 0px -1px 0px 0px #E5E5E5;
                .itemName {
                    display: flex;
                    flex-direction: column;
                    .itemName1 {
                        font-size: 14px;
                        font-weight: 400;
                      line-height: 22px;
                    }
                    .itemName2 {
                        font-size: 14px;
                        font-weight: 400;
                      @include useTheme{
                        color: getVar("text-color1-8a8b92");
                      }
                        line-height: 22px;
                        margin-top: 2px;
                    }
                }
                .itemNum {
                    font-size: 14px;
                    font-weight: 400;
                    color: #F67942;
                    line-height: 14px;
                }
                .fu {
                  @include useTheme{
                    color: getVar("text-color1-8a8b92");
                  }
                }
            }
        }
        .emp{
            height: 480px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            .empImg {
                width: 100px;
            }
            .empTit {
                font-size: 16px;
                font-weight: 400;
              @include useTheme {
                color: getVar("text-color1-8a8b92");
              }
              line-height: 24px;
                margin-top: 8px;
            }
        }
    }

.body-theme[data-theme=dark] {
  .pointDialog .bTop {
    box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
  }
  .dialist .diaItem {
    box-shadow: inset 0px -1px 0px 0px rgba(255,255,255,0.2);
  }
}
</style>
