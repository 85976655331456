<template>
  <el-dialog
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="collectDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left"></div>
        <fm-button
        @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content">
        <img :src="$utils.getPng('web4/icon_success')" />
        <span>{{ content }}</span>
      </div>
      <div class="d-footer">
        <fm-button type="primary" round @click="handleClose">知道了</fm-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShow: Boolean,
    content: {
      type: String,
      default: '收藏成功 请前往收藏-收藏合集查看'
    }
  },
  methods:{
    handleClose(){
      this.$emit('handleClose')
    }
  }
};
</script>

<style></style>
