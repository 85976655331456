<template>
  <el-dialog :visible.sync="isShow" width="480px" custom-class="shareDialog" :append-to-body="true"
    :close-on-click-modal="false">
    <div class="dialog-body">
      <div class="d-header">
        <div class="left"><span>分享多个文件</span></div>
        <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
      </div>
      <div class="d-content">
        <div class="warn-box">
          <svg-btn icon-class="icon-tips-circle-fill" />
          <span>严禁上传或分享反动、暴力、色情、违法及侵权内容的文件。</span>
        </div>
        <div class="linkContent">
          <!-- <div class="linkbox scrollDialog">
            <div class="item" v-for="item in linkList" :key="item">
              {{ item }}
            </div> 
          </div> -->
          <el-input ref="inputText" type="textarea" v-model="linkStr" :autosize="{
            minRows: 2, maxRows: 11
          }" @mousedown.native="handleMouseDown" />
        </div>

      </div>
      <div class="d-footer">
        <fm-button type="primary" round @click="toCopyLink"> 复制链接 </fm-button>
        <fm-button @click="handleClose" type="info" plain round> 确 定 </fm-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      isShow: false,
      linkList: [],
      linkStr: ''
    };
  },
  created() {

  },
  methods: {
    toCopyLink() {
      this.$copyText(this.linkStr).then(
        () => {
          this.$toast("复制成功", 1);
        },
        () => {
          this.$toast("复制失败", 2);
        }
      );
      this.$refs['inputText'].select()
    },
    handleClose() {
      this.isShow = false
    },
    handleMouseDown(event) {
    const textarea = this.$refs.inputText.$el.querySelector('textarea');
    const rect = textarea.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;
    const clickPos = this.getPositionFromCoordinates(textarea, clickX, clickY);
    textarea.focus();
    textarea.setSelectionRange(clickPos, clickPos);
  },
  getPositionFromCoordinates(textarea, x, y) {
    const text = textarea.value;
    const lines = text.split('\n');
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
    const charWidth = parseInt(window.getComputedStyle(textarea).fontSize);
    const line = Math.floor(y / lineHeight);
    const column = Math.floor(x / charWidth);
    
    let position = 0;
    for (let i = 0; i < line && i < lines.length; i++) {
      position += lines[i].length + 1; // +1 for the newline
    }
    position += Math.min(column, lines[line]?.length ?? 0);
    return Math.max(position, 0);
  },
  },
};
</script>

<style lang="scss">
.linkContent {
  width: 432px;
  border-radius: 8px;
  margin: 16px auto;

  // @include useTheme {
  //    border: getVar('border-color-e5e5e5');
  // }

  .linkbox {
    width: 429px;
    height: 200px;
    border-radius: 8px;
    padding: 10px 12px;
    overflow-y: scroll;
    margin-right: 12px;
    font-size: 12px;

    .item {
      line-height: 20px;
    }

    .item:nth-child(2n) {
      margin-bottom: 10px;
    }
  }


}
</style>
<style lang="scss">
.linkContent {
  .el-textarea .el-textarea__inner {
    background: #fff !important;
    max-height: calc(100vh - 300px) !important;
    padding-top: 10px;

    @include useTheme {
      border: getVar('border-color-e5e5e5');
    }

    resize: none;
  }

  .el-textarea .el-textarea__inner:focus {
    border: 1px solid #f67942 !important;
  }


}
</style>
