<template>
  <div class="hover-pop">
    <div
        @mouseenter="onMouseenterDiv"
        @mouseleave="onMouseleaveDiv"
    >
      <slot></slot>
    </div>
    <transition name="fade-scale">
      <div
          v-show="visible"
          class="hover-pop-box"
          @mouseenter="onMouseenter"
          @mouseleave="onMouseleave"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      visible: false
    }
  },
  methods: {
    onMouseenterDiv() {
      this.clearTimer()
      this.visible = true
    },
    onMouseleaveDiv() {
      this.timer = setTimeout(() => {
        this.visible = false
        this.timer = null
      }, 100)
    },
    onMouseenter() {
      this.clearTimer()
      this.visible = true
    },
    onMouseleave() {
      this.timer = setTimeout(() => {
        this.visible = false
        this.timer = null
      }, 100)
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hover-pop {
  position: relative;
}
.hover-pop-box {
  position: absolute;
  top: 62px;
  right: 0;
  z-index: 1;
}
</style>
