<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/components/Dialog/WatchMovieDia.vue
 * @Description: 观看视频得福利点的弹窗
-->

<template>
    <div class="EquipmentDialog">
        <el-dialog :visible.sync="watchMovieFlag" :before-close="handleClose" :close-on-click-modal="false"
            :append-to-body="true">
            <div class="diaBox">
                <div class="dialogTop">
                    <div class="left">
                        <img class="icon" :src="$utils.getPng('web4/icon_i')" alt="" srcset="" />
                        <div class="tit2"> 看视频得福利点</div>
                    </div>
                    <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
                </div>
                <div class="dialogCenter">
                    <div class="imgBox">
                        <img class="code" :src="$utils.getPng('downapp')" />
                    </div>
                    <div class="tit">扫码下载APP</div>
                    <div class="tit2">请前往 <span style="color: #F67942;">飞猫盘</span> APP-福利社看视频得福利点</div>
                </div>

            </div>
        </el-dialog>

    </div>
</template>

<script>

export default {
    data() {
        return {
            watchMovieFlag: false,
        };
    },
    created() {

    },
    methods: {
        handleClose() {
            this.watchMovieFlag = false;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
    width: 480px;
    min-height: 208px;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
        0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    border-radius: 16px;

    .el-dialog__body {
        padding: 0;
    }
}

.diaBox {
    .dialogTop {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 20px 24px 0 24px;

        .left {
            display: flex;
            align-items: center;
        }

        .icon {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 8px;
        }

        .tit2 {
            height: 24px;
            font-size: 16px;
            font-weight: 500;

            @include useTheme {
                color: getVar("text-color2-2c2c2c");
            }

            line-height: 24px;
        }
    }

    .dialogCenter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 38px;

        .imgBox {
            width: 120px;
            height: 120px;

            @include useTheme {
                background: getVar("text-color1-ffffff");
                border: getVar('border-color-e5e5e5');
            }

            box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.08);
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: center;

            .code {
                width: 110px;
                height: 110px;
                // border-radius: 4px;
            }

        }

        .tit {
            width: 219px;
            height: 20px;
            font-weight: 400;
            font-size: 12px;

            @include useTheme {
                color: getVar("text-color2-8a8b92");
            }

            line-height: 20px;
            text-align: center;
            margin-top: 12px;
        }

        .tit2 {
            height: 24px;
            font-weight: 400;
            font-size: 16px;

            @include useTheme {
                color: getVar("text-color2-2c2c2c");
            }

            line-height: 24px;
            margin-bottom: 32px;
            margin-top: 24px;
            text-align: center;

        }

    }
}
</style>
