<template>
  <div class="fmCloud" @mousemove.prevent="selectItems">
    <!-- v-infinite-scroll="getfilesList" -->

    <div
      class="file-content"
      ref="scrollBox"
      infinite-scroll-distance="50"
      infinite-scroll-immediate="false"
      @mousedown="handleMouseDown($event, -1)"
      @mouseup.prevent="endSelection"
    >
      <div class="tab-th">
        <div class="selectAll">
          <div @click="allCheck == 2 ? cancelCheckedVal() : setCheckedVal()">
            <svg-btn
              :icon-class="
                allCheck == 2
                  ? 'icon-file-check'
                  : allCheck == 0
                    ? 'icon-file-uncheck-' + $store.state.theme
                    : 'icon-file-checks'
              "
            >
            </svg-btn>
          </div>
          <span>{{ $t('cloud.fileName') }}</span>
        </div>
        <div
          class="pointer"
          :class="['lineup2', { pointer: screenId == 0 }]"
          @click="changeSort(screenId == 2 ? 3 : 2)"
          style="width: 343px"
        >
          <span>{{ $t('cloud.size') }}</span>
          <img
            v-show="screenId == 2 || screenId == 3"
            :src="
              screenId == 2
                ? $utils.getPng('web4/icon_order_descending')
                : $utils.getPng('web4/icon_order_ascending')
            "
          />
        </div>
        <div
          class="pointer"
          :class="['lineup3', { pointer: screenId == 1 }]"
          @click="changeSort(screenId == 0 ? 1 : 0)"
        >
          <span>{{ $t('cloud.creatTime') }}</span>
          <img
            v-show="screenId == 0 || screenId == 1"
            :src="
              screenId == 0
                ? $utils.getPng('web4/icon_order_descending')
                : $utils.getPng('web4/icon_order_ascending')
            "
          />
        </div>
      </div>
      <div
        class="file-list"
        v-loading="skeletonLoading"
      >
        <div
          class="file-item-hover"
          v-for="(item, index) in filesList"
          :key="index"
          @mousedown="handleMouseDown($event, index)"
          @mouseenter="handleMouseenter($event, index)"
          :class="[
            { hoverItem: item.isHover || checkedFiles.indexOf(index) > -1 },
          ]"
        >
          <a-dropdown :trigger="dropdown" @visibleChange="visibleChange">
            <div
              class="file-item"
              :class="{ lockeditem: item.is_locked == '1' }"
            >
              <div class="lineup1">
                <svg-btn
                  class="icon_checks"
                  @click.prevent="chooseFile(index)"
                  :icon-class="
                    checkedFiles.indexOf(index) > -1
                      ? 'icon-file-check'
                      : 'icon-file-uncheck-' + $store.state.theme
                  "
                ></svg-btn>
                <div class="openFoldername">
                  <div class="file_icon">
                    <img
                      class="icon"
                      @error="onError($event, item)"
                      :src="$utils.fileIcon(item.extension)"
                    />
                  </div>
                  <el-tooltip
                    open-delay="1000"
                    popper-class="item list-tooltip"
                    effect="dark"
                    :content="`${item.name}${item.extension ? item.extension : ''}`"
                    placement="bottom"
                  >
                    <span class="name"
                      >{{ item.name }}{{ item.extension }}</span
                    >
                  </el-tooltip>
                </div>
                <div class="locked-info" v-show="item.is_locked == '1'">
                  <svg-btn icon-class="icon-tips-warning" />
                  <span>已锁定 </span>
                </div>
              </div>
              <div class="tools-box">
                <div class="itemFilesTools" v-show="checkedFiles.length < 2">
                  <template>
                    <div class="act" @click.stop="rightHandle(0)">
                      <el-tooltip
                        open-delay="1000"
                        popper-class="item list-tooltip"
                        effect="dark"
                        content="下载"
                        placement="bottom"
                      >
                        <svg-btn
                          icon-class="icon-tool-download"
                          iconColor="iconColor2"
                        ></svg-btn>
                      </el-tooltip>
                    </div>

                    <div class="act" @click.stop="rightHandle(1)">
                      <el-tooltip
                        open-delay="1000"
                        popper-class="item list-tooltip"
                        effect="dark"
                        content="转存至云盘"
                        placement="bottom"
                      >
                        <svg-btn
                          icon-class="icon-restore"
                          iconColor="iconColor2"
                        ></svg-btn>
                      </el-tooltip>
                    </div>
                  </template>
                </div>
              </div>
              <div class="lineup2" style="width: 343px">
                {{ item.size }}
              </div>
              <div class="lineup3">
                {{ item.intime }}
              </div>
            </div>
            <template #overlay>
              <a-menu class="menu-a" :class="isOpen2 ? 'menu-b' : ''">
                <a-menu-item
                  @click="rightHandle(menu_item.id, item)"
                  v-for="menu_item in isSelectMore ? list4 : list1"
                  :key="menu_item.id"
                  :class="menu_item.id == -1 ? 'line' : 'me-item'"
                  >{{ $t(menu_item.name) }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <div
          v-show="showFlag == 1"
          class="selection-box"
          :style="{
            left: selectionBox.left + 'px',
            top: selectionBox.top + 'px',
            width: selectionBox.width + 'px',
            height: selectionBox.height + 'px',
          }"
        ></div>

        <div class="isOver" v-if="isOver">{{ $t(cloud.noMore) }}</div>
      </div>
      <!-- <div class="file-default" v-if="!loading && filesList.length == 0">
        <img :src="$utils.getPng('web4/file_default')" />
        <div class="search" v-if="listParams.keywords == ''">
          <span>暂无分享文件</span><span>分享后可以在这里查看</span>
        </div>
        <div class="search" v-else>
          <span>暂无相关文件</span><span>试试搜索其他关键词</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      screenId: 0,
      filesList: [], //分享列表
      allCheck: 0,
      checkedFiles: [], //已选的分享数据
      total: 0,
      loading: true,
      list: {
        //查询分享列表参数
        size_sort: "",
        sort: 1, //1最新0最早
        limit: 60,
        keywords: "",
        page: 0,
        type: 0, //类型0所有，1文件，2合集
      },
      handleItem: {}, //当前操作项
      isOver: false,

      startX: 0,
      startY: 0,
      selectionBox: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      moveBox: {
        left: 0,
        top: 0,
      },
      isLoading2: true,
      list1: [
        { id: 0, name: "downPage.download" },
        { id: -1, name: "" },
        { id: 1, name: "downPage.saveFile" },
      ],
      list4: [
        { id: 3, name: "移动到" },
        { id: -1, name: "" },
        { id: 5, name: "删除" },
      ],
      showFlag: 0,
      isSelecting: 0,
      isOpen: false,
      isOpen2: true,
      isSelectMore: false,
      order: false,
      dropdown: ["contextmenu"],
    };
  },
  props: {
    tableList: {
      type: Array,
      default: () => [],
    },
    skeletonLoading: {
      type: Boolean,
      default: false,
    },
  },

  created() {},
  mounted() {
    this.$store.commit("setAttr", {
      name: "endSelectFuncallback",
      val: this.endSelectFun,
    });
  },
  watch: {
    checkedFiles: {
      handler(newval, oldVal) {
        if (newval.length > 0 && newval.length < this.filesList.length) {
          this.allCheck = 1;
        } else if (newval.length == 0) {
          this.allCheck = 0;
        } else if (newval.length == this.filesList.length) {
          this.allCheck = 2;
        }
      },
      immediate: true,
    },
  },
  methods: {
    setNav(item) {
      this.$router.push({
        path: item.url,
      });
    },

    //排序
    changeSort(id) {
      this.screenId = id;
      switch (id) {
        case 0:
          this.listParams.sort = 1;
          this.listParams.size_sort = "";
          break;
        case 1:
          this.listParams.sort = 0;
          this.listParams.size_sort = "";
          break;
        case 2:
          this.listParams.size_sort = 1;
          this.listParams.sort = "";
          break;
        case 3:
          this.listParams.size_sort = 0;
          this.listParams.sort = "";
          break;
        default:
          break;
      }
      this.listParams.page = 0;
      // this.getfilesList(1);
    },
    //下拉框出现/隐藏时触发
    changeVisible(e, item) {
      item.isHover = e;
    },
    // 单个点击选中
    chooseShare(index) {
      // 点击文件选中
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles.push(index);
      } else {
        this.checkedFiles.splice(exist, 1);
      }
    },
    // 全选
    setCheckedVal() {
      // 点击文件选中
      this.checkedFiles = [];
      var tmpfiles = this.filesList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        this.checkedFiles.push(i);
      }
      this.allCheck = 1;
    },
    // 取消全选
    cancelCheckedVal() {
      this.allCheck = 0;
      this.checkedFiles = [];
    },

    handleMouseDown: _.debounce(
      function (event, index) {
        if (index == -1) {
          // this.checkedFiles = [];
        }
        this.selectionBox = {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        };

        this.startX = event.clientX;
        this.startY = event.clientY;

        const currentX = event.clientX;
        const currentY = event.clientY;
        this.moveBox = {
          left: 0,
          top: 0,
        };
        // 检查触发事件的元素是否是 file-item-hover 的父元素
        if (event.button == 0) {
          let arr = ["icon_checks", "iconColor"];
          if (!arr.includes(event.target.classList[0])) {
            //点击icon_checks 即左侧选中多选不触发handleMouseDown 只触发chooseFile
            // 执行你的 mousedown 逻辑
            this.selectItemOne(index);
            this.moveBox = {
              left: currentX,
              top: currentY - 56,
            };
          }
        } else if (event.button == 1) {
        } else if (event.button == 2) {
          // this.checkedFiles = [];
          if (index == -1) return;
          var exist = this.checkedFiles.indexOf(index);
          if (exist == -1) {
            this.checkedFiles = [];
            this.checkedFiles.push(index);
          } else {
            // this.checkedFiles.splice(exist, 1);
          }
          if (this.checkedFiles.length > 1) {
            this.isSelectMore = true;
          } else {
            this.isSelectMore = false;
          }
        }
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    handleMouseenter(event, index) {},
    chooseFile(index) {
      // 点击文件选中
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles.push(index);
      } else {
        this.checkedFiles.splice(exist, 1);
      }
    },

    selectItemOne(index) {
      // 点击文件选中
      if (index == -1) {
        this.isSelecting = 1;
        return;
      }
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles = [];
        this.checkedFiles.push(index);
        this.isSelecting = 1;
      } else {
        this.isSelecting = 2;
        // this.checkedFiles.splice(exist, 1);
      }
    },
    selectItems: _.debounce(
      function (event) {
        const currentX = event.clientX;
        const currentY = event.clientY;

        // 计算选择框的位置和大小
        const minX = Math.min(this.startX, currentX);
        const minY = Math.min(this.startY, currentY);
        const maxX = Math.max(this.startX, currentX);
        const maxY = Math.max(this.startY, currentY);
        this.selectionBox = {
          left: minX,
          top: minY - 56,
          width: maxX - minX,
          height: maxY - minY,
        };
        if (this.isSelecting == 2) {
          this.moveBox = {
            left: currentX,
            top: currentY - 56,
          };
          let file_arr = [];
          let folder_arr = [];
          let chooseOne = this.filesList[this.checkedFiles[0]];

          let str = `${chooseOne.name}${chooseOne.extension || ""}`;

          let obj = {
            icon:
              chooseOne.is_file == "0"
                ? chooseOne.ext_icon
                : chooseOne.showPlay
                  ? chooseOne.ext_icon
                  : this.$utils.fileIcon(chooseOne.extension),
            name: str,
            count: this.checkedFiles.length,
          };
          // this.checkStr = str;
          this.$store.commit("setAttr", {
            name: "check_str",
            val: obj,
          });

          this.checkedFiles.map((item) => {
            if (this.filesList[item].is_file == "1") {
              file_arr.push(this.filesList[item].id);
            } else {
              folder_arr.push(this.filesList[item].id);
            }
          });
          this.trage_ids = file_arr.toString();
          this.trage_move_ids = folder_arr.toString();
          this.showFlag = 2;
          this.$store.commit("setAttr", {
            name: "showFlag",
            val: 2,
          });
        } else if (this.isSelecting == 1) {
          // 遍历可选择项，判断是否在选择框内并选中
          this.filesList.forEach((item, index) => {
            const itemElement = document.querySelector(
              `.file-item-hover:nth-child(${this.filesList.indexOf(item) + 1})`
            );
            const itemRect = itemElement.getBoundingClientRect();
            if (itemRect.top > minY - 56 && itemRect.bottom < maxY + 69) {
              if (!this.checkedFiles.includes(index)) {
                this.checkedFiles.push(index);
              }
              this.showFlag = 1;
              this.$store.commit("setAttr", {
                name: "showFlag",
                val: 1,
              });
            } else {
              const index = this.checkedFiles.indexOf(index);
              if (index !== -1) {
                this.checkedFiles.splice(index, 1);
              }
            }
          });
        }
      },
      0,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    endSelection() {
      this.showFlag = 0;
    },
    endSelectFun() {
      // this.isSelecting = 0;
      setTimeout(() => {
        this.isSelecting = 0;
        this.checkStr = "";
        this.showFlag = 0;
        this.$store.commit("setAttr", {
          name: "showFlag",
          val: 0,
        });
        this.$store.commit("setAttr", {
          name: "check_str",
          val: "",
        });
      }, 0);
    },
    rightHandle(id, item) {
      switch (id) {
        case 0:
          this.$emit("openMemPopUp", 1);
          break;
        case 1:
          this.$emit("openMemPopUp", 0);
          break;
        case 2:
          break;
        case 3:
          break;
        case 4:
          break;
        case 5:
          break;
        case 6:
          break;
        default:
          break;
      }
    },
    visibleChange(e) {
      if (e) {
        this.dropdown = ["contextmenu", "click"];
        this.isOpen = e;
        this.isOpen2 = true;
      } else {
        this.isOpen2 = false;
        this.dropdown = ["contextmenu"];
      }
    },
    onError(e, item) {
      this.$set(item, "showPlay", false);
    },
    goDownload($event, item) {
      this.$emit("goDownload", $event, item);
    },
    getTableList(list) {
      list.forEach((item, index) => {
        item.isHover = false;
        item.itemloading = false;
        item.showPlay = true;
      });

      this.filesList = list;
      this.setCheckedVal();
      this.dropdown = ["contextmenu"];
    },
  },
  computed: {
    pageValue() {
      return this.listParams.page;
    },
  },
};
</script>

<style lang="scss" scoped>
.fmCloud {
  height: auto;
}
.file-content {
  // background: skyblue;
  margin-top: 0;
  height: 430px;
  overflow: auto;
  // padding: 0;
}
</style>
