import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data){
    if (data?.ak){
        let plaintext = utils.rd(data.ak, unescape(sfile));
        let obj = utils.secret(data.ed, plaintext, true);
        return JSON.parse(obj);
    }else{
        return data
    }
}

function inParameter(data){
    if (data){
        return { sn: ed, jt: utils.secret(data, ak)}
    }else{
        return data
    }
}

export function getQuestionsDetail(data) {
    return request({
        url: "/user-service/helpCenter/productOrderDetail",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

export function getFeedbackList(data) {
    return request({
        url: "/user-service/helpCenter/getProductionHis",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
export function reply(data) {
    return request({
        url: "/user-service/helpCenter/reply",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
