import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data) {
  if (data?.ak) {
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  } else {
    return data;
  }
}

function inParameter(data) {
  if (data) {
    return { sn: ed, jt: utils.secret(data, ak) };
  } else {
    return data;
  }
}

//热门问题/二级分类下问题
export function getQuestions(data) {
  return request({
    url: "/user-service/helpCenter/getQuestions",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//获取工单分类列表
export function getCspCategory(data) {
  return request({
    url: "/user-service/helpCenter/getCspCategory",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//查看问题详情
export function getQuestionsDetail(data) {
  return request({
    url: "/user-service/helpCenter/getQuestionsDetail",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//提交工单
export function commitProductionOrder(data) {
  return request({
    url: "/user-service/helpCenter/commitProductionOrder",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//更换手机号 确认订单
export function alterPhoneAddAPI(data) {
  return request({
    url: "/user-service/helpCenter/alterPhoneAdd",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}
//更换手机号 填写资料
export function alterPhoneAddInfoAPI(data) {
  return request({
    url: "/user-service/helpCenter/alterPhoneAddInfo",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//注销短信验证码
export function userDelSendCode(data) {
  return request({
    url: "/user-service/user/userDelSendCode",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//用户注销申请
export function accountDelReply(data) {
  return request({
    url: "/user-service/user/accountDelReply",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//申请详情
export function accountDelDetail(data) {
  return request({
    url: "/user-service/user/accountDelDetail",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//撤销申请
export function accountDelCancle(data) {
  return request({
    url: "/user-service/user/accountDelCancle",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//反馈历史未读红点数
export function getProductionNordNumAPI(data) {
  return request({
    url: "/user-service/helpCenter/getProductionNordNum",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

export function phoneLoginCodeAPI(data) {
  return request({
    url: "/user-service/passport/phoneLoginCode",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//获取国家区号getPhonePcode(更换手机号使用)
export function getPhonePcodeAPI(data) {
  return request({
    url: "/user-service/common/getPhonePcode",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}
//获取注销理由
export function accountDelReasonAPI(data) {
  return request({
    url: "/user-service/user/accountDelReason",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

