<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="isShowModel"
    :append-to-body="true"
    class="cancelCollect"
    top="0vh"
  >
    <div class="diaBox1">
      <div class="dialogTop">
        <div class="left">
          <div class="tit1">提示</div>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="isShowModel = false"
        ></fm-button>
      </div>
      <div class="dialogCenter">
        <div class="dialog-tips">
          <img :src="$utils.getPng('toast_icon2')" />
          <span class="ext">确定取消收藏吗？</span>
        </div>
        <div class="bottom">
          <fm-button
            class="btn"
            round
            type="primary"
            size="medium"
            @click="confirm"
            >确 定</fm-button
          >
          <fm-button
            class="btn btn1"
            round
            type="info"
            size="medium"
            @click="isShowModel = false"
            >取 消</fm-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShowModel: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    confirm() {
      this.$emit("likeChange");
    },
  },
};
</script>

<style lang="scss" scoped>
.cancelCollect {
  ::v-deep .el-dialog {
    width: 480px;
    height: auto;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    @include useTheme {
      background: getVar("text-color3-ffffff");
      color: getVar("text-color1-2c2c2c");
    }
    border-radius: 16px;
    .el-dialog__body {
      padding: 0;
    }
  }

  .diaBox1 {
    .dialogTop {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 20px 24px 10px 24px;
      .left {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .tit1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        @include useTheme {
          color: getVar("text-color1-2c2c2c");
        }
      }
    }
    .dialogCenter {
      padding: 20px 24px;
      .dialog-tips {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          font-size: 18px;
          font-weight: 500;
          line-height: 18px;
          margin: 35px 0;
        }
      }

      .bottom {
        width: 100%;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          width: 110px;
          height: 36px;
          margin: 0 8px;
        }
        .btn1 {
          background: rgba(246, 121, 66, 0.1);
          color: #f67942;
          &:hover {
            background: #f67942;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
