function stringTonum(a) {
  var str = a.toLowerCase().split("");
  var num = 0;
  var al = str.length;
  var getCharNumber = function (charx) {
    return charx.charCodeAt() - 96;
  };
  var numout = 0;
  var charnum = 0;
  for (var i = 0; i < al; i++) {
    charnum = getCharNumber(str[i]);
    numout += charnum * Math.pow(26, al - i - 1);
  }
  return numout;
}
var matchers = function() {
  var CSS_INTEGER = "[-\\+]?\\d+%?";
  var CSS_NUMBER = "[-\\+]?\\d*\\.\\d+%?";
  var CSS_UNIT = "(?:" + CSS_NUMBER + ")|(?:" + CSS_INTEGER + ")";
  var PERMISSIVE_MATCH3 = "[\\s|\\(]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")\\s*\\)?";
  var PERMISSIVE_MATCH4 = "[\\s|\\(]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")[,|\\s]+(" + CSS_UNIT + ")\\s*\\)?";
  return {
    CSS_UNIT: new RegExp(CSS_UNIT),
    rgb: new RegExp("rgb" + PERMISSIVE_MATCH3),
    rgba: new RegExp("rgba" + PERMISSIVE_MATCH4),
    hsl: new RegExp("hsl" + PERMISSIVE_MATCH3),
    hsla: new RegExp("hsla" + PERMISSIVE_MATCH4),
    hsv: new RegExp("hsv" + PERMISSIVE_MATCH3),
    hsva: new RegExp("hsva" + PERMISSIVE_MATCH4),
    hex3: /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
    hex6: /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
    hex4: /^#?([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/,
    hex8: /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/
  };
}();
var dayjs_minExports = {};
var dayjs_min = {
  get exports() {
    return dayjs_minExports;
  },
  set exports(v) {
    dayjs_minExports = v;
  }
};
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
(function(module, exports) {
  !function(t2, e) {
    module.exports = e();
  }(commonjsGlobal, function() {
    var t2 = 1e3, e = 6e4, n = 36e5, r = "millisecond", i = "second", s = "minute", u = "hour", a = "day", o = "week", f = "month", h3 = "quarter", c = "year", d = "date", l = "Invalid Date", $ = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, y = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, M = {
      name: "en",
      weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
      months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
      ordinal: function ordinal(t3) {
        var e2 = ["th", "st", "nd", "rd"], n2 = t3 % 100;
        return "[" + t3 + (e2[(n2 - 20) % 10] || e2[n2] || e2[0]) + "]";
      }
    }, m = function m2(t3, e2, n2) {
      var r2 = String(t3);
      return !r2 || r2.length >= e2 ? t3 : "" + Array(e2 + 1 - r2.length).join(n2) + t3;
    }, v = {
      s: m,
      z: function z(t3) {
        var e2 = -t3.utcOffset(), n2 = Math.abs(e2), r2 = Math.floor(n2 / 60), i2 = n2 % 60;
        return (e2 <= 0 ? "+" : "-") + m(r2, 2, "0") + ":" + m(i2, 2, "0");
      },
      m: function t3(e2, n2) {
        if (e2.date() < n2.date())
          return -t3(n2, e2);
        var r2 = 12 * (n2.year() - e2.year()) + (n2.month() - e2.month()), i2 = e2.clone().add(r2, f), s2 = n2 - i2 < 0, u2 = e2.clone().add(r2 + (s2 ? -1 : 1), f);
        return +(-(r2 + (n2 - i2) / (s2 ? i2 - u2 : u2 - i2)) || 0);
      },
      a: function a2(t3) {
        return t3 < 0 ? Math.ceil(t3) || 0 : Math.floor(t3);
      },
      p: function p2(t3) {
        return {
          M: f,
          y: c,
          w: o,
          d: a,
          D: d,
          h: u,
          m: s,
          s: i,
          ms: r,
          Q: h3
        }[t3] || String(t3 || "").toLowerCase().replace(/s$/, "");
      },
      u: function u2(t3) {
        return void 0 === t3;
      }
    }, g = "en", D = {};
    D[g] = M;
    var p = function p2(t3) {
      return t3 instanceof _;
    }, S = function t3(e2, n2, r2) {
      var i2;
      if (!e2)
        return g;
      if ("string" == typeof e2) {
        var s2 = e2.toLowerCase();
        D[s2] && (i2 = s2), n2 && (D[s2] = n2, i2 = s2);
        var u2 = e2.split("-");
        if (!i2 && u2.length > 1)
          return t3(u2[0]);
      } else {
        var a2 = e2.name;
        D[a2] = e2, i2 = a2;
      }
      return !r2 && i2 && (g = i2), i2 || !r2 && g;
    }, w = function w2(t3, e2) {
      if (p(t3))
        return t3.clone();
      var n2 = "object" == typeof e2 ? e2 : {};
      return n2.date = t3, n2.args = arguments, new _(n2);
    }, O = v;
    O.l = S, O.i = p, O.w = function(t3, e2) {
      return w(t3, {
        locale: e2.$L,
        utc: e2.$u,
        x: e2.$x,
        $offset: e2.$offset
      });
    };
    var _ = function() {
      function M2(t3) {
        this.$L = S(t3.locale, null, true), this.parse(t3);
      }
      var m2 = M2.prototype;
      return m2.parse = function(t3) {
        this.$d = function(t4) {
          var e2 = t4.date, n2 = t4.utc;
          if (null === e2)
            return /* @__PURE__ */ new Date(NaN);
          if (O.u(e2))
            return /* @__PURE__ */ new Date();
          if (e2 instanceof Date)
            return new Date(e2);
          if ("string" == typeof e2 && !/Z$/i.test(e2)) {
            var r2 = e2.match($);
            if (r2) {
              var i2 = r2[2] - 1 || 0, s2 = (r2[7] || "0").substring(0, 3);
              return n2 ? new Date(Date.UTC(r2[1], i2, r2[3] || 1, r2[4] || 0, r2[5] || 0, r2[6] || 0, s2)) : new Date(r2[1], i2, r2[3] || 1, r2[4] || 0, r2[5] || 0, r2[6] || 0, s2);
            }
          }
          return new Date(e2);
        }(t3), this.$x = t3.x || {}, this.init();
      }, m2.init = function() {
        var t3 = this.$d;
        this.$y = t3.getFullYear(), this.$M = t3.getMonth(), this.$D = t3.getDate(), this.$W = t3.getDay(), this.$H = t3.getHours(), this.$m = t3.getMinutes(), this.$s = t3.getSeconds(), this.$ms = t3.getMilliseconds();
      }, m2.$utils = function() {
        return O;
      }, m2.isValid = function() {
        return !(this.$d.toString() === l);
      }, m2.isSame = function(t3, e2) {
        var n2 = w(t3);
        return this.startOf(e2) <= n2 && n2 <= this.endOf(e2);
      }, m2.isAfter = function(t3, e2) {
        return w(t3) < this.startOf(e2);
      }, m2.isBefore = function(t3, e2) {
        return this.endOf(e2) < w(t3);
      }, m2.$g = function(t3, e2, n2) {
        return O.u(t3) ? this[e2] : this.set(n2, t3);
      }, m2.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, m2.valueOf = function() {
        return this.$d.getTime();
      }, m2.startOf = function(t3, e2) {
        var n2 = this, r2 = !!O.u(e2) || e2, h4 = O.p(t3), l2 = function l3(t4, e3) {
          var i2 = O.w(n2.$u ? Date.UTC(n2.$y, e3, t4) : new Date(n2.$y, e3, t4), n2);
          return r2 ? i2 : i2.endOf(a);
        }, $2 = function $3(t4, e3) {
          return O.w(n2.toDate()[t4].apply(n2.toDate("s"), (r2 ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(e3)), n2);
        }, y2 = this.$W, M3 = this.$M, m3 = this.$D, v2 = "set" + (this.$u ? "UTC" : "");
        switch (h4) {
          case c:
            return r2 ? l2(1, 0) : l2(31, 11);
          case f:
            return r2 ? l2(1, M3) : l2(0, M3 + 1);
          case o:
            var g2 = this.$locale().weekStart || 0, D2 = (y2 < g2 ? y2 + 7 : y2) - g2;
            return l2(r2 ? m3 - D2 : m3 + (6 - D2), M3);
          case a:
          case d:
            return $2(v2 + "Hours", 0);
          case u:
            return $2(v2 + "Minutes", 1);
          case s:
            return $2(v2 + "Seconds", 2);
          case i:
            return $2(v2 + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, m2.endOf = function(t3) {
        return this.startOf(t3, false);
      }, m2.$set = function(t3, e2) {
        var n2, o2 = O.p(t3), h4 = "set" + (this.$u ? "UTC" : ""), l2 = (n2 = {}, n2[a] = h4 + "Date", n2[d] = h4 + "Date", n2[f] = h4 + "Month", n2[c] = h4 + "FullYear", n2[u] = h4 + "Hours", n2[s] = h4 + "Minutes", n2[i] = h4 + "Seconds", n2[r] = h4 + "Milliseconds", n2)[o2], $2 = o2 === a ? this.$D + (e2 - this.$W) : e2;
        if (o2 === f || o2 === c) {
          var y2 = this.clone().set(d, 1);
          y2.$d[l2]($2), y2.init(), this.$d = y2.set(d, Math.min(this.$D, y2.daysInMonth())).$d;
        } else
          l2 && this.$d[l2]($2);
        return this.init(), this;
      }, m2.set = function(t3, e2) {
        return this.clone().$set(t3, e2);
      }, m2.get = function(t3) {
        return this[O.p(t3)]();
      }, m2.add = function(r2, h4) {
        var d2, l2 = this;
        r2 = Number(r2);
        var $2 = O.p(h4), y2 = function y3(t3) {
          var e2 = w(l2);
          return O.w(e2.date(e2.date() + Math.round(t3 * r2)), l2);
        };
        if ($2 === f)
          return this.set(f, this.$M + r2);
        if ($2 === c)
          return this.set(c, this.$y + r2);
        if ($2 === a)
          return y2(1);
        if ($2 === o)
          return y2(7);
        var M3 = (d2 = {}, d2[s] = e, d2[u] = n, d2[i] = t2, d2)[$2] || 1, m3 = this.$d.getTime() + r2 * M3;
        return O.w(m3, this);
      }, m2.subtract = function(t3, e2) {
        return this.add(-1 * t3, e2);
      }, m2.format = function(t3) {
        var e2 = this, n2 = this.$locale();
        if (!this.isValid())
          return n2.invalidDate || l;
        var r2 = t3 || "YYYY-MM-DDTHH:mm:ssZ", i2 = O.z(this), s2 = this.$H, u2 = this.$m, a2 = this.$M, o2 = n2.weekdays, f2 = n2.months, h4 = function h5(t4, n3, i3, s3) {
          return t4 && (t4[n3] || t4(e2, r2)) || i3[n3].slice(0, s3);
        }, c2 = function c3(t4) {
          return O.s(s2 % 12 || 12, t4, "0");
        }, d2 = n2.meridiem || function(t4, e3, n3) {
          var r3 = t4 < 12 ? "AM" : "PM";
          return n3 ? r3.toLowerCase() : r3;
        }, $2 = {
          YY: String(this.$y).slice(-2),
          YYYY: this.$y,
          M: a2 + 1,
          MM: O.s(a2 + 1, 2, "0"),
          MMM: h4(n2.monthsShort, a2, f2, 3),
          MMMM: h4(f2, a2),
          D: this.$D,
          DD: O.s(this.$D, 2, "0"),
          d: String(this.$W),
          dd: h4(n2.weekdaysMin, this.$W, o2, 2),
          ddd: h4(n2.weekdaysShort, this.$W, o2, 3),
          dddd: o2[this.$W],
          H: String(s2),
          HH: O.s(s2, 2, "0"),
          h: c2(1),
          hh: c2(2),
          a: d2(s2, u2, true),
          A: d2(s2, u2, false),
          m: String(u2),
          mm: O.s(u2, 2, "0"),
          s: String(this.$s),
          ss: O.s(this.$s, 2, "0"),
          SSS: O.s(this.$ms, 3, "0"),
          Z: i2
        };
        return r2.replace(y, function(t4, e3) {
          return e3 || $2[t4] || i2.replace(":", "");
        });
      }, m2.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, m2.diff = function(r2, d2, l2) {
        var $2, y2 = O.p(d2), M3 = w(r2), m3 = (M3.utcOffset() - this.utcOffset()) * e, v2 = this - M3, g2 = O.m(this, M3);
        return g2 = ($2 = {}, $2[c] = g2 / 12, $2[f] = g2, $2[h3] = g2 / 3, $2[o] = (v2 - m3) / 6048e5, $2[a] = (v2 - m3) / 864e5, $2[u] = v2 / n, $2[s] = v2 / e, $2[i] = v2 / t2, $2)[y2] || v2, l2 ? g2 : O.a(g2);
      }, m2.daysInMonth = function() {
        return this.endOf(f).$D;
      }, m2.$locale = function() {
        return D[this.$L];
      }, m2.locale = function(t3, e2) {
        if (!t3)
          return this.$L;
        var n2 = this.clone(), r2 = S(t3, e2, true);
        return r2 && (n2.$L = r2), n2;
      }, m2.clone = function() {
        return O.w(this.$d, this);
      }, m2.toDate = function() {
        return new Date(this.valueOf());
      }, m2.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, m2.toISOString = function() {
        return this.$d.toISOString();
      }, m2.toString = function() {
        return this.$d.toUTCString();
      }, M2;
    }(), T = _.prototype;
    return w.prototype = T, [["$ms", r], ["$s", i], ["$m", s], ["$H", u], ["$W", a], ["$M", f], ["$y", c], ["$D", d]].forEach(function(t3) {
      T[t3[1]] = function(e2) {
        return this.$g(e2, t3[0], t3[1]);
      };
    }), w.extend = function(t3, e2) {
      return t3.$i || (t3(e2, _, w), t3.$i = true), w;
    }, w.locale = S, w.isDayjs = p, w.unix = function(t3) {
      return w(1e3 * t3);
    }, w.en = D[g], w.Ls = D, w.p = {}, w;
  });
})(dayjs_min);
const dayjs = dayjs_minExports;
function ptToPx(pt) {
  let dpi = 96
  return pt * dpi / 72
}
var indexedColor = ["#000000", "#FFFFFF", "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", "#000000", "#FFFFFF", "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", "#800000", "#008000", "#000080", "#808000", "#800080", "#008080", "#C0C0C0", "#808080", "#9999FF", "#993366", "#FFFFCC", "#CCFFFF", "#660066", "#FF8080", "#0066CC", "#CCCCFF", "#000080", "#FF00FF", "#FFFF00", "#00FFFF", "#800080", "#800000", "#008080", "#0000FF", "#00CCFF", "#CCFFFF", "#CCFFCC", "#FFFF99", "#99CCFF", "#FF99CC", "#CC99FF", "#FFCC99", "#3366FF", "#33CCCC", "#99CC00", "#FFCC00", "#FF9900", "#FF6600", "#666699", "#969696", "#003366", "#339966", "#003300", "#333300", "#993300", "#993366", "#333399", "#333333", "#000000"];
var themeColor = ["#FFFFFF", "#000000", "#BFBFBF", "#323232", "#4472C4", "#ED7D31", "#A5A5A5", "#FFC000", "#5B9BD5", "#71AD47", "#0070c0"];
var names = {
  aliceblue: "f0f8ff",
  antiquewhite: "faebd7",
  aqua: "0ff",
  aquamarine: "7fffd4",
  azure: "f0ffff",
  beige: "f5f5dc",
  bisque: "ffe4c4",
  black: "000",
  blanchedalmond: "ffebcd",
  blue: "00f",
  blueviolet: "8a2be2",
  brown: "a52a2a",
  burlywood: "deb887",
  burntsienna: "ea7e5d",
  cadetblue: "5f9ea0",
  chartreuse: "7fff00",
  chocolate: "d2691e",
  coral: "ff7f50",
  cornflowerblue: "6495ed",
  cornsilk: "fff8dc",
  crimson: "dc143c",
  cyan: "0ff",
  darkblue: "00008b",
  darkcyan: "008b8b",
  darkgoldenrod: "b8860b",
  darkgray: "a9a9a9",
  darkgreen: "006400",
  darkgrey: "a9a9a9",
  darkkhaki: "bdb76b",
  darkmagenta: "8b008b",
  darkolivegreen: "556b2f",
  darkorange: "ff8c00",
  darkorchid: "9932cc",
  darkred: "8b0000",
  darksalmon: "e9967a",
  darkseagreen: "8fbc8f",
  darkslateblue: "483d8b",
  darkslategray: "2f4f4f",
  darkslategrey: "2f4f4f",
  darkturquoise: "00ced1",
  darkviolet: "9400d3",
  deeppink: "ff1493",
  deepskyblue: "00bfff",
  dimgray: "696969",
  dimgrey: "696969",
  dodgerblue: "1e90ff",
  firebrick: "b22222",
  floralwhite: "fffaf0",
  forestgreen: "228b22",
  fuchsia: "f0f",
  gainsboro: "dcdcdc",
  ghostwhite: "f8f8ff",
  gold: "ffd700",
  goldenrod: "daa520",
  gray: "808080",
  green: "008000",
  greenyellow: "adff2f",
  grey: "808080",
  honeydew: "f0fff0",
  hotpink: "ff69b4",
  indianred: "cd5c5c",
  indigo: "4b0082",
  ivory: "fffff0",
  khaki: "f0e68c",
  lavender: "e6e6fa",
  lavenderblush: "fff0f5",
  lawngreen: "7cfc00",
  lemonchiffon: "fffacd",
  lightblue: "add8e6",
  lightcoral: "f08080",
  lightcyan: "e0ffff",
  lightgoldenrodyellow: "fafad2",
  lightgray: "d3d3d3",
  lightgreen: "90ee90",
  lightgrey: "d3d3d3",
  lightpink: "ffb6c1",
  lightsalmon: "ffa07a",
  lightseagreen: "20b2aa",
  lightskyblue: "87cefa",
  lightslategray: "789",
  lightslategrey: "789",
  lightsteelblue: "b0c4de",
  lightyellow: "ffffe0",
  lime: "0f0",
  limegreen: "32cd32",
  linen: "faf0e6",
  magenta: "f0f",
  maroon: "800000",
  mediumaquamarine: "66cdaa",
  mediumblue: "0000cd",
  mediumorchid: "ba55d3",
  mediumpurple: "9370db",
  mediumseagreen: "3cb371",
  mediumslateblue: "7b68ee",
  mediumspringgreen: "00fa9a",
  mediumturquoise: "48d1cc",
  mediumvioletred: "c71585",
  midnightblue: "191970",
  mintcream: "f5fffa",
  mistyrose: "ffe4e1",
  moccasin: "ffe4b5",
  navajowhite: "ffdead",
  navy: "000080",
  oldlace: "fdf5e6",
  olive: "808000",
  olivedrab: "6b8e23",
  orange: "ffa500",
  orangered: "ff4500",
  orchid: "da70d6",
  palegoldenrod: "eee8aa",
  palegreen: "98fb98",
  paleturquoise: "afeeee",
  palevioletred: "db7093",
  papayawhip: "ffefd5",
  peachpuff: "ffdab9",
  peru: "cd853f",
  pink: "ffc0cb",
  plum: "dda0dd",
  powderblue: "b0e0e6",
  purple: "800080",
  rebeccapurple: "663399",
  red: "f00",
  rosybrown: "bc8f8f",
  royalblue: "4169e1",
  saddlebrown: "8b4513",
  salmon: "fa8072",
  sandybrown: "f4a460",
  seagreen: "2e8b57",
  seashell: "fff5ee",
  sienna: "a0522d",
  silver: "c0c0c0",
  skyblue: "87ceeb",
  slateblue: "6a5acd",
  slategray: "708090",
  slategrey: "708090",
  snow: "fffafa",
  springgreen: "00ff7f",
  steelblue: "4682b4",
  tan: "d2b48c",
  teal: "008080",
  thistle: "d8bfd8",
  tomato: "ff6347",
  turquoise: "40e0d0",
  violet: "ee82ee",
  wheat: "f5deb3",
  white: "fff",
  whitesmoke: "f5f5f5",
  yellow: "ff0",
  yellowgreen: "9acd32"
};
var trimLeft = /^\s+/;
var trimRight = /\s+$/;
function parseIntFromHex(val) {
  return parseInt(val, 16);
}
function convertHexToDecimal(h3) {
  return parseIntFromHex(h3) / 255;
}
function stringInputToObject(color) {
  color = color.replace(trimLeft, "").replace(trimRight, "").toLowerCase();
  var named = false;
  if (names[color]) {
    color = names[color];
    named = true;
  } else if (color == "transparent") {
    return {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
      format: "name"
    };
  }
  var match;
  if (match = matchers.rgb.exec(color)) {
    return {
      r: match[1],
      g: match[2],
      b: match[3]
    };
  }
  if (match = matchers.rgba.exec(color)) {
    return {
      r: match[1],
      g: match[2],
      b: match[3],
      a: match[4]
    };
  }
  if (match = matchers.hsl.exec(color)) {
    return {
      h: match[1],
      s: match[2],
      l: match[3]
    };
  }
  if (match = matchers.hsla.exec(color)) {
    return {
      h: match[1],
      s: match[2],
      l: match[3],
      a: match[4]
    };
  }
  if (match = matchers.hsv.exec(color)) {
    return {
      h: match[1],
      s: match[2],
      v: match[3]
    };
  }
  if (match = matchers.hsva.exec(color)) {
    return {
      h: match[1],
      s: match[2],
      v: match[3],
      a: match[4]
    };
  }
  if (match = matchers.hex8.exec(color)) {
    return {
      r: parseIntFromHex(match[1]),
      g: parseIntFromHex(match[2]),
      b: parseIntFromHex(match[3]),
      a: convertHexToDecimal(match[4]),
      format: named ? "name" : "hex8"
    };
  }
  if (match = matchers.hex6.exec(color)) {
    return {
      r: parseIntFromHex(match[1]),
      g: parseIntFromHex(match[2]),
      b: parseIntFromHex(match[3]),
      format: named ? "name" : "hex"
    };
  }
  if (match = matchers.hex4.exec(color)) {
    return {
      r: parseIntFromHex(match[1] + "" + match[1]),
      g: parseIntFromHex(match[2] + "" + match[2]),
      b: parseIntFromHex(match[3] + "" + match[3]),
      a: convertHexToDecimal(match[4] + "" + match[4]),
      format: named ? "name" : "hex8"
    };
  }
  if (match = matchers.hex3.exec(color)) {
    return {
      r: parseIntFromHex(match[1] + "" + match[1]),
      g: parseIntFromHex(match[2] + "" + match[2]),
      b: parseIntFromHex(match[3] + "" + match[3]),
      format: named ? "name" : "hex"
    };
  }
  return false;
}
function boundAlpha(a) {
  a = parseFloat(a);
  if (isNaN(a) || a < 0 || a > 1) {
    a = 1;
  }
  return a;
}
function inputToRGB(color) {
  let rgb = {
    r: 0,
    g: 0,
    b: 0
  };
  let a = 1;
  let format = false;
  if (typeof color == "string") {
    rgb = stringInputToObject(color);
  }
  a = boundAlpha(a);
  return {
    format: rgb.format || format,
    r: Math.min(255, Math.max(rgb.r, 0)),
    g: Math.min(255, Math.max(rgb.g, 0)),
    b: Math.min(255, Math.max(rgb.b, 0)),
    a
  };
}
function pad2(c) {
  return c.length == 1 ? "0" + c : "" + c;
}
function rgbToHex(r, g, b, allow3Char) {
  var hex = [pad2(Math.round(r).toString(16)), pad2(Math.round(g).toString(16)), pad2(Math.round(b).toString(16))];
  if (allow3Char && hex[0].charAt(0) == hex[0].charAt(1) && hex[1].charAt(0) == hex[1].charAt(1) && hex[2].charAt(0) == hex[2].charAt(1)) {
    return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0);
  }
  return hex.join("");
}
function transferArgbColor(originColor) {
  if (typeof originColor === "object") {
    return "#000000";
  }
  if (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(originColor)) {
    return originColor.startsWith("#") ? originColor : "#" + originColor;
  }
  originColor = originColor.trim().toLowerCase();
  var color = {};
  try {
    var argb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(originColor);
    color.r = parseInt(argb[2], 16);
    color.g = parseInt(argb[3], 16);
    color.b = parseInt(argb[4], 16);
    color.a = parseInt(argb[1], 16) / 255;

    let rgb = inputToRGB("rgba(".concat(color.r, ", ").concat(color.g, ", ").concat(color.b, ", ").concat(color.a, ")"));
    if (rgb.r < 1)
      rgb.r = Math.round(rgb.r);
    if (rgb.g < 1)
      rgb.g = Math.round(rgb.g);
    if (rgb.b < 1)
      rgb.b = Math.round(rgb.b);

    return '#' + rgbToHex(rgb.r, rgb.g, rgb.b);
  } catch (e) {
    console.warn(e);
  }
}
function HexToRgb(str) {
  str = str.replace("#", "");
  var hxs = str.match(/../g);
  for (var i = 0; i < 3; i++)
    hxs[i] = parseInt(hxs[i], 16);
  return hxs;
}
function RgbToHex(a, b, c) {
  var hexs = [a.toString(16), b.toString(16), c.toString(16)];
  for (var i = 0; i < 3; i++)
    if (hexs[i].length == 1)
      hexs[i] = "0" + hexs[i];
  return "#" + hexs.join("");
}
function getDarkColor(color, level) {
  var rgbc = HexToRgb(color);
  for (var i = 0; i < 3; i++)
    rgbc[i] = Math.floor(rgbc[i] * (1 - level));
  return RgbToHex(rgbc[0], rgbc[1], rgbc[2]);
}
function getLightColor(color, level) {
  var rgbc = HexToRgb(color);
  for (var i = 0; i < 3; i++)
    rgbc[i] = Math.floor((255 - rgbc[i]) * level + rgbc[i]);
  return RgbToHex(rgbc[0], rgbc[1], rgbc[2]);
}
function transferThemeColor(themeIndex, tint) {
  if (themeIndex >= themeColor.length) {
    return "#2c2c2c";
  }
  if (typeof tint === "undefined") {
    return themeColor[themeIndex];
  } else if (tint > 0) {
    return getLightColor(themeColor[themeIndex], tint);
  } else {
    return getDarkColor(themeColor[themeIndex], Math.abs(tint));
  }
}

function getStyle(cell) {
  let result = {}
  var backGroundColor = null;
  if (cell.style.fill) {
    if (cell.style.fill.fgColor) {
      if (cell.style.fill.fgColor.argb) {
        backGroundColor = transferArgbColor(cell.style.fill.fgColor.argb);
      } else if (cell.style.fill.fgColor.theme || cell.style.fill.fgColor.theme === 0) {
        backGroundColor = transferThemeColor(cell.style.fill.fgColor.theme, cell.style.fill.fgColor.tint);
      } else if (cell.style.fill.fgColor.indexed || cell.style.fill.fgColor.indexed === 0) {
        backGroundColor = indexedColor[cell.style.fill.fgColor.indexed] || "#C7C9CC";
      }
    }
    if (!backGroundColor && cell.style.fill.bgColor) {
      if (cell.style.fill.bgColor.indexed || cell.style.fill.bgColor.indexed === 0) {
        backGroundColor = indexedColor[cell.style.fill.fgColor.indexed] || "#C7C9CC";
      } else {
        backGroundColor = "#C7C9CC";
      }
    }
  }
  if (backGroundColor) {
    result.bgcolor = backGroundColor
  }
  var fontColor = null;
  if (cell.style.font && cell.style.font.color) {
    if (cell.style.font.color.argb) {
      fontColor = transferArgbColor(cell.style.font.color.argb);
    } else if (cell.style.font.color.theme || cell.style.font.color.theme === 0) {
      fontColor = transferThemeColor(cell.style.font.color.theme, cell.style.font.color.tint);
    } else if (cell.style.font.color.indexed || cell.style.font.color.indexed === 0) {
      fontColor = indexedColor[cell.style.font.color.indexed] || "#000000";
    }
  }
  if (fontColor && fontColor !== '#000000') {
    result.color = fontColor
  }
  if (cell.style.alignment) {
    if (cell.style.alignment.horizontal) {
      result.al_h = cell.style.alignment.horizontal
    }
    if (cell.style.alignment.vertical) {
      result.al_v = cell.style.alignment.vertical
    }
    if (cell.style.alignment.wrapText) {
      result.textwrap = true
    }
  }
  if (cell.style.strike) {
    result.strike = true
  }
  if (cell.style.underline) {
    result.underline = true
  }
  if (cell.style.font) {
    let font = cell.style.font
    result.size = Math.floor(ptToPx(font.size))
    result.font = "".concat(font.italic ? "italic" : "", " ").concat(font.bold ? "bold" : "", " ").concat('' + result.size, "px ").concat(font.name)
  }
  if (cell.style.border) {
    var styleBorder = {};
    Object.keys(cell.style.border).forEach(function(position) {
      var originBorder = cell.style.border[position];
      var bordColor = "#000000";
      if (typeof originBorder.color === "string") {
        bordColor = originBorder.color;
      } else if (originBorder.color) {
        if (originBorder.color.argb) {
          bordColor = transferArgbColor(originBorder.color.argb);
        } else if (originBorder.color.theme || originBorder.color.theme === 0) {
          bordColor = transferThemeColor(originBorder.color.theme, originBorder.color.tint);
        } else if (originBorder.color.indexed || originBorder.color.indexed === 0) {
          bordColor = indexedColor[originBorder.color.indexed];
        }
      }
      styleBorder[position] = [originBorder.style || "thin", bordColor];
    });
    if (Object.keys(styleBorder).length > 0) {
      result.border = styleBorder
    }
  }
  return result;
}

function getCellText(cell) {
  var numFmt = cell.numFmt, value = cell.value, type = cell.type;
  switch (type) {
    case 2:
      try {
        if (cell.style.numFmt) {
          if (cell.style.numFmt.endsWith("%")) {
            var precision = cell.style.numFmt.match(/\.(\d+)%/);
            if (precision) {
              return (value * 100).toFixed(precision[1].length) + "%";
            } else {
              return value * 100 + "%";
            }
          } else if (/0(\.0+)?/.test(cell.style.numFmt)) {
            if (value === 0 && cell.style.numFmt.startsWith("_")) {
              return "-";
            }
            var _precision = cell.style.numFmt.match(/0\.(0+)(_|;|$)/);
            if (_precision) {
              _precision = _precision[1].length;
            } else {
              _precision = 0;
            }
            var result = value.toFixed(_precision) + "";
            if (cell.style.numFmt.includes("#,##")) {
              result = result.split(".");
              var number = result[0].split("").reverse();
              var newNumber = [];
              for (var i = 0; i < number.length; i++) {
                newNumber.push(number[i]);
                if ((i + 1) % 3 === 0 && i < number.length - 1 && number[i + 1] !== "-") {
                  newNumber.push(",");
                }
              }
              result[0] = newNumber.reverse().join("");
              result = result.join(".");
            }
            return result;
          }
        }
        return value + "";
      } catch (e) {
        return value;
      }
    case 3:
      return value;
    case 4:
      switch (numFmt) {
        case "yyyy-mm-dd;@":
          return dayjs(value).format("YYYY-MM-DD");
        case "mm-dd-yy":
          return dayjs(value).format("YYYY/MM/DD");
        case "[$-F800]dddd, mmmm dd, yyyy":
          return dayjs(value).format("YYYY年M月D日 ddd");
        case 'm"月"d"日";@':
          return dayjs(value).format("M月D日");
        case "yyyy/m/d h:mm;@":
        case 'm/d/yy "h":mm':
          return dayjs(value).subtract(8, "hour").format("YYYY/M/DD HH:mm");
        case "h:mm;@":
          return dayjs(value).format("HH:mm");
        default:
          return dayjs(value).format("YYYY-MM-DD");
      }
    case 5:
      return value.text;
    case 6:
      return value.result;
    case 8:
      return cell.text;
    default:
      return value;
  }
}

function decode64(text) {
  return new TextDecoder('gbk').decode(Uint8Array.from(text, (c) => c.charCodeAt(0)))
}

function getCellStyle2(Cellxf, Borders, Fills, Fonts) {
  let result = {}
  if (Cellxf.alignment) {
    if (Cellxf.alignment.horizontal) {
      result.al_h = Cellxf.alignment.horizontal
    }
    if (Cellxf.alignment.vertical) {
      let vertical = Cellxf.alignment.vertical
      if (vertical === 'center') {
        vertical = 'middle'
      }
      result.al_v = vertical
    }
    if (Cellxf.alignment.wrapText) {
      result.textwrap = true
    }
  }
  if (Cellxf.fontid) {
    let _font = Fonts[Cellxf.fontid]
    result.size = Math.floor(ptToPx(_font.sz))
    result.font = "".concat(_font.italic ? "italic" : "", " ").concat(_font.bold ? "bold" : "", " ").concat('' + result.size, "px ").concat(_font.name)
    if (_font.underline) {
      result.underline = true
    }
    if (_font.color && _font.color.rgb) {
      result.color = '#' + _font.color.rgb
    }
  }
  if (Cellxf.fillid) {
    let _fill = Fills[Cellxf.fillid]
    if (_fill.fgColor && _fill.fgColor.rgb) {
      result.bgcolor = '#' + _fill.fgColor.rgb
    }
  }
  return result
}

function setArray(a, r, c, v) {
  if (!a[r]) {
    a[r] = new Array()
  }
  if (!a[r][c]) {
    a[r][c] = v
  }
}

let urlMap = []

export function formatXlsx(_src, _ext) {
  return new Promise((resolve, reject) => {
    let sheets = []
    if (_ext !== 'xlsx') {
      fetch(_src)
        .then((response) => {
          if (response.ok) {
            return response.arrayBuffer()
          } else {
            let msg = response.statusText
            if (response.status === 404) {
              msg = '文件不存在'
            }
            return Promise.reject(msg)
          }
        })
        .then(async (response) => {
          let workbook = XLSX.read(response, { cellStyles: true })
          let stylesMap = workbook.Styles || {}
          let Cellxf = stylesMap.CellXf || []
          let Borders = stylesMap.Borders || []
          let Fills = stylesMap.Fills || []
          let Fonts = stylesMap.Fonts || []
          let count = 1
          for (let sheetName in workbook.Sheets) {
            let data = workbook.Sheets[sheetName]
            let arr = new Array()
            let _encode = 'gbk'
            let merges = {}
            let gridColWidth = new Array()
            let gridRowHeight = new Array()
            let styles = new Array()
            let rowCount = 50
            let columnCount = 30
            if (data['!merges']) {
              for (let i = 0; i < data['!merges'].length; i++) {
                let val = data['!merges'][i]
                let prop = val.e.r + ':' + val.e.c
                merges[prop] = {
                  r: val.s.r,
                  c: val.s.c,
                  er: val.e.r,
                  ec: val.e.c,
                }
              }
            }
            if (data['!ref']) {
              let range = XLSX.utils.decode_range(data['!ref']);
              rowCount = range.e.r - range.s.r + 1
              columnCount = range.e.c - range.s.c + 1
            }
            if (data['!cols']) {
              for (let i = 0; i < columnCount + 1; i++) {
                let _val = data['!cols'][i] || {}
                gridColWidth[i] = Math.ceil(_val.width * 8) || 0
              }
            }
            if (data['!rows']) {
              for (let i = 0; i < rowCount + 1; i++) {
                let _val = data['!rows'][i] || {}
                gridRowHeight[i] = Math.ceil(ptToPx(_val.hpt)) || 0
              }
            }
            for (let dataKey in data) {
              if (dataKey[0] !== '!') {
                let num = dataKey.replace(/^\D+/, '')
                let abc = dataKey.replace(num, '')
                let index = stringTonum(abc) - 1
                num = Number(num) - 1

                if (!arr[num]) {
                  arr[num] = new Array()
                }

                let row = arr[num]
                let val = data[dataKey] || {}

                if (Cellxf.length > 0 && val._cxfi) {
                  let _cell_cx = Cellxf[val._cxfi]
                  if (_cell_cx) {
                    let _cell_style = getCellStyle2(_cell_cx, Borders, Fills, Fonts)
                    if (_cell_style) {
                      if (!styles[num]) {
                        styles[num] = new Array()
                      }
                      styles[num][index] = _cell_style
                    }
                  }
                }

                let _vl = val.v
                if (_vl || _vl === 0) {
                  if (_ext === 'csv' && typeof _vl !== 'number') {
                    if (_encode === 'gbk') {
                      try {
                        btoa(val.v)
                        _vl = decode64(_vl)
                      } catch (e) {
                        _encode = ''
                      }
                    }
                  }

                  row[index] = _vl
                }
              }
            }
            let aSheet = {
              id: count,
              name: sheetName,
              media: [],
              merges,
              rowCount,
              columnCount,
              data: arr,
              styles,
              gridColWidth,
              gridRowHeight
            }
            sheets.push(aSheet)
            count++
          }
          resolve({
            sheets,
            workData: {
              media: [],
              views: []
            }
          })
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    } else {
      fetch(_src,)
        .then((response) => {
          if (response.ok) {
            return response.arrayBuffer()
          } else {
            let msg = response.statusText
            if (response.status === 404) {
              msg = '文件不存在'
            }
            return Promise.reject(msg)
          }
        })
        .then(async (response) => {
          let workbook = new ExcelJS.Workbook()
          await workbook.xlsx.load(response)
          workbook.eachSheet((worksheet, sheetId) => {
            let _model = worksheet.model
            let merges = {}
            if (_model.merges && _model.merges.length > 0) {
              for (let i = 0; i < _model.merges.length; i++) {
                let _val = _model.merges[i]
                let v_split = _val.split(':')
                let _rc = {}
                for (let j = 0; j < v_split.length; j++) {
                  let _vl = v_split[j]
                  let num = _vl.replace(/^\D+/, '')
                  let abc = _vl.replace(num, '')
                  let index = stringTonum(abc) - 1
                  num = Number(num) - 1

                  let __p1 = j === 0 ? 'r' : 'er'
                  let __p2 = j === 0 ? 'c' : 'ec'

                  _rc[__p1] = num
                  _rc[__p2] = index
                }
                merges[(_rc.er+':'+_rc.ec)] = _rc
              }
            }
            let aSheet = {
              id: sheetId,
              name: worksheet.name,
              merges,
              media: [],
              rowCount: worksheet.rowCount,
              columnCount: worksheet.columnCount,
              data: [],
              styles: [],
              gridColWidth: [],
              gridRowHeight: [],
              properties: worksheet.properties
            }
            let sheet_data = new Array()
            let styles_data = new Array()
            let gridColWidth = []
            let gridRowHeight = []

            worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
              let _row_ = rowNumber - 1
              sheet_data[_row_] = new Array()
              styles_data[_row_] = new Array()
              row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                let _col_ = colNumber - 1
                if (_row_ === 0) {
                  gridColWidth.push(Math.ceil(cell._column.width * 8) || 0)
                }
                if (_col_ === 0) {
                  gridRowHeight.push(Math.ceil(ptToPx(row.height)) || 0)
                }

                let ___vl = getCellText(cell)
                let _obj = null
                if (___vl && typeof ___vl === 'object') {
                  ___vl = ___vl.text || ___vl.result
                }

                let __row = cell.row - 1
                let __col = cell.col - 1
                let _prop = __row + ':' + __col

                if (cell.isMerged) {
                  let __merge = merges[_prop]
                  if (__merge) {
                    sheet_data[__merge.r][__merge.c] = ___vl
                    let ___cell = worksheet.getRow(__merge.r + 1).getCell(__merge.c + 1)
                    let _s_obj = getStyle(___cell)
                    let _e_obj = getStyle(cell)
                    if (_s_obj.border || _e_obj.border) {
                      _s_obj.border = {
                        ..._e_obj.border,
                        ..._s_obj.border
                      }
                    }
                    if (___cell.type === 5) {
                      _s_obj.link = ___cell.value.hyperlink
                    }
                    if (Object.keys(_s_obj).length > 0) {
                      styles_data[__merge.r][__merge.c] = _s_obj
                    }
                  }
                  ___vl = ''
                } else {
                  _obj = getStyle(cell)
                }

                sheet_data[_row_][_col_] = ___vl
                if (_obj) {
                  if (cell.type === 5) {
                    _obj.link = cell.value.hyperlink
                  }
                  if (Object.keys(_obj).length > 0) {
                    styles_data[_row_][_col_] = _obj
                  }
                }
              });
            });

            if (worksheet._media && worksheet._media.length > 0) {
              for (let i = 0; i < worksheet._media.length; i++) {
                let _media = worksheet._media[i]
                let mediaObj = {
                  id: _media.imageId,
                  type: _media.type,
                  range: {
                    r: _media.range.tl.nativeRow,
                    c: _media.range.tl.nativeCol,
                    er: _media.range.br.nativeRow,
                    ec: _media.range.br.nativeCol,
                    editAs: _media.range.br.editAs,
                    ext: _media.range.br.ext,
                    hyperlinks: _media.range.br.hyperlinks
                  }
                }
                setArray(styles_data, mediaObj.range.er, mediaObj.range.ec, {})
                setArray(styles_data, mediaObj.range.r, mediaObj.range.c, {})
                setArray(sheet_data, mediaObj.range.er, mediaObj.range.ec, null)
                setArray(sheet_data, mediaObj.range.r, mediaObj.range.c, null)
                styles_data[mediaObj.range.er][mediaObj.range.ec].media = i + ''
                styles_data[mediaObj.range.r][mediaObj.range.c].media = i + ''
                aSheet.media.push(mediaObj)
              }
            }

            aSheet.data = sheet_data
            aSheet.styles = styles_data
            aSheet.gridRowHeight = gridRowHeight
            aSheet.gridColWidth = gridColWidth
            sheets.push(aSheet)
          })

          if (workbook.media && workbook.media.length > 0) {
            for (let i = 0; i < workbook.media.length; i++) {
              let imgData = workbook.media[i]
              let _blob = new Blob([imgData.buffer], { type: imgData.type + '/' + imgData.extension })
              let _url = (URL || webkitURL).createObjectURL(_blob);
              workbook.media[i].buffer = _url
              urlMap.push(_url)
            }
          }

          resolve({
            sheets,
            workData: {
              media: workbook.media,
              views: workbook.views
            }
          })
        })
        .catch((err) => {
          reject(err)
        })
    }
  })
}

export function clearUrl() {
  for (let i = urlMap.length - 1; i >= 0; i--) {
    (URL || webkitURL).revokeObjectURL(urlMap[i]);
    urlMap.splice(i, 1)
  }
}
