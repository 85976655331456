import Vue from 'vue'
import CollectionSuccess from "@/components/Dialog/CollectionSuccess.vue";
import CancelCollectBox from "@/views/Account/compoents/CancelCollectBox.vue";
import { addCollect, delCollect } from "@/utils/apiList/subscribe";

function createDiv(id) {
    let isExit = document.querySelector('#' + id)
    if (!isExit) {
        let isExit = document.createElement('div')
        isExit.id = id
        document.body.appendChild(isExit)
    }
    return isExit
}

function createModel(id) {
    createDiv(id)
    let box = id === 'collSuc' ? CollectionSuccess : CancelCollectBox
    const dialogModel = Vue.extend(box)
    return new dialogModel().$mount('#' + id)
}

export function handleCancelCollect() {
    return new Promise((resolve) => {
        let dia = createModel('collCancel')
        dia.isShowModel = true
        let state = ''
        dia.$watch('isShowModel', (val) => {
            if (!val && !state) {
                closeDia(dia)
                resolve('no')
            }
        })
        dia.$on('likeChange', () => {
            closeDia(dia)
            state = 'yes'
            dia.isShowModel = false
            resolve('yes')
        })
    })
}

function closeDia(dia) {
    setTimeout(() => {
        dia.$destroy()
    }, 600)
}

export async function handleCollect(args) {
    // args.action 1 收藏 0 取消
    let result = {
        state: false,
        msg: ''
    }
    if (args.action === '0') {
        let state = await handleCancelCollect()
        if (state === 'no') return result
    }
    let res, key, msg
    if (args.type === 'file') {
        key = 'file_id'
        msg = '收藏成功 请前往收藏-收藏文件查看'
    } else {
        key = 'gathers_id'
        msg = '收藏成功 请前往收藏-收藏合集查看'
    }
    try {
        if (args.action === '1') {
            res = await addCollect({
                [key]: args.id
            })
        } else {
            key += 's'
            res = await delCollect({
                [key]: args.id
            })
        }
        if (res.status == '1') {
            result.state = true
            if (args.action === '1') {
                let dia = createModel('collSuc')
                dia.isShow = true
                dia.content = msg
                dia.$on('handleClose', () => {
                    dia.isShow = false
                    closeDia(dia)
                })
            }
        } else {
            result.msg = res.msg
        }
    } catch (e) {}
    return result
}
