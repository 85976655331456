<template>
  <div class="app-container">
    <H5 v-if="isMobile" />
    <PC v-else />
  </div>
</template>

<script>
import PC from "./PC.vue";
import H5 from "./H5.vue";
export default {
  components: {
    PC,
    H5
  },
  data(){
    return {
      isMobile: false
    }
  },
  created(){
    const ua = navigator.userAgent;
    this.isMobile = (!(/(iPad).*OS\s([\d_]+)/).test(ua) && /(iPhone\sOS)\s([\d_]+)/.test(ua)) || /(Android)\s+([\d.]+)/.test(ua);
  },
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
